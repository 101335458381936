import React from 'react'

const LayoutContext = React.createContext()

class LayoutProvider extends React.Component {
  constructor() {
    super()

    this.state = {
      header: true,
      footer: true,
    }

    this._setLayout = this._setLayout.bind(this)
  }

  _setLayout(settings) {
    this.setState({
      ...settings,
    })
  }

  render() {
    const { header, footer } = this.state

    return (
      <LayoutContext.Provider
        value={{
          header: header,
          footer: footer,
          setLayout: this._setLayout,
        }}
      >
        {this.props.children}
      </LayoutContext.Provider>
    )
  }
}

const LayoutConsumer = LayoutContext.Consumer

export { LayoutProvider, LayoutConsumer }
