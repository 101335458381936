import React, { Fragment } from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import DesktopContainer from 'layouts/DesktopContainer'
import Heading from 'components.v2/Heading'
import PageHeader from 'layouts/PageHeader'

import SearchInput from './SearchInput'
import TrendsSection from './TrendsSection'
import SuggestionSection from './SuggestionSection'
import withContainer from './SearchOverlayContainer'
import NoMatch from 'components.v2/NoMatch'
import SearchCuisineGrid from './SearchCuisineGrid'

const SearchOverlay = ({ sites, menus, hasQuery, noMatch, disallowHide }) => (
  <Container>
    <PageHeader />
    <DesktopContainer background="gray7">
      <SearchInput className="xl:py-30" disallowHide={disallowHide} />
      <TrendsSection />
      {noMatch && <NoMatch className="mt-80" />}
    </DesktopContainer>

    {!noMatch &&
      (hasQuery ? (
        <Fragment>
          <SuggestionSection className="mb-30" heading="Places" items={sites} />
          <SuggestionSection heading="On the menu" items={menus} />
        </Fragment>
      ) : (
        <DesktopContainer background="white">
          <section className="px-15 py-20 xl:px-0 xl:py-30">
            <Heading level={2}>Fancy something else?</Heading>
            <SearchCuisineGrid className="pt-20 xl:pt-30" />
          </section>
        </DesktopContainer>
      ))}
  </Container>
)

SearchOverlay.propTypes = {
  sites: PT.array.isRequired,
  menus: PT.array.isRequired,
  hasQuery: PT.bool.isRequired,
  noMatch: PT.bool.isRequired,
  disallowHide: PT.bool.isRequired,
}

export default withContainer(SearchOverlay)

const Container = styled.article`
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding-bottom: 60px;
  background-color: ${p => p.theme.colors.gray7};
`
