import { combineReducers } from 'redux'
import { handleActions, createAction } from 'redux-actions'
import networkReducerFactory from 'utils/networkReducerFactory'

const PREFIX = 'unicorn/smsv/'

/* Action Creators + Factory Reducers */
const { requestSendCode, resetSendCodeError, sendCode } = networkReducerFactory(
  PREFIX,
  'SEND_CODE',
)
const { requestVerify, resetVerifyError, verify } = networkReducerFactory(
  PREFIX,
  'VERIFY',
)
const { requestResendCode, resendCode } = networkReducerFactory(
  PREFIX,
  'RESEND_CODE',
)

// listened by saga only
export const requestVerifyIfNeeded = createAction(
  `${PREFIX}VERIFY_IF_NEEDED`,
  () => null,
  x => x,
)

export const setMobile = createAction(`${PREFIX}SET_MOBILE`)
export {
  requestSendCode,
  requestVerify,
  resetSendCodeError,
  resetVerifyError,
  requestResendCode,
}

/* Mobile Reducer */
const mobileInitState = null
const mobileReducer = handleActions(
  {
    [setMobile]: (state, { payload }) => payload,
    UPDATE_PROFILE_SUCCESS: (state, { customer }) => customer.mobile || null,
  },
  mobileInitState,
)

/* Main Reducer */
export default combineReducers({
  mobile: mobileReducer,
  sendCode,
  verify,
  resendCode,
})

/* Selectors */
export const selectMobile = state => state.smsv.mobile
export const selectSendCode = state => state.smsv.sendCode
export const selectVerify = state => state.smsv.verify
export const selectResendCode = state => state.smsv.resendCode
