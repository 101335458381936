import { put, select, take, race } from 'redux-saga/effects'
import { takeFirst } from 'utils/sagaHelpers'
import * as modals from 'store/modals'
import { smsv as smsvApi, getErrorMessage } from 'api'

import {
  fetchProfile,
  selectIsLoading,
  selectProfile,
} from 'auth/modules/profile'

import {
  requestSendCode,
  requestVerify,
  requestVerifyIfNeeded,
  requestResendCode,
  selectMobile,
  selectSendCode,
  selectVerify,
  setMobile,
} from './smsv'

const { SMS_SEND_CODE, SMS_VERIFY } = modals.types

export default [
  takeFirst(String(requestVerifyIfNeeded), function* verifyIfNeededTask({
    meta: onFail = () => {},
  }) {
    /* Make sure profile has loaded before proceeding */
    const hasProfile = Object.keys(yield select(selectProfile)).length

    let isProfileLoading = yield select(selectIsLoading)
    if (!hasProfile && !isProfileLoading) yield put(fetchProfile())

    isProfileLoading = yield select(selectIsLoading)
    if (isProfileLoading) {
      const { rejected } = yield race({
        fulfilled: take(String(fetchProfile.fulfilled)),
        rejected: take(String(fetchProfile.rejected)),
      })

      if (rejected) {
        onFail(rejected)
        return
      }
    }
    /* End of "Make sure profile has loaded..." */

    let isVerified = (yield select(selectVerify)).data
    let isSMSVModalOpen = [SMS_SEND_CODE, SMS_VERIFY].includes(
      yield select(modals.selectVisibleModal),
    )
    const mobile = yield select(selectMobile)

    if (isVerified || isSMSVModalOpen) {
      return
    }

    yield put(modals.show(SMS_SEND_CODE))

    if (mobile) yield put(requestSendCode())

    /**
     * If we have onFail listener, let's wait until the modal closes/changes to non-SMSV,
     * then call the listener if we indeed failed
     */
    if (onFail) {
      while (true) {
        const { show, hide } = yield race({
          show: take(modals.show),
          hide: take(modals.hide),
        })

        if (hide) {
          break
        }

        isSMSVModalOpen = [SMS_SEND_CODE, SMS_VERIFY].includes(
          show.payload.visible,
        )

        if (!isSMSVModalOpen) {
          break
        }
      }

      isVerified = (yield select(selectVerify)).data

      if (!isVerified) {
        onFail()
      }
      /* End of "If we have onFail..." */
    }
  }),
  takeFirst(String(requestSendCode), function* sendCodeTask({ payload, meta }) {
    try {
      const mobile = payload || (yield select(selectMobile))
      const verifId = yield smsvApi
        .sendCode({ mobile })
        .then(r => r.data.verificationId)

      yield put(requestSendCode.fulfilled({ payload: verifId }))
      yield put(setMobile(mobile))
      yield put(modals.show(SMS_VERIFY))
      meta.resolve()
    } catch (error) {
      yield put(requestSendCode.rejected({ payload: getErrorMessage(error) }))
      meta.reject(error)
    }
  }),
  takeFirst(String(requestResendCode), function* sendCodeTask({ meta }) {
    try {
      const mobile = yield select(selectMobile)
      const { data: oldVerifId } = yield select(selectSendCode)
      const verifId = yield smsvApi
        .sendCode({ mobile, resendId: oldVerifId })
        .then(r => r.data.verificationId)

      yield put(requestSendCode.fulfilled({ payload: verifId }))
      yield put(requestResendCode.fulfilled())
      meta.resolve()
    } catch (error) {
      yield put(requestResendCode.rejected({ payload: getErrorMessage(error) }))
      meta.reject(error)
    }
  }),
  takeFirst(String(requestVerify), function* verifyTask({
    payload: verifCode,
    meta,
  }) {
    try {
      const { data: verifId } = yield select(selectSendCode)
      const isVerified = !!(yield smsvApi.verify(verifCode, verifId))

      yield put(requestVerify.fulfilled({ payload: isVerified }))
      yield put(modals.hide())
      meta.resolve()
    } catch (error) {
      yield put(requestVerify.rejected({ payload: getErrorMessage(error) }))
      meta.reject(error)
    }
  }),
]
