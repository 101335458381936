import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { toRenderProps } from 'recompose'
import get from 'lodash/get'
import Cookies from 'js-cookie'

import { selectHasRehydrated } from 'store/rehydration'
import { AuthConsumer, AuthProvider } from 'contexts/auth-context'
import { LoadingErrorScreen } from 'components.v2/Loading/styled'
import Spinner from 'components.v2/Spinner'
import { getVenueMainPageUrl } from './whiteLabel'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'
import { isMultiVendor } from 'utils/multiVendor'

const RedirectComponent = ({ to = '/404', route }) => {
  if (get(route, 'location.pathname') && isMultiVendor()) {
    // Expires after 5 minutes
    Cookies.set(COOKIE_REDIRECT_PATH, get(route, 'location.pathname'), {
      expires: new Date(Date.now() + 1 * (5 * 60 * 1000)),
    })
  }

  return <Redirect to={to} />
}

const AuthRouteWithSubs = route => {
  return (
    <AuthConsumer>
      {({ isAuthenticated }) => (
        <Route
          path={route.path}
          render={props =>
            isAuthenticated === true ? (
              <route.component {...props} routes={route.routes} />
            ) : (
              <RedirectComponent to="/" route={route} />
            )
          }
        />
      )}
    </AuthConsumer>
  )
}

const AuthRoute = route => {
  return (
    <AuthConsumer>
      {({ isAuthenticated }) => (
        <Route
          path={route.path}
          render={props =>
            isAuthenticated === true ? (
              <Route {...props} {...route} />
            ) : (
              <RedirectComponent to={getVenueMainPageUrl()} route={route} />
            )
          }
        />
      )}
    </AuthConsumer>
  )
}

const withSubRoutes = route => Array.isArray(route.routes) && route.component

const RehydrationState = toRenderProps(
  connect(state => ({ hasRehydrated: selectHasRehydrated(state) })),
)

const Loader = props => {
  return (
    <LoadingErrorScreen {...props}>
      <Spinner className="mx-auto" width={70} height={70} thickness={5} />
    </LoadingErrorScreen>
  )
}

const createRouteKey = (route, i) => {
  return Array.isArray(route.path) ? route.path.join(',') : `${route.path}-${i}`
}

/**
 * Render routes from array.
 *
 * @return {ReactDOM}
 */
export default (routes = []) => {
  return (
    <AuthProvider>
      <RehydrationState>
        {({ hasRehydrated }) => (
          <Switch>
            {routes.map(({ requireAuth, ...route }, i) => {
              const withSubs = withSubRoutes(route)
              const routeKey = createRouteKey(route, i)

              if (requireAuth && withSubs) {
                return hasRehydrated ? (
                  <AuthRouteWithSubs key={routeKey} {...route} />
                ) : (
                  <Loader key={routeKey} />
                )
              } else if (requireAuth) {
                return hasRehydrated ? (
                  <AuthRoute key={routeKey} {...route} />
                ) : (
                  <Loader key={routeKey} />
                )
              } else if (withSubs) {
                return (
                  <Route
                    key={routeKey}
                    path={route.path}
                    render={props => (
                      <route.component {...props} routes={route.routes} />
                    )}
                  />
                )
              } else {
                return <Route key={routeKey} {...route} />
              }
            })}
          </Switch>
        )}
      </RehydrationState>
    </AuthProvider>
  )
}
