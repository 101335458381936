import React, { useContext, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PT from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { show, hide, types } from 'store/modals'
import LoginForm from 'common/auth/LoginFormV2'
import StylableModal from 'components.v2/Modal/StylableModal'
import { RegisterFormV2 as RegisterForm } from 'common/auth/RegisterForm'
import titleCase from 'utils/titleCase'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import { ConfigContext } from 'contexts/config-context'
import GoogleLogin from './GoogleLoginV2'
import Icon from 'components.v2/Icon/IconV2'
import SocialButton from './SocialButtonV2'
import media from 'utils/media'
import BaseButton from 'components.v2/Button'
import noScroll from 'no-scroll'

const SUCCESS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  REGISTER_FORM: 'register-form',
}

const withContainer = connect(null, { show, hide })

const withGuestOrder = connect(state => {
  return {
    isGuestCheckout: selectGuestCheckoutHasEnabled(state),
  }
})

const ModalCmpt = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 470px;
    width: ${p => (p.small ? 'calc(100vw - 60px)' : 'calc(100vw - 30px)')};
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
    width: 100%;
    overflow-y: hidden;
    ${media.phone`
      width: calc(100% - 30px);
    `}
  }

  &__content.LOGIN_V2,
  &__content.REGISTER_V2 {
    ${media.phone`
      .LOGIN_V2,
      .REGISTER_V2 {
        height: 100%;
        border-radius: 0px;
      }
    `}

    ${media.phone`
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 0px;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const GuestCheckout = compose(withGuestOrder)(
  ({ hasGuestCheckoutEnabled, show }) => {
    const config = useContext(ConfigContext)

    const renderGuestCheckoutUi = () =>
      hasGuestCheckoutEnabled && get(config, 'features.guest_checkout.enabled')

    const handleShowGuestCheckout = () => {
      show(types.GUEST_V2, {
        ...(hasGuestCheckoutEnabled && {
          hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
        }),
      })
    }

    return (
      <Fragment>
        {renderGuestCheckoutUi() ? (
          <GuestButton className="w-full" onClick={handleShowGuestCheckout}>
            Continue as a guest
          </GuestButton>
        ) : (
          <div />
        )}
      </Fragment>
    )
  },
)

class AuthModal extends React.Component {
  handleSuccess = registrationType => () => {
    const { type, hide, trackEvent, handleSuccess } = this.props

    noScroll.off()
    hide()

    if (type !== types.REGISTER) return

    if (registrationType === SUCCESS.FACEBOOK) {
      trackEvent(events['SIGNUP_CLICK_FACEBOOK'])
    }

    if (registrationType === SUCCESS.GOOGLE) {
      trackEvent(events['SIGNUP_CLICK_GOOGLE'])
    }

    if (registrationType === SUCCESS.REGISTER_FORM) {
      trackEvent(events['SIGNUP_CLICK_EMAIL'])
    }
    handleSuccess && handleSuccess()
  }

  handleRequestCLose = () => {
    if (this.props.disableRequestCLose !== true) {
      this.props.hide()
      noScroll.off()
    }
  }

  render() {
    const {
      type,
      show,
      hide,
      disableRequestCLose,
      email,
      hasGuestCheckoutEnabled,
      handleSuccess,
    } = this.props

    noScroll.on()

    const handleSuccessLoginForm = () => {
      handleSuccess && handleSuccess()
      noScroll.off()
      hide()
    }

    return (
      <ModalCmpt
        isOpen
        contentLabel={titleCase(type)}
        onRequestClose={this.handleRequestCLose}
        noCloseButton
        prependClassName={type}
      >
        <Container className={type}>
          {{
            [types.AUTH]: () => (
              <div className="w-full">
                <Title className="auth">Continue with an account</Title>
                <ButtonSection className="flex">
                  <SocialButton
                    onSuccess={this.handleSuccess(SUCCESS.FACEBOOK)}
                    provider="facebook"
                  />
                  <GoogleLogin
                    onGoogleSignIn={this.handleSuccess(SUCCESS.GOOGLE)}
                  />
                  <ButtonIcon
                    onClick={() => {
                      show(types.LOGIN_V2, {
                        ...(disableRequestCLose === true && {
                          disableRequestCLose: disableRequestCLose,
                        }),
                        ...(email && {
                          email: email,
                        }),
                        ...(hasGuestCheckoutEnabled && {
                          hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
                        }),
                        handleSuccess,
                      })
                    }}
                  >
                    <Icon src="GuestEmail.svg" />
                  </ButtonIcon>
                </ButtonSection>
                {hasGuestCheckoutEnabled && (
                  <GuestCheckout hasGuestCheckoutEnabled show={show} />
                )}
              </div>
            ),
            [types.REGISTER_V2]: () => (
              <RegisterForm
                onSuccess={this.handleSuccess(SUCCESS.REGISTER_FORM)}
                className="w-full"
                email={email}
                show={show}
              />
            ),
            [types.LOGIN_V2]: () => (
              <LoginForm
                show={show}
                onSuccess={handleSuccessLoginForm}
                className="w-full"
                email={email}
                hasGuestCheckoutEnabled={hasGuestCheckoutEnabled}
              />
            ),
          }[type]()}
        </Container>
      </ModalCmpt>
    )
  }
}

AuthModal.propTypes = {
  type: PT.oneOf(['LOGIN_V2', 'REGISTER_V2', 'AUTH', 'GUEST_V2']),
  disableRequestCLose: PT.bool,
  hasGuestCheckoutEnabled: PT.bool,
  email: PT.string,
}

export const Container = styled.div`
  width: 470px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
  border-radius: 12px;

  padding: 40px 24px;

  overflow-y: auto;

  ${media.phone`
    width: 100%;
    text-align: center;
  `}

  .guest {
    ${media.phone`
      width: 100vw;
    `}
  }
`

export const Title = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.phone`
    &.auth {
      font-size: 18px;
    }

  `}
`

export const LoginFormHeader = styled(Title)`
  ${media.phone`
    flex-direction: column;
    gap: 32px;
    & > div {
      font-size: 24px;
      text-align: left;
    }
  `}
`

const ButtonSection = styled.div`
  margin-top: 32px;
  justify-content: center;
  gap: 16px;
`

export const ButtonIcon = styled.button`
  width: 88px;
  height: 56px;

  border: 1px solid #e6e6e6;
  border-radius: 12px;
  background: linear-gradient(0deg, #fafafa, #fafafa);

  &:active {
    background: #e6e6e6;
  }

  &:hover {
    background: rgb(0, 0, 0, 0.05);
  }
`

export const BackButton = styled.div`
  &:active {
    border: none;
  }

  &:hover {
    cursor: pointer;
  }
`

const GuestButton = styled(BaseButton)`
  background: #f26722;
  border-radius: 8px;
  height: 56px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  ${media.phone`
    font-size: 14px;
    line-height: 20px;
  `}

  color: #ffffff;

  margin-top: 20px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

export default compose(withContainer, withAnalyticsHandler)(AuthModal)
