import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import notif from 'services/notification'
import * as modals from 'store/modals'
import { getErrorMessage } from 'api'

import {
  requestSendCode,
  resetSendCodeError,
  selectMobile,
  selectSendCode,
} from 'store/smsv'

import {
  withFormValidator,
  isRequired,
  isMobileNum,
  composeValidators as composeV,
} from 'utils/validator'

export default compose(
  connect(
    state => ({
      mobile: selectMobile(state),
      sendCodeState: selectSendCode(state),
    }),
    {
      requestSendCode,
      show: modals.show,
      handleHide: modals.hide,
      clearAsyncError: resetSendCodeError,
    },
  ),

  withProps(({ show, requestSendCode, mobile }) => ({
    initValues: { mobile },
    handleBack:
      mobile && (() => show(modals.types.SMS_VERIFY, { shouldKeepData: true })),
    onSubmit: fields => {
      requestSendCode(fields.mobile).catch(error => {
        notif.error({
          title: 'Failed to send SMS verification code',
          message: getErrorMessage(error),
        })
      })
    },
    onSubmitFail: () =>
      notif.error({
        title: 'Failed to send SMS verification code',
        message: 'Please check the form errors',
      }),
  })),

  withFormValidator({
    mobile: composeV(isRequired, isMobileNum)('Number'),
  }),
)
