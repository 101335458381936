import React from 'react'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import PT from 'prop-types'

import { getError } from 'utils/get-message'
import CardAdderModal from 'containers/CardAdder/CardAdderModal'
import SourcesList from './SourcesList'
import SourcesListItem from './SourcesListItem'
import withNotifications from 'enhancers/withNotifications'

import {
  selectPaymentSources,
  selectPaymentSourcesIsLoaded,
  selectPaymentSourcesIsLoading,
  updatePaymentSource,
  removePaymentSource,
} from 'store/payment'
import { FZ_CLICK_TO_PAY_HOSTED_PAYMENT, PAYMENT_SOURCE_TYPE, PAYMENT_SOURCE_NAME } from 'store/payment/const'
import { setClickToPayAsPaymentSource } from 'store/payment'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'

class Sources extends React.Component {
  static propTypes = {
    isLoading: PT.bool,
    isLoaded: PT.bool,
    sources: PT.array,
  }

  state = {
    isCardModalOpen: false,
  }

  onPrimary = ({ id }) => async () => {
    try {
      const { trackEvent, setClickToPayAsPaymentSource } = this.props
      if (id === FZ_CLICK_TO_PAY_HOSTED_PAYMENT) {
        trackEvent({
          ...events.SELECT_PAYMENT_SOURCE,
          type: PAYMENT_SOURCE_TYPE.CLICK_TO_PAY,
          name: PAYMENT_SOURCE_NAME.CLICK_TO_PAY
        })
        setClickToPayAsPaymentSource.bind(null,FZ_CLICK_TO_PAY_HOSTED_PAYMENT)()
      } else {
        await this.props.updatePaymentSource({
          paymentSourceId: id,
        })
      }
      this.props.notify({
        type: 'success',
        message: 'Payment source was successfully updated.',
      })
    } catch (err) {
      this.props.notify({
        type: 'error',
        message: getError(err, 'There was a problem updating payment source.'),
      })
    }
  }

  onDelete = ({ id }) => async () => {
    try {

      await this.props.removePaymentSource(id)
      
      this.props.notify({
        type: 'success',
        message: 'Payment source was successfully deleted.',
      })
    } catch (err) {
      this.props.notify({
        type: 'error',
        message: getError(err, 'There was a problem deleting payment source.'),
      })
    }
  }

  renderSource = details => {
    return (
      <SourcesListItem
        key={details.id}
        {...details}
        onPrimary={this.onPrimary(details)}
        onDelete={this.onDelete(details)}
      />
    )
  }

  get cardModal() {
    return (
      this.state.isCardModalOpen && (
        <CardAdderModal onClose={this.toggleCardModal} isOpen />
      )
    )
  }

  toggleCardModal = () => {
    this.setState({
      isCardModalOpen: !this.state.isCardModalOpen,
    })
  }

  render() {
    const { sources } = this.props

    return (
      <SourcesList onAddSource={this.props.onAddSource ? this.props.onAddSource : this.toggleCardModal}>
        {sources
          .sort((a, b) => (a.type === FZ_CLICK_TO_PAY_HOSTED_PAYMENT ? -1 : b.type === FZ_CLICK_TO_PAY_HOSTED_PAYMENT ? 1 : 0))
          .map(this.renderSource)}
        {this.cardModal}
      </SourcesList>
    )
  }
}

const withRedux = connect(
  createSelector(
    selectPaymentSources,
    selectPaymentSourcesIsLoaded,
    selectPaymentSourcesIsLoading,
    (sources, isLoaded, isLoading) => {
      return { sources, isLoading, isLoaded }
    },
  ),
  { updatePaymentSource, removePaymentSource, setClickToPayAsPaymentSource },
)

export default compose(withRedux, withNotifications, withAnalyticsHandler)(Sources)
