import parsePhoneNumber from 'libphonenumber-js'
export const emailRegex = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/ // eslint-disable-line no-useless-escape
const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
const applyMsg = (fn, msg) => (...args) => (fn(...args) ? null : msg)

/**
 * These functions returns null if valid, message if not
 */

export const isEmail = fieldName =>
  applyMsg(
    x => emailRegex.test(x.toLowerCase()),
    fieldName ? `${fieldName} must be a valid email` : 'Invalid email',
  )

export const optionalEmail = fieldName =>
  applyMsg(
    x => (x ? emailRegex.test(x.toLowerCase()) : true),
    fieldName ? `${fieldName} must be a valid email` : 'Invalid email',
  )

export const isRequired = fieldName =>
  applyMsg(
    x => !!x,
    fieldName ? `${fieldName} is required` : 'Missing required fields',
  )

export const isName = fieldName =>
  applyMsg(
    x => nameRegex.test(x),
    fieldName ? `${fieldName} must be a valid name` : 'Invalid name',
  )

export const isMobileNum = fieldName =>
  applyMsg(
    x => {
      const n = parsePhoneNumber(x, {
        defaultCountry: 'AU',
      })

      if (n && !n.isValid() && !/^\+/.test(x)) {
        const alt = parsePhoneNumber(`+${x}`, {
          defaultCountry: 'AU',
        })

        return alt && alt.isValid()
      }

      return !!n && n.isValid()
    },
    fieldName
      ? `${fieldName} must be a valid mobile number`
      : 'Invalid mobile number',
  )

// watches
// sameWith

/**
 * Compose functions to a validator that calls the functions (left to right) and returns the first
 * error if any, otherwise returns null
 *
 * ```
 * const f = composeValidators(isRequired, isEmail)('Email')
 * f('') // 'Email is required'
 * f('asdf') // 'Invalid email'
 * f('a@b.c') // null
 * ```
 */
export const composeValidators = (head, ...tail) => fieldName => (...args) =>
  head(fieldName)(...args) ||
  (tail.length ? composeValidators(...tail)(fieldName)(...args) : null)
