import Loadable from 'react-loadable'
import loading from 'components.v2/Loading/Loading'

import Error404 from 'pages/errors/Error404'
import Error500 from 'pages/errors/Error500'

export default [
  {
    key: 'home',
    path: '/',
    exact: true,
    modules: ['homepage'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "homepage" */ 'pages/homepage'),
      loading,
    }),
  },
  {
    key: 'venue',
    path: '/restaurant/:id/:name',
    exact: true,
    modules: ['venue'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "venue" */ 'pages/vendor'),
      loading,
    }),
  },
  {
    key: 'order',
    path: '/order/:id',
    modules: ['order'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "order" */ 'pages/order'),
      loading,
    }),
  },
  {
    key: 'precinct',
    path: '/precinct/:place',
    modules: ['precinct'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "precinct" */ 'pages/precinct'),
      loading,
    }),
  },
  {
    key: 'account.points',
    path: '/account/points',
    modules: ['account.points'],
    requireAuth: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "account.points" */ 'pages/account/points'),
      loading,
    }),
  },
  {
    key: 'account',
    path: '/account',
    modules: ['account'],
    requireAuth: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "account" */ 'pages/account'),
      loading,
    }),
  },
  {
    key: 'redeem-referral-code',
    path: '/register/:referralCode',
    modules: ['redeem-referral-code'],
    exact: true,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "redeem-referral-code" */ 'pages/redeem-referral-code'
        ),
      loading,
    }),
  },
  {
    key: 'logout',
    path: '/logout',
    modules: ['logout'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "logout" */ 'auth/Logout'),
      loading,
    }),
  },
  {
    key: 'reset-password',
    path: '/reset-password/:token',
    exact: true,
    modules: ['reset-password'],
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "reset-password" */ 'pages/reset-password'),
      loading,
    }),
  },
  {
    key: 'reset-account',
    path: '/reset-account',
    exact: true,
    modules: ['reset-account'],
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "reset-account" */ 'pages/reset-account'),
      loading,
    }),
  },
  {
    key: 'confirm-email',
    path: '/confirm-email/:token',
    exact: true,
    modules: ['confirm-email'],
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "confirm-email" */ 'pages/confirm-email'),
      loading,
    }),
  },
  {
    key: 'suburbs',
    path: '/:city(sydney|melbourne|brisbane|perth)',
    exact: true,
    modules: ['suburbs'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "suburbs" */ 'pages/suburbs'),
      loading,
    }),
  },
  {
    key: 'suburb',
    path: '/:city(sydney|melbourne|brisbane|perth)/:suburb',
    exact: true,
    modules: ['suburbs'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "suburb" */ 'pages/suburb'),
      loading,
    }),
  },
  {
    key: 'cuisines',
    path: '/cuisines',
    exact: true,
    modules: ['cuisines'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "cuisines" */ 'pages/cuisines'),
      loading,
    }),
  },
  {
    key: 'cuisines',
    path: '/cuisines/:cuisine',
    exact: true,
    modules: ['cuisine'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "cuisine" */ 'pages/cuisine'),
      loading,
    }),
  },
  {
    key: 'search',
    path: ['/search', '/:city/:suburb/:query'],
    exact: true,
    modules: ['search'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "search" */ 'pages/search'),
      loading,
    }),
  },
  {
    key: 'party',
    path: '/party/:partyCode',
    exact: true,
    modules: ['party'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "party" */ 'pages/party'),
      loading,
    }),
  },
  {
    key: 'payment',
    path: '/payment/verify-clicktopay',
    exact: true,
    modules: ['payment'],
    component: Loadable({
      loader: () => import(/* webpackChunkName: "party" */ 'pages/payment'),
      loading,
    }),
  },
  {
    key: 'tokenconnect',
    path: '/tokenconnect',
    exact: true,
    modules: ['tokenconnect'],
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "party" */ 'pages/tokenconnect'),
      loading,
    }),
  },
  {
    key: '500',
    path: '/500',
    exact: true,
    modules: ['500'],
    component: Error500,
  },
  {
    key: '404',
    path: '*',
    exact: true,
    modules: ['404'],
    component: Error404,
  },
]
