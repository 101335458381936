import React, { PureComponent } from 'react'

import Button from 'components.v2/Button'
import Modal from 'components.v2/Modal'
import PT from 'prop-types'
import { Heading, Text } from './styled'

export default class extends PureComponent {
  static propTypes = {
    cancelText: PT.string,
    confirmText: PT.string,
    description: PT.string,
    isLoading: PT.bool,
    onCancel: PT.func,
    onConfirm: PT.func,
    title: PT.string,
  }

  static defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    description: 'Are you you sure you want to continue?',
    isDisabled: false,
    isLoading: false,
    onCancel: () => {},
    onConfirm: () => {},
    title: 'Confirmation',
  }

  onCancel = () => {
    if (!this.props.isDisabled) {
      this.props.onCancel()
    }
  }

  render() {
    const {
      cancelText,
      confirmText,
      description,
      isOpen,
      onConfirm,
      isDisabled,
      isLoading,
      title,
    } = this.props
    /* eslint-disable react/jsx-no-duplicate-props  */
    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Edit password"
        onRequestClose={this.onCancel}
        small
      >
        <div className="text-center pb-15 px-15">
          <Heading className="mt-40">{title}</Heading>
          <Text as="p" className="mt-10 xl:mt-15">
            {description}
          </Text>
          <div className="flex -mx-5 mt-30">
            <div className="w-1/2 px-5">
              <Button
                variant="primary"
                type="button"
                className="w-full"
                large
                largeText
                variant="outline-primary"
                onClick={this.onCancel}
                disabled={isDisabled}
              >
                {cancelText}
              </Button>
            </div>
            <div className="w-1/2 px-5">
              <Button
                variant="primary"
                type="button"
                className="w-full"
                large
                largeText
                onClick={onConfirm}
                loading={isLoading}
                disabled={isDisabled}
              >
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
