import { handleActions } from 'redux-actions'

const SET_GUEST_CHECKOUT = 'unicorn/guest_checkout/SET_GUEST_CHECKOUT'
const UNSET_GUEST_CHECKOUT = 'unicorn/guest_checkout/UNSET_GUEST_CHECKOUT'

export const setGuestCheckoutEmail = ({ ...fields }) => ({
  type: SET_GUEST_CHECKOUT,
  ...fields,
})

export const unsetGuestCheckoutEmail = () => ({ type: UNSET_GUEST_CHECKOUT })

const initialState = {
  customer: {
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
  },
  enabled: false,
  lastGuestEmail: '',
}

export default handleActions(
  {
    [SET_GUEST_CHECKOUT]: (state, { email, firstName, lastName, mobile }) => {
      return {
        ...state,
        customer: {
          email,
          firstName,
          lastName,
          mobile,
        },
        enabled: true,
        lastGuestEmail: email,
      }
    },
    [UNSET_GUEST_CHECKOUT]: state => {
      return {
        ...state,
        customer: {
          email: '',
          firstName: '',
          lastName: '',
          mobile: '',
        },
        enabled: false,
      }
    },
  },
  initialState,
)
