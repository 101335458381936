import React from 'react'

import ErrorScreen from './ErrorScreen'

export default () => (
  <ErrorScreen
    title="Unexpected Error"
    message="Hmm. Something went wrong, please try refreshing the page."
  />
)
