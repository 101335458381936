import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { Link, Button } from 'common/auth/Shared/sharedStyled'
import withContainer from './RegisterFormContainer'
import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import LINKS from 'const/links'

const RegisterForm = ({
  fields,
  isAuthenticating,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  isReferralCodeVisible,
  setIsReferralCodeVisible,
  email,
}) => (
  <form onSubmit={handleSubmit} noValidate className={className}>
    {!!errors.length &&
      errors.map((error, i) => <FormError key={i}>{error}</FormError>)}

    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.firstName.value}
      hasError={!!fields.firstName.error}
      className="block w-full"
      type="text"
      name="firstName"
      placeholder="First name"
    />
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.lastName.value}
      hasError={!!fields.lastName.error}
      className="block w-full mt-10"
      type="text"
      name="lastName"
      placeholder="Last name"
    />
    <Input
      {...(email ? { autoFocus: true } : {})}
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.email.value || email}
      hasError={!!fields.email.error}
      className="block w-full mt-10"
      type="email"
      name="email"
      placeholder="Email"
    />
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.mobile.value}
      hasError={!!fields.mobile.error}
      className="block w-full mt-10"
      type="tel"
      name="mobile"
      placeholder="Mobile"
    />
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.password.value}
      hasError={!!fields.password.error}
      className="block w-full mt-10"
      type="password"
      name="password"
      placeholder="Password"
    />
    {isReferralCodeVisible && (
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.referralCode.value}
        hasError={!!fields.referralCode.error}
        className="block w-full mt-10"
        type="text"
        name="referralCode"
        placeholder="Referral code"
      />
    )}

    <Terms>
      By signing up you agree to our{' '}
      <TermsLink href={LINKS.TERMS} target="_blank" rel="noopener">
        Terms of Service
      </TermsLink>{' '}
      and{' '}
      <TermsLink href={LINKS.PRIVACY} target="_blank" rel="noopener">
        Privacy Policy
      </TermsLink>
    </Terms>

    <div
      className={`flex items-center mt-20 ${
        isReferralCodeVisible ? 'justify-end' : 'justify-between'
      }`}
    >
      {!isReferralCodeVisible && (
        <Link type="button" onClick={() => setIsReferralCodeVisible(true)}>
          Have a referral code?
        </Link>
      )}

      <Button
        variant="primary"
        type="submit"
        disabled={isAuthenticating}
        actionsReversed={actionsReversed}
      >
        {isAuthenticating ? 'Processing…' : 'Sign up'}
      </Button>
    </div>
  </form>
)

RegisterForm.propTypes = {
  ...formPropTypes,
  isReferralCodeVisible: PT.bool.isRequired,
  setIsReferralCodeVisible: PT.func.isRequired,
}

const Terms = styled.small`
  display: block;
  margin-top: 10px;
  font-size: 11px;
  color: ${p => p.theme.colors.gray4};
`

const TermsLink = styled.a`
  color: ${p => p.theme.colors.gray4};
  text-decoration: underline;
`

export default withContainer(RegisterForm)
