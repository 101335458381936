import React from 'react'
import PropTypes from 'prop-types'
import * as svgComponents from './svg'
import styled from 'styled-components'

import addPx from 'utils/addPxIfNumber'

/**
 * This component dynamically loads an svg in the ./svg directory.
 * To allow proper scaling and styling, make sure that the svg file has its
 * width and height attribute set to "1em" (so that it adjusts according to
 * font-size), and that it has a viewBox attribute
 */
const Icon = ({
  type,
  size = '1em',
  fill,
  stroke = 'none',
  strokeWidth = 1,
  ...props
}) => {
  const Svg = svgComponents[type]

  return (
    <Container
      size={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...props}
    >
      <Svg />
    </Container>
  )
}

Icon.propTypes = {
  // Make sure that type prop is a file name of an svg file in ./svg directory
  type: ({ type }) => {
    if (!type) { return new Error('Icon requires a string "type" prop, but got a falsy value') }
    if (!svgComponents[type]) { return new Error(`Icon of type "${type}" does not exist`) }
  },
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => addPx(props.size)};
  height: ${props => addPx(props.size)};
  font-size: ${props => addPx(props.size)};
  width: ${props => addPx(props.size)};
  fill: ${props => props.theme.colors[props.fill] || props.fill || 'currentColor'};
  stroke: ${props => props.stroke};
  stroke-width: ${props => addPx(props.strokeWidth)};
`

export default Icon
