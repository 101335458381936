import React from 'react'
import PT from 'prop-types'

import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import BaseButton from 'components.v2/Button'
import FormError from 'components.v2/FormError'
import Input from 'components.v2/Input'
import withContainer from './GuestCheckoutFormContainer'
import styled from 'styled-components'
import media from 'utils/media'
import get from 'lodash/get'

const Form = styled.form`
  & > div > input {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    background: #f2f2f2;

    border-radius: 8px;

    & :focus {
      border: 1.5px solid #f26722;
    }
    & :not(:focus) {
      border: 1.5px solid #cccccc;
    }
  }

  & > div {
    margin-top: 20px;
  }

  display: flex;
  flex-direction: column;

  margin-top: 32px;
`

const SubmitButton = styled(BaseButton)`
  background: #f26722;
  border-radius: 8px;
  margin-top: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  width: 100%;
  height: 52px;

  ${media.phone`
    position: absolute;
    bottom: 32px;
    left: 24px;
    width: calc(100% - 48px);
  `}

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

const Caption = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px

  display: flex;
  align-items: center;

  color: #999999;
`

const CustomFormError = styled(FormError)`
  font-family: 'Montserrat';
  font-style: normal;
`

const GuestCheckoutForm = ({
  fields,
  isAuthenticating,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  email,
  serviceType,
  buttonAlign,
}) => (
  <div>
    <Form onSubmit={handleSubmit} className={`${className} text-left`}>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.firstName.value}
        hasError={!!fields.firstName.error}
        className="block w-full"
        type="text"
        name="firstName"
        placeholder="First name"
      />

      {get(fields, 'firstName.error') && (
        <CustomFormError>{get(fields, 'firstName.error')}</CustomFormError>
      )}
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.lastName.value}
        hasError={!!fields.lastName.error}
        className="block w-full mt-10"
        type="text"
        name="lastName"
        placeholder="Last name"
      />
      {get(fields, 'lastName.error') && (
        <CustomFormError>{get(fields, 'lastName.error')}</CustomFormError>
      )}
      <Input
        {...(email ? { autoFocus: true } : {})}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.email.value || email}
        hasError={!!fields.email.error}
        className="block w-full mt-10"
        type="email"
        name="email"
        placeholder="Email"
      />
      {get(fields, 'email.error') && (
        <CustomFormError>{get(fields, 'email.error')}</CustomFormError>
      )}
      <Caption>Add your email to get your order receipt</Caption>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.mobile.value}
        hasError={!!fields.mobile.error}
        className="block w-full mt-10"
        type="tel"
        name="mobile"
        placeholder="Mobile"
      />
      {get(fields, 'mobile.error') && (
        <CustomFormError>{get(fields, 'mobile.error')}</CustomFormError>
      )}
      <input
        value={serviceType}
        type="hidden"
        name="serviceType"
        className="hidden"
      />
      <div>
        <SubmitButton
          variant="primary"
          type="submit"
          disabled={isAuthenticating}
          actionsReversed={actionsReversed}
          buttonAlign={buttonAlign}
        >
          {isAuthenticating ? 'Processing…' : 'Review payment'}
        </SubmitButton>
      </div>
    </Form>
  </div>
)

GuestCheckoutForm.propTypes = {
  ...formPropTypes,
  ...{
    serviceType: PT.string,
    buttonAlign: PT.string,
  },
}

export default withContainer(GuestCheckoutForm)
