import styled, { keyframes } from 'styled-components'
import { string, number } from 'prop-types'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const VARIANTS = {
  primary: { sColor: 'primary', pColor: 'gray6' },
  'alt-primary': { sColor: 'white', pColor: 'primaryDarker' },
  'alt-secondary': { sColor: 'white', pColor: 'secondaryDarker' },
  facebook: { sColor: 'facebookDarker', pColor: 'white' },
  google: { sColor: 'googleDarker', pColor: 'white' },
}

// Modified version of https://codesandbox.io/s/2prqo9p5wn
const Spinner = styled.div`
  border-radius: 50%;
  border: ${p => p.sizeMultiplier * p.thickness}px solid
    ${p => p.theme.colors[p.sColor || VARIANTS[p.variant].sColor]};
  border-top: ${p => p.sizeMultiplier * p.thickness}px solid
    ${p => p.theme.colors[p.pColor || VARIANTS[p.variant].pColor]};
  height: ${p => `${p.sizeMultiplier * p.height}px`};
  width: ${p => `${p.sizeMultiplier * p.width}px`};
  animation: ${spin} 1s linear infinite;
`

Spinner.propTypes = {
  width: number,
  height: number,
  thickness: number,
  sColor: string,
  pColor: string,
  variant: string,
  sizeMultiplier: number,
}

Spinner.defaultProps = {
  width: 20,
  height: 20,
  thickness: 3,
  sizeMultiplier: 1,
  variant: 'primary',
}

/** @component */
export default Spinner
