import React from 'react'

import ShadowCard from 'components/ShadowCard'

import { AccountCardInner } from './styled'

const AccountCard = ({ children, ...rest }) => {
  return (
    <ShadowCard>
      <AccountCardInner {...rest}>{children}</AccountCardInner>
    </ShadowCard>
  )
}

export const AccountCardBase = ShadowCard

export default AccountCard
