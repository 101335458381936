import client from 'client'
import { fetchAds } from './ads'
import isPlainObject from 'lodash/isPlainObject'

// data from /search and /venues stores venues in these keys
const VENUE_KEYS = ['venues', 'sites', 'menus']

/**
 * Multiple Venues
 */

const withDiscounts = fetchFn => async ({ shouldMerge, range, ...params }) => {
  const { lat, lng } = params

  if (!lat || !lng) return fetchFn(params)

  const [{ads}, { data, ...response }] = await Promise.all([
    fetchAds({ lat, lng, range }),
    fetchFn(params),
  ])

  const entries = isPlainObject(data) ? Object.entries(data) : []

  const dataWithAds = entries.reduce((acc, [k, v]) => {
    if (!VENUE_KEYS.includes(k)) {
      acc[k] = v
    } else {
      // Merges ad info to venue info if they match
      acc[k] = v.map(venue => ({
        ...ads.find(ad => ad.id === venue.id),
        ...venue,
      }))

      // Push excess/non-matching ads to venues result
      if (shouldMerge)
        ads.forEach(ad => {
          const doesVenuesHaveThisAd = v.find(venue => venue.id === ad.id)
          if (!doesVenuesHaveThisAd) acc[k].push(ad)
        })

      // show venues with promotions first
      acc[k] = acc[k].sort((a, b) =>
        a.discount && b.discount ? 0 : a.discount ? -1 : b.discount ? 1 : 0,
      )
    }

    return acc
  }, {})

  return { ...response, data: dataWithAds }
}

const fetchVenues = params => client(true).get('/venues', { params })

const searchVenues = ({ lat, lng, ...params }) =>
  client(true).get('/search', {
    params: { ...params, lat, lng },
  })

// Smartly uses /venues or /search depending on params
export const smartSearchVenues = ({ query, site, menu, ...passedParams }) => {
  const method = query || site || menu ? searchVenues : fetchVenues
  const params = query
    ? { site: query, menu: query, ...passedParams }
    : { site, menu, ...passedParams }

  return method(params)
}

export const fetchVenuesWithDiscounts = withDiscounts(fetchVenues)
export const smartSearchVenuesWithDiscounts = withDiscounts(smartSearchVenues)

/**
 * Individual Venues
 */

export const fetchVenueDescription = id =>
  client(true)
    .get(`/venues/${id}/metadata`)
    .then(r => r.data)

export const fetchVenueDetails = (id, params = {}) =>
  client(true).get(`/venues/${id}`, { params: { mode: 'full', ...params } })

export const fetchVenueMenu = (id, params) =>
  client(true)
    .get(`/venues/${id}/menus`, { params })
    .then(resp => resp.data.categories)

export const referVenue = params => {
  return client().post('/customers/me/refer/venue', { venue: params })
}

export const getTag = tag =>
  client(true)
    .get(`/venues/tags/${tag}`)
    .then(r => r.data)
