import client from 'client'
import pick from 'lodash/pick'

const FETCH_LOYALTY = 'unicorn/payment/FETCH_LOYALTY'

const initialState = {
  isLoading: false,
  isLoaded: false,
  loyalties: [],
  params: {
    limit: 5,
    offset: 1,
  },
}

const requestLoyalty = ({ limit, offset }) => {
  return client()
    .get(`/customers/me/loyalty?limit=${limit}&offset=${offset}`)
    .then(response => {
      return pick(response.data, ['loyalties', 'loyalty_only', 'nextPage'])
    })
}

export const fetchLoadLoyalty = params => async dispatch => {
  try {
    dispatch({ type: `${FETCH_LOYALTY}_PENDING` })
    const loyalties = await requestLoyalty(params)
    const payload = {
      params,
      ...loyalties,
    }
    dispatch({
      type: `${FETCH_LOYALTY}_FULFILLED`,
      payload,
    })
  } catch (err) {
    dispatch({
      type: `${FETCH_LOYALTY}_REJECTED`,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: 'Unable to load loyalty!',
        },
      },
    })
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case `${FETCH_LOYALTY}_FULFILLED`:
      return Object.assign({}, state, {
        loyalties: [
          ...state.loyalties,
          ...action.payload.loyalties.concat(action.payload.loyalty_only),
        ],
        isLoading: false,
        isLoaded: true,
        params: {
          limit: state.params.limit,
          offset: state.params.offset + 1,
          nextPage: action.payload.nextPage || false,
        },
      })

    case `${FETCH_LOYALTY}_PENDING`:
      return Object.assign({}, state, {
        isLoading: true,
      })
    case `${FETCH_LOYALTY}_REJECTED`:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: false,
      })
    default:
      return state
  }
}
