import React from 'react'
import { connect } from 'react-redux'

import { selectVisibleModal, selectData, types } from 'store/modals'
import WhiteLabelAuth from 'common/auth/WhiteLabelShared/AuthModal'
import ForgotPassword, { ForgotPasswordV2 } from 'common/auth/ForgotPassword'
import VerifyModal from 'common/smsv/VerifyModal'
import SendCodeModal from 'common/smsv/SendCodeModal'
import SearchModal from 'common/SearchOverlay'
import PartyModal from 'common/party'
import AuthV2 from 'common/auth/Shared/AuthModalV2'
import { GuestModalV2 } from 'common/GuestModal'
import CardAdderModal from 'containers/CardAdder/CardAdderModal'
import PaymentSourceModal from 'common/PaymentSourceModal'

const withContainer = connect(state => ({
  visibleModal: selectVisibleModal(state),
  data: selectData(state),
}))

const ModalSwitch = ({ visibleModal, data }) => {
  switch (visibleModal) {
    case types.LOGIN:
    case types.LOGIN_ICON:
    case types.REGISTER:
      return <WhiteLabelAuth type={visibleModal} />
    case types.LOGIN_V2:
    case types.REGISTER_V2:
    case types.AUTH:
      return <AuthV2 type={visibleModal} {...data} />
    case types.GUEST_V2:
      return <GuestModalV2 type={visibleModal} {...data} />
    case types.FORGOT:
    case types.FORGOT_SENT:
      return <ForgotPassword type={visibleModal} />
    case types.FORGOT_V2:
    case types.FORGOT_V2_SENT:
      return <ForgotPasswordV2 type={visibleModal} {...data} />
    case types.SMS_VERIFY:
      return <VerifyModal />
    case types.SMS_SEND_CODE:
      return <SendCodeModal />
    case types.SEARCH:
      return <SearchModal />
    case types.PARTY_NOTIF:
      return <PartyModal {...data} />
    case types.PAYMENT_SOURCE:
      return <PaymentSourceModal />
    case types.CARD_ADDER:
      return <CardAdderModal {...data} />
    default:
      return null
  }
}

export default withContainer(ModalSwitch)
