import client from 'client'

export const fetchOrderParties = params => {
  return client()
    .get('/orderparties', { params })
    .then(resp => resp.data)
}

export const getOrderParty = (orderPartyId, isLoggedIn) => {
  return client(!isLoggedIn)
    .get(`/orderparties/${orderPartyId}`)
    .then(resp => resp.data)
}

export const getOrderPartyStatus = partyCode => {
  return client()
    .get(`/orderparties/status/${partyCode}`)
    .then(resp => resp.data)
}

export const leaveOrderParty = orderPartyId => {
  return client()
    .put(`/orderparties/${orderPartyId}/invites`, { status: 'DECLINED' })
    .then(resp => resp.data)
}

export const joinOrderParty = orderPartyId => {
  return client()
    .post(`/orderparties/${orderPartyId}/members`)
    .then(resp => resp.data)
}

export const updateOrderPartyOrder = (orderPartyId, payload) => {
  return client()
    .put(`/orderparties/${orderPartyId}/order`, payload)
    .then(resp => resp.data)
}

export const deleteOrderParty = orderPartyId => {
  return client()
    .delete(`/orderparties/${orderPartyId}`)
    .then(resp => resp.data)
}

export const createOrderParty = ({
  vendorId,
  customerIds = [],
  inviteMessage,
}) =>
  client().post('/orderparties', {
    vendorId,
    customerIds,
    inviteMessage,
  })

export const checkCustomerOrderParty = customerId =>
  client()
    .get(`/orderparties?customerId=${customerId}`)
    .then(res => res.data)

export default {
  fetchOrderParties,
  getOrderParty,
  leaveOrderParty,
  joinOrderParty,
  deleteOrderParty,
  createOrderParty,
  updateOrderPartyOrder,
  getOrderPartyStatus,
  checkCustomerOrderParty,
}
