import { all } from 'redux-saga/effects'
import venuesSagas from 'pages/search/venues.saga'
import orderSagas from 'pages/order/modules/order.saga'
import multiVendorOrderSagas from 'pages/order/modules/multi.vendor.order.saga'
import cuisinesSagas from './cuisines/cuisines.saga'
import homepageSagas from './homepage/homepage.saga'
import authSagas from 'auth/modules/auth.saga'
import profileSagas from 'auth/modules/profile.saga'
import smsvSagas from './smsv/smsv.saga'
import trendsSagas from './trends/trends.saga'
import suburbsSagas from './suburbs/suburbs.saga'
import orderPartySaga from 'pages/party/orderParty.saga'

const sagas = [
  ...venuesSagas,
  ...cuisinesSagas,
  ...homepageSagas,
  ...orderSagas,
  ...multiVendorOrderSagas,
  ...orderPartySaga,
  ...authSagas,
  ...profileSagas,
  ...smsvSagas,
  ...trendsSagas,
  ...suburbsSagas,
]

export default function* root() {
  yield all(sagas)
}
