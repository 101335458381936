import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import PT from 'prop-types'

import { selectIsResettingPassword, forgotPassword } from 'auth/modules/auth'
import { types, hide, show } from 'store/modals'
import Confirmation from './Confirmation'
import EmailForm from './EmailForm'
import Modal from 'components.v2/Modal'

class ForgotPassword extends PureComponent {
  handleHide = () => {
    const { type, show, hide, isSubmitting } = this.props
    if (isSubmitting) return
    if (type === types.FORGOT) {
      hide()
    } else if (type === types.FORGOT_SENT) {
      show(types.FORGOT)
    } else {
      hide()
    }
  }

  handleSubmit = async ({ email }) => {
    try {
      const { show, forgotPassword } = this.props

      await forgotPassword(email)

      show(types.FORGOT_SENT)
    } catch (err) {
      console.error(err)
    }
  }

  handleLogin = () => this.props.show(types.LOGIN)

  handlePrevious = () => this.props.show(types.FORGOT)

  get modalContent() {
    const { type, isSubmitting } = this.props
    if (type === types.FORGOT) {
      return (
        <EmailForm onSubmit={this.handleSubmit} isSubmitting={isSubmitting} />
      )
    } else if (type === types.FORGOT_SENT) {
      return (
        <Confirmation
          onPrevious={this.handlePrevious}
          onLogin={this.handleLogin}
        />
      )
    }

    return null
  }

  render() {
    return (
      <Modal
        isOpen
        contentLabel="Forgot Password"
        onRequestClose={this.handleHide}
        className="text-center pb-15 px-15"
        small
      >
        {this.modalContent}
      </Modal>
    )
  }
}

ForgotPassword.propTypes = {
  type: PT.oneOf([ types.FORGOT, types.FORGOT_SENT ]),
  show: PT.func.isRequired,
  hide: PT.func.isRequired,
  forgotPassword: PT.func.isRequired,
  isSubmitting: PT.bool.isRequired,
}

const withContainer = connect(
  (state) => ({
    isSubmitting: selectIsResettingPassword(state),
  }),
  { show, hide, forgotPassword },
)

export default withContainer(ForgotPassword)
