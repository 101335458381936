import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import noScroll from 'no-scroll'
import { MobileScreen, DesktopScreen } from 'utils/screen'
import GuestMenu from './GuestMenu'
import { connect } from 'react-redux'
import {
  selectIsLoaded,
  fetchProfile,
  selectIsLoading,
} from 'auth/modules/profile'
import { ProfileContainer } from './styled'
import { selectIsAuthenticated } from 'auth/modules/auth'
import Icon from 'components.v2/Icon/IconV2'
import { IconSection } from 'layouts/MultiVendorPageHeader/styled'

class Profile extends React.Component {
  state = {
    isOpen: false,
  }

  componentDidMount = () => {
    if (this.props.shouldFetchProfile) this.props.fetchProfile()
  }

  componentWillUnmount() {
    noScroll.off()
  }

  toggleOpen = () => {
    if (this.state.isOpen) {
      noScroll.off()
    } else {
      noScroll.on()
    }
    const isOpen = !this.state.isOpen
    this.props.openProfile(isOpen)
    this.setState({
      isOpen,
    })
  }

  logout = () => {
    this.toggleOpen()
    this.props.logout()
  }

  render() {
    return (
      <Fragment>
        <MobileScreen>
          <IconSection pointer onClick={this.toggleOpen}>
            <Icon src="GuestProfile.svg" />
            <span>Guest</span>
          </IconSection>
          {/* <ProfileModal
            isOpen={isOpen}
            toggleOpen={this.toggleOpen}
            logout={this.logout}
          /> */}
          <GuestMenu />
        </MobileScreen>
        <DesktopScreen>
          <ProfileContainer>
            <IconSection>
              <Icon src="GuestProfile.svg" />
              <span>Guest</span>
            </IconSection>
            <GuestMenu />
          </ProfileContainer>
        </DesktopScreen>
      </Fragment>
    )
  }
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  openProfile: PropTypes.func,
  image: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
}

const withState = connect(
  state => ({
    shouldFetchProfile:
      selectIsAuthenticated(state) &&
      !selectIsLoaded(state) &&
      !selectIsLoading(state),
  }),
  { fetchProfile },
)

export default withState(Profile)
