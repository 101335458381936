const MILLSECS = 1000
const PULL_SECOND = 10

export const PULLING_INTERVAL = MILLSECS * PULL_SECOND

export const DINE_IN = 'dine-in'
export const TAKEAWAY = 'takeaway'
export const DELIVERY = 'delivery'
export const SERVICE_TYPES = [DINE_IN, TAKEAWAY, DELIVERY]

export const ERROR_ADDRESS_REQUIRED = 'addressRequired'

export const CHECKOUT_FLAG_SAYNOTOPLASTIC = 'noWasteEnabled'

export const CHECKOUT_FLAG_GUEST_CHECKOUT = 'guestCheckoutEnabled'

export const NOTE_NO_CUTLERY_PREFIX = 'No cutlery.'

export const PAYMENT_GATEWAY_STRIPE = 'stripe'

export const PAYMENT_GATEWAY_STRIPE_CHECKOUT = 'stripe_checkout'

export const COOKIE_FETCH_ORDER_STATUS = 'fetch-order-status'

export const NOTE_CUSTOMER_PREFIX = 'HEY YOU order'

export const NOTE_DINEIN_PREFIX = 'Dine In at Table'

export const TYPE_MULTI_VENDOR_ORDER = 'multi-vendor'

export const TYPE_WHITELABEL_ORDER = 'whitelabel'

export const CHECKOUT_STATUS_OFFLINE = 'vendorOffline'

export const CHECKOUT_STATUS_CLOSED = 'vendorClosed'

export const UNAVAILABLE = 'UNAVAILABLE'

export const TEMP_UNAVAILABLE = 'TEMP_UNAVAILABLE'
