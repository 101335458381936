import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from 'components/Icon.v2'
import { mobileFirstMedia as media } from 'utils/media'
import LinesEllipsis from 'react-lines-ellipsis'

const Suggestion = ({ img, name, description, url }) => (
  <section className="flex items-center mr-5 xl:mr-0 items-center">
    <Link className="flex-no-shrink" to={url}>
      <Avatar src={img} alt={name} />
    </Link>
    <div className="flex-grow mx-15 xl:mx-20">
      <Name to={url}>{name}</Name>
      <Description>
        <LinesEllipsis text={description} maxLine="2" />
      </Description>
    </div>
    <Link to={url} className="xl:hidden">
      <Icon type="arrowUpperRight" fill="gray5" size={14} className="ml-15" />
    </Link>
  </section>
)

Suggestion.propTypes = {
  img: PT.string.isRequired,
  name: PT.string.isRequired,
  description: PT.string.isRequired,
  url: PT.string.isRequired,
}

export default Suggestion

const Avatar = styled.img`
  display: block;
  border: 1px solid ${p => p.theme.colors.gray5};
  width: 50px;
  height: 50px;

  ${media.screen`
    width: 70px;
    height: 70px;
  `}
`

const Name = styled(Link)`
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: ${p => p.theme.colors.black};
  text-decoration: none;

  ${media.screen`
    font-size: 14px;
  `}
`

const Description = styled.span`
  margin-top: 2px;
  display: block;
  font-size: 14px;
  line-height: 110%;
  color: ${p => p.theme.colors.gray2};

  ${media.screen`d
    font-size: 14px;
  `}
`
