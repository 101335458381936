import { createAction } from 'redux-actions'
import { createPromiseAction } from 'utils/asyncActions'

export default prefix => {
  const prefixedCreateAction = (type, ...props) =>
    createAction(prefix + type, ...props)
  prefixedCreateAction.promise = (type, ...props) =>
    createPromiseAction(prefix + type, ...props)

  return prefixedCreateAction
}
