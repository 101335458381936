import { defineAction } from 'redux-define'
import { getError } from 'utils/get-message'
import { handleActions } from 'redux-actions'

import {
  deleteFavouriteVenue,
  getFavouriteOrders,
  getFavouriteVenues,
  postFavouriteOrder,
  postFavouriteVenue,
} from 'api/favourites'

const TYPES = ['FAILURE', 'SUCCESS', 'REQUEST']

export const FETCH_FAVOURITE_VENUES = defineAction(
  'FETCH_FAVOURITE_VENUES',
  TYPES,
  'unicorn/favourites',
)
export const FETCH_FAVOURITE_ORDERS = defineAction(
  'FETCH_FAVOURITE_ORDERS',
  TYPES,
  'unicorn/favourites',
)

export const fetchFavouriteVenues = () => async dispatch => {
  try {
    dispatch({ type: FETCH_FAVOURITE_VENUES.REQUEST })

    const payload = await getFavouriteVenues()

    dispatch({ type: FETCH_FAVOURITE_VENUES.SUCCESS, payload })
  } catch (err) {
    dispatch({
      type: FETCH_FAVOURITE_VENUES.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
    })
    throw err
  }
}

export const removeFavouriteVenue = venueId => dispatch => {
  return deleteFavouriteVenue(venueId)
    .then(() => dispatch(fetchFavouriteVenues()))
    .catch(err => {
      dispatch({
        type: '@noop',
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: getError(err, 'Unable to delete favourite'),
          },
        },
      })
      throw err
    })
}

export const addFavouriteVenue = venueId => dispatch => {
  return postFavouriteVenue(venueId)
    .then(() => dispatch(fetchFavouriteVenues()))
    .catch(err => {
      dispatch({
        type: '@noop',
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: getError(err, 'Unable to add to favourite'),
          },
        },
      })
      throw err
    })
}

// favourite orders

export const addFavouriteOrder = orderId => dispatch => {
  return postFavouriteOrder(orderId)
    .then(() => dispatch(fetchFavouriteOrders()))
    .catch(err => {
      dispatch({
        type: '@noop',
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: getError(err, 'Unable to add to favourite'),
          },
        },
      })
      throw err
    })
}

export const fetchFavouriteOrders = () => async dispatch => {
  try {
    dispatch({ type: FETCH_FAVOURITE_ORDERS.REQUEST })

    const payload = await getFavouriteOrders()

    dispatch({ type: FETCH_FAVOURITE_ORDERS.SUCCESS, payload })
  } catch (err) {
    dispatch({
      type: FETCH_FAVOURITE_ORDERS.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
    })
    throw err
  }
}

const initialState = {
  venues: {
    isLoading: false,
    isLoaded: false,
    items: [],
  },
  orders: {
    isLoading: false,
    isLoaded: false,
    items: [],
  },
}

export default handleActions(
  {
    [FETCH_FAVOURITE_ORDERS.REQUEST]: state => {
      return {
        ...state,
        orders: { ...state.orders, isLoading: true },
      }
    },
    [FETCH_FAVOURITE_ORDERS.SUCCESS]: (state, { payload = {} }) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          isLoaded: true,
          items: payload,
        },
      }
    },
    [FETCH_FAVOURITE_ORDERS.FAILURE]: state => {
      return {
        ...state,
        orders: { ...state.orders, isLoading: false, isLoaded: false },
      }
    },
    [FETCH_FAVOURITE_VENUES.REQUEST]: state => {
      return {
        ...state,
        venues: { ...state.venues, isLoading: true },
      }
    },
    [FETCH_FAVOURITE_VENUES.SUCCESS]: (state, { payload = {} }) => {
      return {
        ...state,
        venues: {
          ...state.venues,
          isLoading: false,
          isLoaded: true,
          items: payload,
        },
      }
    },
    [FETCH_FAVOURITE_VENUES.FAILURE]: state => {
      return {
        ...state,
        venues: { ...state.venues, isLoading: false, isLoaded: false },
      }
    },
  },
  initialState,
)
