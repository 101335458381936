import styled from 'styled-components'
import { Modal as StyledModal } from 'pages/party/styled'
import { hide } from 'store/modals'
import { connect } from 'react-redux'
import { detachParty } from 'store/order-party/order-party.actions'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

const Layout = styled.div`
  display: flex;
  width: 360px;
  padding: 32px 20px 20px 20px;
  flex-direction: column;

  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 32px;

  & > div > div.icon {
    color: var(--Black, #000);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Cera;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 40px;
    height: 40px;
  }

  .gap-3 {
    gap: 12px;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 13px;
`

const Button = styled.button`
  height: 52px;
  width: 100%;
  border-radius: 8px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &.primary {
    background: var(--Orange-0, #f26722);
    color: var(--White, #fff);
  }

  &.secondary {
    background: var(--Orange-3, #fdf0e9);
    color: var(--Orange-0, #f26722);
  }

  &:focus {
    outline: none;
  }
`

const SubContent = styled.div`
  color: var(--Grey-1, #666);
  text-align: center;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const ContentTitle = styled.div`
  color: var(--Black, #000);
  text-align: center;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

const Modal = styled(StyledModal)`
  &__content {
    width: 360px;
  }
`

const OrderPartyAlert = ({
  hide,
  type,
  hostName,
  history,
  venueId,
  memberName,
  match,
}) => {
  const handleClose = () => hide()

  const handleViewOrder = () => {
    history.push(`/order/party/${venueId}/status`)
    handleClose()
  }

  return (
    <Modal isOpen noCloseButton onRequestClose={handleClose}>
      <Layout>
        <div className="flex items-center flex-col gap-3">
          <div className="icon">
            {
              {
                ORDER_PLACED: '🥳',
                CANCELLED: '😔',
                MEMBER_JOINED: '🥳',
                MEMBER_LEFT: '😿',
                MEMBER_ORDER_ADDED: '🎉',
                REJECTED_VENDOR: '🥺',
                REJECTED_TIMEOUT: '🥺',
                PAYMENT_ISSUE: '🥺',
              }[type]
            }
          </div>
          <ContentTitle>
            {
              {
                ORDER_PLACED: `${hostName} just placed the group order`,
                CANCELLED: `${hostName} has cancelled the group order`,
                MEMBER_JOINED: `${memberName} joined the group order`,
                MEMBER_LEFT: `${memberName} left the group order`,
                MEMBER_ORDER_ADDED: `${memberName} added their order`,
                REJECTED_VENDOR: `${memberName}'s order wasn't processed`,
                REJECTED_TIMEOUT: `${memberName}'s order wasn't confirmed`,
                PAYMENT_ISSUE: `${memberName}'s order faced an issue`,
              }[type]
            }
          </ContentTitle>
          <SubContent>
            {
              {
                REJECTED_VENDOR: `${memberName}'s order faaced some issues at the merchant's end. We recommend ${memberName} to check their order details.`,
                REJECTED_TIMEOUT: `Time's up! Orders need to be accepted quickly, and it seems ${memberName}'s took a bit too long. It might be best for ${memberName} to reorder.`,
                PAYMENT_ISSUE: `Please contact your card issuer for more information, or add another payment method.`,
              }[type]
            }
          </SubContent>
        </div>
        {{
          ORDER_PLACED: () => (
            <Buttons>
              <Button className="secondary" onClick={handleClose}>
                Dismiss
              </Button>
              <Button className="primary" onClick={handleViewOrder}>
                View Order
              </Button>
            </Buttons>
          ),
          CANCELLED: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Got it
              </Button>
            </Buttons>
          ),
          MEMBER_JOINED: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Okay
              </Button>
            </Buttons>
          ),
          MEMBER_LEFT: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Okay
              </Button>
            </Buttons>
          ),
          MEMBER_ORDER_ADDED: () => (
            <Buttons>
              <Button className="secondary" onClick={handleClose}>
                Dismiss
              </Button>
              <Button className="primary" onClick={handleViewOrder}>
                View Order
              </Button>
            </Buttons>
          ),
          REJECTED_VENDOR: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Got it
              </Button>
            </Buttons>
          ),
          REJECTED_TIMEOUT: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Got it
              </Button>
            </Buttons>
          ),
          PAYMENT_ISSUE: () => (
            <Buttons>
              <Button className="primary" onClick={handleClose}>
                Got it
              </Button>
            </Buttons>
          ),
        }[type]()}
      </Layout>
    </Modal>
  )
}

const withContainer = compose(
  withRouter,
  connect(
    (state, { venueId }) => ({
      venueId,
    }),
    {
      hide,
      detachParty,
    },
  ),
)

export default withContainer(OrderPartyAlert)
