import React from 'react'

import ShadowCard from 'components/ShadowCard'

import {
  AccountCardDivider,
  AccountCardTitle,
  AccountCardHeader,
} from './../../components/AccountCard'

import {
  SourceCardInner,
  SourcesHeader,
  SourcesHeaderLeft,
  SourcesHeaderRight,
  SourcesHeaderExpiry,
  SourcesHeaderOptions,
  AccountSourceAdd,
  AccountSourceAddLink,
} from './styled'

export const SourcesCard = ({ children }) => {
  return (
    <ShadowCard>
      <SourceCardInner>{children}</SourceCardInner>
    </ShadowCard>
  )
}

export default ({ onAddSource, children }) => {
  return (
    <SourcesCard>
      <AccountCardHeader>
        <AccountCardTitle>Payment source</AccountCardTitle>
        <AccountSourceAdd>
          <AccountSourceAddLink onClick={onAddSource}>
            + Add new source
          </AccountSourceAddLink>
        </AccountSourceAdd>
      </AccountCardHeader>
      <AccountCardDivider />

      <SourcesHeader>
        <SourcesHeaderLeft>Source</SourcesHeaderLeft>
        <SourcesHeaderRight>
          <SourcesHeaderExpiry />
          <SourcesHeaderOptions>Options</SourcesHeaderOptions>
        </SourcesHeaderRight>
      </SourcesHeader>
      <div>{children}</div>
    </SourcesCard>
  )
}
