import React, { useContext, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PT from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { show, hide, types } from 'store/modals'
import LoginForm from 'common/auth/LoginForm'
import Modal from 'components.v2/Modal'
import RegisterForm from 'common/auth/RegisterForm'
import SocialButton from 'common/auth/Shared/SocialButton'
import titleCase from 'utils/titleCase'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import { ConfigContext } from 'contexts/config-context'
import { GuestCheckoutButton, GuestCheckoutText } from './sharedStyled'
import GoogleLogin from './GoogleLogin'

const SUCCESS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  REGISTER_FORM: 'register-form',
}

const withContainer = connect(
  null,
  { show, hide },
)

const withGuestOrder = connect(state => {
  return {
    isGuestCheckout: selectGuestCheckoutHasEnabled(state),
  }
})

const GuestCheckout = compose(withGuestOrder)(
  ({ hasGuestCheckoutEnabled, show }) => {
    const config = useContext(ConfigContext)

    const renderGuestCheckoutUi = () =>
      hasGuestCheckoutEnabled && get(config, 'features.guest_checkout.enabled')

    const handleShowGuestCheckout = () => {
      show(types.GUEST, {
        ...(hasGuestCheckoutEnabled && {
          hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
        }),
      })
    }

    return (
      <Fragment>
        {renderGuestCheckoutUi() ? (
          <div className="flex pb-10 px-15 xl:px-25">
            <GuestCheckoutButton onClick={handleShowGuestCheckout}>
              <GuestCheckoutText>Continue as guest</GuestCheckoutText>
            </GuestCheckoutButton>
          </div>
        ) : (
          <div />
        )}
      </Fragment>
    )
  },
)

class AuthModal extends React.Component {
  handleSuccess = registrationType => () => {
    const { type, hide, trackEvent } = this.props

    hide()

    if (type !== types.REGISTER) return

    if (registrationType === SUCCESS.FACEBOOK) {
      trackEvent(events['SIGNUP_CLICK_FACEBOOK'])
    }

    if (registrationType === SUCCESS.GOOGLE) {
      trackEvent(events['SIGNUP_CLICK_GOOGLE'])
    }

    if (registrationType === SUCCESS.REGISTER_FORM) {
      trackEvent(events['SIGNUP_CLICK_EMAIL'])
    }
  }

  handleRequestCLose = () => {
    if (this.props.disableRequestCLose !== true) {
      this.props.hide()
    }
  }

  render() {
    const {
      type,
      show,
      hide,
      disableRequestCLose,
      email,
      hasGuestCheckoutEnabled,
    } = this.props
    return (
      <Modal
        isOpen
        contentLabel={titleCase(type)}
        onRequestClose={this.handleRequestCLose}
        noCloseButton
      >
        <div className="flex">
          <Tab
            isActive={type === types.REGISTER}
            onClick={() =>
              type !== types.REGISTER &&
              show(types.REGISTER, {
                ...(disableRequestCLose === true && {
                  disableRequestCLose: disableRequestCLose,
                }),
                ...(email && {
                  email: email,
                }),
                ...(hasGuestCheckoutEnabled && {
                  hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
                }),
              })
            }
          >
            Create an Account
          </Tab>
          <Tab
            isActive={type === types.LOGIN}
            onClick={() =>
              type !== types.LOGIN &&
              show(types.LOGIN, {
                ...(disableRequestCLose === true && {
                  disableRequestCLose: disableRequestCLose,
                }),
                ...(email && {
                  email: email,
                }),
                ...(hasGuestCheckoutEnabled && {
                  hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
                }),
              })
            }
          >
            Login
          </Tab>
        </div>
        <Divider />
        {hasGuestCheckoutEnabled && (
          <GuestCheckout hasGuestCheckoutEnabled show={show} />
        )}
        <div className="flex px-15 xl:px-25">
          <div className="px-5 w-1/2">
            <SocialButton
              onSuccess={this.handleSuccess(SUCCESS.FACEBOOK)}
              provider="facebook"
              className="w-full"
            />
          </div>
          <div className="px-5 w-1/2">
            <GoogleLogin onGoogleSignIn={this.handleSuccess(SUCCESS.GOOGLE)} />
          </div>
        </div>
        <div className="flex pt-10 pb-15 px-20 xl:pb-30 xl:px-30">
          {{
            [types.REGISTER]: () => (
              <RegisterForm
                onSuccess={this.handleSuccess(SUCCESS.REGISTER_FORM)}
                className="w-full"
                email={email}
              />
            ),
            [types.LOGIN]: () => (
              <LoginForm
                show={show}
                onSuccess={hide}
                className="w-full"
                email={email}
              />
            ),
          }[type]()}
        </div>
      </Modal>
    )
  }
}

AuthModal.propTypes = {
  type: PT.oneOf(['LOGIN', 'REGISTER', 'GUEST']),
  disableRequestCLose: PT.bool,
  hasGuestCheckoutEnabled: PT.bool,
  email: PT.string,
}

const Tab = styled.button`
  position: relative;
  padding-top: 5px;
  width: 50%;
  height: 70px;
  font-size: 14px;
  color: ${p => (p.isActive ? p.theme.colors.primary : p.theme.colors.black)};
  outline: none !important;

  &::after {
    content: ' ';
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
    display: ${p => (p.isActive ? 'block' : 'none')};
    height: 2px;
    width: 60%;
    background-color: ${p => p.theme.colors.primary};
  }
`

const Divider = styled.div`
  height: 2px;
  margin: 0 15px 30px;
  background-color: ${p => p.theme.colors.gray7};
`

export default compose(
  withContainer,
  withAnalyticsHandler,
)(AuthModal)
