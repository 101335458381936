import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`

  return (
    <Modal
      appElement={document.body}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      style={{
        overlay: {
          position: 'fixed',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          backgroundColor: 'rgba(0, 0, 0, 0.66)'
        },
        content: {
          background: '#ffffff'
        }
      }}
      {...props}
    />
  )
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string.isRequired
}

export default ReactModalAdapter
