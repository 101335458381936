import React, { Fragment } from 'react'
import Icon from 'components/Icon'

import { MobileScreen, DesktopScreen } from 'utils/screen'
import { ProfileContainer, Avatar } from './styled'

export default () => (
  <Fragment>
    <MobileScreen id="dummy-profile" className="flex items-center">
      <ProfileContainer pointer>
        <Avatar avatar="" />
        <Icon name="fas fa-chevron-down" size={10} />
      </ProfileContainer>
    </MobileScreen>
    <DesktopScreen id="dummy-profile" className="flex items-center">
      <ProfileContainer>
        <Avatar avatar="" />
        <Icon name="fas fa-chevron-down" size={10} />
      </ProfileContainer>
    </DesktopScreen>
  </Fragment>
)
