import React from 'react'
import PropTypes from 'prop-types'

import asset from 'utils/asset'
import Button from 'components.v2/Button'
import Text from 'components.v2/Text'

import {
  LoadingErrorScreen,
  LoadingErrorContent,
  LoadingErrorImg,
} from './styled'

const clickHandler = () => {
  window.location.reload()
}

const LoadingError = ({
  headline = 'Unexpected error',
  showButton = true,
  subheadline = 'Hmm. Something went wrong, please try refreshing the page.',
  handleClick = clickHandler,
}) => {
  return (
    <LoadingErrorScreen>
      <LoadingErrorContent className="pt-60 pb-40 px-15">
        <LoadingErrorImg src={asset('images/404.png')} />
        <Text variant="h1" color="gray4" className="mt-40">
          {headline}
        </Text>
        <Text variant="h4" color="gray4" light>
          {subheadline}
        </Text>
        {showButton && (
          <Button
            className="mt-40 inline-block"
            variant="primary"
            large
            largeText
            onClick={handleClick}
          >
            Refresh page
          </Button>
        )}
      </LoadingErrorContent>
    </LoadingErrorScreen>
  )
}

LoadingError.propTypes = {
  showButton: PropTypes.bool,
  headline: PropTypes.string,
  subheadline: PropTypes.string,
}

export default LoadingError
