import React from 'react'
import PropTypes from 'prop-types'
import { ShadowCardContainer } from './styled'
const ShadowCard = ({ className, children }) => {
  return <ShadowCardContainer className={className}>{children}</ShadowCardContainer>
}

ShadowCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default ShadowCard
