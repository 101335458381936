import styled from 'styled-components'

import media from 'utils/media'
import Icon from 'components/Icon.v2'

export const HeaderContainer = styled.header`
  position: ${props => (props.isFixed ? 'fixed' : 'relative')};
  z-index: ${props => (props.isFixed ? 1 : 'auto')};
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  display: block;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);

  ${media.screen`
    padding: 0 12px;
    width: 100%;
    display: ${props => (props.isOrderPage() ? 'none' : 'block')};    
  `};
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 12px 12px;

  & #dummy-profile {
    display: none;
  }

  .dummy-authed & #dummy-profile {
    display: block;
  }

  .dummy-authed & #dummy-profile + * {
    display: none;
  }
`

export const Logo = styled.img`
  cursor: pointer;
  width: 70px;
  height: 48px;
  ${media.desktop`
    width: 51px;  
    height: 35px;
  `};
`

export const PrecinctLogo = styled.img`
  cursor: pointer;
  height: 32px;
`

export const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  order: 2;
  gap: 30px;

  a {
    text-decoration: none;
  }
`

export const RegisterVenue = styled.button`
  background: #f26722;
  border-radius: 8px;

  height: 40px;
  width: 158px;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #ffffff;
`

export const LoginButton = styled.div``

export const IconContainer = styled.div`
  display: inline;
`

export const Notif = styled.div`
  width: 10px;
  height: 10px;
  background: #f26722;
  border-radius: 25px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;

  color: white;

  position: absolute;
  top: 1px;
  right: 1px;
  border: 1px solid #ffffff;
`

export const IconBox = styled.button`
  width: 40px;
  height: 40px;

  background: #fdf0e9;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProfileIcon = styled(Icon)``

export const IconSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;

  span {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    color: #000;
  }
`

export const IconDisabledSection = styled.div`
  opacity: 0.4;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-decoration: none !important;

  span {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    color: #gray;
  }
`

export const CartPrice = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;

  margin-top: auto;

  ${p => p.hasValue && 'color: #000000;'}
`
export const Avatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.grey};
  border: 1.5px solid black;
  background-image: url(${props => props.avatar});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.multivendor {
    outline: 1.5px solid rgba(0, 0, 0, 0.1);
    outline-offset: -1.5px;
    width: 17px;
    height: 17px;
    border: none;
  }
`
