import styled from 'styled-components'

const HEIGHT = 56

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const HeaderSection = styled.div`
  min-height: ${HEIGHT}px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  height: ${HEIGHT}px;
  width: 100%;
  box-shadow: 0 2px 4px 0 #cccccc,
    0 1px 0 0 ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.white};
`

export const Logo = styled.img`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: 52px;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Background = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.background};
`
