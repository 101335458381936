import { trackEvent } from 'utils/firebase'

const isAnalyticsAction = action => {
  return action.meta && action.meta.type === 'analytics' && action.meta.payload
}

export default () => {
  return next => action => {
    if (isAnalyticsAction(action)) {
      trackEvent(action.meta.payload)
    }
    return next(action)
  }
}
