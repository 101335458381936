import React from 'react'
//import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'

import { trackPage } from 'utils/firebase'
import { getAppType } from 'utils/app'

class Analytics extends React.Component {
  componentDidMount() {
    const { pathname, query = '' } = this.props.location

    trackPage({
      page_path: pathname + query,
      app_type: getAppType(),
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { pathname, query = '' } = this.props.location

      trackPage({
        page_path: pathname + query,
        app_type: getAppType(),
      })
    }
  }

  render() {
    return null
  }
}

export default withRouter(Analytics)
