import React from 'react'

import { Link } from 'react-router-dom'
import Helmet from 'react-helmet-async'

import asset from 'utils/asset'
import Button from 'components.v2/Button'
import Text from 'components.v2/Text'

import { ErrorPage, ErrorContent, ErrorImg } from './styled'

const defaultImage = asset('images/404.png')

export default ({
  title = 'Page not found',
  message = 'Oooh oh, the page you’re looking for doesn’t exist.',
  image = defaultImage,
}) => {
  return (
    <ErrorPage>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Hey You is australia's No.1 order ahead app..."
        />
      </Helmet>
      <ErrorContent className="pt-60 pb-40 px-15">
        <ErrorImg src={image} />
        <Text variant="h1" className="text-center mt-40" color="gray4">
          {title}
        </Text>
        <Text variant="h4" color="gray4" light>
          {message}
        </Text>
        <Button
          className="mt-40 inline-block"
          as={Link}
          to="/"
          variant="primary"
          large
          largeText
        >
          Go home
        </Button>
      </ErrorContent>
    </ErrorPage>
  )
}
