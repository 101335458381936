import React from 'react'
import PropTypes from 'prop-types'

import { TextContainer } from './styled'

import { h4 } from './const'

const H4 = ({
  className,
  color,
  children,
  bold,
  align,
  transform,
  ...props
}) => {
  const { size, lineHeight, element } = h4
  const fontWeight = bold ? 500 : 300
  const Container = TextContainer(element)
  return (
    <Container
      bold={bold}
      size={size}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      align={align}
      transform={transform}
      className={className}
      {...props}
    >
      {children}
    </Container>
  )
}

H4.propTypes = {
  transform: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  bold: PropTypes.bool,
  align: PropTypes.string,
}

export default H4
