import { defineAction } from 'redux-define'
import { getError } from 'utils/get-message'
import { handleActions } from 'redux-actions'
import { get } from 'lodash'

import { fetchStates } from 'api/states'

const TYPES = ['FAILURE', 'SUCCESS', 'REQUEST']

export const FETCH_ADDRESS_STATES = defineAction(
  'FETCH_ADDRESS_STATES',
  TYPES,
  'unicorn/regions',
)

export const fetchAddressStates = country => async dispatch => {
  try {
    dispatch({ type: FETCH_ADDRESS_STATES.REQUEST })

    const payload = await fetchStates(country)

    dispatch({
      type: FETCH_ADDRESS_STATES.SUCCESS,
      payload: get(payload, 'data.regions'),
    })
  } catch (err) {
    dispatch({
      type: FETCH_ADDRESS_STATES.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
    })
    throw err
  }
}

const initialState = {
  states: {
    isLoading: false,
    isLoaded: false,
    items: [],
  },
}

export default handleActions(
  {
    [FETCH_ADDRESS_STATES.REQUEST]: state => {
      return {
        ...state,
        states: { ...state.states, isLoading: true },
      }
    },
    [FETCH_ADDRESS_STATES.SUCCESS]: (state, { payload = {} }) => {
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: false,
          isLoaded: true,
          items: payload,
        },
      }
    },
    [FETCH_ADDRESS_STATES.FAILURE]: state => {
      return {
        ...state,
        states: { ...state.states, isLoading: false, isLoaded: false },
      }
    },
  },
  initialState,
)
