import { put, takeLatest, select } from 'redux-saga/effects'
import { fetchSuburb, fetchSuburbs } from 'api/suburbs'
import * as actions from './suburbs'

export default [
  takeLatest(String(actions.fetch), function* fetchSuburbsTask({
    payload,
    meta: { resolve },
  }) {
    try {
      const suburbs = (yield fetchSuburbs(payload)).data
      const listId = yield select(actions.selectId)

      if (!listId === payload) {
        resolve()
        return
      }

      yield put(
        actions.fetch.fulfilled({
          payload: {
            list: suburbs,
            id: payload,
          },
        }),
      )
      resolve()
    } catch (error) {
      yield put(
        actions.fetch.rejected({
          payload: error,
          meta: {
            type: 'notification',
            payload: {
              title: 'Error',
              message: 'Failed to load suburbs',
              error,
            },
          },
        }),
      )
      resolve()
    }
  }),
  takeLatest(String(actions.fetchSingle), function* fetchSuburbsTask({
    payload: { city, suburb },
    meta: { resolve },
  }) {
    try {
      const suburbDetails = (yield fetchSuburb(city, suburb)).data
      yield put(actions.fetchSingle.fulfilled({ payload: suburbDetails }))
      resolve(suburbDetails)
    } catch (error) {
      yield put(
        actions.fetchSingle.rejected({
          payload: error,
        }),
      )
      resolve()
    }
  }),
]
