import React from 'react'
import PropTypes from 'prop-types'
import asset from 'utils/asset'
import staticAsset from 'utils/staticAsset'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  HeaderContainer,
  HeaderContent,
  RightSection,
  Logo,
  PrecinctLogo,
  LoginButton,
  IconDisabledSection,
  Notif,
  IconSection,
  CartPrice,
  Avatar,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import events from 'const/analytics-events'
import LINKS from './../../const/links'
//import Profile, { Guest } from 'auth/Profile'
import Profile from 'auth/Profile'
import withAnalytics from 'enhancers/withAnalytics'
import WithAuthentication from 'global/WithAuthentication'
import withContainer from './PageHeaderContainer'
import meta from 'const/meta'
import { isOrderUrl } from 'utils/url'
import Icon from 'components.v2/Icon/IconV2'
import { AvatarContainer } from '../../auth/Profile/styled'
import ContentLoader from 'react-content-loader'

/*
 * About <DummyProfile /> & 'dummy-authed' className
 * DummyProfile is part of a hack for performance optimization
 * When server rendered content adds the className 'dummy-authed',
 * we'll be able to show (fake) profile dropdown without having to
 * fetch (and more importantly, cache) individual user data
 */

const TrackedLink = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGO'],
  },
])(Link)

const LoginBtn = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGIN'],
  },
])(LoginButton)

class Header extends React.Component {
  state = { isProfileOpen: false }

  openProfile = isProfileOpen => {
    this.setState({
      isProfileOpen,
    })
  }

  goToBusiness = () => {
    const win = window.open(LINKS.LIST_BUSINESS, '_blank')
    win.opener = null
  }

  isOrderPage = () => {
    const { history } = this.props
    return isOrderUrl(get(history, 'location.pathname', ''))
  }

  componentDidMount = () => {
    const body = document.getElementsByTagName('BODY')[0]
    body.classList.remove('dummy-authed')
  }

  render() {
    const { isProfileOpen } = this.state
    const {
      customer,
      isAuthenticated,
      showLoginModal,
      authActions: { logout },
      totalMultiVendorOrders,
      precinct,
      isGuestCheckout,
      isLoading,
      location,
      //disableGuest,
    } = this.props

    return (
      <HeaderContainer isFixed={isProfileOpen} isOrderPage={this.isOrderPage}>
        <DesktopContainer background="white">
          <HeaderContent>
            {isEmpty(get(precinct, 'logo')) ? (
              <TrackedLink to="/">
                <Logo src={asset('icons/heyyou-logo.svg')} alt={meta.title} />
              </TrackedLink>
            ) : isLoading && /^\/precinct/.test(get(location, 'pathname')) ? (
              <ContentLoader height="32">
                <rect x="0" y="8" height="16" width="121" rx="0" ry="0" />
              </ContentLoader>
            ) : (
              <TrackedLink to={`/precinct/${get(precinct, 'tag')}`}>
                <PrecinctLogo
                  src={staticAsset(`tags/icons/${get(precinct, 'logo')}`)}
                  alt={get(precinct, 'logo')}
                />
              </TrackedLink>
            )}
            <RightSection>
              {totalMultiVendorOrders > 0 ? (
                <Link to={'/order/review'}>
                  <IconSection>
                    <Icon
                      src="Cart2.svg"
                      style={{ width: '24px', height: '24px' }}
                    />
                    <CartPrice hasValue>Carts</CartPrice>
                    {!!totalMultiVendorOrders && <Notif />}
                  </IconSection>
                </Link>
              ) : (
                <IconDisabledSection>
                  <Icon
                    src="CartDisabled.svg"
                    style={{ width: '24px', height: '24px' }}
                  />
                  <CartPrice>Carts</CartPrice>
                </IconDisabledSection>
              )}

              {isAuthenticated ? (
                <Profile
                  logout={logout}
                  openProfile={this.openProfile}
                  isMultiVendor
                  {...customer}
                />
              ) : isGuestCheckout ? (
                <IconSection>
                  <AvatarContainer>
                    <Avatar
                      avatar={asset('images/guest-avatar.jpeg')}
                      className="multivendor"
                    />
                  </AvatarContainer>
                  <span>Guest</span>
                </IconSection>
              ) : (
                <LoginBtn variant="primary" onClick={showLoginModal}>
                  <IconSection>
                    <Icon
                      src="Profile.svg"
                      style={{ width: '24px', height: '24px' }}
                    />
                    <span className="mt-auto">Profile</span>
                  </IconSection>
                </LoginBtn>
              )}
            </RightSection>
          </HeaderContent>
        </DesktopContainer>
      </HeaderContainer>
    )
  }
}

Header.propTypes = {
  customer: PropTypes.object,
  authActions: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default WithAuthentication(withContainer(Header))
