import React, { Fragment, PureComponent } from 'react'
import PT from 'prop-types'
import { Text } from './styled'
import Button from 'components.v2/Button'
import styled from 'styled-components'
import Icon from 'components.v2/Icon/IconV2'
import { Title, Container, BackButton } from '../Shared/AuthModalV2'

const Caption = styled(Text)`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;

  color: #999999;

  margin-top: 12px;
  margin-bottom: 32px;
`

const ResendButton = styled(Button)`
  border-radius: 8px;
  background: #fdf0e9;

  color: var(--orange-0, #f26722);
  text-align: center;

  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 28px;

  height: 52px;
  width: 50%;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      background: #0000001a;
      width: 100%;
      height: 100%;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
    }
  }
`

const LoginButton = styled(Button)`
  border-radius: 8px;
  background: #f26722;

  color: var(--white, #fff);
  text-align: center;

  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 28px;

  height: 52px;
  width: 50%;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      background: #0000001a;
      width: 100%;
      height: 100%;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;
`

export default class Confirmation extends PureComponent {
  static defaultProps = {}

  static propTypes = {
    onPrevious: PT.func.isRequired,
    onLogin: PT.func.isRequired,
  }

  render() {
    const { onPrevious, onLogin } = this.props

    return (
      <Container>
        <Fragment>
          <Title>
            <BackButton className="mr-auto" onClick={onPrevious}>
              <Icon src="BackButton.svg" />
            </BackButton>
            <div className="w-full text-center">Check your email</div>
          </Title>
          <Caption as="p">
            Click on the link that has been sent to your email to continue
            resetting your password.
          </Caption>
          <ButtonContainer>
            <ResendButton
              type="button"
              variant="outline-primary"
              onClick={onPrevious}
            >
              Resend Email
            </ResendButton>

            <LoginButton type="button" variant="primary" onClick={onLogin}>
              Login
            </LoginButton>
          </ButtonContainer>
        </Fragment>
      </Container>
    )
  }
}
