import { put, takeLatest } from 'redux-saga/effects'

import notif from 'services/notification'
import * as api from 'api'
import { fetchCuisines, fetchCuisinesFulfilled } from './cuisines'

export default [
  takeLatest(String(fetchCuisines), function* fetchCuisinesSaga() {
    try {
      const cuisines = yield api.fetchCuisines()
      yield put(fetchCuisinesFulfilled(cuisines))
    } catch (error) {
      notif.error({
        title: 'Error',
        message: `Failed to fetch cuisines`,
        error,
      })
    }
  }),
]
