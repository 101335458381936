import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import {
  fetchProfile,
  updateProfile,
  updatePhoto,
  selectIsLoading,
  selectProfile,
  selectIsUpdating,
} from '../auth/modules/profile'

import { selectMobile } from 'store/smsv'

const customerSelector = createSelector(
  selectMobile,
  selectProfile,
  (mobile, profile) => {
    return {
      ...profile,
      mobile,
    }
  },
)

const mapStateToProps = createSelector(
  selectIsLoading,
  selectIsUpdating,
  customerSelector,
  (isLoading, isUpdating, customer) => ({ isLoading, isUpdating, customer }),
)

export default connect(
  mapStateToProps,
  {
    fetchProfile,
    updateProfile,
    updatePhoto,
  },
)
