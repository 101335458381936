import React from 'react'
import PT from 'prop-types'
import Modal from 'components.v2/Modal'
import CodeInput from 'components.v2/CodeInput'
import Button from 'components.v2/Button'
import NonBreakingPhrase from 'components.v2/NonBreakingPhrase'
import FormError from 'components.v2/FormError'
import prettifyMobile from 'utils/prettifyMobile'
import pick from 'lodash/fp/pick'
import { propTypes as formValidatorPT } from 'utils/validator'
import withContainer from './VerifyModalContainer'
import { Heading, Text, Link } from './sharedStyled'
import TextV2 from 'components.v2/Text'

const VerifyModal = ({
  verifyState,
  resendCodeState,
  mobile,
  fields,
  errors,
  handleHide,
  handleChange,
  handleBlur,
  handleSubmit,
  handleEditMobile,
  handleResendCode,
}) => (
  <Modal
    isOpen
    contentLabel="Verify Modal Number"
    onRequestClose={handleHide}
    className="text-center pb-15 px-15"
    small
  >
    <form onSubmit={handleSubmit} noValidate>
      <Heading className="mt-40">Verify mobile number</Heading>
      <Text as="p" className="mt-10 xl:mt-15 mx-30">
        Please type the verification code sent to{' '}
        <NonBreakingPhrase>{prettifyMobile(mobile)}</NonBreakingPhrase>.{' '}
        <Link type="button" onClick={handleEditMobile}>
          Edit number
        </Link>
      </Text>
      <div className={errors.length ? 'mt-10' : 'mt-30'}>
        {!!errors.length &&
          errors.map((error, i) => <FormError key={i}>{error}</FormError>)}
        <CodeInput
          value={fields.verificationCode.value}
          onChange={handleChange}
          onBlur={handleBlur}
          name="verificationCode"
        />
      </div>
      <Link
        onClick={handleResendCode}
        className="mt-20 xl:mt-30"
        disabled={resendCodeState.isLoading}
        type="button"
      >
        {resendCodeState.isLoading ? 'Resending code…' : 'Resend code'}
      </Link>
      <TextV2
        variant="secondary"
        color="gray3"
        className="mt-10 xl:mt-15 mx-15"
      >
        Note: if your code is not delivered via SMS you might expect an
        automated call with your verification number.
      </TextV2>
      <Button
        variant="primary"
        type="submit"
        className="mt-20 xl:mt-30 w-full"
        disabled={verifyState.isLoading}
        large
        largeText
      >
        {verifyState.isLoading ? 'Processing…' : 'Verify'}
      </Button>
    </form>
  </Modal>
)

VerifyModal.propTypes = {
  ...pick(
    ['handleChange', 'handleBlur', 'handleSubmit', 'fields', 'errors'],
    formValidatorPT,
  ),
  verifyState: PT.shape({
    isLoading: PT.bool.isRequired,
    data: PT.bool,
  }),
  resendCodeState: PT.shape({
    isLoading: PT.bool.isRequired,
  }),
  mobile: PT.string.isRequired,
  handleHide: PT.func.isRequired,
  handleEditMobile: PT.func.isRequired,
  handleResendCode: PT.func.isRequired,
}

export default withContainer(VerifyModal)
