import { compose, mapProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { isOrderUrl, isVenueUrl } from 'utils/url'

const setNoFooter = (history, responsive) =>
  isOrderUrl(get(history, 'location.pathname', '')) ||
  (isVenueUrl(get(history, 'location.pathname', '')) &&
    (responsive.mobile || responsive.tablet))

export default compose(
  connect(state => ({
    responsive: state.responsive,
  })),
  withRouter,
  mapProps(({ cuisines, history, responsive }) => ({
    responsive,
    noFooter: setNoFooter(history, responsive),
  })),
)
