import React from 'react'

import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import { Button } from 'common/auth/Shared/sharedStyled'
import FormError from 'components.v2/FormError'
import Input from 'components.v2/Input'
import withContainer from './LoginFormContainer'
import { BackButton, LoginFormHeader as Title } from '../Shared/AuthModalV2'
import styled from 'styled-components'
import Icon from 'components.v2/Icon/IconV2'
import { types } from 'store/modals'
import get from 'lodash/get'
import media from 'utils/media'
import { MobileScreen, DesktopScreen } from 'utils/screen'
import { emailRegex } from 'utils/validator'

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > div > input {
    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    background: #f2f2f2;

    & :focus {
      border: 1.5px solid #f26722;
    }

    & :not(:focus) {
      border: 1px solid #cccccc;
    }
    border-radius: 8px;
  }

  & > div.block.w-full {
    margin-top: 32px;
  }

  & > .hide {
    display: none !important;
  }

  & > button[type='submit'] {
    margin-top: 32px;
  }

  & > a {
    text-decoration: none;
    margin-top: 12px;
    &:hover {
      cursor: pointer;
    }
    color: var(--green-0, #2e9a9a);
    text-align: right;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 16px;
  }
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  height: 52px;

  &:not(:focus) {
    border: none;
  }

  &:not([disabled]):hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:not([disabled]):active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  ${media.phone`
    position: absolute;
    width: calc(100% - 48px) !important;
    bottom: 40px;
  `}
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
`

const LoginForm = ({
  fields,
  isAuthenticating,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  showForgotPassModal,
  email,
  show,
  state,
  setState,
  verifyEmail,
  hasGuestCheckoutEnabled,
  validateField,
}) => (
  <Form
    onSubmit={
      {
        email: e => {
          validateField(e, 'email')
          if (emailRegex.test(fields.email.value)) {
            verifyEmail({ email: fields.email.value, setState, show })
          }
        },
        password: handleSubmit,
      }[get(state, 'current')]
    }
    className={`${className} text-left`}
  >
    <Title>
      <BackButton
        className="mr-auto"
        onClick={
          {
            email: () => {
              show(types.AUTH, {
                hasGuestCheckoutEnabled: hasGuestCheckoutEnabled,
              })
            },
            password: () => {
              setState({
                current: 'email',
              })
            },
          }[get(state, 'current')]
        }
      >
        {{
          email: () => (
            <div>
              <MobileScreen>
                <Icon src="CloseIcon.svg" />
              </MobileScreen>
              <DesktopScreen>
                <Icon src="BackButton.svg" />
              </DesktopScreen>
            </div>
          ),
          password: () => <Icon src="BackButton.svg" />,
        }[get(state, 'current')]()}
      </BackButton>
      <div className="w-full text-center">
        {
          {
            email: `What's your email?`,
            password: 'Enter your password',
          }[get(state, 'current')]
        }
      </div>
    </Title>

    <Input
      {...(email ? { autoFocus: true } : {})}
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.email.value || email}
      hasError={!!fields.email.error}
      className={get(state, 'current') === 'email' ? 'block w-full' : 'hide'}
      type="email"
      name="email"
      placeholder="Email"
    />
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.password.value}
      hasError={!!fields.password.error}
      className={get(state, 'current') === 'password' ? 'block w-full' : 'hide'}
      type="password"
      name="password"
      placeholder="Password"
    />
    {!!get(fields, `${get(state, 'current')}.error`) && (
      <CustomFormError>
        {get(fields, `${get(state, 'current')}.error`)}
      </CustomFormError>
    )}
    {get(state, 'current') === 'password' && (
      <a onClick={() => showForgotPassModal()} className="ml-auto">
        Forgot password?
      </a>
    )}
    <SubmitButton
      variant="primary"
      type="submit"
      className="w-full"
      disabled={isAuthenticating || get(state, 'isLoading')}
      actionsReversed={actionsReversed}
      loading={isAuthenticating || get(state, 'isLoading')}
    >
      {isAuthenticating
        ? 'Processing…'
        : {
            email: 'Continue',
            password: 'Log in',
          }[get(state, 'current')]}
    </SubmitButton>
  </Form>
)

LoginForm.propTypes = formPropTypes

export default withContainer(LoginForm)
