import styled from 'styled-components'

import Button from 'components.v2/Button'

export const ModalButton = styled(Button)`
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 24px;
`
