import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { show, hide } from 'store/modals'
import Modal from 'components.v2/Modal'
import GuestCheckoutForm from 'common/auth/GuestCheckoutForm'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { Heading } from './styled'

const withModalContainer = connect(
  null,
  { show, hide },
)

class GuestModal extends React.Component {
  handleRequestCLose = () => {
    if (this.props.disableRequestCLose !== true) {
      this.props.hide()
    }
  }

  render() {
    const { hide } = this.props

    return (
      <Modal
        isOpen
        contentLabel="Guest Checkout"
        onRequestClose={this.handleRequestCLose}
      >
        <Heading className="mt-40">Guest Checkout</Heading>
        <div className="flex pt-10 pb-15 px-20 xl:pb-30 xl:px-30 mt:30">
          <GuestCheckoutForm
            actionsReversed
            onSuccess={hide}
            className="w-full"
            buttonAlign="center"
          />
        </div>
      </Modal>
    )
  }
}

export default compose(
  withModalContainer,
  withAnalyticsHandler,
)(GuestModal)
