import styled from 'styled-components'

import media from 'utils/media'

export const HeaderContainer = styled.header`
  position: ${props => (props.isFixed ? 'fixed' : 'relative')};
  z-index: ${props => (props.isFixed ? 1 : 'auto')};
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  display: block;
  background: #ffffff;

  ${media.screen`
    padding: 0 15px;
    display: ${props => (props.isOrderPage() ? 'none' : 'block')};    
  `};
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 78px;

  & #dummy-profile {
    display: none;
  }

  .dummy-authed & #dummy-profile {
    display: block;
  }

  .dummy-authed & #dummy-profile + * {
    display: none;
  }
`

export const Logo = styled.img`
  cursor: pointer;
  width: 70px;
  height: 48px;
  ${media.desktop`
    width: 51px;
    height: 35px;
  `};
`

export const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  justify-content: center;
  align-items: center;

  margin-left: auto;
  order: 2;

  gap: 12px;
`

export const RegisterVenue = styled.button`
  background: #f26722;
  border-radius: 8px;

  height: 40px;
  width: 158px;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #ffffff;
`

export const LoginButton = styled.button`
  width: 76px;
  height: 40px;

  background: #fdf0e9;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 20px 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #f26722;
`

export const IconContainer = styled.div`
  display: inline;
`

export const Notif = styled.div`
  width: 20px;
  height: 20px;
  background: #f26722;
  border-radius: 25px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;

  color: white;

  position: relative;
  top: -18px;
  left: 20px;
`

export const IconBox = styled.button`
  width: 40px;
  height: 40px;

  background: #fdf0e9;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const OrderPartyHeader = styled.div`
  height: 60px;
  width: 100%;
  margin: 0 auto;
  background: #000000;
  padding: 18px 0px;

  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  ${media.screen`
    display:none;
  `}
`
