import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { Avatar } from '../styled'
import events from 'const/analytics-events'
import Icon from 'components.v2/Icon/IconV2'
import withAnalytics from 'enhancers/withAnalytics'

import {
  Outlay,
  ProfileMenuContainer,
  ProfileSection,
  EditButton,
  AvatarSection,
  MenuEditLink,
} from './styled'

const EditButtonTracked = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_PROFILE'],
  },
])(EditButton)

class GuestMenu extends React.PureComponent {
  editProfile = () => {
    this.props.history.push('/account/profile')
  }
  render() {
    const { avatar } = this.props
    return (
      <Outlay>
        <ProfileMenuContainer>
          <ProfileSection>
            <EditButtonTracked onClick={this.editProfile}>
              <MenuEditLink />
            </EditButtonTracked>
            <AvatarSection>
              <Avatar avatar={avatar} />
              <Icon name={`fas fa-chevron-up`} size={10} />
            </AvatarSection>
          </ProfileSection>
        </ProfileMenuContainer>
      </Outlay>
    )
  }
}

GuestMenu.propTypes = {
  history: PropTypes.object,
  avatar: PropTypes.string,
}

export default withRouter(GuestMenu)
