import React from 'react'
import PropTypes from 'prop-types'

import Ring from './Ring'
import { FullScreenContainer, Container, LoaderContainer } from './container'

const Loader = ({ className, page, color, size, elementSize, type, minHeight }) => {
  if (page) {
    return (
      <FullScreenContainer>
        <Container>
          <Ring className={className} color='darkGrey' size={50} borderSize={elementSize} />
        </Container>
      </FullScreenContainer>
    )
  }
  return (
    <LoaderContainer minHeight={minHeight || size * 1.2}>
      <Ring className={className} color={color} size={size} borderSize={elementSize} />
    </LoaderContainer>
  )
}

Loader.propTypes = {
  className: PropTypes.string,
  page: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  elementSize: PropTypes.number,
  minHeight: PropTypes.number
}

export default Loader
