import { put, takeLatest } from 'redux-saga/effects'
import isNode from 'detect-node'
import Cookies from 'js-cookie'

import notif from 'services/notification'
import { requestVerifyIfNeeded } from 'store/smsv'
import EXP from 'const/cookie-exp'
import { getPersistor } from 'App'
import { LOGIN, LOGOUT } from './auth'
import { fetchProfile } from './profile'

export default [
  takeLatest(`${LOGIN}_FULFILLED`, function* fetchProfileOnLogin() {
    yield put(fetchProfile())
    yield put(requestVerifyIfNeeded())

    if (!isNode) Cookies.set('authenticated', true, { expires: EXP })
  }),
  takeLatest(`${LOGOUT}_PENDING`, function notifOnLogoutSuccess() {
    notif.success({ title: 'Logout successful' })

    if (!isNode) {
      Cookies.set('authenticated', false)
      getPersistor().purge()
    }
  }),
]
