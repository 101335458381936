import storage from 'redux-persist/lib/storage'
import { persistBlacklist as orderBlkList } from 'pages/order/modules/order'
import { persistBlacklist as locationBlkList } from 'common/LocationSearchBar/location'
import { persistBlacklist as authBlkList } from 'auth/modules/auth'

export default {
  auth: {
    key: 'unicorn/auth',
    storage,
    blacklist: authBlkList,
  },

  order: {
    key: 'unicorn/order',
    storage,
    blacklist: orderBlkList,
  },

  multiVendorOrder: {
    key: 'unicorn/multiVendorOrder',
    storage,
  },

  orderParty: {
    key: 'unicorn/order-party',
    storage,
  },

  location: {
    key: 'unicorn/location',
    storage,
    blacklist: locationBlkList,
  },

  guestCheckout: {
    key: 'unicorn/guestCheckout',
    storage,
  },

  precinct: {
    key: 'unicorn/precinct',
    storage,
  },
}
