import React from 'react'
import PropTypes from 'prop-types'
import { IconContainer } from './styled'
const Icon = ({ element, className, name, color, size, width, height, ...props }) => {
  const Container = IconContainer(element)

  return (
    <Container element={element} className={className} color={color} size={size} width={width} height={height} {...props}>
      <i className={name} />
    </Container>
  )
}

Icon.defaultProps = {
  element: 'div'
}

Icon.propTypes = {
  element: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
}

export default Icon
