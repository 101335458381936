// Name: vendor

import { combineReducers } from 'redux'

import menu from './menu'
import info from './info'

export default combineReducers({
  menu, info
})
