export const isWhiteLabel = () => {
  const whiteLabelSubdomains = process.env.WHITE_LABEL_SUB_DOMAINS || 'abc'
  const hostname =
    process.env.WHITELABEL_HOSTNAME || 'abc.staging-unicorn.heyyou.io'
  const currentSubdomain = hostname.split('.')[0]

  return whiteLabelSubdomains.split(',').includes(currentSubdomain)
}

export const getVenueMainPageUrl = () => {
  if (typeof window !== 'undefined') {
    let url = localStorage.getItem('restaurantMenuUrl')
    if (isWhiteLabel() && url !== null) {
      return url
    }
  }

  return '/'
}
