import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { show, hide } from 'store/modals'
import StylableModal from 'components.v2/Modal/StylableModal'
import GuestCheckoutForm from 'common/auth/GuestCheckoutForm/v2'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { Container, Title, BackButton } from '../auth/Shared/AuthModalV2'
import Icon from 'components.v2/Icon/IconV2'
import { types } from '../../store/modals/modals'
import styled from 'styled-components'
import media from 'utils/media'
import { MobileScreen, DesktopScreen } from 'utils/screen'

const withModalContainer = connect(
  null,
  { show, hide },
)

const GuestContainer = styled(Container)`
  ${media.phone`
    height: 100%;
    width: 100%;
  `}
`

const CustomModal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 400px;
    width: ${p => (p.small ? 'calc(100vw - 60px)' : 'calc(100vw - 30px)')};
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;

    ${media.phone`
      max-width: 100vw;
      width: 100%;
      height: 100%;
      border-radius: 0px;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const Header = styled(Title)`
  ${media.phone`
    flex-direction: column;
    gap: 32px;
    & > div {
      text-align: left;
    }
  `}
`

class GuestModal extends React.Component {
  handleRequestCLose = () => {
    if (this.props.disableRequestCLose !== true) {
      this.props.hide()
    }
  }

  render() {
    const { hide, show } = this.props

    return (
      <CustomModal
        isOpen
        contentLabel="Guest Checkout"
        onRequestClose={this.handleRequestCLose}
        noCloseButton
      >
        <GuestContainer className="guest">
          <Header>
            <BackButton
              className="mr-auto"
              onClick={() => {
                show(types.AUTH, {
                  hasGuestCheckoutEnabled: true,
                })
              }}
            >
              <DesktopScreen>
                <Icon src="BackButton.svg" />
              </DesktopScreen>
              <MobileScreen>
                <Icon src="CloseIcon.svg" />
              </MobileScreen>
            </BackButton>
            <div className="w-full text-center">Express guest checkout</div>
          </Header>

          <GuestCheckoutForm
            actionsReversed
            onSuccess={hide}
            className="w-full"
            buttonAlign="center"
          />
        </GuestContainer>
      </CustomModal>
    )
  }
}

export default compose(
  withModalContainer,
  withAnalyticsHandler,
)(GuestModal)
