import React from 'react'
import PT from 'prop-types'
import { ButtonIcon } from 'common/auth/Shared/AuthModalV2'
import Icon from 'components.v2/Icon/IconV2'

/**
 * Note: this is dumb component, stateful version is available in the same name in `common/auth` folder
 */

const SocialButton = ({
  actionText = 'Login with',
  extended,
  variant,
  className,
  ...props
}) => (
  <ButtonIcon variant={variant} {...props}>
    <Icon
      src={
        {
          facebook: 'GuestFacebook.svg',
          google: 'GuestGoogle.svg',
        }[variant]
      }
    />
  </ButtonIcon>
)

SocialButton.propTypes = {
  variant: PT.oneOf(['facebook', 'google']),
  /** if true, text with read "Sign up with <SocialMediaName>" instead of "<SocialMediaName>" */
  extended: PT.bool,
  actionText: PT.string,
}

export default SocialButton
