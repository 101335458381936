import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link as LinkBase } from 'react-router-dom'
import divideToCols from 'utils/divideToCols'

import { mobileFirstMedia as media } from 'utils/media'
import Button from 'components.v2/Button'
import DesktopContainer from 'layouts/DesktopContainer'
import Icon from 'components/Icon.v2'
import LinkGroup from './LinkGroup'
import TextSection from './TextSection'
import LINKS from './../../const/links'
import withContainer from './PageFooterContainer'

const PageFooter = ({
  cuisineLinks,
  topSuburbsLinks,
  suburbLinks,
  aboutLinks,
  privacyPolicyLink,
  termsLink,
  noFooter,
}) => {
  if (noFooter) return <div />

  return (
    <footer>
      <DesktopContainer background="primary">
        <UpperContainer className="flex flex-wrap xl:pt-50 xl:pb-40">
          <AboutLinkGroup heading="Company" className="w-full xl:w-1/4">
            {renderLinksFactory(aboutLinks)}
          </AboutLinkGroup>

          <LinkGroup heading="Cuisines" className="w-full xl:w-1/4">
            {renderLinksFactory(cuisineLinks)}
          </LinkGroup>

          <LinkGroup
            heading="Suburbs"
            className="hidden xl:block w-full xl:w-1/4"
          >
            {renderLinksFactory(suburbLinks, true)}
          </LinkGroup>

          <LinkGroup heading="Suburbs" className="xl:hidden w-full">
            {({ openLinkGroup }) => (
              <section className="py-5 bg-primaryShaded">
                {topSuburbsLinks.map(({ title, items }, i) => (
                  <Fragment key={i}>
                    <section className="pt-20 pb-15 px-15">
                      <SuburbsHeading className="pb-20">{title}</SuburbsHeading>

                      {items.map((link, j) => {
                        const content = (
                          <React.Fragment>
                            {link.title}
                            {link.hasArrow && <ArrowIcon />}
                          </React.Fragment>
                        )
                        return (
                          <Link
                            className="py-5"
                            to={link.to}
                            key={j}
                            onFocus={openLinkGroup}
                          >
                            {content}
                          </Link>
                        )
                      })}
                    </section>
                    <SuburbsDivider />
                  </Fragment>
                ))}
              </section>
            )}
          </LinkGroup>

          <div className="xl:hidden w-full">
            <Buttons />
          </div>

          <TextSection
            heading="Acknowledgement of Country"
            className="flex xl:block w-full xl:w-1/4"
          >
            Hey You would like to acknowledge the Traditional Custodians of the
            land on which we live and work, The Gadigal of the Eora Nation are
            the traditional custodians. We recognise their continuing connection
            to the land, rivers, sea and ceremonial grounds and extend that
            respect to all Aboriginal and Torres Strait Island Nations. We pay
            our respect to Elders past, present and emerging.
          </TextSection>
        </UpperContainer>
        <div className="xl:flex xl:justify-between text-center px-15 pt-15 pb-30 xl:px-0 xl:py-40">
          <Copyright>
            &copy; {new Date().getFullYear()} Hey You, All Rights Reserved.
            <CopyrightLinkContainer>
              <NativeCopyrightLink
                className="px-7pt5 py-5 xl:px-0"
                href={privacyPolicyLink.to}
              >
                <span className="hidden xl:block px-10">&#124;</span>{' '}
                {privacyPolicyLink.title}
              </NativeCopyrightLink>
              <NativeCopyrightLink
                className="px-7pt5 py-5 xl:px-0"
                href={termsLink.to}
              >
                <span className="xl:px-10 pr-10 pl-0">&#124;</span>{' '}
                {termsLink.title}
              </NativeCopyrightLink>
            </CopyrightLinkContainer>
          </Copyright>
          <section className="flex items-center justify-center pt-10 xl:p-0">
            <SocialLink
              target="_blank"
              className="instagram"
              rel="noopener"
              href={LINKS.INSTAGRAM}
            >
              <Icon type="instagram" />
            </SocialLink>
            <SocialLink
              target="_blank"
              rel="noopener"
              className="twitter"
              href={LINKS.TWITTER}
            >
              <Icon type="twitter" />
            </SocialLink>
            <SocialLink
              target="_blank"
              className="facebook"
              rel="noopener"
              href={LINKS.FACEBOOK}
            >
              <Icon type="facebook" />
            </SocialLink>
          </section>
        </div>
      </DesktopContainer>
    </footer>
  )
}

export default withContainer(PageFooter)

const Buttons = () => (
  <ButtonParentContainer>
    <Fragment>
      <ButtonContainer className="p-5 xl:px-0 xl:w-1/2">
        <Button
          as="a"
          className="block w-full"
          href={LINKS.APP_DOWNLOAD_IOS}
          target="_blank"
          rel="noopener"
          variant="alt-primary"
        >
          Download our app
        </Button>
      </ButtonContainer>
      <ButtonContainer className="p-5 xl:px-0 xl:w-1/2">
        <Button
          as="a"
          className="block w-full"
          href={LINKS.LIST_BUSINESS}
          target="_blank"
          rel="noopener"
          variant="outline-alt-neutral"
        >
          Partner with us
        </Button>
      </ButtonContainer>
    </Fragment>
  </ButtonParentContainer>
)

const renderLinksFactory = (links, hasButtons) => ({ openLinkGroup }) => (
  <section className="flex flex-wrap px-7pt5 py-15 xl:p-0 bg-primaryShaded xl:bg-transparent">
    {divideToCols(2, links).map((col, i) => (
      <div className="w-1/2 xl:w-full" key={i}>
        {col.map((link, j) => {
          const content = (
            <React.Fragment>
              {link.title}
              {link.hasArrow && <ArrowIcon className="xl:hidden" />}
            </React.Fragment>
          )
          if (
            link.to.indexOf('://') > 0 ||
            link.to.indexOf('//') === 0 ||
            link.to.indexOf('mailto:') === 0
          ) {
            return (
              <NativeLink
                className="px-7pt5 py-5 xl:px-0"
                href={link.to}
                key={j}
                onFocus={openLinkGroup}
              >
                {content}
              </NativeLink>
            )
          } else {
            return (
              <Link
                className="px-7pt5 py-5 xl:px-0"
                to={link.to}
                key={j}
                onFocus={openLinkGroup}
              >
                {content}
              </Link>
            )
          }
        })}
      </div>
    ))}
    {hasButtons && <Buttons />}
  </section>
)

const BaseLinkStyle = `
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
`

const InlineBaseLinkStyle = `
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
`

const Link = styled(LinkBase)`
  ${BaseLinkStyle}
  color: ${p => p.theme.colors.textAltPrimary};
`

const NativeLink = styled.a`
  ${BaseLinkStyle}
  color: ${p => p.theme.colors.textAltPrimary};
`

const NativeCopyrightLink = styled.a`
  ${InlineBaseLinkStyle}
  color: ${p => p.theme.colors.textAltPrimary};  
`

const CopyrightLinkContainer = styled.div`
  display: block;
  ${media.screen`
    display: inline-block;
  `}
`

const ArrowIcon = styled(Icon).attrs({ type: 'arrowRight' })`
  font-size: 12px;
  margin-left: 8px;
`

const SuburbsHeading = styled.h5`
  font-size: 11px;
  color: ${p => p.theme.colors.textAltPrimary};
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const SuburbsDivider = styled.h5`
  margin: 0 15px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.15);

  :last-of-type {
    display: none;
  }
`

const ButtonContainer = styled.div`
  ${media.screen`
    &&& { width: 170px; }
  `}
`

const AboutLinkGroup = styled(LinkGroup)`
  ${media.screen`
    order: -1;
  `}
`

const UpperContainer = styled.div`
  ${media.screen`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  `}
`

const Copyright = styled.small`
  font-size: 12px;
  font-weight: 300;
  color: white;
  letter-spacing: 0.5px;

  ${media.screen`
    font-size: 13px;
  `}
`

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  margin: 0 7.5px;
  color: white;
  font-size: 15px;

  &.twitter {
    font-size: 18px;
  }

  &.facebook {
    margin-left: 2.5px;
  }

  ${media.screen`
    font-size: 17px;

    &.twitter {
      font-size: 21px;
    }

    &.facebook {
      margin-right: 0;
    }
  `}
`

const ButtonParentContainer = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  padding: 0px 15px 20px;

  ${media.screen`
    margin-top: 25px;
    justify-content: flex-start;	
    display: block;
    border-bottom: none;
    padding: 0;
  `}
`
