export const SOURCE_DELETE_CONFIRM = {
  type: 'delete',
  title: 'Delete payment source?',
  description:
    'Are you sure you want to make delete this payment source? This action cannot be undone.',
  confirmText: 'Delete',
}

export const SOURCE_PRIMARY_CONFIRM = {
  type: 'primary',
  title: 'Make Default?',
  description: 'Are you sure you want to make this source default?',
  confirmText: 'Confirm',
}
