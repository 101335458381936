import React from 'react'
import asset from 'utils/asset'
import { Footer, FooterContent, Logo, Title } from './styled'

const PageFooter = () => (
  <Footer>
    <FooterContent>
      <Title>Powered by</Title>
      <a
        href="https://heyyou.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo src={asset('icons/heyyou-logo.svg')} />
      </a>
    </FooterContent>
  </Footer>
)

export default PageFooter
