import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import { hide, show } from 'store/modals'
import Modal from 'components.v2/Modal'
import { Heading, Text, ModalButton, List, ListItem } from './styled'

class GenericModal extends PureComponent {
  handleHide = () => {
    const { hide } = this.props
    hide()
  }

  render() {
    const {
      data: {
        heading,
        subHeading,
        subHeadingList,
        buttonLabel,
        button: ButtonComponent,
      },
    } = this.props
    return (
      <Modal
        isOpen
        contentLabel="Serving To Table"
        onRequestClose={this.handleHide}
        className="text-center pb-20 px-20"
        small
        noCloseButton
      >
        <Fragment>
          {heading && <Heading className="mt-40">{heading}</Heading>}
          {subHeadingList && (
            <List>
              {subHeadingList.map(name => (
                <ListItem key={name}>{name}</ListItem>
              ))}
            </List>
          )}
          {subHeading && <Text as="p">{subHeading}</Text>}
          <div className="flex">
            <div className="w-full">
              {ButtonComponent ? (
                <ButtonComponent handleHide={this.handleHide} />
              ) : (
                <ModalButton
                  className="w-full font-bold"
                  large
                  largeText
                  type="button"
                  variant="primary"
                  onClick={this.handleHide}
                >
                  {buttonLabel ? buttonLabel : 'Confirm'}
                </ModalButton>
              )}
            </div>
          </div>
        </Fragment>
      </Modal>
    )
  }
}

GenericModal.propTypes = {
  show: PT.func.isRequired,
  hide: PT.func.isRequired,
  data: PT.shape({
    heading: PT.string,
    subHeading: PT.string,
    subHeadingList: PT.array,
    buttonLabel: PT.string,
    button: PT.ButtonComponent,
  }).isRequired,
}

const withContainer = connect(
  () => ({}),
  { show, hide },
)

export default withContainer(GenericModal)
