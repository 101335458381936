import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Text from 'components.v2/Text'

import { mobileFirstMedia as media } from 'utils/media'
import asset from 'utils/asset'

const EmptySearch = ({ className }) => (
  <section className={`text-center ${className}`}>
    <Image alt="" src={asset('images/empty-search.png')} />
    <Text variant="h2" className="mt-25" color="gray4" align="center">
      No match found
    </Text>
    <Text color="gray4" align="center" light>
      Please check your spelling or try
      <br /> searching for something else
    </Text>
  </section>
)

EmptySearch.propTypes = {
  className: PT.string,
}

export default EmptySearch

const Image = styled.img`
  width: 150px;

  ${media.screen`
    width: 250px;
  `}
`
