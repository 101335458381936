import { createPromiseAction } from 'utils/asyncActions'
import { handleActions } from 'redux-actions'
import client from 'client'
import get from 'lodash/get'
import omit from 'lodash/omit'

export const fetchProfile = createPromiseAction('unicorn/profile/FETCH_PROFILE')

const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

const initialState = {
  isLoaded: false,
  isLoading: false,
  customer: {
    address: {},
    accounts: [],
    balance: { total: 0, available: '$0', expire: '' },
  },
  isUpdating: false,
}

export const updateProfile = function(data = {}) {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST })

      const updateResponse = await client().put('/customers/me', data)

      // Only update email. Not retrieving updated data
      if (
        !(Object.keys(data).length === 1 && Object.keys(data)[0] === 'email') &&
        updateResponse
      ) {
        const response = await client().get('/customers/me')

        const customer = get(response, 'data.customer', {})

        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          customer,
        })
      }
    } catch (err) {
      dispatch({ type: UPDATE_PROFILE_FAILURE })
      throw err
    }
  }
}

export const updatePhoto = function(data) {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST })

      const updateResponse = await client().post('/customers/me/image', data, {
        headers: { 'content-type': data.type },
      })

      if (updateResponse) {
        const response = await client().get('/customers/me')

        const customer = get(response, 'data.customer', {})

        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          customer,
        })
      }
    } catch (err) {
      dispatch({ type: UPDATE_PROFILE_FAILURE })
      throw err
    }
  }
}

export const sendConfirmEmail = function() {
  return async () => {
    try {
      await client().post('/customers/me/send_email_verification')
    } catch (err) {
      throw err
    }
  }
}

export default handleActions(
  {
    [fetchProfile]: state => ({ ...state, isLoading: true }),
    [fetchProfile.fulfilled]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      customer: payload,
    }),
    [fetchProfile.rejected]: state => ({
      ...state,
      isLoading: false,
      isLoaded: false,
    }),
    [UPDATE_PROFILE_REQUEST]: state => ({
      ...state,
      isUpdating: false,
    }),
    [UPDATE_PROFILE_FAILURE]: state => ({
      ...state,
      isUpdating: false,
    }),
    [UPDATE_PROFILE_SUCCESS]: (state, action) => {
      return {
        ...state,
        isUpdating: false,
        customer: {
          ...state.customer,
          ...action.customer,
        },
      }
    },
  },
  initialState,
)

export const selectIsLoading = state => state.profile.isLoading
export const selectIsLoaded = state => state.profile.isLoaded

export const selectProfile = state => {
  return omit(state.profile.customer, ['accounts', 'address', 'balance'])
}

export const selectIsUpdating = state => state.profile.isUpdating

export const selectAccounts = state => {
  return get(state.profile, 'customer.accounts', [])
}

export const selectAddress = state => get(state.profile, 'customer.address', [])
