import styled from 'styled-components'
import { setDefault } from 'utils/css'

export const StyledText = styled.span`
  color: ${props => props.theme.colors[props.color]};
  font-weight: ${props => props.fontWeight};

  font-size: ${props => props.size}px;
`

export const TextContainer = element => styled(`${element}`)`
  margin: 0;
  color: ${props => props.theme.colors[props.color]};
  font-size: ${props => setDefault(props.size, 'inherit', 'px')};
  line-height: ${props => setDefault(props.lineHeight, 'inherit', 'px')};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => setDefault(props.align, 'inherit')};
  text-transform: ${props => setDefault(props.transform, 'none')};
`
