import React from 'react'

const CreditCard = props => (
  <svg
    width='1em'
    height='1em'
    fillRule='evenodd'
    clipRule='evenodd'
    {...props}
  >
    <path d='M22 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h20zm1 8H1v8a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-8zM8 16v1H3v-1h5zm13-2v1h-3v-1h3zm-10 0v1H3v-1h8zM1 8v2h22V8H1zm22-1V5a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h22z' />
  </svg>
)

export default CreditCard
