import 'nodelist-foreach-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'

import ErrorBoundary from 'containers/ErrorBoundary'
import App from './App'
import store, { persistor } from 'store'

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

renderMethod(
  <ErrorBoundary>
    <App store={store} persistor={persistor} />
  </ErrorBoundary>,
  document.getElementById('root'),
)
