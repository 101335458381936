import React from 'react'

const AngleRight = props => (
  <svg width='1em' height='1em' viewBox='0 0 6 10' {...props}>
    <path
      d='M1.333 1.202l3.334 3.846-3.334 3.91'
      stroke='#999'
      strokeWidth={1.5}
      fill='none'
      fillRule='evenodd'
    />
  </svg>
)

export default AngleRight
