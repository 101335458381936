import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import StripeForm from './StripeForm'
import {
  Modal,
  ModalCloseButton,
  ModalCloseButtonIcon,
  ModalHeading,
  ModalLoader,
} from './styled'

import { addPaymentSource, fetchPaymentConfig } from 'store/payment'
import withCustomer from 'enhancers/withCustomer'

import get from 'lodash/get'

import { LoadingScreen } from 'components.v2/Loading/styled'
import Spinner from 'components.v2/Spinner'
import notif from 'services/notification'

/**
 * Component for handling adding of to user account.
 */
class CardAdderModal extends React.Component {
  state = {
    isLoading: true,
    stripePromise: null,
  }

  /**
   * @property {Object} propTypes
   */
  static propTypes = {
    isOpen: PT.bool,
    onClose: PT.func,
  }

  /**
   * Handles close modal
   */
  handleClose = () => this.props.onClose()

  async getStripeKey() {
    return this.props
      .fetchPaymentConfig()
      .then(data => {
        const stripePromise = loadStripe(
          get(data, 'value.stripe.publishableKey'),
        )
        this.setState({ stripePromise: stripePromise, isLoading: false })
      })
      .catch(error => {
        this.setState({ isLoading: false })
        notif.error({
          title: 'Adding new payment source failed',
          message: error.message,
          error,
        })
        this.props.onClose()
      })
  }

  componentDidMount() {
    this.getStripeKey()
  }

  render() {
    const { isOpen } = this.props
    return (
      <Modal isOpen={isOpen}>
        <ModalCloseButton onClick={this.handleClose}>
          <ModalCloseButtonIcon />
        </ModalCloseButton>
        <ModalHeading>Enter card details</ModalHeading>
        {!this.state.isLoading ? (
          <Elements stripe={this.state.stripePromise}>
            <StripeForm
              addPaymentSource={this.props.addPaymentSource}
              handleClose={this.handleClose}
            />
          </Elements>
        ) : (
          <ModalLoader>
            <LoadingScreen>
              <Spinner
                className="mx-auto"
                width={40}
                height={40}
                thickness={5}
              />
            </LoadingScreen>
          </ModalLoader>
        )}
      </Modal>
    )
  }
}

const withRedux = connect(
  null,
  { addPaymentSource, fetchPaymentConfig },
)

export default compose(
  withRedux,
  withCustomer,
)(CardAdderModal)
