export const fonts = {
  h1: {
    size: 24,
    lineHeight: 32
  },
  h2: {
    size: 18,
    lineHeight: 24
  },
  h3: {
    size: 16, lineHeight: 20
  },
  h4: {
    size: 14, lineHeight: 20
  },
  text: {
    size: 14, lineHeight: 20
  },
  smallText: {
    size: 12, lineHeight: 14
  }
}
