import { take, fork, call } from 'redux-saga/effects'

export function* takeFirst(pattern, saga, ...args) {
  return yield fork(function*() {
    while (true) {
      const action = yield take(pattern)
      if (saga) yield call(saga, action, ...args)
    }
  })
}
