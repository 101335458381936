import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import noScroll from 'no-scroll'
import Icon from 'components/Icon'

import { MobileScreen, DesktopScreen } from 'utils/screen'
import WhiteLabelProfileModal from './WhiteLabelProfileModal'
import WhiteLabelProfileMenu from './WhiteLabelProfileMenu'
import { ProfileContainer, Avatar } from './styled'
import { connect } from 'react-redux'
import {
  selectIsLoaded,
  fetchProfile,
  selectIsLoading,
} from 'auth/modules/profile'
import { selectIsAuthenticated } from 'auth/modules/auth'

class WhiteLabelProfile extends React.Component {
  state = {
    isOpen: false,
  }

  componentDidMount = () => {
    if (this.props.shouldFetchProfile) this.props.fetchProfile()
  }

  componentWillUnmount() {
    noScroll.off()
  }

  toggleOpen = () => {
    if (this.state.isOpen) {
      noScroll.off()
    } else {
      noScroll.on()
    }
    const isOpen = !this.state.isOpen
    this.props.openProfile(isOpen)
    this.setState({
      isOpen,
    })
  }

  logout = () => {
    this.toggleOpen()
    this.props.logout()
  }

  render() {
    const { image, firstname, lastname } = this.props
    const { isOpen } = this.state
    const icon = isOpen ? 'up' : 'down'

    return (
      <Fragment>
        <MobileScreen>
          <ProfileContainer pointer onClick={this.toggleOpen}>
            <Avatar avatar={image} />
            <Icon name={`fas fa-chevron-${icon}`} size={10} />
          </ProfileContainer>
          <WhiteLabelProfileModal
            isOpen={isOpen}
            toggleOpen={this.toggleOpen}
            logout={this.logout}
            image={image}
            firstname={firstname}
            lastname={lastname}
          />
        </MobileScreen>
        <DesktopScreen>
          <ProfileContainer>
            <Avatar avatar={image} />
            <Icon name={`fas fa-chevron-${icon}`} size={10} />
            <WhiteLabelProfileMenu avatar={image} />
          </ProfileContainer>
        </DesktopScreen>
      </Fragment>
    )
  }
}

WhiteLabelProfile.propTypes = {
  logout: PropTypes.func.isRequired,
  openProfile: PropTypes.func,
  image: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
}

const withState = connect(
  state => ({
    shouldFetchProfile:
      selectIsAuthenticated(state) &&
      !selectIsLoaded(state) &&
      !selectIsLoading(state),
  }),
  { fetchProfile },
)

export default withState(WhiteLabelProfile)
