import React from 'react'
import { Footer, FooterContent, Title } from './styled'
import withContainer from './PageFooterContainer'
import Icon from 'components.v2/Icon/IconV2'

const PageFooter = ({ noFooter }) => {
  if (noFooter) return <div />

  return (
    <Footer>
      <FooterContent
        href="https://info.heyyou.com.au/getstarted"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Title>Powered by</Title>
        <Icon src="heyyou-logo.svg" />
      </FooterContent>
    </Footer>
  )
}

export default withContainer(PageFooter)
