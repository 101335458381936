import update from 'immutability-helper'
import get from 'lodash/get'

import { handleActions, combineActions } from 'redux-actions'

import {
  FETCH_ORDER_PARTIES,
  GET_ORDER_PARTY,
  CREATE_ORDER_PARTY,
  LEAVE_ORDER_PARTY,
  DELETE_ORDER_PARTY,
  DETACH_PARTY,
  GET_ORDER_PARTY_STATUS,
  pullStatus,
  placeOrderParty,
} from './order-party.constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  latestPlaceOrderParty: '',
  placeOrderParty: {},
  parties: {},
}

const createParties = (items = []) => {
  const parties = {}
  for (let i = 0; i < items.length; i++) {
    parties[items[i]['vendorId']] = items[i]
  }
  return parties
}

export default handleActions(
  {
    [FETCH_ORDER_PARTIES.REQUEST]: state => {
      return update(state, { isLoading: { $set: true } })
    },
    [FETCH_ORDER_PARTIES.SUCCESS]: (state, { payload: { parties } }) => {
      return update(state, {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        parties: { $set: createParties(parties) },
      })
    },
    [FETCH_ORDER_PARTIES.FAILURE]: state => {
      return update(state, {
        isLoading: { $set: false },
        isLoaded: { $set: false },
      })
    },
    [GET_ORDER_PARTY.REQUEST]: state => {
      return update(state, {
        isLoading: { $set: true },
      })
    },
    [GET_ORDER_PARTY.SUCCESS]: (state, { payload: { party } }) => {
      return {
        ...state,
        parties: {
          ...state.parties,
          [party.vendorId]: {
            ...get(state.parties, party.vendorId, {}),
            ...party,
          },
        },
        isLoading: false,
      }
    },
    [`${CREATE_ORDER_PARTY}_PENDING`]: state => {
      return update(state, {
        isLoading: { $set: true },
        isLoaded: { $set: false },
      })
    },
    [`${CREATE_ORDER_PARTY}_FULFILLED`]: (state, { payload }) => {
      const { vendorId } = JSON.parse(get(payload, 'config.data', {}))
      const partyCode = get(payload, 'data.inviteLink', '')
        .split('/')
        .slice(-1)[0]
      return {
        ...state,
        parties: {
          [vendorId]: {
            ...get(payload, 'data'),
            partyCode,
          },
        },
        type: 'host',
        isLoading: false,
        isLoaded: true,
      }
    },
    [`${GET_ORDER_PARTY.FINISHED}`]: (state, { payload }) => {
      const { vendorId } = payload
      return {
        ...state,
        parties: {
          [vendorId]: payload,
        },
        isLoading: false,
        isLoaded: true,
      }
    },
    [`${GET_ORDER_PARTY_STATUS}_FULFILLED`]: (state, { payload }) => {
      const latestPlacedOrderId = get(state, 'latestPlaceOrderParty')
      const isLatest = latestPlacedOrderId === get(payload, 'id')
      return {
        ...state,
        placeOrderParty: {
          ...get(state, 'placeOrderParty'),
          ...(isLatest && {
            [get(payload, 'id')]: {
              ...get(state, `placeOrderParty.${get(payload, 'id')}`),
              ...payload,
            },
          }),
        },
        ...(!isLatest && {
          parties: {
            ...get(state, 'parties'),
            [get(payload, 'vendorId')]: {
              ...get(state, `parties.${get(payload, 'vendorId')}`),
              ...payload,
            },
          },
        }),
      }
    },
    [String(placeOrderParty.fulfilled)]: (state, { payload: venueId }) => {
      const partyId = get(state, `parties.${venueId}.id`)
      return {
        ...state,
        latestPlaceOrderParty: partyId,
        placeOrderParty: {
          ...state.placeOrderParty,
          [partyId]: get(state, `parties.${venueId}`),
        },
        parties: {
          ...get(state, 'parties'),
          [venueId]: {},
        },
      }
    },
    [String(pullStatus.fulfilled)]: (state, { payload }) => {
      const latestPlaceOrderParty = get(state, 'latestPlaceOrderParty')
      const isLatest = latestPlaceOrderParty === get(payload, 'id')

      return {
        ...state,
        placeOrderParty: {
          ...get(state, 'placeOrderParty'),
          ...(isLatest && {
            [get(payload, 'id')]: payload,
          }),
        },
        parties: {
          ...get(state, 'parties'),
          ...(!isLatest && {
            [get(payload, 'vendorId')]: {
              ...get(state, `parties.${get(payload, 'vendorId')}`),
              ...payload,
            },
          }),
        },
      }
    },
    [String(pullStatus.rejected)]: (state, { payload }) => {
      const latestPlaceOrderParty = get(state, 'latestPlaceOrderParty')
      const isLatest = latestPlaceOrderParty === get(payload, 'id')

      return {
        ...state,
        placeOrderParty: {
          ...get(state, 'placeOrderParty'),
          ...(isLatest && {
            [get(payload, 'id')]: payload,
          }),
        },
        ...(!isLatest && {
          parties: {
            ...get(state, 'parties'),
            [get(payload, 'vendorId')]: {
              ...get(state, `parties.${get(payload, 'vendorId')}`),
              ...payload,
            },
          },
        }),
      }
    },
    [combineActions(
      DETACH_PARTY,
      DELETE_ORDER_PARTY.SUCCESS,
      LEAVE_ORDER_PARTY.SUCCESS,
      GET_ORDER_PARTY_STATUS,
    )]: (state, { payload: { venueId } }) => {
      return update(state, {
        parties: {
          $unset: [venueId],
        },
      })
    },
  },
  initialState,
)
