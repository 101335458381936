import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { Link, Button } from 'common/auth/Shared/sharedStyled'
import withContainer from './RegisterFormContainer'
import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import { LoginFormHeader as Title } from 'common/auth/Shared/AuthModalV2'
import Icon from 'components.v2/Icon/IconV2'
import { types } from 'store/modals'
import get from 'lodash/get'
import media from 'utils/media'

const Form = styled.form`
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  ${media.phone`
    margin-bottom: 90px;
  `}

  & > div > div > input {
    background: #f2f2f2;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    & :focus {
      border: 1.5px solid #f26722;
    }

    & :not(:focus) {
      border: 1px solid #cccccc;
    }
    border-radius: 8px;
  }

  & > div > div.block.w-full {
    margin-top: 20px;
  }

  & > .hide {
    display: none !important;
  }

  & > div > button[type='button'] {
    margin-top: 12px;
  }

  & > button[type='submit'] {
    margin-top: 32px;
  }
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  height: 52px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  ${media.phone`
    position: absolute;
    width: calc(100% - 48px) !important;
    bottom: 40px;
  `}
`

const ReferralButton = styled(Link)`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;

  color: #2e9a9a;
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
`

const RegisterForm = ({
  fields,
  isAuthenticating,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  isReferralCodeVisible,
  setIsReferralCodeVisible,
  email,
  show,
}) => (
  <Form onSubmit={handleSubmit} className={`${className} text-left`}>
    <Title>
      <Icon
        src="BackButton.svg"
        className="mr-auto"
        onClick={() => {
          show(types.LOGIN_V2, {
            hasGuestCheckoutEnabled: true,
          })
        }}
      />
      <div className="w-full text-center">Let’s create your account!</div>
    </Title>

    <div>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.firstName.value}
        hasError={!!fields.firstName.error}
        className="block w-full"
        type="text"
        name="firstName"
        placeholder="First name"
      />
      {get(fields, 'firstName.error') && (
        <CustomFormError>{get(fields, 'firstName.error')}</CustomFormError>
      )}
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.lastName.value}
        hasError={!!fields.lastName.error}
        className="block w-full mt-10"
        type="text"
        name="lastName"
        placeholder="Last name"
      />
      {get(fields, 'lastName.error') && (
        <CustomFormError>{get(fields, 'lastName.error')}</CustomFormError>
      )}
      <Input
        {...(email ? { autoFocus: true } : {})}
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.email.value || email}
        hasError={!!fields.email.error}
        className="block w-full mt-10"
        type="email"
        name="email"
        placeholder="Email"
      />
      {get(fields, 'email.error') && (
        <CustomFormError>{get(fields, 'email.error')}</CustomFormError>
      )}
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.mobile.value}
        hasError={!!fields.mobile.error}
        className="block w-full mt-10"
        type="tel"
        name="mobile"
        placeholder="Mobile"
      />
      {get(fields, 'mobile.error') && (
        <CustomFormError>{get(fields, 'mobile.error')}</CustomFormError>
      )}
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={fields.password.value}
        hasError={!!fields.password.error}
        className="block w-full mt-10"
        type="password"
        name="password"
        placeholder="Password"
      />
      {get(fields, 'password.error') && (
        <CustomFormError>{get(fields, 'password.error')}</CustomFormError>
      )}
      {isReferralCodeVisible && (
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={fields.referralCode.value}
          hasError={!!fields.referralCode.error}
          className="block w-full mt-10"
          type="text"
          name="referralCode"
          placeholder="Referral code"
        />
      )}
      {!isReferralCodeVisible && (
        <ReferralButton
          type="button"
          onClick={() => setIsReferralCodeVisible(true)}
          className="w-full"
        >
          Have a referral code?
        </ReferralButton>
      )}
    </div>

    <SubmitButton
      variant="primary"
      type="submit"
      disabled={isAuthenticating}
      actionsReversed={actionsReversed}
      className="w-full"
    >
      {isAuthenticating ? 'Processing…' : 'Create acount'}
    </SubmitButton>
  </Form>
)

RegisterForm.propTypes = {
  ...formPropTypes,
  isReferralCodeVisible: PT.bool.isRequired,
  setIsReferralCodeVisible: PT.func.isRequired,
}

export default withContainer(RegisterForm)
