import React, { Fragment } from 'react'
import PT from 'prop-types'

/** Renders it's (`string`) children, replacing spaces with non-breaking spaces (`&nbsp;`) */
const NonBreakingPhrase = ({ children }) =>
  children.split(' ').map((w, i, ws) => (
    i === ws.length - 1
      ? <Fragment key={i}>{w}</Fragment>
      : <Fragment key={i}>{w}&nbsp;</Fragment>
  ))

NonBreakingPhrase.propTypes = {
  children: PT.string.isRequired
}

/** @component */
export default NonBreakingPhrase
