import React, { PureComponent, Fragment } from 'react'
import PT from 'prop-types'
import { Heading, Text } from './styled'
import Button from 'components.v2/Button'

export default class Confirmation extends PureComponent {
  static defaultProps = {}

  static propTypes = {
    onPrevious: PT.func.isRequired,
    onLogin: PT.func.isRequired,
  }

  render() {
    const { onPrevious, onLogin } = this.props

    return (
      <Fragment>
        <Heading className="mt-40">Check your email</Heading>
        <Text as="p" className="mt-10 mb-30 xl:mt-15">
          Click on the link that has been sent to your email to continue
          resetting your password.
        </Text>
        <div className="flex -mx-2">
          <div className="w-1/2 px-5">
            <Button
              className="w-full"
              large
              largeText
              type="button"
              variant="outline-primary"
              onClick={onPrevious}
            >
              Resend Email
            </Button>
          </div>

          <div className="w-1/2 px-5">
            <Button
              className="w-full"
              large
              largeText
              type="button"
              variant="primary"
              onClick={onLogin}
            >
              Login
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}
