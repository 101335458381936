import React from 'react'
import { withProps } from 'recompose'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import PT from 'prop-types'

import { trackEvent } from 'utils/firebase'
import { getAppType } from 'utils/app'

export const withAnalyticsHandler = withProps({
  trackEvent,
})

export default (options = []) => WrappedComponent => {
  class AnalyticsComponent extends React.Component {
    static propTypes = {
      analytics: PT.arrayOf(
        PT.shape({
          eventType: PT.string.isRequired,
          options: PT.oneOfType([PT.object, PT.array]),
        }),
      ),
      trackEvent: PT.func,
    }

    handleEvent = (eventType, options) => (...args) => {
      if (typeof this.props[eventType] === 'function') {
        this.props[eventType](...args)
      }

      if (isPlainObject(options)) {
        this.props.trackEvent({
          ...options,
          app_type: getAppType(),
        })
        return
      }

      if (isArray(options)) {
        options.forEach(option =>
          this.props.trackEvent({
            ...option,
            app_type: getAppType(),
          }),
        )
        return
      }
    }

    createEventHandlers = opts => {
      if (isArray(opts) && opts.length > 0) {
        const handlers = {}
        for (let i = 0; i < opts.length; i++) {
          handlers[opts[i]['eventType']] = this.handleEvent(
            opts[i]['eventType'],
            opts[i]['options'],
          )
        }
        return handlers
      } else {
        return {}
      }
    }

    render() {
      const { analytics, trackEvent, ...rest } = this.props
      const props = {
        ...rest,
        ...this.createEventHandlers(analytics || options),
      }
      return <WrappedComponent {...props} />
    }
  }
  return withAnalyticsHandler(AnalyticsComponent)
}
