import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { withState } from 'recompose'
import { Collapse } from 'react-collapse'
import { mobileFirstMedia as media } from 'utils/media'
import Icon from 'components/Icon.v2'

const withContainer = withState('isOpen', 'setOpen', false)
const LinkGroup = ({ isOpen, setOpen, heading, children, ...props }) => (
  <section {...props}>
    <Heading onClick={() => setOpen(!isOpen)}>
      <span>{heading}</span>
      <span className="xl:hidden">
        <Icon type={isOpen ? 'angleUp' : 'angleDown'} />
      </span>
    </Heading>

    <Collapse isOpened={isOpen} className="xl:hidden">
      {children({ openLinkGroup: () => setOpen(true) })}
    </Collapse>

    <div className="hidden xl:block">
      {children({ openLinkGroup: () => {} })}
    </div>
  </section>
)

LinkGroup.propTypes = {
  isOpen: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  heading: PT.string.isRequired,
  children: PT.func.isRequired,
}

export default withContainer(LinkGroup)

const Heading = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  padding: 15px;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.textAltPrimary};
  font-size: 12px;
  font-weight: 500;

  ${media.screen`
    padding: 0 0 10px 0;    
    text-transform: uppercase;
    letter-spacing: 1px;
  `}
`
