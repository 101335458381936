import React from 'react'
import PropTypes from 'prop-types'

const desktopContainerFactory = ({ Background, Container }) => {
  const DesktopContainer = ({ background, children, ...props }) => (
    <Background color={background} {...props}>
      <Container>
        {children}
      </Container>
    </Background>
  )

  DesktopContainer.propTypes = {
    background: PropTypes.string,
    children: PropTypes.node
  }

  return DesktopContainer
}

export default desktopContainerFactory
