import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'

import withCuisines from 'store/cuisines/CuisinesContainer'
import { changeCuisineInput } from 'common/CuisineSearchBar/cuisine'
import { searchVenues, updateSearchRoute } from 'pages/search/venues'
import DumbCuisineGrid from 'components.v2/CuisineGrid'

export default compose(
  withCuisines,
  withRouter,

  connect(
    null,
    { changeCuisineInput, searchVenues, updateSearchRoute },
  ),

  // add `onSelect` on cuisine data
  withProps(
    ({
      cuisines,
      changeCuisineInput,
      searchVenues,
      updateSearchRoute,
      history,
    }) => ({
      cuisines: cuisines.map(c => ({
        ...c,
        onSelect: () => {
          changeCuisineInput(c.name)
          searchVenues()

          if (history.location.pathname === '/search') updateSearchRoute()
        },
      })),
    }),
  ),
)(DumbCuisineGrid)
