import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'

const TrendItem = ({ name, emoji, className, onClick }) => (
  <Container className={className} onClick={onClick}>
    <Emoji>{emoji}</Emoji>
    <Name>{name}</Name>
  </Container>
)

TrendItem.propTypes = {
  name: PT.string.isRequired,
  emoji: PT.string.isRequired,
  onClick: PT.func.isRequired,
  className: PT.string,
}

export default TrendItem

const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 10px solid ${p => p.theme.colors.white};
  padding-bottom: 5px;
  height: 95px;
  width: 95px;
  background-color: #FCFCFC;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.03);
`

const Emoji = styled.div`
  font-size: 22px;
  line-height: 100%;
`

const Name = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 100%;
`
