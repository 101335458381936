import styled from 'styled-components'

/* @component */
export default styled.button`
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.primary};
`
