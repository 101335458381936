import styled from 'styled-components'
import PT from 'prop-types'
import { mobileFirstMedia as media } from 'utils/media'

/**
 * NOTE: Deprecated, let's stick with `Text` from now on
 * A `styled` element with configurable `hx` element (`h1` by default)
 * */
const Heading = styled.h1.attrs({
  as: p => p.as || `h${p.level}`,
})`
  color: ${p => p.theme.colors.textPrimary};
  font-weight: 500;
  line-height: 125%;
  font-size: ${p =>
    ({
      '1': '24px',
      '2': '18px',
    }[p.level])};

  ${media.screen`
    font-size: ${p => ({ '1': '30px', '2': '20px' }[p.level])};
  `}
`

Heading.propTypes = {
  /** `1` for h1 tag and styles, `2` for h2, etc */
  level: PT.oneOf([1, 2]),
}

Heading.defaultProps = {
  level: 1,
}

/** @component */
export default Heading
