export const MENU = [
  {
    name: 'free',
    label: 'Get $5',
    icon: 'Gift.svg',
    link: '/account/referral',
    analytics: 'CLICK_REFERRAL',
  },
  {
    name: 'favourites',
    label: 'Favourites',
    icon: 'Heart.svg',
    link: '/account/favourites',
    analytics: 'CLICK_FAVOURITES',
  },
  {
    name: 'payment',
    label: 'Payment',
    icon: 'Coin.svg',
    link: '/account/payment',
    analytics: 'CLICK_PAYMENT',
  },
  {
    name: 'history',
    label: 'History',
    icon: 'Receipt.svg',
    link: '/account/history',
    analytics: 'CLICK_HISTORY',
  },
  {
    name: 'loyalty',
    label: 'Loyalty Cards',
    icon: 'Stamp.svg',
    link: '/account/loyalty',
    analytics: 'CLICK_LOYALTY',
  },
  {
    name: 'redeem',
    label: 'Redeem Code',
    icon: 'Tag.svg',
    link: '/account/redeem',
    analytics: 'CLICK_REDEEM_CODE',
  },
  {
    name: 'contact-us',
    label: 'Contact Us',
    icon: 'Support.svg',
    link: '/account/contact-us',
    analytics: 'CLICK_CONTACT_US',
  },
]

export const MULTIVENDOR_MENU = [
  {
    name: 'payment',
    label: 'Payment',
    icon: 'Coin.svg',
    link: '/account/payment',
    analytics: 'CLICK_PAYMENT',
  },
  {
    name: 'history',
    label: 'History',
    icon: 'Receipt.svg',
    link: '/account/history',
    analytics: 'CLICK_HISTORY',
  },
  {
    name: 'redeem',
    label: 'Redeem Code',
    icon: 'Tag.svg',
    link: '/account/redeem',
    analytics: 'CLICK_REDEEM_CODE',
  },
  {
    name: 'contact-us',
    label: 'Contact Us',
    icon: 'Support.svg',
    link: '/account/contact-us',
    analytics: 'CLICK_CONTACT_US',
  },
]

export const WHITELABEL_MENU = [
  {
    name: 'payment',
    label: 'Payment',
    icon: 'Coin.svg',
    link: '/account/payment',
    analytics: 'CLICK_PAYMENT',
  },
  {
    name: 'history',
    label: 'History',
    icon: 'Receipt.svg',
    link: '/account/history',
    analytics: 'CLICK_HISTORY',
  },
  {
    name: 'contact-us',
    label: 'Contact Us',
    icon: 'Support.svg',
    link: '/account/contact-us',
    analytics: 'CLICK_CONTACT_US',
  },
]
