import { combineReducers } from 'redux'
import { reducer as responsiveReducer } from 'react-responsive-redux'
import { persistReducer } from 'redux-persist'

import { LOGOUT } from 'auth/modules/auth'
import auth from 'auth/modules/auth'
import cuisine from '../common/CuisineSearchBar/cuisine'
import cuisines from './cuisines'
import favourites from './favourites'
import homepage from './homepage'
import location from '../common/LocationSearchBar/location'
import loyalty from './loyalty'
import modals from './modals'
import order from '../pages/order/modules/order'
import multiVendorOrder from '../pages/order/modules/multi.vendor.order'
import orderHistory from './order-history'
import orderParty from './order-party'
import payment from './payment'
import persistConfig from './persistConfig'
import profile from '../auth/modules/profile'
import rehydration from './rehydration'
import smsv from './smsv'
import suburbs from './suburbs'
import trends from './trends'
import vendor from '../pages/vendor/modules'
import venues from 'pages/search/venues'
import regions from './states'
import guestCheckout from './guest-checkout'
import precinct from './precinct'

/**
 * Create base (persisted) app reducer
 */
const appReducer = combineReducers({
  responsive: responsiveReducer,
  order: persistReducer(persistConfig.order, order),
  multiVendorOrder: persistReducer(
    persistConfig.multiVendorOrder,
    multiVendorOrder,
  ),
  orderHistory,
  orderParty: persistReducer(persistConfig.orderParty, orderParty),
  location: persistReducer(persistConfig.location, location),
  auth: persistReducer(persistConfig.auth, auth),
  cuisines, // the cuisines data
  cuisine, // the cuisine input value on cuisine search bar
  vendor,
  profile,
  payment,
  venues,
  homepage,
  modals,
  smsv,
  trends,
  favourites,
  suburbs,
  loyalty,
  rehydration,
  regions,
  guestCheckout: persistReducer(persistConfig.guestCheckout, guestCheckout),
  precinct: persistReducer(persistConfig.precinct, precinct),
})

/**
 * Extend base reducer to reset on logout and track rehydration progress
 */
const resetState = appReducer(undefined, { type: null })
const sensitiveStateKeys = [
  'auth',
  'smsv',
  'profile',
  'order',
  'payment',
  'history',
]

export default (state, action) => {
  let updatedState = appReducer(state, action)

  if (action.type === `${LOGOUT}_PENDING`)
    sensitiveStateKeys.forEach(key => {
      updatedState = {
        ...updatedState,
        [key]: resetState[key],
      }
    })

  return updatedState
}
