import styled from 'styled-components'
import colors from 'theme/colors'

export const ErrorPage = styled.div`
  align-items: center;
  background-color: ${colors['gray7']};
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 78px);
  width: 100%;
`

export const ErrorContent = styled.div`
  text-align: center;
  color: ${colors['gray4']};

  > button {
    width: 150px;
  }
`

export const ErrorImg = styled.img`
  width: 100%;
  max-width: 300px;
`
