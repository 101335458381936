import debounce from 'lodash/debounce'
import isNode from 'detect-node'
import normalizedCompare from 'utils/normalizedCompare'
import bugsnagClient from 'services/bugsnag-client'

const REG_TO_CITY = {
  'New South Wales': 'Sydney',
  'Northern Territory': 'Darwin',
  'Western Australia': 'Perth',
  Victoria: 'Melbourne',
  Queensland: 'Brisbane',
  Tasmania: 'Hobart',
  'Australian Capital Territory': 'Canberra',
  'South Australia': 'Adelaide',
}

const getCitySuburbs = addressCmpts => {
  const suburbObj = addressCmpts.find(ac => ac.types.includes('locality'))
  const suburb = suburbObj && suburbObj.long_name

  const regionObj = addressCmpts.find(ac =>
    ac.types.includes('administrative_area_level_1'),
  )
  const city = regionObj && REG_TO_CITY[regionObj.long_name]

  if (city && suburb) return { city, suburb }
  else return null
}

const matchByCitySuburbs = (items, city, suburb) =>
  items.find(i => {
    const match = getCitySuburbs(i.address_components)
    return (
      match &&
      normalizedCompare(match.city, city) &&
      normalizedCompare(match.suburb, suburb)
    )
  })

const _fetchAutocompleItems = async (input, resolve, reject) => {
  if (isNode) return

  const { autocomplete } = await window.googleServices

  autocomplete.getPlacePredictions(
    {
      input,
      componentRestrictions: {
        country: 'au',
      },
    },
    (predictions, status) => {
      switch (status) {
        case 'OK':
          resolve(predictions)
          break
        case 'ZERO_RESULTS':
          resolve([])
          break
        default:
          resolve([])
          bugsnagClient.notify(
            new Error(
              `Google Autocomplete Service failed with status: ${status}`,
            ),
          )
      }
    },
  )
}

const fetchAutocompleteItemsDebounced = debounce(_fetchAutocompleItems, 400)

export const fetchAutocompleteItems = input =>
  new Promise((resolve, reject) => {
    fetchAutocompleteItemsDebounced(input, resolve, reject)
  })

export const fetchLocationDetails = (
  { citySuburb, placeid, location },
  shouldAddName,
) =>
  new Promise(async (resolve, reject) => {
    if (isNode) return

    let params
    const { geocoder } = await window.googleServices
    const { city, suburb } = citySuburb || {}

    if (citySuburb) {
      const state = Object.entries(REG_TO_CITY).find(([k, v]) =>
        normalizedCompare(v, city),
      )[0]

      params = { address: `${suburb} ${state || city}`, region: 'au' }
    } else if (placeid) {
      params = { placeId: placeid, region: 'au' }
    } else if (location) {
      params = { location, region: 'au' }
    }

    geocoder.geocode(params, (res, status) => {
      switch (status) {
        case 'OK':
          const match = citySuburb
            ? matchByCitySuburbs(res, city, suburb)
            : res[0]

          if (!match) {
            resolve()
            return
          }

          const matchloc = match.geometry.location
          const matchAddress = getCitySuburbs(match.address_components) || {}
          const details = {
            lat: matchloc.lat(),
            lng: matchloc.lng(),
            city: matchAddress.city,
            suburb: matchAddress.suburb,
          }

          if (shouldAddName)
            details.locationName =
              suburb || match.address_components[0].short_name

          resolve(details)
          break
        default:
          reject(
            new Error(`Google Geocode Service failed with status: ${status}`),
          )
      }
    })
  })

export const fetchCurrentLocationDetails = async () => {
  // Get lat-lng from navigator.geolocation
  const [lat, lng] = await new Promise((resolve, reject) => {
    if (
      !navigator ||
      !navigator.geolocation ||
      !navigator.geolocation.getCurrentPosition
    ) {
      reject(new Error('no geolocation'))
    }

    navigator.geolocation.getCurrentPosition(
      pos => resolve([pos.coords.latitude, pos.coords.longitude]),
      e => reject(e),
      { timeout: 60000 },
    )
  })

  // Get matchedAddresses from Google API using the LatLng
  const matchedAddresses = await new Promise(async (resolve, reject) => {
    if (isNode) return

    const { geocoder } = await window.googleServices

    geocoder.geocode({ location: { lat, lng } }, (addresses, status) => {
      switch (status) {
        case 'OK':
          resolve(addresses)
          break
        default:
          reject(
            new Error(`Google Geocode Service failed with status: ${status}`),
          )
      }
    })
  })

  if (!matchedAddresses || !matchedAddresses.length) {
    throw new Error('Current location matched no address')
  }

  // Get locationName (preferably suburb) from from matchedAddresses
  const flatAddressCmpts = matchedAddresses.reduce(
    (acc, { address_components }) => [...acc, ...address_components],
    [],
  ) // eslint-disable-line camelcase

  const suburbAddresssCmpt =
    flatAddressCmpts.find(({ types }) => types.includes('locality')) ||
    flatAddressCmpts.find(({ types }) =>
      types.includes('administrative_area_level_1'),
    )

  // If matched no suburb from matchedAddresses, just use the first locationName
  const locationName =
    (suburbAddresssCmpt && suburbAddresssCmpt.short_name) ||
    matchedAddresses[0].short_name

  return { locationName, lat, lng }
}
