import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as actions from '../auth/modules/auth'

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
})

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)
