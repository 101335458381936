import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

// todo merge all styled components for modals

export const Heading = styled.h1`
  font-size: 18px;
  font-weight: 500;

  ${media.screen`
    font-size: 24px;
  `}
`

export const Text = styled.span`
  font-size: ${p => (p.large ? '15px' : '13px')};
  line-height: 150%;
  color: ${p => p.theme.colors.gray3};
  margin-bottom: 15px;

  ${media.screen`
    font-size: 15px;
  `}
`

export const Link = styled.button`
  color: ${p => p.theme.colors.secondary};
  opacity: ${p => (p.disabled ? 0.5 : 1)};
`
