import identity from 'lodash/identity'

export const createPromiseAction = (
  type,
  payloadCreator = identity,
  metaCreator = () => ({})
) => {
  const thunk = (...args) => dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type,
        meta: { resolve, reject, ...metaCreator(...args) },
        payload: payloadCreator(...args)
      })
    })

  thunk.fulfilled = (action) =>
    ({ type: type + '_FULFILLED', ...action })
  thunk.rejected = (action) =>
    ({ type: type + '_REJECTED', error: true, ...action })
  thunk.toString = () => type
  thunk.fulfilled.toString = () => type + '_FULFILLED'
  thunk.rejected.toString = () => type + '_REJECTED'

  return thunk
}

export const fulfilled = (action) => {
  if (
    (typeof action === 'string') ||
    (typeof action === 'function' && action.toString)
  ) {
    return action + '_FULFILLED'
  }

  if (action.type) {
    return { ...action, type: fulfilled(action.type) }
  }

  throw new Error('asyncActions.fulfilled() only accepts redux actionType or actionCreator')
}

export const rejected = (action) => {
  if (
    (typeof action === 'string') ||
    (typeof action === 'function' && action.toString)
  ) {
    return action + '_REJECTED'
  }

  if (action.type) {
    return { ...action, type: rejected(action.type) }
  }

  throw new Error('asyncActions.rejected() only accepts redux actionType or actionCreator')
}
