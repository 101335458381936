import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import notif from 'services/notification'
import * as modals from 'store/modals'
import { withFormValidator } from 'utils/validator'
import { getErrorMessage } from 'api'

import {
  requestVerify,
  resetVerifyError,
  requestResendCode,
  selectVerify,
  selectMobile,
  selectResendCode,
} from 'store/smsv'

const verifCodeRegex = /^[0-9]{4}$/

export default compose(
  connect(
    state => ({
      verifyState: selectVerify(state),
      resendCodeState: selectResendCode(state),
      asyncError: selectVerify(state).error,
      mobile: selectMobile(state),
    }),
    {
      clearAsyncError: resetVerifyError,
      handleHide: modals.hide,
      requestResendCode,
      requestVerify,
      show: modals.show,
    },
  ),

  withProps(({ show, hide, requestVerify, requestResendCode }) => ({
    handleEditMobile: () => show(modals.types.SMS_SEND_CODE),
    onSubmit: fs => {
      requestVerify(fs.verificationCode)
        .then(() => {
          notif.success({
            title: 'Success',
            message: 'Mobile number verified',
          })
        })
        .catch(error => {
          notif.error({
            title: 'SMS verification failed',
            message: getErrorMessage(error),
          })
        })
    },
    handleResendCode: () => {
      requestResendCode()
        .then(() => {
          notif.success({ title: 'Resent SMS verification code' })
        })
        .catch(error => {
          notif.error({
            title: 'Failed to resend SMS verification code',
            message: getErrorMessage(error),
          })
        })
    },
    onSubmitFail: () =>
      notif.error({
        title: 'SMS verification failed',
        message: 'please check the form errors',
      }),
  })),

  withFormValidator({
    verificationCode: v =>
      verifCodeRegex.test(v) ? undefined : 'Incomplete verification code',
  }),
)
