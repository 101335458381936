import { compose, mapProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { connect } from 'react-redux'

import withCuisines from 'store/cuisines/CuisinesContainer'
import { isOrderUrl, isVenueUrl } from 'utils/url'

import {
  topSuburbsLinks,
  suburbLinks,
  aboutLinks,
  partnersLinks,
  privacyPolicyLink,
  termsLink,
} from './PageFooter.const'

const prepareCuisines = cuisines => {
  if (!Array.isArray(cuisines)) return []
  return cuisines
    .slice(0, 7)
    .map(c => ({
      title: c.name,
      to: encodeURI(`/cuisines/${c.name}`.toLowerCase()),
    }))
    .concat([{ title: 'Show all', to: '/cuisines', hasArrow: true }])
}

const setNoFooter = (history, responsive) =>
  isOrderUrl(get(history, 'location.pathname', '')) ||
  (isVenueUrl(get(history, 'location.pathname', '')) &&
    (responsive.mobile || responsive.tablet))

export default compose(
  connect(state => ({
    responsive: state.responsive,
  })),
  withCuisines,
  withRouter,
  mapProps(({ cuisines, history, responsive }) => ({
    cuisineLinks: prepareCuisines(cuisines),
    topSuburbsLinks,
    suburbLinks,
    aboutLinks,
    partnersLinks,
    privacyPolicyLink,
    termsLink,
    history,
    responsive,
    noFooter: setNoFooter(history, responsive),
  })),
)
