import styled, { css } from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'
import { string, bool, object, oneOfType } from 'prop-types'

const Text = styled.p.attrs({
  as: p => p.as || variantToTag(p.variant),
})`
  ${p =>
    mapViewportsToStyles(
      p.variant,
      variant =>
        ({
          h1: css`
            font-size: 30px;
            line-height: 40px;
            font-weight: 500;

            ${media.screen`
        font-size: 50px;
        line-height: 60px;
      `}
          `,
          h2: css`
            font-size: 25px;
            line-height: 35px;
            font-weight: 500;

            ${media.screen`
        font-size: 30px;
        line-height: 40px;
      `}
          `,
          h3: css`
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;

            ${media.screen`
        font-size: 25px;
        line-height: 35px;
      `}
          `,
          h4: css`
            font-size: 18px;
            line-height: 25px;
            font-weight: 500;

            ${media.screen`
        font-size: 20px;
        line-height: 30px;
      `}
          `,
          body: css`
            font-size: 14px;
            line-height: 20px;

            ${media.screen`
        font-size: 16px;
        line-height: 25px;
      `}
          `,
          secondary: css`
            font-size: 13px;
            line-height: 20px;

            ${media.screen`
        font-size: 14px;
        line-height: 20px;
      `}
          `,
          tertiary: css`
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1px;

            ${media.screen`
        font-size: 13px;
        line-height: 20px;
      `}
          `,
          tiny: css`
            font-size: 11px;
            line-height: 20px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;

            ${media.screen`
        font-size: 12px;
        line-height: 20px;
      `}
          `,
        }[variant]),
    )}

  color: ${p => p.theme.colors[p.color] || p.color} !important;
  text-decoration: none;
  text-align: ${p => p.align};

  ${p =>
    p.bold
      ? 'font-weight: 500;'
      : p.light
      ? 'font-weight: 300 !important;'
      : ''}
  ${p => (p.solid ? 'line-height: 1em !important;' : '')}
`

Text.propTypes = {
  variant: oneOfType([string, object]),
  light: oneOfType([string, bool]),
  bold: oneOfType([string, bool]),
  solid: oneOfType([string, bool]),
  color: string,
}

Text.defaultProps = {
  variant: 'body',
  color: 'black',
  align: 'left',
}

/** @component */
export default Text

/** Internal Functions */
const mapViewportsToStyles = (viewportToKey, writeCssLines) => {
  if (typeof viewportToKey === 'string') return writeCssLines(viewportToKey)

  const defaultStyle =
    viewportToKey && viewportToKey.default
      ? writeCssLines(viewportToKey.default)
      : ''

  // if viewportToKey is object, write css for each viewport (object's key)
  const sizePerViewport = Object.entries(media).reduce(
    (acc, [viewport, mediaQuery]) =>
      viewportToKey && viewportToKey[viewport]
        ? css`
            ${acc}
            ${mediaQuery`
            ${writeCssLines(viewportToKey[viewport])}
          `}
          `
        : acc,
    defaultStyle,
  )

  return sizePerViewport
}

const variantToTag = passedVariant => {
  const variant =
    typeof passedVariant === 'object'
      ? passedVariant.screen ||
        passedVariant.desktop ||
        passedVariant.table ||
        passedVariant.default
      : passedVariant

  return ['h1', 'h2', 'h3', 'h4'].includes(variant) ? variant : 'p'
}
