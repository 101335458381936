const isNode = require('detect-node')
const PROD_HOSTNAME = 'heyyou.com.au'

const err = () => {
  let dedupeIds = []
  const notif = require('izitoast')
  const colors = require('../theme/colors').default
  const successIcon =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAQCAYAAAAS7Y8mAAAABmJLR0QA/wD/AP+gvaeTAAAAhklEQVQ4je3UMQrCQBAF0Ih4CIXcwU4srT2fNpLOlLmEF/AYNoKksLJ4FhlQQqpkGsHf/8eyM0xRJAVzVLhhmYnWujywykAXaAK9Y/1Hfx1FiTL9pbHQLbZpaJROURjER/8pZjhE8YndZLSHH7/xtOkP4Je0ldIdk7NPWmwmoT38ihf2Y503Oe5m829uurUAAAAASUVORK5CYII='
  const errorIcon =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABxklEQVRIie2Wv09TYRiF368hGJIOdGh06EJC0oWgE0YX2EldNTjBggP/AJGETXTDAQZY+RGYcGA0IN2EDRZ1cMClaWwkdGhDy+NyGj/LbW9Lezv1TZree8655/ma9ru3Zv3pT8AAGSAP/AamewV9BFzxb/4AD3sB3hZwD9jX8VbU0CmBikBKr6K0yaigA8CFIG89fUnaOTAQBXhBgG/AoKc/AL7LW+g2NAkUVJ4J8F/IKwDJboI3VXzoaSfAF+/8UJmNbkGfAlWgBIx6OgDe+agyVWCiU2gMOBPjfZ33H1jaB8mnQKwT8JyKfgHxFsBxZQFm7wsdBnIqmQnw74Clv5aVA4bvA/6oguMGfiOwA45lr7YLfQxUgBtgrB2wvCe6vgKMtwp1QFa9a01yZaDUxF9XRxZwrYBf6YI8kGiSyxBwM/H8hDoAXoZB/V/lfOgqQwZ4o67L+l1RH1xR8GvYPgSOgM8hmZi6AN41CqX1vd0Cz1r4NFm8W2aT3HN1loF0UOCTVrYTVtbuALvqPqg3UjKugVQEYP8Pw4iZWe3BPaT3uJldNtieXVlD7SBmZuac+2Fmy2ZWjIpoZgUzW3TO/YyQ0Z+78xfmn4v+UFOKbQAAAABJRU5ErkJggg=='
  const config = {
    progressBar: false,
    color: 'white',
    titleColor: 'white',
    messageColor: 'white',
    iconColor: 'white',
  }

  return Object.entries(notif)
    .map(([name, fn]) => [
      name,
      ({ error, dedupeId, ...options }, ...otherArgs) => {
        // Log errors
        const isProd = window.location.hostname === PROD_HOSTNAME
        if (error && !isNode && !isProd) {
          console.error('Notification:', error || options.title)
        }

        // Early exit if still open
        if (dedupeIds.includes(dedupeId)) return

        // If asked to dedupe, we keep track of it
        let onClosing = () => {}
        if (dedupeId) {
          dedupeIds.push(dedupeId)
          onClosing = () => {
            dedupeIds = dedupeIds.filter(id => id !== dedupeId)
          }
        }

        const dynamicConfig = {
          class: [name, !options.message && 'has-icon']
            .filter(x => x)
            .join(' '),
          backgroundColor:
            name === 'success'
              ? colors.secondary
              : name === 'error'
              ? colors.primary
              : '',
          iconUrl: options.message
            ? ''
            : name === 'success'
            ? successIcon
            : name === 'error'
            ? errorIcon
            : '',
          close: !!options.message || !!options.hasCloseButton,
        }

        const mergedConfig = {
          ...config,
          ...dynamicConfig,
          ...options,
          message: options.message || '',
          ...(name === 'error' && { timeout: false }),
        }

        return notif[name](mergedConfig, onClosing, ...otherArgs)
      },
    ])
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) // transform entries back to object
}

export default isNode ? { error: console.error } : err()
