import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import promiseMiddleware from 'redux-promise-middleware'
import thunk from 'redux-thunk'

import rootReducer from './reducer'
import rootSaga from './saga'
import notifications from './middlewares/notifications'
import analyticsTracker from './middlewares/analytics-tracker'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [
  thunk,
  promiseMiddleware(),
  sagaMiddleware,
  notifications,
  analyticsTracker,
]
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

const initialState = window.__REDUX_STATE__
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
)

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
export default store
