import React from 'react'

import renderRoutes from 'utils/renderRoutes'

import { Switch } from 'react-router-dom'
import Root from 'layouts/GlobalLayout/Root'
import WhiteLabelRoot from 'layouts/GlobalLayout/WhiteLabelRoot'
import MultiVendorRoot from 'layouts/GlobalLayout/MultiVendorRoot'
import ScrollToTop from './ScrollToTop'
import { isWhiteLabel } from 'utils/whiteLabel'
import { isMultiVendor } from 'utils/multiVendor'

const routes = () => {
  return process.env.NODE_ENV === 'production'
    ? require('./routes').default
    : require('./routes.dev').default
}

const RootLayout = props => {
  if (isMultiVendor()) return <MultiVendorRoot {...props} />

  return isWhiteLabel() ? <WhiteLabelRoot {...props} /> : <Root {...props} />
}

class Router extends React.Component {
  render() {
    return (
      <RootLayout {...this.props}>
        <ScrollToTop>
          <Switch>
            {this.props.children}
            {renderRoutes(routes())}
          </Switch>
        </ScrollToTop>
      </RootLayout>
    )
  }
}

export default Router
