import { handleActions } from 'redux-actions'
import { createPromiseAction } from 'utils/asyncActions'
import slugify from 'utils/slugify'

const initialState = {
  listId: null,
  list: [],
  single: null,
}

// Actions
export const fetch = createPromiseAction('unicorn/suburbs/FETCH')
export const fetchSingle = createPromiseAction(
  'unicorn/suburbs/FETCH_SINGLE',
  (city, suburb) => ({ city, suburb }),
)

// Reducer
export default handleActions(
  {
    [fetch]: (state, { payload }) =>
      slugify(payload) === slugify(state.id || '')
        ? state
        : { ...state, listId: null, list: [] },

    [fetch.fulfilled]: (state, { payload }) => ({
      ...state,
      id: payload.id,
      list: payload.list,
    }),

    [fetchSingle]: (state, { payload }) => ({ ...state, single: null }),

    [fetchSingle.fulfilled]: (state, { payload }) => ({
      ...state,
      single: payload,
    }),
  },
  initialState,
)

// Selectors
export const select = state => state.suburbs.list
export const selectId = state => state.suburbs.id
export const selectSingle = state => state.suburbs.single
