import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import { hide, show, types } from 'store/modals'
import StyleableModal from 'components.v2/Modal/StylableModal'
import Sources from 'pages/account/payment/Sources'
import styled from 'styled-components'
import { fetchPaymentSources } from 'store/payment'
import { ModalButton } from './styled'

const Modal = styled(StyleableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 650px;
    width: 100%;
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;

    div[class*="ShadowCardContainer"] {
      box-shadow: none;
      width: 100%;
    }
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

class PaymentSourceModal extends PureComponent {
  handleHide = () => {
    const { hide, fetchPaymentSources } = this.props
    hide()
    fetchPaymentSources()
  }

  render() {
    const { show, hide } = this.props
    const handleAddSource = ()=> {
      show(types.CARD_ADDER, {
        onClose: () => {
          hide()
          show(types.PAYMENT_SOURCE)
        },
        isOpen: true
      })
    }

    return (
      <Modal
        isOpen
        contentLabel="Serving To Table"
        onRequestClose={this.handleHide}
        prependClassName="text-center pb-20 px-20"
        small
        noCloseButton
      >
        <Sources onAddSource={handleAddSource} onModal={true}/> 
        <ModalButton className="w-full font-bold"
          large
          largeText
          type="button"
          variant="primary"
          onClick={hide}
          >
          Done
        </ModalButton>
      </Modal>
    )
  }
}

PaymentSourceModal.propTypes = {
  hide: PT.func.isRequired,
}

const withContainer = connect(
  () => ({}),
  { hide, show, fetchPaymentSources}, 
)

export default withContainer(PaymentSourceModal)
