import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
   transform: rotate(0deg);
  }
  100% {
   transform: rotate(360deg);
  }
`

export const RingContent = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: ${props => -props.index * 0.15}s;
`

export const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  ${RingContent} {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    ${''}
    border: ${props => props.borderSize}px solid transparent;

    border-color: ${props => props.theme.colors[props.color]} transparent transparent transparent;
  }
`
