import React, { Fragment } from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/Icon.v2'
import { mobileFirstMedia as media } from 'utils/media'
import withContainer from './SearchInputContainer'

const SearchInput = ({
  value,
  inputRef,
  handleChange,
  handleClear,
  handleHide,
  disallowHide,
  ...props
}) => (
  <Container {...props}>
    <InputGroup>
      <SearchIcon />
      <Input
        ref={inputRef}
        onChange={handleChange}
        value={value}
        placeholder="Search for restaurant or cuisine"
      />

      {value && (
        <Fragment>
          <ClearDt onClick={handleClear}>Clear</ClearDt>
          <ClearMb onClick={handleClear}>
            <Icon type="close" size={8} fill="white" />
          </ClearMb>
        </Fragment>
      )}
    </InputGroup>

    {!disallowHide && (
      <Fragment>
        <CancelButtonDt type="button" onClick={handleHide}>
          <Icon type="close" size={30} className="block" />
          <CloseText>ESC</CloseText>
        </CancelButtonDt>
        <CancelButtonMb onClick={handleHide}>Cancel</CancelButtonMb>
      </Fragment>
    )}
  </Container>
)

SearchInput.propTypes = {
  value: PT.string.isRequired,
  inputRef: PT.object.isRequired,
  handleChange: PT.func.isRequired,
  handleClear: PT.func.isRequired,
  handleHide: PT.func.isRequired,
  disallowHide: PT.bool.isRequired,
}

export default withContainer(SearchInput)

const Container = styled.section`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: ${p => p.theme.colors.primary};

  ${media.screen`
    padding: 0;
    background-color: transparent;
  `}
`

const InputGroup = styled.section`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0 13px;
  background-color: ${p => p.theme.colors.white};

  ${media.screen`
    padding: 0 20px;
  `}
`

const SearchIcon = styled(Icon).attrs({ type: 'search' })`
  color: ${p => p.theme.colors.primary};
  font-size: 24px;

  ${media.screen`
  `}
`

const Input = styled.input`
  display: block;
  margin-left: 10px;
  font-size: 16px;
  flex-grow: 1;
  height: 40px;
  outline: none;

  ${media.screen`
    margin-left: 20px;
    height: 60px;
    font-size: 20px;
  `}
`

const ClearDt = styled.button.attrs({ type: 'button' })`
  display: none;
  margin-left: 20px;
  color: ${p => p.theme.colors.primary};
  font-weight: 300;
  font-size: 13;
  text-transform: uppercase;

  ${media.screen`
    display: block
  `}
`

const ClearMb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  background-color: ${p => p.theme.colors.gray4};
  border-radius: 100%;

  ${media.screen`
    display: none;
  `}
`

const CancelButtonDt = styled.button`
  margin-left: 30px;
  display: none;

  ${media.screen`
    display: block;
  `}
`

const CancelButtonMb = styled.button`
  margin-left: 10px;
  color: ${p => p.theme.colors.white};

  ${media.screen`
    display: none;
  `}
`

const CloseText = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 100%;
  color: ${p => p.theme.colors.gray1};
  letter-spacing: 1px;
  text-transform: uppercase;
`
