import { put, take, takeLatest, fork, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNode from 'detect-node'
import bugsnagClient from 'services/bugsnag-client'
import find from 'lodash/find'
import { show, types } from 'store/modals'

import * as orderApi from 'api/order'
import {
  placeOrder,
  pullStatus,
  selectOrdersByVenueId,
  selectIsSubmitting,
  ordersToItemsPayload,
  ordersToTotal,
  discountToTotal,
  feesToTotal,
  selectDiscountsByVenueId,
  selectFeesByVenueId,
  smartSelectOrderById,
} from './order'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import config from 'app-config'
import { selectPlaceOrderParty } from 'store/order-party/order-party.selectors'
import { placeOrderParty } from 'store/order-party/order-party.constants'
import getHistory from 'services/history'
import { getQueryParams } from 'utils/url'
import { isWhiteLabel } from 'utils/whiteLabel'
import { isMultiVendor } from 'utils/multiVendor'

const PULL_INTERVAL = 10000

export default [
  fork(function* placeOneOrderAtATime() {
    while (true) {
      const action = yield take(placeOrder)

      try {
        const {
          payload: {
            venueId,
            serviceType,
            note,
            payment,
            seatingInfo,
            orderPartyId,
            customerId,
            address,
            mobile,
            customer,
            orderType,
          },
          meta: { resolve },
        } = action

        const state = yield select(x => x)
        const venueOrders = selectOrdersByVenueId(state, venueId)
        const items = ordersToItemsPayload(venueOrders)

        const history = getHistory()

        const qstr = getQueryParams(get(history, 'location.search'))
        const isGfoOrder =
          get(qstr, 'channel') === 'gfo_redirect' &&
          !(isWhiteLabel() || isMultiVendor())

        const params = {
          venueId,
          items,
          serviceType,
          orderNote: note,
          payment,
          seatingInfo,
          orderPartyId,
          customerId,
          address,
          mobile,
          ...(customer && { customer: customer }),
          ...(orderType && { orderType: orderType }),
          queryString: isGfoOrder ? '?channel=gfo_redirect' : '',
        }

        const orderData = yield orderApi.submit(params)
        const orderId =
          config.ORDER_SUBMIT_VERSION === '2'
            ? orderData
            : get(orderData, 'orderId')

        yield put(
          placeOrderParty.fulfilled({
            payload: venueId,
          }),
        )

        reportGA(state, venueId)

        const isAnonymous = yield select(selectGuestCheckoutHasEnabled)
        yield put(
          placeOrder.fulfilled({
            payload: {
              venueId,
              orderId,
              note,
              payment,
              seatingInfo,
              orderPartyId,
              customerId,
              address,
              mobile,
              isAnonymous,
            },
          }),
        )
        resolve({
          ...orderData,
          ...(orderPartyId && {
            orderPartyId,
            venueId,
          }),
        })
      } catch (error) {
        const {
          payload: { venueId },
          meta: { reject },
        } = action

        yield put(
          placeOrder.rejected({
            payload: formatError(error),
            meta: { venueId },
          }),
        )
        yield put(unsetGuestCheckoutEmail())
        reject(error)
      }
    }
  }),
  takeLatest(String(pullStatus), function* pullStatusTask({
    payload: orderId,
    meta: { resolve, reject },
  }) {
    try {
      const isSubmitting = yield select(selectIsSubmitting)
      const { isAnonymous, venueId } = yield select(
        smartSelectOrderById,
        orderId,
      )
      if (isSubmitting) {
        // Do we really need this? I think no
        // reject(new Error('Pulling blocked because an order is being placed'))
        return
      }

      const orderParty = yield select(selectPlaceOrderParty, venueId)

      const {
        status: { flags },
        reason,
        etaInMinutes,
        acceptedAt,
        paymentTitle,
        paymentMessage,
        paymentNote,
        action,
        members,
      } = yield isAnonymous
        ? orderApi.fetchAnonymousOrderStatus(orderId).then(resp => resp.data)
        : orderApi
            .fetchStatus(orderId, get(orderParty, 'id'))
            .then(resp => resp.data)

      const paymentErrorData = {
        title: paymentTitle,
        message: paymentMessage,
        note: paymentNote,
        action,
      }

      const status = flags
      const state = yield select(x => x)
      const orders = state.order.orders

      const statePlacedOrderMembers = get(
        orders,
        `${venueId}.placedOrders.${orderId}.members`,
        [],
      )

      const orderMembers = members ? members : []

      yield* orderMembers.map(function*(member) {
        const savedMember = find(statePlacedOrderMembers, { id: member.id })
        const savedMemberFlags = savedMember ? savedMember.flags : []

        if (JSON.stringify(savedMemberFlags) != JSON.stringify(member.flags)) {
          let memberName = ''
          if (member.isLeader) memberName = orderParty.leaderCustomerName
          else {
            const orderMember = find(orderParty.members, { id: member.id })
            memberName = orderMember ? orderMember.customerName : ''
          }

          if (member.flags.includes('REJECTED-VENDOR')) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: 'REJECTED_VENDOR',
                memberName: memberName,
              }),
            )
          }

          if (member.flags.includes('REJECTED-TIMEOUT')) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: 'REJECTED_TIMEOUT',
                memberName: memberName,
              }),
            )
          }

          if (
            member.flags.includes('PAYMENT_FAILED') ||
            member.flags.includes('PAYMENT_TIMEOUT')
          ) {
            yield put(
              show(types.ORDER_PARTY_ALERT, {
                type: 'PAYMENT_ISSUE',
                memberName: memberName,
              }),
            )
          }
        }
      })

      yield put(
        pullStatus.fulfilled({
          payload: {
            status,
            orderId,
            etaInMinutes,
            acceptedAt,
            reason,
            paymentErrorData,
            ...(get(orderParty, 'id') && { members }),
          },
        }),
      )
      resolve()

      if (
        status.includes('PENDING') ||
        status.includes('AWAITING_PAYMENT') ||
        status.includes('ACCEPTED') ||
        (!isEmpty(members) &&
          members.find(
            i =>
              get(i, 'flags', []).includes('PENDING') ||
              get(i, 'flags', []).includes('AWAITING_PAYMENT') ||
              get(i, 'flags', []).includes('ACCEPTED'),
          ))
      ) {
        yield delay(PULL_INTERVAL)
        yield put(pullStatus(orderId))
      }
    } catch (error) {
      yield put(
        pullStatus.rejected({
          payload: { error: formatError(error), orderId },
        }),
      )
      reject(error)
    }
  }),
]

const formatError = e => get(['response', 'data', 'status'], e) || e

const reportGA = (state, venueId) => {
  try {
    // Note that on development, there will be no `gtag()` even on client side (staging/production only)
    if (isNode || typeof window.gtag !== 'function') return

    const ordersTotal = ordersToTotal(selectOrdersByVenueId(state, venueId))
    const discountTotal = discountToTotal(
      selectDiscountsByVenueId(state, venueId),
    )
    const feesTotal = feesToTotal(selectFeesByVenueId(state, venueId))
    const total = ordersTotal + feesTotal + discountTotal

    window.gtag('event', 'conversion', {
      send_to: 'AW-850287204/NZ2zCPvs84EBEOS0uZUD',
      value: total,
      currency: 'AUD',
    })
  } catch (e) {
    if (bugsnagClient) bugsnagClient.notify(e)
  }
}
