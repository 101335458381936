import React from 'react'

import { formPropTypes } from 'common/auth/Shared/auth.helpers'
import { Link, Button } from 'common/auth/Shared/sharedStyled'
import FormError from 'components.v2/FormError'
import Input from 'components.v2/Input'
import withContainer from './LoginFormContainer'

const LoginForm = ({
  fields,
  isAuthenticating,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  className,
  actionsReversed,
  showForgotPassModal,
  email,
}) => (
  <form onSubmit={handleSubmit} noValidate className={className}>
    {!!errors.length &&
      errors.map((error, i) => <FormError key={i}>{error}</FormError>)}
    <Input
      {...(email ? { autoFocus: true } : {})}
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.email.value || email}
      hasError={!!fields.email.error}
      className="block w-full"
      type="email"
      name="email"
      placeholder="Email"
    />
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      value={fields.password.value}
      hasError={!!fields.password.error}
      className="block w-full mt-10"
      type="password"
      name="password"
      placeholder="Password"
    />
    <div className="flex items-center justify-between mt-20">
      <Link type="button" onClick={() => showForgotPassModal()}>
        Forgot password?
      </Link>
      <Button
        variant="primary"
        type="submit"
        disabled={isAuthenticating}
        actionsReversed={actionsReversed}
      >
        {isAuthenticating ? 'Processing…' : 'Login'}
      </Button>
    </div>
  </form>
)

LoginForm.propTypes = formPropTypes

export default withContainer(LoginForm)
