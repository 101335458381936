import React from 'react'
import isNode from 'detect-node'

import { LoadingErrorScreen } from './styled'
import LoadingError from './LoadingError'
import Spinner from 'components.v2/Spinner'

export default ({ err, timedOut, pastDelay }) => {
  if (err) {
    console.error(err)
    if (!isNode && window && window.bugsnagClient) {
      window.bugsnagClient.notify(err)
    }
    return <LoadingError />
  } else if (timedOut) {
    return <LoadingError title="Loading took longer than expected" />
  } else if (pastDelay) {
    // When the loader has taken longer than the delay
    return (
      <LoadingErrorScreen>
        <Spinner className="mx-auto" width={70} height={70} thickness={5} />
      </LoadingErrorScreen>
    )
  } else {
    // When the loader has just started
    return null
  }
}
