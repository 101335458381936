const styleguideColors = {
  primary: '#F47325',
  primaryDarker: '#E55B01',
  primaryDarkest: '#CC5100',
  secondary: '#2E9A9A',
  secondaryDarker: '#2A8988',
  secondaryDarkest: '#257A79',
  tertiary: '#F1B81B',
  tertiaryDarker: '#D9A516',
  tertiaryDarkest: '#C19215',
  whitelabel: '#000000',
  whitelabelLighter: '#242424',
  peachDark: '#FBD1BC',
  peach: '#FDEFE8',
  peachLight: '#FEF7F4',
  twitter: '#1DA1F2',
  facebook: '#3B5998',
  facebookDarker: '#2F4779',
  google: '#DC4E41',
  googleDarker: '#CC0000',
  black: '#333333',
  gray1: '#666666',
  gray2: '#808080',
  gray3: '#999999',
  gray4: '#CCCCCC',
  gray5: '#E5E5E5',
  gray6: '#F2F2F2',
  gray7: '#F7F7F7',
  gray8: '#F1F1F1',
  white: '#FFFFFF',
  orange: '#F26722',
}

const legacyColors = {
  textDark: '#333333',
  textLight: '#808080',
  lightGrey: '#F2F2F2',
  grey: '#CCCCCC',
  darkGrey: '#999999',
  inputBackground: '#FAFAFA',
  darkerTeal: '#257A79',
  darkTeal: '#2A8988',
  teal: '#2F9998',
  lightTeal: '#6DB7B6',
  lighterTeal: '#C0E0E0',
  lightestTeal: '#F4FAFA',
  darkerOrange: '#CC5100',
  darkOrange: '#E55B00',
  orange: '#FF6600',
  lightOrange: '#FF934C',
  lighterOrange: '#FFD1B2',
  darkerYellow: '#C19314',
  darkYellow: '#D9A517',
  yellow: '#F2B81A',
  lightYellow: '#F5CD5E',
  lighterYellow: '#FBE9BA',
  darkerGrey: '#333333',
  lighterGrey: '#F2F2F2',
  primaryTextColor: '#424242',
  textColorLight: '#878787',
  bodyColor: '#E1E1E1',
  acceptButtonColor: '#73DB6D',
  rejectButtonColor: '#F5614F',
  readyButtonColor: '#F1B81B',
  normalColorBg: '#F5F4F4',
  warningColorBg: '#EEC557',
  dangerColorBg: '#EE8679',
  acceptedGreen: '#ABEAA8',
  acceptedGreen2: '#77D972',
  grayText60: '#999999',
  grayText73: '#BABABA',
  redBadge: '#FE3824',
  background: '#F7F7F7',
  border: '#F2F2F2',
  placeholder: '#8C8A99',
  textPrimary: '#333333',
  textAltPrimary: '#FFFFFF',
  textSecondary: '#999999',
  bgPrimary: '#FFFFFF',
  bgSecondary: '#F7F7F7',
  bgTertiary: '#EFEFEF',
  borderPrimary: '#999999',
  borderSecondary: '#F2F2F2',
}

const colors = { ...legacyColors, ...styleguideColors }

export default colors
