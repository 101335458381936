import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps, lifecycle } from 'recompose'
import debounce from 'lodash/fp/debounce'

import { hide } from 'store/modals'
import { changeCuisineInput, getQuery } from 'common/CuisineSearchBar/cuisine'
import { searchVenues, updateSearchRoute } from 'pages/search/venues'

const UPDATE_ROUTE_DEBOUNCE_RATE = 1000

export default compose(
  connect(
    state => ({ value: getQuery(state) }),
    dispatch => {
      const debouncedUpdateSearchRoute = debounce(
        UPDATE_ROUTE_DEBOUNCE_RATE,
        () => dispatch(updateSearchRoute({ shouldRedir: false })))

      return {
        ...bindActionCreators(
          { hide, changeCuisineInput, searchVenues, updateSearchRoute },
          dispatch
        ),
        debouncedUpdateSearchRoute
      }
    }),

  withHandlers({
    handleClear: ({ changeCuisineInput, searchVenues, updateSearchRoute }) => () => {
      changeCuisineInput('')
      updateSearchRoute({ shouldRedir: false })
      searchVenues()
    },
    handleChange: ({ changeCuisineInput, searchVenues, debouncedUpdateSearchRoute }) => ev => {
      changeCuisineInput(ev.target.value)
      debouncedUpdateSearchRoute({ shouldRedir: false })
      searchVenues()
    },
    handleHide: ({ hide }) => () => hide()
  }),

  withProps(() => ({ inputRef: React.createRef() })),

  lifecycle({
    componentDidMount () {
      this.props.inputRef.current.focus()
    }
  })
)
