import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import styled from 'styled-components'

import StylableModal from 'components.v2/Modal/StylableModal'
import { hide } from 'store/modals'

const Layout = styled.div`
  display: flex;
  padding: 32px 20px 20px 20px;
  flex-direction: column;

  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 32px;
`

const Button = styled.button`
  height: 52px;
  width: 100%;
  border-radius: 8px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &.primary {
    background: var(--Orange-0, #f26722);
    color: var(--White, #fff);
  }

  &:focus {
    outline: none;
  }
`

const Subcontent = styled.div`
  color: var(--Grey-1, #666);
  text-align: center;
  margin-top: 12px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const ContentTitle = styled.div`
  color: var(--Black, #000);
  text-align: center;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

const Modal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;
    width: 340px;
    outline: none !important;
  }

  ,
  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.44);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

class EmailUpdatedModal extends PureComponent {
  handleHide = () => {
    const { hide } = this.props
    hide()
  }

  render() {
    return (
      <Modal isOpen onRequestClose={this.handleHide}>
        <Layout>
          <div className="flex flex-col">
            <ContentTitle>Email address updated</ContentTitle>
            <Subcontent>
              For security reasons, you've been logged out. Please log back in
              with your new email address.
            </Subcontent>
          </div>
          <Button className="primary" onClick={this.handleHide}>
            Got it
          </Button>
        </Layout>
      </Modal>
    )
  }
}

EmailUpdatedModal.propTypes = {
  hide: PT.func.isRequired,
}

const withContainer = connect(null, { hide })

export default withContainer(EmailUpdatedModal)
