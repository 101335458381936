import React from 'react'

const ArrowUpperRight = props => (
  <svg width='1em' height='1em' viewBox='0 0 13 13' {...props}>
    <path fill='none' d='M-1-1h15v15H-1z' />
    <g>
      <path d='M12.475 1.26v8.173h-1.499V3.07L1.65 12.396a.75.75 0 1 1-1.061-1.06l9.327-9.327H3.552v-1.5h8.174a.75.75 0 0 1 .75.75z' />
    </g>
  </svg>
)

export default ArrowUpperRight
