import styled from 'styled-components'

export const Footer = styled.footer`
  height: 83px;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContent = styled.a`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

export const Logo = styled.img`
  height: 23px;
`

export const Title = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #999999;
`
