import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import times from 'lodash/times'
import withContainer from './CodeInputContainer'

const CodeInput = ({ _value, handleChange, handleFocus, handleKeyDown, inputRefs, className }) => (
  <div className={`flex justify-center ${className}`}>
    {times(4, i => (
      <NumberInput
        key={i}
        ref={inputRefs[i]}
        onFocus={handleFocus(i)}
        onKeyDown={handleKeyDown(i)}
        onChange={handleChange(i)}
        value={_value[i] || ''}
      />
    ))}
  </div>
)

CodeInput.propTypes = {
  _value: PT.string,
  handleChange: PT.func.isRequired,
  handleFocus: PT.func.isRequired,
  handleKeyDown: PT.func.isRequired,
  inputRefs: PT.arrayOf(PT.object).isRequired,
  className: PT.string
}

const NumberInput = styled.input.attrs({ required: true })`
  display: flex;
  align-items: center;
  border: 1px solid ${p => p.theme.colors.gray5};
  border-radius: 3px;
  width: 50px;
  height: 60px;
  background-color: ${p => p.theme.colors.gray7};
  font-size: 30px;
  text-align: center;
  outline: none;

  & + & {
    margin-left: 10px;
  }

  :valid {
    border-color: ${p => p.theme.colors.primary}
  }

  :focus {
    border-color: ${p => p.theme.colors.primary}
  }
`

export default withContainer(CodeInput)
