export default passedPath => {
  let prefix = process.env.WEB_URL || ''
  let path = passedPath

  // Remove trailing slash of prefix
  if (prefix[prefix.length - 1] === '/') prefix = prefix.slice(0, -1)

  // Remove preceding slash of path
  if (path && path[0] === '/') path = path.slice(1)

  return `${prefix}/${path}`
}
