import React from 'react'
import { string } from 'prop-types'
import createSubPartyModal from './createSubPartyModal'

export const types = {
  PARTY_INVITATION_RECEIVED: 'PARTY_INVITATION_RECEIVED',
  PARTY_CANCELLED: 'PARTY_CANCELLED',
  PARTY_ORDER_MISSED: 'PARTY_ORDER_MISSED',
  PARTY_EXPIRED: 'PARTY_EXPIRED',
  PARTY_ORDER_SENT_TO_HOST: 'PARTY_ORDER_SENT_TO_HOST',
}

const closeAndRedirect = ({ hide, history, venueId }) => () => {
  hide()
  history.push(`/restaurant/${venueId}/menu`)
}

const modals = {
  [types.PARTY_INVITATION_RECEIVED]: createSubPartyModal({
    heading: 'TODO',
    body: 'TODO',
    createAction: closeAndRedirect,
    actionText: 'Order food now',
  }),

  [types.PARTY_CANCELLED]: createSubPartyModal({
    heading: props => `${props.hostName} cancelled the Party`,
    body: props => `…but you can still order from ${props.venueName}`,
    createAction: closeAndRedirect,
    actionText: 'Order food now',
  }),

  [types.PARTY_ORDER_MISSED]: createSubPartyModal({
    heading: 'Sorry, your order was missed',
    body: props =>
      `${
        props.venueName
      } is currently at capacity and missed your order. You will be refunded. Please try again in a few minutes and let Leonara know you’ve ordered.`,
    createAction: closeAndRedirect,
    actionText: 'Order food now',
  }),

  [types.PARTY_EXPIRED]: createSubPartyModal({
    heading: props => `Oh no… \n ${props.partyName} is over`,
    body: null,
    createAction: closeAndRedirect,
    actionText: 'Order food now',
  }),

  [types.PARTY_ORDER_SENT_TO_HOST]: createSubPartyModal({
    heading: 'Your order was added to the party',
    body:
      'We’re waiting for the party host to send your order. We’ll send you a notification shortly.',
    createAction: closeAndRedirect,
    actionText: 'Order food now',
  }),
}

const PartyModal = ({ subType, ...props }) => {
  const SubPartyModal = modals[subType]
  return <SubPartyModal {...props} />
}

PartyModal.propTypes = {
  subType: string.isRequired,
}

export default PartyModal
