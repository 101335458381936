import client from 'client'
import get from 'lodash/get'

const regex = /btq:\/\/venue\/([0-9]+)/ // matches venue deeplink (btq://venue/123)

export const fetchAds = ({ lat, lng, ...params }) =>
  client(true)
    .get('/ads', {
      params,
      headers: {
        'btq-lat': lat,
        'btq-lon': lng,
      },
    })
    .then(res => ({
        ...get(res,'data'),
        ads: get(res,'data.ads.slides',[])
          .filter(ad => ad.promotion && ad.action.match(regex))
          .map(ad => ({
            id: Number(ad.action.match(regex)[1]),
            coverImage: ad.source,
            name: ad.promotion.name,
            discount: {
              title: ad.promotion.title,
              time: ad.promotion.subtitle,
            },
          })),
        checkoutAds: get(res,'data.checkoutAds.slides',[]),
        walletAds: get(res,'data.walletAds.slides',[]),
        heroAds: get(res,'data.heroAds.slides',[])
      })
    )
