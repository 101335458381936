import { handleActions } from 'redux-actions'

const SET_PRECINCT = 'unicorn/precinct/SET_PRECINCT'
const UNSET_PRECINCT = 'unicorn/precinct/UNSET_PRECINCT'

export const setPrecinct = payload => ({
  type: SET_PRECINCT,
  ...payload,
})

export const unsetPrecinct = () => ({ type: UNSET_PRECINCT })

const initialState = {
  logo: '',
  tag: '',
  displayName: '',
}

export default handleActions(
  {
    [SET_PRECINCT]: (state, { tag, displayName, logo }) => {
      return {
        ...state,
        tag,
        displayName,
        logo,
      }
    },
    [UNSET_PRECINCT]: state => {
      return {
        ...state,
        tag: '',
        displayName: '',
        logo: '',
      }
    },
  },
  initialState,
)
