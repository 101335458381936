import React from 'react'
import PT from 'prop-types'
import Icon from 'components.v2/Icon/IconV2'

import {
  DropdownWrapper,
  DropdownActionLink,
  DropdownItems,
  DropdownItem,
} from './styled'
import { FZ_CLICK_TO_PAY_HOSTED_PAYMENT } from 'store/payment/const'

class SourcesItemDropdown extends React.Component {
  dropdownMenu = null

  static defaultProps = {
    onPrimary: () => {},
    onDelete: () => {},
    isPrimary: false,
    type: ''
  }

  static propTypes = {
    onPrimary: PT.func,
    onDelete: PT.func,
    isPrimary: PT.bool,
    type: PT.string
  }

  state = {
    isOpen: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClose)
  }

  handleOpen = event => {
    event.preventDefault()

    this.setState(
      {
        isOpen: true,
      },
      () => {
        document.addEventListener('click', this.handleClose)
      },
    )
  }

  handleClose = event => {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ isOpen: false }, () => {
        document.removeEventListener('click', this.handleClose)
      })
    }
  }

  handleClickItem = callback => event => {
    event.preventDefault()
    this.setState(
      {
        isOpen: false,
      },
      () => {
        document.removeEventListener('click', this.handleClose)
        callback()
      },
    )
  }

  render() {
    return (
      <DropdownWrapper
        ref={element => {
          this.dropdownMenu = element
        }}
      >
        <DropdownActionLink onClick={this.handleOpen}>
          <Icon src="Cog.svg" />
        </DropdownActionLink>
        {this.state.isOpen && (
          <DropdownItems>
            { this.props.type !== FZ_CLICK_TO_PAY_HOSTED_PAYMENT &&
            <DropdownItem onClick={this.handleClickItem(this.props.onDelete)}>
              Delete source
            </DropdownItem>
            }
            {!this.props.isPrimary && (
              <DropdownItem
                onClick={this.handleClickItem(this.props.onPrimary)}
              >
                Set as default
              </DropdownItem>
            )}
          </DropdownItems>
        )}
      </DropdownWrapper>
    )
  }
}

export default SourcesItemDropdown
