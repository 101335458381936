import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import ButtonBase from 'components.v2/Button'
import IconBase from 'components/Icon.v2'
import capitalize from 'lodash/capitalize'

/**
 * Note: this is dumb component, stateful version is available in the same name in `common/auth` folder
 */
const SocialButton = ({
  actionText = 'Login with',
  extended,
  variant,
  className,
  ...props
}) => (
  <Button
    variant={variant}
    className={'flex items-center justify-center ' + className}
    {...props}
  >
    <Icon type={variant} />
    <Text className={variant}>
      {extended ? `${actionText} ${capitalize(variant)}` : capitalize(variant)}
    </Text>
  </Button>
)

SocialButton.propTypes = {
  variant: PT.oneOf(['facebook', 'google']),
  /** if true, text with read "Sign up with <SocialMediaName>" instead of "<SocialMediaName>" */
  extended: PT.bool,
  actionText: PT.string,
}

export default SocialButton

const Button = styled(ButtonBase)`
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
`

const Icon = styled(IconBase)`
  font-size: ${prop =>
    ({
      facebook: '21px',
      google: '29px',
    }[prop.type])};
  margin-right: ${prop =>
    ({
      facebook: '10px',
      google: '13px',
    }[prop.type])};
`

const Text = styled.span`
  &.facebook {
    display: inline-block;
    padding-top: 3px;
  }
`
