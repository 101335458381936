import React from 'react'
import PropTypes from 'prop-types'

import { TextContainer } from './styled'

import { h3 } from './const'

const H3 = ({ color, children, bold, align, transform, ...props }) => {
  const { size, lineHeight, element } = h3
  const fontWeight = bold ? 500 : 300
  const Container = TextContainer(element)
  return (
    <Container
      bold={bold}
      size={size}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      align={align}
      transform={transform}
      {...props}
    >
      {children}
    </Container>
  )
}

H3.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  bold: PropTypes.bool,
  align: PropTypes.string,
}

export default H3
