const injectableStyles = (componentFactory, defaultStyledCmpts) =>
  (overrideStyledCmpts = {}) => {
    const customisedCmpts = Object.entries(overrideStyledCmpts)
      .map(([componentName, customiseCmptFn]) =>
        [componentName, customiseCmptFn(defaultStyledCmpts[componentName])])
      .reduce((acc, [k, v]) =>
        ({ ...acc, [k]: v }), {})
    const mergedComponents = { ...defaultStyledCmpts, ...customisedCmpts }

    return componentFactory(mergedComponents)
  }

export default injectableStyles
