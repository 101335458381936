import PT from 'prop-types'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'

export const withFormValidator = validators =>
  compose(
    withStateHandlers(
      ({ initValues = [] }) => ({
        errors: [],
        fields: mapValues(validators, (v, k) => ({
          value: initValues[k] || '',
        })),
      }),
      {
        handleChange: ({ fields, errors, ...state }) => ({
          target: { name, value },
        }) => {
          const newError = validators[name] && validators[name](value)
          const existingError = fields[name].error

          /**
           * Remove field-specific error if cleared/changed.
           * Note that any other error will not be shown onChange, only onBlur.
           */
          if (!existingError || existingError !== newError) {
            const updatedFields = {
              ...fields,
              [name]: { value, error: undefined },
            }

            return {
              ...state,
              fields: updatedFields,
              errors: getErrors(updatedFields),
            }
          } else {
            return {
              ...state,
              fields: { ...fields, [name]: { value, error: existingError } },
            }
          }
        },
        handleBlur: ({ fields, ...state }) => ({ target: { name, value } }) => {
          const error = validators[name] && validators[name](value)
          const updatedFields = { ...fields, [name]: { value, error } }

          return {
            ...state,
            fields: updatedFields,
            errors: getErrors(updatedFields),
          }
        },
        handleHidden: ({ fields, ...state }) => ({ name, value }) => {
          const error = validators[name] && validators[name](value)
          const updatedFields = { ...fields, [name]: { value, error } }

          return {
            ...state,
            fields: updatedFields,
            errors: getErrors(updatedFields),
          }
        },
        handleSubmit: (
          { fields, errors, ...state },
          { onSubmit, onSubmitFail = () => {} },
        ) => ev => {
          ev.preventDefault()

          const updatedFields = mapValues(fields, ({ value }, name) => ({
            value,
            error: validators[name] && validators[name](value),
          }))
          const updatedErrors = getErrors(updatedFields)

          if (!updatedErrors.length) {
            onSubmit(getValues(updatedFields))
          } else {
            onSubmitFail()
          }

          return {
            ...state,
            fields: updatedFields,
            errors: updatedErrors,
          }
        },
        setAsyncError: ({ errors, ...state }) => asyncError => ({
          ...state,
          errors: [...errors, asyncError],
        }),
        validateField: ({ fields, ...state }) => (e, name) => {
          e.preventDefault()
          const error =
            validators[name] && validators[name](get(fields, `${name}.value`))
          const updatedFields = {
            ...fields,
            [name]: { value: get(fields, `${name}.value`), error },
          }

          return {
            ...state,
            fields: updatedFields,
            errors: getErrors(updatedFields),
          }
        },
      },
    ),

    lifecycle({
      componentDidUpdate() {
        const { asyncError, setAsyncError, clearAsyncError } = this.props

        if (asyncError) {
          clearAsyncError()
          setAsyncError(asyncError)
        }
      },
    }),
  )

export const propTypes = {
  handleChange: PT.func.isRequired,
  handleBlur: PT.func.isRequired,
  handleSubmit: PT.func.isRequired,
  handleHidden: PT.func,
  setAsyncError: PT.func.isRequired,
  fields: PT.objectOf(
    PT.shape({
      value: PT.string.isRequired,
      error: PT.string,
    }),
  ).isRequired,
  errors: PT.arrayOf(PT.string).isRequired,
}

const getErrors = fields =>
  Object.values(fields).reduce(
    (acc, { error }) => (error ? [...acc, error] : acc),
    [],
  )

const getValues = fields => mapValues(fields, f => f.value)
