import React from 'react'

import { NavLink } from 'react-router-dom'
import colors from 'theme/colors'
import IconV2 from 'components.v2/Icon/IconV2'
import styled from 'styled-components'
import { Text } from 'components/Text'

export const MenuIcon = ({ src, ...rest }) => {
  return (
    <IconV2
      {...rest}
      src={src}
      beforeInjection={svg => {
        svg.setAttribute(
          'style',
          'width: 22px; height: 22px; margin-right: 20px',
        )
      }}
      wrapper="span"
    />
  )
}

export const Outlay = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  > div {
    display: none;
  }

  &:hover {
    opacity: 1;
    top: -10px;
    right: -25px;
    width: 245px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    > div {
      display: flex;
    }
  }
`

export const ProfileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  padding-bottom: 24px;
`

export const ProfileSection = styled.div`
  margin: 0 15px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

export const EditButton = styled.div`
  cursor: pointer;
  flex: 1;
`

export const AvatarSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
`

export const MenuList = styled.ul`
  margin-top: 12px;
`

export const MenuItem = styled.li`
  align-items: center;
  display: flex;
  font-weight: 500;
`

export const MenuLink = styled(NavLink)`
  align-items: center;
  color: ${colors['textDark']};
  display: flex;
  height: 40px;
  padding-left: 30px;
  text-decoration: none;

  :hover {
    background-color: rgba(46, 154, 154, 0.05);
  }
`

export const MenuEditLink = styled(props => {
  return (
    <Text
      align="center"
      fontWeight={500}
      size={13}
      color="teal"
      transform="uppercase"
      {...props}
    >
      Edit profile
    </Text>
  )
})`
  letter-spacing: 1px;
`
