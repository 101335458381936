import axios from 'axios'
import get from 'lodash/get'
import omit from 'lodash/omit'
import values from 'lodash/values'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import set from 'lodash/fp/set'
import filter from 'lodash/filter'
import entries from 'lodash/entries'
import reverse from 'lodash/reverse'
import map from 'lodash/map'
import join from 'lodash/join'
import reduce from 'lodash/reduce'
import flatten from 'lodash/flatten'
import shortid from 'shortid'
import Decimal from 'decimal.js'
import { v4 as uuidv4 } from 'uuid'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment-timezone'

import client from 'client'
import {
  createPaymentIntent,
  getCustomerLatestMultiVendorOrders,
  getGuestLatestMultiVendorOrders,
} from 'api'
import { createPromiseAction } from 'utils/asyncActions'
import notif from 'services/notification'
import {
  ordersToTotal,
  discountToTotal,
  feesToTotal,
  ordersToItemsPayload,
  applyUpdater,
} from './order'
import {
  TAKEAWAY,
  ERROR_ADDRESS_REQUIRED,
  PAYMENT_GATEWAY_STRIPE,
  PAYMENT_GATEWAY_STRIPE_CHECKOUT,
  TYPE_MULTI_VENDOR_ORDER,
  NOTE_CUSTOMER_PREFIX,
  CHECKOUT_STATUS_OFFLINE,
  CHECKOUT_STATUS_CLOSED,
} from 'pages/order/const'
import {
  LOCAL_STORAGE_DINEIN_TABLE,
  LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION,
  STATUS_MENU_ITEM_UNAVAILABLE,
} from 'pages/vendor/const'
import {
  selectGuestCheckoutHasEnabled,
  selectLatesGuestEmail,
} from 'store/guest-checkout/selectors'
import {
  selectHasSufficientPaymentSource,
  selectPaymentSourcesIsLoading,
} from 'store/payment'
import getHistory from 'services/history'
import { getPrice, CURRENCY_SYMBOL } from 'utils/currency'
import config from 'app-config'
import { show, types } from 'store/modals'

/**
 * Constants
 */
const CHECKOUT_ORDER = 'unicorn/multiVendorOrder/CHECKOUT_ORDER'
const ADD_ORDER = 'unicorn/multiVendorOrder/ADD_ORDER'
const UPDATE_ORDER = 'unicorn/multiVendorOrder/UPDATE_ORDER'
const CHANGE_ORDER_QTY = 'unicorn/multiVendorOrder/CHANGE_ORDER_QTY'
const UPSIZE_ORDER = 'unicorn/multiVendorOrder/UPSIZE_ORDER'
const REMOVE_ORDER = 'unicorn/multiVendorOrder/REMOVE_ORDER'
const REMOVE_ORDERS = 'unicorn/multiVendorOrder/REMOVE_ORDERS'
const REMOVE_ORDER_ITEMS = 'unicorn/multiVendorOrder/REMOVE_ORDER_ITEM'
export const CHANGE_SERVICE_TYPE =
  'unicorn/multiVendorOrder/CHANGE_SERVICE_TYPE'
export const CLEAR_DELIVERY_INFORMATION =
  'unicorn/multiVendorOrder/CLEAR_DELIVERY_INFORMATION'
export const CHANGE_DELIVERY_INFORMATION =
  'unicorn/multiVendorOrder/CHANGE_DELIVERY_INFORMATION'
export const VALIDATE_DELIVERY_INFORMATION =
  'unicorn/multiVendorOrder/VALIDATE_DELIVERY_INFORMATION'
export const UNVALIDATE_DELIVERY_INFORMATION =
  'unicorn/multiVendorOrder/UNVALIDATE_DELIVERY_INFORMATION'
export const SET_NOTE = 'unicorn/multiVendorOrder/SET_NOTE'
export const SET_VOUCHER = 'unicorn/multiVendorOrder/SET_VOUCHER'
export const SET_SEATING_INFO = 'unicorn/multiVendorOrder/SET_SEATING_INFO'
export const SET_NO_CUTLERY = 'unicorn/multiVendorOrder/SET_NO_CUTLERY'
export const SET_IS_LOADING = 'unicorn/multiVendorOrder/SET_IS_LOADING'
export const RELOAD_PLACED_ORDER =
  'unicorn/multiVendorOrder/RELOAD_PLACED_ORDER'
export const SHOW_MULTIVENDOR_CART =
  'unicorn/multiVendorOrder/SHOW_MULTIVENDOR_CART'
export const TOGGLE_MULTIVENDOR_EXPAND =
  'unicorn/multiVendorOrder/TOGGLE_MULTIVENDOR_EXPAND'
export const FETCH_RECENT_ORDERS =
  'unicorn/multiVendorOrder/FETCH_RECENT_ORDERS'
export const FETCH_RECENT_GUEST_ORDERS =
  'unicorn/multiVendorOrder/FETCH_RECENT_GUEST_ORDERS'
export const RESET_RECENT_ORDERS =
  'unicorn/multiVendorOrder/RESET_RECENT_ORDERS'

/**
 * Action Creators
 */
export const placeOrder = createPromiseAction(
  'unicorn/multiVendorOrder/PLACE_ORDER',
)
export const pullStatus = createPromiseAction(
  'unicorn/multiVendorOrder/PULL_STATUS',
)
export const pullMultipleCartStatus = createPromiseAction(
  'unicorn/multiVendorOrder/PULL_MULTIPLE_CART_STATUS',
)

const checkoutSrc = axios.CancelToken.source()

export const checkoutMultiVendorOrder = (args = {}) => (dispatch, getState) => {
  checkoutSrc.cancel('Cancelled for newer request')
  const { shouldRemoveUnavailableItems } = args

  const state = getState()
  const multiVendorOrders = selectMultiVendorOrders(state)
  const cart = multiVendorOrders.map(ord => ({
    venueId: get(ord, 'vendorInfo.id'),
    items: ordersToItemsPayload(get(ord, 'orders')),
  }))

  dispatch({
    type: CHECKOUT_ORDER,
    meta: { reqId: shortid.generate() },
    payload: client()
      .get('/nonce')
      .then(({ data }) =>
        client().post('/orders/multi/checkout', {
          cancelToken: checkoutSrc.token,
          nonce: data.nonces[0],
          serviceType: 'takeaway',
          orderType: 'multi-vendor',
          cart,
        }),
      )
      .then(data => data.data)
      .catch(error => {
        if (
          get(error, 'response.data.status.flags', []).includes(
            ERROR_ADDRESS_REQUIRED,
          )
        ) {
          notif.error({
            message: 'Delivery address required.',
            error,
          })
        }

        if (
          shouldRemoveUnavailableItems &&
          get(error, 'response.data.invalidItems')
        ) {
          const productIds = get(error, 'response.data.invalidItems')
            .filter(
              item => get(item, 'status') === STATUS_MENU_ITEM_UNAVAILABLE,
            )
            .reduce((prev, cur) => [...prev, get(cur, 'id')], [])

          const unavailableItems = map(
            get(error, 'response.data.invalidItems'),
            item => item.name,
          )

          dispatch({
            type: REMOVE_ORDER_ITEMS,
            payload: { productIds },
          })
          dispatch(checkoutMultiVendorOrder())
          dispatch(
            show(types.GENERIC, {
              heading: `${join(unavailableItems, ', ')} ${
                unavailableItems.length === 1 ? 'is' : 'are'
              } no longer available. We've updated your cart to reflect this. Sorry about that!`,
              buttonLabel: 'Got it',
            }),
          )
        }
      }),
  })
}

export const getRecentOrdersByCustomerId = customerId => dispatch =>
  dispatch({
    type: FETCH_RECENT_ORDERS,
    payload: getCustomerLatestMultiVendorOrders(customerId),
  })

export const getRecentOrdersByCartIds = cartIds => dispatch =>
  dispatch({
    type: FETCH_RECENT_GUEST_ORDERS,
    payload: getGuestLatestMultiVendorOrders(cartIds),
  })

export const resetRecentOrders = () => dispatch =>
  dispatch({
    type: RESET_RECENT_ORDERS,
  })

export const reloadOrderStatus = order => dispatch => {
  dispatch({ type: RELOAD_PLACED_ORDER, payload: { order } })
}

export const addMultiVendorOrder = (venueId, order, opts, vendorInfo) => (
  dispatch,
  getState,
) => {
  dispatch({ type: ADD_ORDER, payload: { venueId, order, vendorInfo } })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectMultiVendorOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(
      checkoutMultiVendorOrder({
        venueId,
        shouldRemoveUnavailableItems: get(opts, 'removeUnavailableItems'),
      }),
    )
  }
}

export const updateMultiVendorOrder = (venueId, order, opts, vendorInfo) => (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_ORDER, payload: { venueId, order, vendorInfo } })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectMultiVendorOrdersByVenueId(state, venueId).length

  if (shouldCheckout && hasOrders) {
    dispatch(
      checkoutMultiVendorOrder({
        venueId,
        shouldRemoveUnavailableItems: get(opts, 'removeUnavailableItems'),
      }),
    )
  }
}

export const upsizeMultiVendorOrder = ({
  id,
  serialisedOptions,
  venueId,
  opts,
  index,
}) => (dispatch, getState) => {
  dispatch({
    type: UPSIZE_ORDER,
    payload: { id, serialisedOptions, venueId, index },
  })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectMultiVendorOrderItems(state).length

  if (shouldCheckout && hasOrders) {
    dispatch(
      checkoutMultiVendorOrder({
        venueId,
        shouldRemoveUnavailableItems: get(opts, 'removeUnavailableItems'),
      }),
    )
  }
}

export const changeMultiVendorOrderQty = (
  { serialisedOptions, id },
  venueId,
  quantity,
  opts,
) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_ORDER_QTY,
    payload: { orderId: id, serialisedOptions, venueId, quantity },
  })

  const state = getState()
  const shouldCheckout = get(opts, 'checkout', true)
  const hasOrders = !!selectMultiVendorOrderItems(state).length

  if (shouldCheckout && hasOrders) {
    dispatch(
      checkoutMultiVendorOrder({
        venueId,
        shouldRemoveUnavailableItems: get(opts, 'removeUnavailableItems'),
      }),
    )
  }
}

export const removeOrdersByVendorIds = vendorIds => dispatch =>
  dispatch({ type: REMOVE_ORDERS, payload: { vendorIds } })

/**
 * Reducer
 */

const initialState = {
  orders: {},
  multiCheckout: {},
  isSubmitting: false,
  isChecking: false,
  checkoutReqId: null,
  placedOrders: {},
  recentOrders: [],
  recentGuestOrders: [],
}

const reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case REMOVE_ORDER_ITEMS:
      const newOrders = entries(get(state, 'orders')).reduce(
        (prev, [key, value]) => ({
          ...prev,
          ...{
            [key]: {
              ...value,
              orders: get(value, 'orders').filter(
                prod =>
                  !includes(get(payload, 'productIds', []), get(prod, 'id')),
              ),
            },
          },
        }),
        {},
      )
      const emptyVendorIds = values(newOrders).reduce((ids, cur) => {
        if (isEmpty(get(cur, 'orders')))
          return [...ids, get(cur, 'vendorInfo.id')]

        return ids
      }, [])

      return {
        ...state,
        orders: omit(newOrders, emptyVendorIds),
      }

    case REMOVE_ORDER:
      return {
        ...state,
        orders: omit(state.orders, [payload.orderId]),
      }

    case REMOVE_ORDERS:
      return {
        ...state,
        orders: omit(state.orders, get(payload, 'vendorIds')),
      }

    case ADD_ORDER:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, venueOrder => {
          const { selectedOptionIds, ...partialNewOrder } = payload.order
          const newOrder = {
            ...partialNewOrder,
            serialisedOptions: JSON.stringify(selectedOptionIds),
          }

          const oldOrderIndex = venueOrder.orders.findIndex(
            oldOrder =>
              oldOrder.serialisedOptions === newOrder.serialisedOptions &&
              oldOrder.id === newOrder.id,
          )

          return {
            ...{
              ...venueOrder,
              ...(get(payload, 'vendorInfo') && {
                vendorInfo: get(payload, 'vendorInfo'),
              }),
            },
            orders:
              oldOrderIndex === -1
                ? [...venueOrder.orders, newOrder]
                : venueOrder.orders.map(order =>
                    order.serialisedOptions === newOrder.serialisedOptions
                      ? {
                          ...order,
                          quantity: order.quantity + newOrder.quantity,
                        }
                      : order,
                  ),
          }
        }),
        ...(get(payload, 'vendorInfo.multiVendorGroupName') && {
          latestPrecinct: get(payload, 'vendorInfo.multiVendorGroupName'),
        }),
      }

    case UPDATE_ORDER:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, venueOrder => {
          const { selectedOptionIds, index, ...partialNewOrder } = payload.order
          const newOrder = {
            ...partialNewOrder,
            serialisedOptions: JSON.stringify(selectedOptionIds),
          }

          return {
            ...{
              ...venueOrder,
              ...(get(payload, 'vendorInfo') && {
                vendorInfo: get(payload, 'vendorInfo'),
              }),
            },
            orders: venueOrder.orders.map((order, i) =>
              index === i ? newOrder : order,
            ),
          }
        }),
        ...(get(payload, 'vendorInfo.multiVendorGroupName') && {
          latestPrecinct: get(payload, 'vendorInfo.multiVendorGroupName'),
        }),
      }

    case UPSIZE_ORDER: {
      const { serialisedOptions, id, venueId, index } = payload
      const venueOrder = state.orders[venueId]

      const updatedState = {
        ...state,
        orders: {
          ...state.orders,
          [venueId]: {
            ...venueOrder,
            orders: venueOrder.orders.map(
              (order, orderIndex) =>
                order.id === id && index === orderIndex
                  ? {
                      ...order,
                      serialisedOptions: JSON.stringify(serialisedOptions),
                      unitPrice: reduce(
                        serialisedOptions,
                        (currentPrice, o) =>
                          currentPrice.plus(
                            reduce(
                              o,
                              (acc, i) => {
                                const selectedOption = flatten(
                                  values(order.optionGroups),
                                ).find(option =>
                                  option.options.find(j => j.id === i),
                                )
                                const currentOption = selectedOption.options.find(
                                  j => j.id === i,
                                )
                                if (currentOption)
                                  return acc + getPrice(currentOption.price)
                                return acc
                              },
                              0,
                            ),
                          ),
                        new Decimal(getPrice(order.price)),
                      ).toNumber(),
                    }
                  : order,
              [],
            ),
          },
        },
      }

      const emptyVendorIds = values(get(updatedState, 'orders')).reduce(
        (ids, cur) => {
          if (isEmpty(get(cur, 'orders')))
            return [...ids, get(cur, 'vendorInfo.id')]

          return ids
        },
        [],
      )

      return {
        ...updatedState,
        orders: omit(get(updatedState, 'orders'), emptyVendorIds),
      }
    }

    case CHANGE_ORDER_QTY: {
      const { serialisedOptions, orderId, venueId, quantity } = payload
      const venueOrder = state.orders[venueId]

      const updatedState = {
        ...state,
        orders: {
          ...state.orders,
          [venueId]: {
            ...venueOrder,
            orders: venueOrder.orders.reduce((orders, order) => {
              const areOrdersIdentical =
                order.serialisedOptions === serialisedOptions &&
                order.id === orderId

              if (!areOrdersIdentical) return [...orders, order]

              if (quantity > 0) return [...orders, { ...order, quantity }]

              return orders // payload.quantity === 0 thus no need to include it to orders anymore
            }, []),
          },
        },
      }

      const emptyVendorIds = values(get(updatedState, 'orders')).reduce(
        (ids, cur) => {
          if (isEmpty(get(cur, 'orders')))
            return [...ids, get(cur, 'vendorInfo.id')]

          return ids
        },
        [],
      )

      return {
        ...updatedState,
        orders: omit(get(updatedState, 'orders'), emptyVendorIds),
      }
    }

    case SET_NOTE:
      return {
        ...updateVenueOrderInfoById(state, payload.venueId, {
          note: payload.note,
        }),
        isSubmitting: false,
      }

    case `${CHECKOUT_ORDER}_PENDING`:
      return {
        ...state,
        multiCheckout: {},
        isChecking: true,
        checkoutReqId: meta.reqId,
        isRedirecting: false,
      }

    case `${CHECKOUT_ORDER}_FULFILLED`:
      return meta.reqId === state.checkoutReqId
        ? {
            ...state,
            multiCheckout: payload,
            isChecking: false,
          }
        : state

    case `${CHECKOUT_ORDER}_REJECTED`:
      return { ...state, isChecking: false }

    case SET_IS_LOADING:
      return {
        ...state,
        isChecking: payload.isLoading,
      }

    case String(placeOrder):
      return {
        ...state,
        isSubmitting: true,
        ...(get(payload, 'isAnonymous') && { isRedirecting: true }),
      }

    case String(placeOrder.fulfilled):
      return {
        ...state,
        multiCheckout: {},
        orders: {},
        latestPlacedCartId: get(payload, 'cartId'),
        placedOrders: {
          ...get(state, 'placedOrders'),
          [get(payload, 'cartId')]: {
            orders: omit(
              get(state, 'orders'),
              get(payload, 'excludedVendors', []),
            ),
            multiCheckout: get(state, 'multiCheckout'),
            excludedVendors: get(payload, 'excludedVendors', []),
            ...(get(payload, 'isAnonymous') && {
              isAnonymous: true,
              createdAtClient: moment().format(),
              email: get(payload, 'latestGuestEmail'),
            }),
          },
        },
        isSubmitting: false,
      }

    case String(placeOrder.rejected):
      return {
        ...state,
        error: payload,
        isSubmitting: false,
      }

    case String(RELOAD_PLACED_ORDER):
      return updatePlacedOrderById(state, get(payload, 'order.cartId'), {
        multiCheckout: {
          fees: get(payload, 'order.order.fees'),
          discounts: get(payload, 'order.order.discounts'),
          venuesOrderTotal: get(payload, 'order.order.venuesOrderTotal'),
        },
        orders: get(payload, 'order.order.orders').reduce((prev, cur) => {
          prev[get(cur, 'vendor.id').toString()] = {
            orders: get(cur, 'items')
              .reduce((prev, cur) => {
                prev[get(cur, 'product.id').toString()] = get(
                  prev,
                  `${get(cur, 'product.id')}`,
                )
                  ? Object.assign(get(prev, `${get(cur, 'product.id')}`), {
                      quantity: new Decimal(
                        get(prev, `${get(cur, 'product.id')}.quantity`),
                      )
                        .plus(1)
                        .toNumber(),
                    })
                  : {
                      id: get(cur, 'id'),
                      name: get(cur, 'product.name'),
                      price: `${CURRENCY_SYMBOL}${get(cur, 'cost')}`,
                      unitPrice: new Decimal(get(cur, 'cost'))
                        .plus(
                          get(cur, 'options', []).reduce(
                            (p, c) =>
                              new Decimal(p)
                                .plus(get(c, 'price', 0))
                                .toNumber(),
                            0,
                          ),
                        )
                        .toNumber(),
                      quantity: 1,
                      ...(get(cur, 'options') && {
                        optionGroups: {
                          normal: get(cur, 'options').reduce(
                            (a, b) => [
                              ...a,
                              ...[
                                {
                                  name: get(b, 'option.optionGroup.name'),
                                  options: [
                                    {
                                      id: get(b, 'id'),
                                      name: get(b, 'option.value'),
                                    },
                                  ],
                                },
                              ],
                            ],
                            [],
                          ),
                        },
                      }),
                      serialisedOptions: JSON.stringify(
                        get(cur, 'options', []).reduce(
                          (c, d) => [...c, get(d, 'id')],
                          [],
                        ),
                      ),
                    }
                return prev
              }, [])
              .reduce((prev, cur) => [...prev, ...[cur]], []),
            note: (!isEmpty(get(cur, 'customerNote', ''))
              ? get(cur, 'customerNote', '')
              : ''
            )
              .replace(`${NOTE_CUSTOMER_PREFIX}. `, '')
              .replace(NOTE_CUSTOMER_PREFIX, ''),
            voucher: '',
            vendorInfo: get(cur, 'vendor'),
          }
          return prev
        }, {}),
        cartId: get(payload, 'order.cartId'),
      })

    case String(pullStatus):
      const existingPlacedOrder = selectPlacedOrderById(state, payload)

      return updatePlacedOrderById(state, payload, {
        status: {
          ...get(existingPlacedOrder, `status`, {}),
        },
        error: null,
      })

    case String(pullStatus.fulfilled):
      return updatePlacedOrderById(state, payload.orderId, {
        status: {
          ...pick(payload, [
            'orderStatuses',
            'paymentStatus',
            'etaInMinutes',
            'acceptedAt',
            'orderId',
            'reason',
            'paymentErrorData',
          ]),
        },
      })

    case String(pullStatus.rejected):
      return updatePlacedOrderById(state, payload.orderId, {
        status: {
          error: payload.error,
        },
      })

    case String(pullMultipleCartStatus):
      const existingPlacedOrder2 = selectPlacedOrderById(state, payload)

      return updatePlacedOrderById(state, payload, {
        status: {
          ...get(existingPlacedOrder2, `status`, {}),
        },
        error: null,
      })

    case String(pullMultipleCartStatus.fulfilled):
      return updatePlacedOrderById(state, payload.orderId, {
        status: {
          ...pick(payload, [
            'orderStatuses',
            'paymentStatus',
            'etaInMinutes',
            'acceptedAt',
            'orderId',
            'reason',
            'paymentErrorData',
          ]),
        },
      })

    case String(pullMultipleCartStatus.rejected):
      return updatePlacedOrderById(state, payload.orderId, {
        status: {
          error: payload.error,
        },
      })

    case `${FETCH_RECENT_ORDERS}_FULFILLED`:
    case `${FETCH_RECENT_GUEST_ORDERS}_FULFILLED`:
      const latestOrders = get(payload, 'cart', []).reduce(
        (prev, cur) => [
          ...prev,
          {
            ...cur,
            orders: get(cur, 'orders').reduce(
              (orderlist, curOrder) => [
                ...orderlist,
                {
                  ...curOrder,
                  vendorInfo: get(curOrder, 'vendor'),
                  orders: get(curOrder, 'items')
                    .reduce((prev, cur) => {
                      prev[get(cur, 'product.id').toString()] = get(
                        prev,
                        `${get(cur, 'product.id')}`,
                      )
                        ? Object.assign(
                            get(prev, `${get(cur, 'product.id')}`),
                            {
                              quantity: new Decimal(
                                get(prev, `${get(cur, 'product.id')}.quantity`),
                              )
                                .plus(1)
                                .toNumber(),
                            },
                          )
                        : {
                            id: get(cur, 'id'),
                            name: get(cur, 'product.name'),
                            price: `${CURRENCY_SYMBOL}${get(cur, 'cost')}`,
                            unitPrice: new Decimal(get(cur, 'cost'))
                              .plus(
                                get(cur, 'options', []).reduce(
                                  (p, c) =>
                                    new Decimal(p)
                                      .plus(get(c, 'price', 0))
                                      .toNumber(),
                                  0,
                                ),
                              )
                              .toNumber(),
                            quantity: 1,
                            ...(get(cur, 'options') && {
                              optionGroups: {
                                normal: get(cur, 'options').reduce(
                                  (a, b) => [
                                    ...a,
                                    ...[
                                      {
                                        name: get(b, 'option.optionGroup.name'),
                                        options: [
                                          {
                                            id: get(b, 'id'),
                                            name: get(b, 'option.value'),
                                          },
                                        ],
                                      },
                                    ],
                                  ],
                                  [],
                                ),
                              },
                            }),
                            serialisedOptions: JSON.stringify(
                              get(cur, 'options', []).reduce(
                                (c, d) => [...c, get(d, 'id')],
                                [],
                              ),
                            ),
                          }
                      return prev
                    }, [])
                    .reduce((prev, cur) => [...prev, ...[cur]], []),
                },
              ],
              [],
            ),
            ordersSubTotal: get(cur, 'orders')
              .reduce(
                (acc, x) =>
                  acc.plus(
                    get(x, 'venuesOrderTotal')
                      .filter(
                        total => get(total, 'venueId') === get(x, 'vendor.id'),
                      )
                      .reduce(
                        (totals, y) =>
                          totals.plus(getPrice(get(y, 'subTotal'))),
                        new Decimal(0),
                      ),
                  ),
                new Decimal(0),
              )
              .toNumber(),
            ordersQuantity: get(cur, 'orders')
              .reduce(
                (acc, x) =>
                  acc.plus(
                    get(x, 'venuesOrderTotal')
                      .filter(
                        total => get(total, 'venueId') === get(x, 'vendor.id'),
                      )
                      .reduce(
                        (qtys, y) => qtys.plus(get(y, 'quantity')),
                        new Decimal(0),
                      ),
                  ),
                new Decimal(0),
              )
              .toNumber(),
            discountTotal: get(cur, 'orders')
              .reduce(
                (acc, x) =>
                  acc.plus(
                    get(x, 'discounts')
                      .filter(
                        discount =>
                          get(discount, 'venueId') === get(x, 'vendor.id'),
                      )
                      .reduce(
                        (discounts, y) =>
                          discounts.plus(getPrice(get(y, 'amount'))),
                        new Decimal(0),
                      ),
                  ),
                new Decimal(0),
              )
              .toNumber(),
            feesTotal: get(cur, 'orders')
              .reduce(
                (acc, x) =>
                  acc.plus(
                    get(x, 'fees')
                      .filter(
                        fee => get(fee, 'venueId') === get(x, 'vendor.id'),
                      )
                      .reduce(
                        (fees, y) => fees.plus(getPrice(get(y, 'amount'))),
                        new Decimal(0),
                      ),
                  ),
                new Decimal(0),
              )
              .toNumber(),
            ordersTotal: get(cur, 'orders')
              .reduce(
                (acc, x) =>
                  acc.plus(
                    get(x, 'venuesOrderTotal')
                      .filter(
                        total => get(total, 'venueId') === get(x, 'vendor.id'),
                      )
                      .reduce(
                        (totals, y) => totals.plus(getPrice(get(y, 'total'))),
                        new Decimal(0),
                      ),
                  ),
                new Decimal(0),
              )
              .toNumber(),
          },
        ],
        [],
      )

      return {
        ...state,
        recentOrders: latestOrders,
        ...(type === `${FETCH_RECENT_ORDERS}_FULFILLED` && {
          recentOrders: latestOrders,
        }),
        ...(type === `${FETCH_RECENT_GUEST_ORDERS}_FULFILLED` && {
          recentGuestOrders: latestOrders,
        }),
      }

    case RESET_RECENT_ORDERS:
      return {
        ...state,
        recentOrders: [],
      }

    default:
      return state
  }
}

/**
 * Selectors
 */

export const selectMultiVendorOrderInfoByVenueId = (state, venueId) => {
  const orderInfo = get(state, `multiVendorOrder.orders.${venueId}`)
  return orderInfo && { ...orderInfo, venueId }
}

export const selectMultiVendorIsChecking = state =>
  state.multiVendorOrder.isChecking

export const selectMultiVendorCheckout = state =>
  state.multiVendorOrder.multiCheckout

export const selectMultiVendorLatestPrecinct = state =>
  state.multiVendorOrder.latestPrecinct

export const selectMultiVendorIsRedirecting = state =>
  state.multiVendorOrder.isRedirecting

export const selectMultiVendorRecentOrders = state =>
  state.multiVendorOrder.recentOrders

export const selectMultiVendorRecentTransformedGuestOrders = state =>
  state.multiVendorOrder.recentGuestOrders

export const selectMultiVendorRecentGuestOrders = state =>
  reverse(
    values(get(state, 'multiVendorOrder.placedOrders')).filter(
      cart =>
        get(cart, 'orders') &&
        get(cart, 'isAnonymous') &&
        get(cart, 'email') === selectLatesGuestEmail(state) &&
        //14400 secs = 4 hours
        moment().diff(moment(get(cart, 'createdAtClient')), 'seconds') > 0 &&
        moment().diff(moment(get(cart, 'createdAtClient')), 'seconds') <= 14400,
    ),
  )

export const selectMultiVendorRecentGuestOrdersCartIds = state =>
  selectMultiVendorRecentGuestOrders(state).reduce(
    (prev, cur) => [...prev, get(cur, 'cartId')],
    [],
  )

export const selectMultiVendorOrders = state =>
  values(get(state, `multiVendorOrder.orders`, []))

export const selectMultiVendorOrdersByPrecinct = (state, precinct) =>
  filter(
    values(get(state, `multiVendorOrder.orders`, [])),
    vendor => vendor.vendorInfo.multiVendorGroupName === precinct,
  )

export const selectMultiVendorOrderItems = state =>
  selectMultiVendorOrders(state).reduce(
    (prev, cur) => [...prev, ...get(cur, 'orders', [])],
    [],
  )

export const selectMultiVendorOrdersByVenueId = (state, venueId) =>
  get(state, `multiVendorOrder.orders.${venueId}.orders`, [])

export const selectMultiVendorDiscountsByVenueId = (state, venueId) =>
  get(state, `multiVendorOrder.orders.${venueId}.checkout.discounts`, [])

export const selectMultiVendorOrderItemsDiscounts = state =>
  selectMultiVendorOrders(state).reduce(
    (prev, cur) => [...prev, ...get(cur, 'checkout.discounts', [])],
    [],
  )

export const selectMultiVendorFeesByVenueId = (state, venueId) =>
  get(state, `multiVendorOrder.orders.${venueId}.checkout.fees`, [])

export const selectMultiVendorOrderItemsFees = state =>
  selectMultiVendorOrders(state).reduce(
    (prev, cur) => [...prev, ...get(cur, 'checkout.fees', [])],
    [],
  )

export const selectIsSubmitting = state => state.multiVendorOrder.isSubmitting

export const selectCanPlaceMultiVendorOrder = (state, priceToPay) => {
  const hasOrder = selectMultiVendorOrders(state).length
  const isGuestCheckout = selectGuestCheckoutHasEnabled(state)

  const isCheckingOut = selectMultiVendorIsChecking(state)

  const isLoadingPaymentSource = selectPaymentSourcesIsLoading(state)
  const hasSufficientPaymentSource = !isGuestCheckout
    ? selectHasSufficientPaymentSource(state, priceToPay)
    : true

  const isLoading = !!(isCheckingOut || isLoadingPaymentSource)
  const canPlaceOrder = !!(!isLoading && hasOrder && hasSufficientPaymentSource)

  return {
    isLoading,
    value: canPlaceOrder,
  }
}

export const selectTotal = state => {
  const selectedOrders = selectMultiVendorOrderItems(state)
  const discounts = selectMultiVendorOrderItemsDiscounts(state)
  const fees = selectMultiVendorOrderItemsFees(state)

  const ordersTotal = ordersToTotal(selectedOrders)
  const discountTotal = discountToTotal(discounts)
  const feesTotal = feesToTotal(fees)

  return new Decimal(ordersTotal)
    .plus(discountTotal)
    .plus(feesTotal)
    .toNumber()
}

export const selectLatestPlacedCartId = state =>
  state.multiVendorOrder.latestPlacedCartId

export const selectPlacedMultiVendorOrderById = (state, cartId) => {
  const existingPlacedOrder =
    cartId && state.multiVendorOrder.placedOrders[cartId]
  return existingPlacedOrder && { ...existingPlacedOrder }
}

export const selectPlacedOrderMultiById = (state, cartId) => {
  return get(state, `multiVendorOrder.placedOrders.${cartId}`, {})
}

export const smartSelectMultiOrderById = (state, id) => {
  const value = selectPlacedOrderMultiById(state, id)
  return value
}

export const selectMultiVendorTotals = state => {
  const multiVendorCheckoutData = selectMultiVendorCheckout(state)
  const offlineVendors = values(get(multiVendorCheckoutData, 'status')).reduce(
    (prev, cur) => {
      const ids =
        includes(get(cur, 'flags', []), CHECKOUT_STATUS_OFFLINE) ||
        includes(get(cur, 'flags', []), CHECKOUT_STATUS_CLOSED)
          ? [get(cur, 'venueId')]
          : []
      return [...prev, ...ids]
    },
    [],
  )

  const ordersQuantity = values(
    get(multiVendorCheckoutData, 'venuesOrderTotal'),
  )
    .filter(qty => !includes(offlineVendors, get(qty, 'venueId')))
    .reduce((acc, x) => acc.plus(get(x, 'quantity')), new Decimal(0))
    .toNumber()
  const ordersTotal = values(get(multiVendorCheckoutData, 'venuesOrderTotal'))
    .filter(total => !includes(offlineVendors, get(total, 'venueId')))
    .reduce((acc, x) => acc.plus(get(x, 'total')), new Decimal(0))
    .toNumber()
  const ordersSubTotal = values(
    get(multiVendorCheckoutData, 'venuesOrderTotal'),
  )
    .filter(total => !includes(offlineVendors, get(total, 'venueId')))
    .reduce((acc, x) => acc.plus(getPrice(get(x, 'subTotal'))), new Decimal(0))
    .toNumber()
  const discountTotal = values(get(multiVendorCheckoutData, 'discounts'))
    .filter(discount => !includes(offlineVendors, get(discount, 'venueId')))
    .reduce((acc, x) => acc.plus(getPrice(get(x, 'amount'))), new Decimal(0))
    .toNumber()

  const feesTotal = values(get(multiVendorCheckoutData, 'fees'))
    .filter(fee => !includes(offlineVendors, get(fee, 'venueId')))
    .reduce((acc, x) => acc.plus(getPrice(get(x, 'amount'))), new Decimal(0))
    .toNumber()

  return {
    ordersQuantity,
    ordersTotal,
    ordersSubTotal,
    discountTotal,
    feesTotal,
  }
}

/**
 * Helpers
 */

const orderSubmitV3 = async (placeOrder, params, ownProps) => {
  const paymentConfig = await ownProps.fetchPaymentConfig()
  const stripe = await loadStripe(
    get(paymentConfig, 'value.stripe.publishableKey'),
  )

  return await placeOrder(params)
    .then(async ({ orderId, pi_client_secret, checkout_url, cart_id }) => {
      localStorage.removeItem(`${LOCAL_STORAGE_DINEIN_TABLE}_${params.venueId}`)
      localStorage.removeItem(
        `${LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION}_${params.venueId}_${params.seatingInfo}`,
      )

      if (
        get(params, 'payment.type') === PAYMENT_GATEWAY_STRIPE_CHECKOUT &&
        checkout_url
      ) {
        window.location.href = checkout_url
        await ownProps.unsetGuestCheckoutEmail()
        return
      }

      if (!params.orderPartyId && !checkout_url) {
        getHistory().replace(`/order/cart/${cart_id}/status`)
      }

      const confirmRes = await stripe.confirmCardPayment(pi_client_secret)

      if (confirmRes.error) {
        notif.error({
          title: 'Error',
          message: get(confirmRes, 'error.message'),
        })
      }
    })
    .catch(err => {
      const message = get(err, 'response.data.status.message')
      const unavailableItems = get(err, 'response.data.invalidItems', [])
        .filter(item => get(item, 'status') === STATUS_MENU_ITEM_UNAVAILABLE)
        .reduce((prev, cur) => [...prev, get(cur, 'id')], [])

      if (!isEmpty(unavailableItems)) {
        ownProps.reCalculateOrderAndRemoveUnavailableItems()
        return
      }

      message &&
        ownProps.notify({
          type: 'error',
          message,
        })
    })
    .finally(async () => {
      await ownProps.handleIsLoadingChange(false)
    })
}

export const handlePlaceOrder = async (state, ownProps, placeOrder) => {
  if (!state.canPlaceOrder.value) {
    return
  }

  const isGuestCheckout = get(state, 'isGuestCheckout')
  const guestCheckoutCustomer = get(state, 'guestCheckoutCustomer')
  const cart = get(ownProps, 'orders', [])
    .filter(
      ord => !includes(get(state, 'offlineVendors'), get(ord, 'vendorInfo.id')),
    )
    .map(ord => ({
      venueId: get(ord, 'vendorInfo.id'),
      items: ordersToItemsPayload(get(ord, 'orders')),
      serviceType: TAKEAWAY,
      ...(get(ord, 'note') && { orderNote: get(ord, 'note') }),
    }))

  if (isEmpty(cart)) {
    await notif.error({
      title: 'Selected venues are not available.',
      message: 'Please try other venues.',
    })
    return
  }

  await ownProps.handleIsLoadingChange(true)
  let intentId = null // eslint-disable-line no-unused-vars
  let authorized = true // eslint-disable-line no-unused-vars
  const credits = getPrice(get(state, 'sources.credit.available', ''))
  const transactionTotal = new Decimal(state.priceToPay)
    .minus(credits)
    .toNumber()

  if (
    get(state, 'sources.card.type') === PAYMENT_GATEWAY_STRIPE &&
    transactionTotal > 0 &&
    config.ORDER_SUBMIT_VERSION === '2'
  ) {
    const intent = await createPaymentIntent({
      charge: transactionTotal,
      idempotent_key: uuidv4(),
    })

    if (intent.data.requires_action) {
      const paymentConfig = await ownProps.fetchPaymentConfig()
      const stripe = await loadStripe(
        get(paymentConfig, 'value.stripe.publishableKey'),
      )
      await stripe
        .confirmCardPayment(intent.data.pi_client_secret)
        .then(function(confirm) {
          if (confirm.error) {
            notif.error({
              title: 'Error',
              message: get(confirm, 'error.message'),
            })

            authorized = false
          } else {
            intentId = confirm.paymentIntent.id
          }
        })
    } else {
      intentId = intent.data.pi_id
    }
  }

  // SEND ORDER
  const params = {
    ...(get(state, 'sources.card.type') === PAYMENT_GATEWAY_STRIPE &&
      config.ORDER_SUBMIT_VERSION === '3' && {
        payment: {
          type: get(state, 'sources.card.type'),
        },
      }),
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        payment: { type: PAYMENT_GATEWAY_STRIPE_CHECKOUT },
      }),
    serviceType: TAKEAWAY,
    customerId: state.customerId,
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        customer: { ...guestCheckoutCustomer },
      }),
    ...(isGuestCheckout &&
      guestCheckoutCustomer && {
        isAnonymous: true,
      }),
    ...(cart && { cart: cart }),
    orderType: TYPE_MULTI_VENDOR_ORDER,
    excludedVendors: get(state, 'offlineVendors'),
  }

  orderSubmitV3(placeOrder, params, ownProps)
}

/**
 * Internal Functions
 */

const initialVenueOrder = {
  checkout: {},
  orders: [],
  note: '',
  voucher: '',
  vendorInfo: {},
}

const updateVenueOrderInfoById = (orderState, venueId, updater) => {
  const existingVenueOrderInfo =
    selectMultiVendorOrderInfoByVenueId(
      { multiVendorOrder: orderState },
      venueId,
    ) || initialVenueOrder
  const updatedOrder = applyUpdater(existingVenueOrderInfo, updater)
  return set(`orders.${venueId}`, updatedOrder, orderState)
}

const updatePlacedOrderById = (orderState, cartId, updater) => {
  const existingPlacedOrder = selectPlacedOrderById(orderState, cartId)
  const updatedPlacedOrder = applyUpdater(existingPlacedOrder, updater)
  return set(`placedOrders.${cartId}`, updatedPlacedOrder, orderState)
}

export const selectPlacedOrderById = (state, cartId) => {
  const existingPlacedOrder = cartId && get(state, `placedOrders.${cartId}`)
  return existingPlacedOrder && { ...existingPlacedOrder, cartId }
}

export default reducer
