import React, { Component } from 'react'

import { Formik, Form } from 'formik'
import PT from 'prop-types'
import * as Yup from 'yup'

import { Heading, Text } from './styled'
import Button from 'components.v2/Button'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { emailRegex } from 'utils/validator'

export default class extends Component {
  static propsTypes = {
    isSubmitting: PT.bool,
    onSubmit: PT.func,
  }

  render() {
    const { isSubmitting, onSubmit } = this.props
    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .matches(emailRegex, 'Invalid email address') // TODO: Add to a constant value
            .required('Email is required!'), // TODO: Add to a constant value
        })}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleBlur }) => {
          return (
            <Form>
              <Heading className="mt-40">Forgot Password</Heading>
              <Text as="p" className="mt-10 mb-30 xl:mt-15">
                Enter your email address below and we will send you a link to
                reset your password.
              </Text>

              <div className="text-left">
                {errors.email && <FormError>{errors.email}</FormError>}
              </div>

              <div className="mb-30">
                <Input
                  className="block w-full"
                  disabled={isSubmitting}
                  hasError={!!errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Email"
                  type="email"
                  value={values.email}
                />
              </div>

              <div className="flex -mx-5">
                <Button
                  className="w-full px-0"
                  disabled={isSubmitting}
                  large
                  largeText
                  type="submit"
                  variant="primary"
                >
                  {isSubmitting ? 'Please Wait...' : 'Reset Password'}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
