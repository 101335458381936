import { connect } from 'react-redux'
import { compose, lifecycle, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router-dom'
import isNode from 'detect-node'

import {
  selectSiteAndMenuSuggestions,
  selectIsLoading,
} from 'pages/search/venues'
import { getQuery } from 'common/CuisineSearchBar/cuisine'
import { hide } from 'store/modals'
import { getIsLocationSelected } from 'common/LocationSearchBar/location'

export default compose(
  connect(
    state => {
      const query = getQuery(state)
      const { sites, menus } = selectSiteAndMenuSuggestions(state)
      const hasLocation = getIsLocationSelected(state)

      return {
        noMatch: Boolean(
          query && !selectIsLoading(state) && (!menus.length && !sites.length),
        ),
        hasQuery: !!query,
        sites,
        menus,
        hasLocation,
      }
    },
    { hide },
  ),

  withRouter,

  withPropsOnChange(
    ['location', 'hasLocation', 'hasQuery'],
    ({ location, hasLocation, hasQuery }) => ({
      disallowHide:
        location.pathname === '/search' && !hasLocation && !hasQuery,
    }),
  ),

  lifecycle({
    componentDidMount() {
      this.unlisten = this.props.history.listen(now => {
        const prev = this.props.location

        if (prev.pathname !== now.pathname) this.props.hide()
      })

      if (!isNode) {
        this.keydownListener = passEv => {
          const ev = passEv || window.event

          if (this.props.disallowHide) return

          const isEscape =
            'key' in ev
              ? ev.key === 'Escape' || ev.key === 'Esc'
              : ev.keyCode === 27

          if (isEscape) this.props.hide()
        }

        document.addEventListener('keydown', this.keydownListener)
      }
    },

    componentWillUnmount() {
      this.unlisten()

      if (this.keydownListener)
        document.removeEventListener('keydown', this.keydownListener)
    },
  }),
)
