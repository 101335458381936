import { REHYDRATE } from 'redux-persist'
import config from '../persistConfig'

const targetRehydrationCount = Object.keys(config).length
const initialState = { rehydrationCount: 0, hasRehydrated: false }

export const selectHasRehydrated = state => state.rehydration.hasRehydrated

export default (state = initialState, action) =>
  action.type === REHYDRATE
    ? {
        rehydrationCount: state.rehydrationCount + 1,
        hasRehydrated: state.rehydrationCount + 1 === targetRehydrationCount,
      }
    : state
