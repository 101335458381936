import React from 'react'
import Button from 'components.v2/Button'
import get from 'lodash/get'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { createSetupIntent } from 'api'
import { getError } from 'utils/get-message'
import { getStripeError } from 'utils/get-stripe-message'
import notif from 'services/notification'

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardNumberError: null,
      cardExpiryError: null,
      cardCvcError: null,
      busy: false,
      postal: '',
      name: '',
    }
  }

  handleChange = event => {
    this.setState({
      [`${event.elementType}Error`]: get(event, 'error.message', null),
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    !this.state.busy &&
      this.setState({ busy: true }, async () => {
        const { stripe, elements, addPaymentSource, handleClose } = this.props
        const {
          cardNumberError,
          cardExpiryError,
          cardCvcError,
          name,
          postal,
        } = this.state
        let paymentMethod = null

        if (
          !stripe ||
          !elements ||
          cardNumberError ||
          cardExpiryError ||
          cardCvcError
        ) {
          return
        }

        // Create setup intent
        const setupIntent = await createSetupIntent()

        // Confirm setup
        await stripe
          .confirmCardSetup(get(setupIntent, 'data.clientSecret'), {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name,
                address: {
                  postal_code: postal,
                },
              },
            },
          })
          .then(confirmIntent => {
            if (confirmIntent.error) {
              notif.error(getStripeError(confirmIntent))
              return
            }
            paymentMethod = get(confirmIntent, 'setupIntent.payment_method')
          })
          .catch(error => {
            notif.error({
              title: 'Error',
              message: getError(error),
            })
          })
          .finally(() => {
            // Attempt to add source regardless of stripe outcome
            addPaymentSource({ payment_method: paymentMethod })
              .catch(e => {
                notif.error({
                  title: 'Error',
                  message: getError(e),
                })
              })
              .finally(() => {
                this.setState({ busy: false }, handleClose)
              })
          })
      })
  }

  render() {
    const { stripe } = this.props
    const {
      cardNumberError,
      cardExpiryError,
      cardCvcError,
      busy,
      name,
      postal,
    } = this.state

    return (
      <form>
        <div className="form-row">
          <label className="stripe-form-label" htmlFor="name">
            Card holder name
          </label>
          <input
            id="name"
            className="StripeElement Details"
            placeholder="Jenny Rosen"
            value={name}
            disabled={busy}
            onChange={event => {
              this.setState({ name: event.target.value })
            }}
          />
          <label className="stripe-form-label" htmlFor="card-number">
            Card number
          </label>
          <CardNumberElement
            id="card-number"
            onChange={this.handleChange}
            options={{
              disabled: busy,
              style: {
                base: {
                  fontSize: '16px',
                },
              },
            }}
          />
          <div className="card-errors" role="alert">
            {cardNumberError}
          </div>
          <label className="stripe-form-label" htmlFor="card-expiry">
            Expiration date
          </label>
          <CardExpiryElement
            id="card-expiry"
            onChange={this.handleChange}
            options={{
              disabled: busy,
              style: {
                base: {
                  fontSize: '16px',
                },
              },
            }}
          />
          <div className="card-errors" role="alert">
            {cardExpiryError}
          </div>
          <label className="stripe-form-label" htmlFor="card-cvc">
            CVC
          </label>
          <CardCvcElement
            id="card-cvc"
            onChange={this.handleChange}
            options={{
              disabled: busy,
              style: {
                base: {
                  fontSize: '16px',
                },
              },
            }}
          />
          <div className="card-errors" role="alert">
            {cardCvcError}
          </div>
          <label className="stripe-form-label" htmlFor="postal">
            Postal code
          </label>
          <input
            id="postal"
            placeholder="12345"
            className="StripeElement Details"
            value={postal}
            disabled={busy}
            onChange={event => {
              this.setState({ postal: event.target.value })
            }}
          />
        </div>
        <Button
          variant="primary"
          type="submit"
          className="block mx-auto mt-40 mb-20"
          disabled={!stripe || busy}
          onClick={this.handleSubmit}
        >
          {busy ? 'Please Wait' : 'Add Card'}
        </Button>
      </form>
    )
  }
}

export default CheckoutForm
