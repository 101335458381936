import styled from 'styled-components'

import { mobileFirstMedia as media } from 'utils/media'

export const Heading = styled.h1`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;

  ${media.screen`
    font-size: 24px;
  `}
`
