import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet-async'

import { Container, Content } from './styled'
import meta from 'const/meta'
import config from 'app-config'
import WhiteLabelModals from 'common/WhiteLabelModals'
import Analytics from 'containers/Analytics'
import { LayoutConsumer } from 'contexts/layout-context'
import { ConfigConsumer } from 'contexts/config-context'
import WhiteLabelPageHeader from 'layouts/WhiteLabelPageHeader'
import WhiteLabelPageFooter from 'layouts/WhiteLabelPageFooter'
import Maintenance from './Maintenance'
import get from 'lodash/get'
import DesktopContainer from 'layouts/DesktopContainer'

class Root extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Analytics />
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="og:title" content={meta.title} />
          <meta name="og:site_name" content={meta.ogSitename} />
          <meta name="og:description" content={meta.description} />
          <meta name="og:image" content={meta.ogImage} />
          <meta name="og:app_id" content={config.FACEBOOK_APP_ID} />
        </Helmet>
        <ConfigConsumer>
          {p =>
            get(p, 'maintenance.consumer_web_whitelabel') ? (
              <LayoutConsumer>
                {({ header }) => (
                  <Container>
                    {header && <DesktopContainer />}
                    <Content>
                      <Maintenance />
                    </Content>
                  </Container>
                )}
              </LayoutConsumer>
            ) : (
              <LayoutConsumer>
                {({ header, footer }) => (
                  <Container>
                    {header && (
                      <WhiteLabelPageHeader location={this.props.location} />
                    )}
                    <Content>{this.props.children}</Content>
                    {footer && <WhiteLabelPageFooter />}
                  </Container>
                )}
              </LayoutConsumer>
            )
          }
        </ConfigConsumer>
        <WhiteLabelModals />
      </React.Fragment>
    )
  }
}

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Root
