import PT from 'prop-types';

export const formPropTypes = {
  fields: PT.objectOf(
    PT.shape({
      value: PT.string.isRequired,
      error: PT.string,
    })
  ).isRequired,
  isAuthenticating: PT.bool.isRequired,
  errors: PT.arrayOf(PT.string).isRequired,
  handleSubmit: PT.func.isRequired,
  handleChange: PT.func.isRequired,
  handleBlur: PT.func.isRequired,
  className: PT.string,
  actionsReversed: PT.bool,
  show: PT.func,
};
