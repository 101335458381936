import get from 'lodash/get'

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import values from 'lodash/values'

import { createSelector } from 'reselect'

// prettier-ignore
export const selectOrderPartyByOrderId = createSelector(
  state => get(state, 'orderParty.parties', {}),
  (state, { match }) => match.params.id,
  (parties, orderPartyId) => {
    return parties[orderPartyId]
  },
)

export const selectPlaceOrderParty = (state, venueId) => {
  const latestPlaceOrderParty = get(state, 'orderParty.latestPlaceOrderParty')
  const placedOrderParty = get(
    state,
    `orderParty.placeOrderParty.${latestPlaceOrderParty}`,
  )

  if (get(placedOrderParty, 'vendorId') == venueId) {
    return placedOrderParty
  }
  return null
}

export const selectPartyByVenueId = (state, venueId) =>
  get(state, `orderParty.parties.${venueId}`, null)

export const smartSelectOrderPartyId = (state, venueId) =>
  selectPlaceOrderParty(state, venueId) || selectPartyByVenueId(state, venueId)

export const selectOrderParty = (state, venueId) =>
  get(state, `orderParty.parties[${venueId}]`)

export const smartSelectOrderPartyByCode = (state, code) =>
  selectPlaceOrderPartyByCode(state, code) ||
  selectOrderPartyByCode(state, code)

export const selectPlaceOrderPartyByCode = (state, code) =>
  find(
    values(get(state, 'orderParty.placeOrderParty', {})),
    party => get(party, 'partyCode') === code,
  )
export const selectOrderPartyByCode = (state, code) =>
  find(
    values(get(state, `orderParty.parties`, {})),
    party => get(party, 'partyCode') === code,
  )

export const selectIsLoading = createSelector(
  state => get(state, 'orderParty.isLoading'),
  isLoading => isLoading,
)

export const selectIsLoaded = createSelector(
  state => get(state, 'orderParty.isLoaded'),
  isLoaded => isLoaded,
)

const status = {
  ACCEPTED_STATUS: 'ACCEPTED',
  ORDER_PLACED: 'ORDER_PLACED',
  DECLINED: 'DECLINED',
}

export const selectIsMember = createSelector(
  (state, { venueId }) => get(state, `orderParty.parties[${venueId}]`, {}),
  orderParty => {
    if (!orderParty) return false
    const { memberStatus } = orderParty
    return (
      memberStatus === status.ACCEPTED_STATUS ||
      memberStatus === status.ORDER_PLACED ||
      memberStatus === status.DECLINED
    )
  },
)

export const selectIsLeader = createSelector(
  state => get(state, 'profile.customer.id', null),
  (state, { venueId }) => get(state, `orderParty.parties[${venueId}]`, {}),
  (customerId, orderParty) => {
    if (isEmpty(orderParty) || !customerId) return false
    return orderParty.leaderCustomerId === customerId
  },
)
