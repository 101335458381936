import {
  login,
  selectError,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import get from 'lodash/get'
import Cookies from 'js-cookie'

import notif from 'services/notification'
import { show, types } from 'store/modals'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import {
  withFormValidator,
  isRequired,
  isEmail,
  composeValidators as composeV,
} from 'utils/validator'
import getHistory from 'services/history'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isAuthenticating: selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {} }) => ({
      onSubmit: ({ email, password }) =>
        dispatch(login(email, password))
          .then(onSuccess)
          .then(dispatch(unsetGuestCheckoutEmail()))
          .then(() => notif.success({ title: 'Login successful' }))
          .then(() => {
            const redirectPath = Cookies.get(COOKIE_REDIRECT_PATH)
            if (redirectPath) {
              Cookies.remove(COOKIE_REDIRECT_PATH)
              getHistory().push(redirectPath)
            }
          })
          .catch(err => {
            notif.error({
              title: get(err, 'response.data.status.message', 'Login failed!'),
              hasCloseButton: true,
            })
          }),
      clearAsyncError: () => dispatch(clearError()),
      showForgotPassModal: () => dispatch(show(types.FORGOT)),
    }),
  ),

  withProps({
    onSubmitFail: () =>
      notif.error({
        title: 'Login failed',
        message: 'please check the form errors',
      }),
  }),

  withFormValidator({
    email: composeV(isRequired, isEmail)('Email'),
    password: isRequired('Password'),
  }),
)
