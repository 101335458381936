import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import withAnalytics from 'enhancers/withAnalytics'
import events from 'const/analytics-events'
import Icon from 'components.v2/Icon/IconV2'
import getVenueUrl from 'utils/venue-url'
import withContainer from './WhiteLabelLogoContainer'

const TrackedLink = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGO'],
  },
])(Link)

const WhiteLabelLogo = ({ venueId, venueName }) => {
  return (
    <TrackedLink to={getVenueUrl(venueId, venueName)}>
      <Icon src="Shop.svg" />
    </TrackedLink>
  )
}

WhiteLabelLogo.propTypes = {
  venueId: PropTypes.number,
  venueName: PropTypes.string,
}

export default withContainer(WhiteLabelLogo)
