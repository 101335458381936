import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import values from 'lodash/values'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import Decimal from 'decimal.js'
import Cookies from 'js-cookie'

import { show, types } from 'store/modals'
import { selectPrecinct } from 'store/precinct/selectors'
import { selectGuestCheckoutHasEnabled } from 'store/guest-checkout/selectors'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import { formatPrice } from 'utils/currency'
import { selectMultiVendorOrders } from 'pages/order/modules/multi.vendor.order'
import { selectIsLoading } from 'pages/search/venues'
import { COOKIE_REDIRECT_PATH } from 'const/cookie'
import getHistory from 'services/history'

export default compose(
  withRouter,
  connect(
    state => {
      const totalSingleOrders = values(get(state, 'order.orders'))
        .reduce((prev, cur) => [...prev, ...get(cur, 'orders', [])], [])
        .reduce((acc, x) => acc.plus(get(x, 'quantity')), new Decimal(0))
        .toNumber()

      const multiVendorOrder = selectMultiVendorOrders(state)

      const offlineVendors = filter(
        get(state, 'venues.sites', []),
        venue => venue.status !== 'OPEN',
      ).reduce((prev, curr) => [...prev, get(curr, 'id')], [])

      const totalMultiVendorOrders = multiVendorOrder
        .filter(
          vendor => !includes(offlineVendors, get(vendor, 'vendorInfo.id')),
        )
        .reduce((prev, cur) => [...prev, ...get(cur, 'orders', [])], [])
        .reduce((acc, x) => acc.plus(get(x, 'quantity')), new Decimal(0))
        .toNumber()

      const totalAmount = multiVendorOrder
        .filter(
          vendor => !includes(offlineVendors, get(vendor, 'vendorInfo.id')),
        )
        .reduce((prev, cur) => [...prev, ...get(cur, 'orders', [])], [])
        .reduce(
          (acc, x) =>
            acc.plus(
              new Decimal(get(x, 'unitPrice')).times(get(x, 'quantity')),
            ),
          new Decimal(0),
        )
        .toNumber()

      const isLoading = selectIsLoading(state)

      return {
        totalSingleOrders,
        totalMultiVendorOrders,
        precinct: selectPrecinct(state),
        totalPrice: formatPrice(totalAmount),
        isGuestCheckout: selectGuestCheckoutHasEnabled(state),
        isLoading,
        hasRehydrated: get(state, 'rehydration.hasRehydrated'),
      }
    },

    dispatch => ({
      showLoginModal: () =>
        dispatch(show(types.AUTH, { hasGuestCheckoutEnabled: true })),
      disableGuest: () => dispatch(unsetGuestCheckoutEmail()),
    }),
  ),

  lifecycle({
    componentDidMount() {
      const {
        showLoginModal,
        isAuthenticated,
        hasRehydrated,
        isGuestCheckout,
      } = this.props
      if (
        !isAuthenticated &&
        !isGuestCheckout &&
        hasRehydrated &&
        Cookies.get(COOKIE_REDIRECT_PATH)
      ) {
        showLoginModal()
      }
    },

    componentDidUpdate() {
      const {
        showLoginModal,
        isAuthenticated,
        isGuestCheckout,
        hasRehydrated,
      } = this.props
      if (
        !isAuthenticated &&
        !isGuestCheckout &&
        hasRehydrated &&
        Cookies.get(COOKIE_REDIRECT_PATH)
      ) {
        showLoginModal()
      } else if (
        isAuthenticated &&
        hasRehydrated &&
        Cookies.get(COOKIE_REDIRECT_PATH)
      ) {
        getHistory().push(Cookies.get(COOKIE_REDIRECT_PATH))
        Cookies.remove(COOKIE_REDIRECT_PATH)
      }
    },
  }),
)
