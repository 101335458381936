import { connect } from 'react-redux'
import { compose, withProps, mapProps, withState } from 'recompose'
import SocialLogin from 'react-social-login'
import omit from 'lodash/fp/omit'
import shortid from 'shortid'

import { login } from 'auth/modules/auth'
import CONFIG from 'app-config'
import notif from 'services/notification'
import noScroll from 'no-scroll'

export default compose(
  connect(null, { login }),

  withState('key', 'changeKey', shortid.generate()),

  withProps(({ login, provider, onSuccess = () => {}, changeKey }) => ({
    provider,
    variant: provider,
    version: 'v14.0',
    appId: {
      facebook: CONFIG.FACEBOOK_APP_ID,
      google: CONFIG.GOOGLE_CLIENT_ID,
    }[provider],
    onLoginSuccess: ({ token: t }) => {
      login(
        '',
        {
          facebook: `facebook:${t.accessToken}`,
          google: `googlejwt:${t.idToken}`,
        }[provider],
      )
        .then(() => {
          notif.success({ title: 'Login successful' })
          onSuccess()
        })
        .then(noScroll.off())
        .catch(error =>
          notif.error({
            title: 'Login failed',
            message: 'please try again',
            error,
          }),
        )
    },
    onLoginFailure: error => {
      notif.error({
        title: 'Authentication failed',
        message: 'please try again',
        error,
      })

      changeKey(shortid.generate())
    },
  })),

  SocialLogin,

  withProps(props => ({
    onClick: props.triggerLogin,
    loading: props.isLoginQueued || props.isLoginOngoing,
  })),

  mapProps(omit(['onSuccess'])),
)
