export default (colCount, items) => {
  const countPerCol = calcQtyPerCol(colCount, items.length)
  const itemsClone = [...items]
  const columns = []

  let i = 0

  while (i < colCount) {
    columns[i] = itemsClone.splice(0, countPerCol[i])
    i++
  }

  return columns
}

// Internal Functions
const calcQtyPerCol = (colCount, x) => {
  const quotient = Math.floor(x / colCount)
  const remainder = x % colCount

  if (!remainder) { return [quotient, quotient, quotient, quotient] }

  if (remainder === colCount - 1) { return [quotient + 1, quotient + 1, quotient + 1, quotient] }

  const neededToComplete = colCount - remainder - 1

  if (neededToComplete < quotient) {
    return [quotient + 1, quotient + 1, quotient + 1, quotient - neededToComplete]
  }

  let toAdd = remainder

  return [quotient, quotient, quotient, quotient]
    .map(x => {
      if (toAdd) {
        toAdd--
        return x + 1
      }

      return x
    })
}
