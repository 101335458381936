import client from 'client'
import get from 'lodash/get'

export const getFavouriteOrders = (userId = 'me') => {
  return client()
    .get(`/customers/${userId}/orders/favourites`)
    .then(resp => get(resp, 'data.orders', []))
}

export const postFavouriteOrder = (orderId, userId = 'me') => {
  return client()
    .post(`/customers/${userId}/orders/favourites`, { orderId })
    .then(resp => get(resp, 'data', {}))
}

export const getFavouriteVenues = (userId = 'me') => {
  return client()
    .get(`/customers/${userId}/venues/favourites`)
    .then(resp => get(resp, 'data.venues', []))
}

export const postFavouriteVenue = (venueId, userId = 'me') => {
  return client()
    .post(`/customers/${userId}/venues/favourites`, { venueId })
    .then(resp => get(resp, 'data', {}))
}

export const deleteFavouriteVenue = (venueId, userId = 'me') => {
  return client()
    .delete(`/customers/${userId}/venues/favourites`, { data: { venueId } })
    .then(resp => get(resp, 'data', {}))
}
