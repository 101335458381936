import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import asset from 'utils/asset'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  HeaderContainer,
  HeaderContent,
  RightSection,
  Logo,
  RegisterVenue,
  LoginButton,
  OrderPartyHeader,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import events from 'const/analytics-events'
import LINKS from './../../const/links'
import Profile, { DummyProfile } from 'auth/Profile'
import withAnalytics from 'enhancers/withAnalytics'
import WithAuthentication from 'global/WithAuthentication'
import withContainer from './PageHeaderContainer'
import meta from 'const/meta'
import { isOrderUrl } from 'utils/url'

/*
 * About <DummyProfile /> & 'dummy-authed' className
 * DummyProfile is part of a hack for performance optimization
 * When server rendered content adds the className 'dummy-authed',
 * we'll be able to show (fake) profile dropdown without having to
 * fetch (and more importantly, cache) individual user data
 */

const TrackedLink = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGO'],
  },
])(Link)

const BusinessBtn = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_BUSINESS'],
  },
])(RegisterVenue)

const LoginBtn = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGIN'],
  },
])(LoginButton)

class Header extends React.Component {
  state = { isProfileOpen: false }

  openProfile = isProfileOpen => {
    this.setState({
      isProfileOpen,
    })
  }

  goToBusiness = () => {
    const win = window.open(LINKS.LIST_BUSINESS, '_blank')
    win.opener = null
  }

  isOrderPage = () => {
    const { history } = this.props
    return isOrderUrl(get(history, 'location.pathname', ''))
  }

  componentDidMount = () => {
    const body = document.getElementsByTagName('BODY')[0]
    body.classList.remove('dummy-authed')
  }

  render() {
    const { isProfileOpen } = this.state
    const {
      customer,
      isAuthenticated,
      showLoginModal,
      authActions: { logout },
      orderParty,
    } = this.props

    const isHost = get(orderParty, 'leaderCustomerId') === get(customer, 'id')

    return (
      <HeaderContainer isFixed={isProfileOpen} isOrderPage={this.isOrderPage}>
        {!isEmpty(orderParty) && !isHost && isAuthenticated && (
          <OrderPartyHeader>
            <DesktopContainer>
              {get(orderParty, 'leaderCustomerName', '').slice(0, -3)}’s group
              order - Everyone pays for themselves
            </DesktopContainer>
          </OrderPartyHeader>
        )}
        <DesktopContainer background="white">
          <HeaderContent>
            <TrackedLink to="/">
              <Logo src={asset('icons/heyyou-logo.svg')} alt={meta.title} />
            </TrackedLink>

            <RightSection>
              <BusinessBtn onClick={this.goToBusiness}>
                Register my venue
              </BusinessBtn>
              {isAuthenticated ? (
                <Profile
                  logout={logout}
                  openProfile={this.openProfile}
                  {...customer}
                />
              ) : (
                <Fragment>
                  <DummyProfile />
                  <LoginBtn variant="primary" onClick={showLoginModal}>
                    Login
                  </LoginBtn>
                </Fragment>
              )}
            </RightSection>
          </HeaderContent>
        </DesktopContainer>
      </HeaderContainer>
    )
  }
}

Header.propTypes = {
  customer: PropTypes.object,
  authActions: PropTypes.object,
  isAuthenticated: PropTypes.bool,
}

export default WithAuthentication(withContainer(Header))
