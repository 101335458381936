import React from 'react'
import PropTypes from 'prop-types'

import { TextContainer } from './styled'

import { text, smallText } from './const'

const Text = ({
  size: fontSize,
  color,
  children,
  light,
  align,
  small,
  bold,
  thin,
  transform,
  ...props
}) => {
  const { size, lineHeight, element } = small ? smallText : text
  const fontWeight = bold ? 500 : thin ? 300 : 300
  const Container = TextContainer(element)

  const textColor = color || (light ? 'textLight' : 'textDark')

  return (
    <Container
      size={fontSize || size}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={textColor}
      align={align}
      transform={transform}
      {...props}
    >
      {children}
    </Container>
  )
}

Text.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node,
  color: PropTypes.string,
  align: PropTypes.string,
  light: PropTypes.bool,
  small: PropTypes.bool,
  thin: PropTypes.bool,
}

export default Text
