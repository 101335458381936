import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import { hide, show } from 'store/modals'
import Modal from 'components.v2/Modal'
import { Heading, Text, ModalButton, Container } from './styled'

class GenericModal extends PureComponent {
  handleHide = () => {
    const { hide } = this.props
    hide()
  }

  handleRemove = () => {
    const {
      data: { action },
    } = this.props
    if (action) {
      action()
    }
    this.handleHide()
  }

  render() {
    const {
      data: { message },
    } = this.props
    return (
      <Modal
        isOpen
        onRequestClose={this.handleHide}
        className="text-center"
        small
        noCloseButton
      >
        <Fragment>
          <Container>
            <Heading>An item isn't available</Heading>
            <Text as="p">{message}</Text>
            <ModalButton
              className="w-full font-bold"
              large
              largeText
              type="button"
              variant="primary"
              onClick={this.handleRemove}
            >
              Remove items
            </ModalButton>
          </Container>
        </Fragment>
      </Modal>
    )
  }
}

GenericModal.propTypes = {
  show: PT.func.isRequired,
  hide: PT.func.isRequired,
  data: PT.shape({
    heading: PT.string,
    subHeading: PT.string,
    subHeadingList: PT.array,
    buttonLabel: PT.string,
    button: PT.ButtonComponent,
  }).isRequired,
}

const withContainer = connect(
  () => ({}),
  { show, hide },
)

export default withContainer(GenericModal)
