import styled from 'styled-components'

export const Background = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors[props.color] || props.color};
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1180px;
`
