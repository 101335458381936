import SUBURBS from './suburbs.json'
import pick from 'lodash/pick'
import mapValues from 'lodash/mapValues'

const CITIES = ['Sydney', 'Melbourne', 'Brisbane', 'Perth']

export default {
  getCities: () => CITIES,

  getSuburbsByCity: city =>
    SUBURBS
      .filter(s => s.city.toLowerCase() === city.toLowerCase())
      .map(s => s.suburb)
      .sort(),

  getLatLngByCitySuburb: (city, suburb) =>
    pick(
      SUBURBS.find(s => (
        s.city.toLowerCase() === city.toLowerCase() &&
        s.suburb.toLowerCase() === suburb.toLowerCase()
      )),
      ['lat', 'lng']),

  getTopSuburbsPerCity: () => {
    const suburbsPerCity = SUBURBS.reduce((acc, { city, ...suburb }) => {
      if (!acc[city]) {
        acc[city] = []
      }

      acc[city].push(suburb)

      return acc
    }, {})

    const topSuburbsPerCity = mapValues(suburbsPerCity, SUBURBS =>
      SUBURBS
        .sort((l, r) => Number(l.venues_count) > Number(r.venues_count))
        .slice(-5)
        .reverse())

    return topSuburbsPerCity
  }
}
