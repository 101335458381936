import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

const TextSection = ({ heading, children, ...props }) => (
  <Section {...props}>
    <Heading>
      <span>{heading}</span>
    </Heading>

    <Description>{children}</Description>
  </Section>
)

TextSection.propTypes = {
  heading: PT.string.isRequired,
}

export default TextSection

const Section = styled.section`
  flex-direction: column;
`

const Heading = styled.h4`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 15px 15px;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.textAltPrimary};
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-transform: uppercase;

  ${media.screen`
    padding: 0 0 10px 0;
    border-bottom: none;    
    letter-spacing: 1px;
  `}
`

const Description = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 15px 30px;
  background-color: ${p => p.theme.colors.primary};
  color: ${p => p.theme.colors.textAltPrimary};
  font-size: 13px;
  font-weight: 300;
  width: 100%;
  line-height: 30px;

  ${media.screen`
    padding: 0 0 20px 0;
    border-bottom: none;
    letter-spacing: 1px;
    letter-spacing: 0.5px;
  `}
`
