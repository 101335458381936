import React from 'react'
import { withStateHandlers, compose } from 'recompose'
import styled, { css } from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'
import { bool, string, element, func, object } from 'prop-types'
import InputLeftContainer from './InputLeftContainer'
import InputRightContainer from './InputRightContainer'
import InputButton from './InputButton'

/* Constants */
const heightsMap = { small: 40, regular: 50, large: 60 }
const fontSizesMap = { small: 16, regular: 16, large: 20 }

/** @component */
const Input = ({
  leftContent,
  rightContent,
  size = 'regular',
  borderColor = '#cccccc',
  borderColorActive = 'primary',
  handleFocus,
  handleBlur,
  className,
  hasError,
  hasFocus,
  ref,
  ...props
}) => (
  <ContainerEl size={size} className={className}>
    {leftContent && <SideContainer>{leftContent}</SideContainer>}

    <StyledInput
      borderColor={borderColor}
      borderColorActive={borderColorActive}
      hasFocus={hasFocus}
      hasError={hasError}
      ref={ref}
      size={size}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      hasLeftContent={!!leftContent}
      hasRightContent={!!rightContent}
    />

    {rightContent && <SideContainer>{rightContent}</SideContainer>}
  </ContainerEl>
)

Input.propTypes = {
  leftContent: element,
  rightContent: element,
  size: string,
  borderColor: string,
  borderColorActive: string,
  hasError: bool,
  /** This is passed to the input inside the container */ className: string,
  /** @ignore */ hasFocus: bool.isRequired,
  /** @ignore */ handleFocus: func.isRequired,
  /** @ignore */ handleBlur: func.isRequired,
  /** @ignore */ ref: object.isRequired,
}

/** Container */
const withContainer = compose(
  withStateHandlers(
    { hasFocus: false },
    {
      handleFocus: ({ onFocus }) => ev => {
        if (onFocus) onFocus(ev)
      },
      handleBlur: ({ onBlur }) => ev => {
        if (onBlur) onBlur(ev)
      },
    },
  ),
  React.forwardRef,
)

/** Styled Components */
const StyledInput = styled.input`
  flex-grow: 1;
  min-width: 0;
  padding: ${p =>
    `0 ${p.hasRightContent ? 0 : '15px'} 0 ${p.hasLeftContent ? 0 : '15px'}`};
  height: 100%;
  font-size: 16px;
  line-height: 125%;
  color: ${p => p.theme.colors.textPrimary};
  outline: none;

  border: ${p => {
    let bColor

    if (p.hasError) bColor = 'primary'
    else if (p.hasFocus) bColor = p.borderColorActive
    else bColor = p.borderColor

    if (bColor === 'none') bColor = 'white'

    return `1px solid ${p.theme.colors[bColor] || bColor}`
  }};

  ${p =>
    mapViewportsToStyles(p.size, fontSizesMap, fs => `font-size: ${fs}px;`)}

  ::placeholder {
    color: ${p => p.theme.colors.textPrimary};
  }
`

const ContainerEl = styled.div`
  display: flex !important;

  ${p =>
    mapViewportsToStyles(
      p.size,
      heightsMap,
      h => css`
        height: ${h}px;
        background-color: white;

        ${InputButton} {
          height: ${h}px;
          width: ${h}px;
        }
      `,
    )}
`

const SideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1px;
  bottom: -1px;
  height: calc(100% + 2px);

  &:first-child {
    left: -1px;
  }

  &:last-child {
    right: -1px;
  }
`

/** Internal Functions */
const mapViewportsToStyles = (viewportToKey, keyToValue, writeCssLines) => {
  if (typeof viewportToKey === 'string')
    return writeCssLines(keyToValue[viewportToKey])

  const defaultStyle = viewportToKey.default
    ? writeCssLines(keyToValue[viewportToKey.default])
    : ''

  // if size is object, write css for each viewport (object's key)
  const sizePerViewport = Object.entries(media).reduce(
    (acc, [viewport, mediaQuery]) =>
      viewportToKey[viewport]
        ? css`
            ${acc}
            ${mediaQuery`
            ${writeCssLines(keyToValue[viewportToKey[viewport]])}
          `}
          `
        : acc,
    defaultStyle,
  )

  return sizePerViewport
}

export { InputButton, InputLeftContainer, InputRightContainer }
export default withContainer(Input)
