import { connect } from 'react-redux'
import { compose, withProps, withState } from 'recompose'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { getErrorMessage } from 'api'
import notif from 'services/notification'

import {
  register,
  selectError,
  selectIsRegistering,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'

import {
  withFormValidator,
  isRequired,
  isEmail,
  isName,
  composeValidators as composeV,
} from 'utils/validator'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isAuthenticating:
        selectIsRegistering(state) || selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {} }) => ({
      onSubmit: ({ referralCode: referredBy, ...fields }) =>
        dispatch(register({ referredBy, ...fields }))
          .then(onSuccess)
          .then(() => notif.success({ title: 'Registration successful' }))
          .catch(error => {
            notif.error({
              title: 'Registration failed',
              message: getErrorMessage(error),
              error,
            })
            throw error
          }),
      clearAsyncError: () => dispatch(clearError()),
    }),
  ),

  withRouter,

  withProps(({ location }) => ({
    initValues: {
      referralCode:
        qs.parse(location.search, { ignoreQueryPrefix: true }).referralcode ||
        '',
    },
    onSubmitFail: () =>
      notif.error({
        title: 'Registration failed',
        message: 'please check the form errors',
      }),
  })),

  withState(
    'isReferralCodeVisible',
    'setIsReferralCodeVisible',
    ({ location }) =>
      !!qs.parse(location.search, { ignoreQueryPrefix: true }).referralcode,
  ),

  withFormValidator({
    firstName: composeV(isRequired, isName)('First name'),
    lastName: composeV(isRequired, isName)('Last name'),
    email: composeV(isRequired, isEmail)('Email'),
    mobile: composeV(isRequired)('Mobile'),
    password: isRequired('Password'),
    referralCode: null,
  }),
)
