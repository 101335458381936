import styled from 'styled-components'

export const FullScreenContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const LoaderContainer = styled.div`
  min-height: ${props => props.minHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`
