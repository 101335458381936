import styled from 'styled-components'

import Button from 'components.v2/Button'

// todo merge all styled components for modals

export const Container = styled.div`
  padding: 28px 20px;

  & > button {
    margin-top: 28px;
  }
`

export const Heading = styled.h2`
  color: var(--black, #000);
  text-align: center;

  font-family: 'Montserrat', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const Text = styled.span`
  color: #666;
  text-align: center;

  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  margin-top: 12px;
`

export const List = styled.ul`
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  text-align: center;
  display: flex;
  list-style: disc;
  justify-content: center;
  flex-direction: column;
  text-align: left;
`

export const ListItem = styled.li`
  margin: 0 auto;
`

export const Link = styled.button`
  color: ${p => p.theme.colors.secondary};
  opacity: ${p => (p.disabled ? 0.5 : 1)};
`

export const ModalButton = styled(Button)`
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 24px;
`
