import bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import config from 'app-config'

export const metaDataManager = (() => {
  let metaData

  return {
    set: newMetaData => {
      metaData = newMetaData
    },
    get: () => metaData,
    clear: () => {
      metaData = null
    },
  }
})()

const bugsnagClient = config.ENABLE_BUGSNAG
  ? bugsnag.start({
      apiKey: config.BUGSNAG_KEY,
      releaseStage: process.env.ENV,
      appType: 'react-unicorn-client',
      appVersion: '1.0.0',
      plugins: [new BugsnagPluginReact()],
      beforeSend: report => {
        const metaData = metaDataManager.get()
        if (!metaData) return
        report.updateMetaData('metaData', metaData)
        metaDataManager.clear()
      },
    })
  : null

if (bugsnagClient) {
  bugsnagClient.metaData = {
    metaData: null,
  }
}

export default bugsnagClient
