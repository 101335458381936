import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import PT from 'prop-types'
import { hide, show } from 'store/modals'
import Modal from 'components.v2/Modal'
import { Heading, Text } from './styled'
import Button from 'components.v2/Button'

class VenueModal extends PureComponent {
  handleHide = () => {
    const {
      hide,
      data: { storageName },
    } = this.props
    hide()
    localStorage.setItem(storageName, 1)
  }

  render() {
    const {
      data: { tblNum },
    } = this.props
    return (
      <Modal
        isOpen
        contentLabel="Serving To Table"
        onRequestClose={this.handleHide}
        className="text-center pb-15 px-15"
        small
        noCloseButton
      >
        <Fragment>
          <Heading className="mt-40">Serving to Table {tblNum}</Heading>
          <Text as="p" className="mt-10 mb-30 xl:mt-15">
            Add food and drinks to your cart and when you checkout, we'll serve
            it straight to your table.
          </Text>
          <div className="flex -mx-2">
            <div className="w-full px-5">
              <Button
                className="w-full font-bold"
                large
                largeText
                type="button"
                variant="primary"
                onClick={this.handleHide}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Fragment>
      </Modal>
    )
  }
}

VenueModal.propTypes = {
  show: PT.func.isRequired,
  hide: PT.func.isRequired,
  data: PT.object.isRequired,
}

const withContainer = connect(
  () => ({}),
  { show, hide },
)

export default withContainer(VenueModal)
