import get from 'lodash/get'
import find from 'lodash/find'
import kebabCase from 'lodash/kebabCase'
import { handleActions } from 'redux-actions'

import createActionFactory from 'utils/createActionFactory'
import { fetchVenueDescription, fetchVenueDetails } from 'api/venues'

import { addCityToVenue } from './venue.helpers'
import bugsnagClient from 'services/bugsnag-client'
import getVenueUrl from 'utils/venue-url'
import { TAG_MULTI_VENDOR_TYPE } from 'pages/vendor/const'

/**
 * Actions
 */

const ca = createActionFactory(`unicorn/venue/info/`)
const _fetchVenue = ca.promise('FETCH_VENUE')
export const loadVendor = id => (dispatch, getState) => {
  const isVenueLoaded = String(id) === String(selectInfo(getState()).id)

  if (isVenueLoaded) return Promise.resolve()

  dispatch(_fetchVenue(id))

  return Promise.all([
    fetchVenueDetails(id),
    fetchVenueDescription(id).catch(e => {
      // We can ignore if only venue description fails
      bugsnagClient.notify(e)
      return {}
    }),
  ])
    .then(([detailsResp, descResp]) => ({
      // merge description into details
      ...detailsResp.data.venue,
      description: descResp.description,
    }))
    .then(addCityToVenue)
    .then(venueInfo => {
      const { id, name } = venueInfo
      localStorage.setItem('restaurantMenuUrl', getVenueUrl(id, name))
      const multiVendTag = find(get(venueInfo, 'allTags', []), {
        type: TAG_MULTI_VENDOR_TYPE,
      })
      return dispatch(
        _fetchVenue.fulfilled({
          payload: {
            ...venueInfo,
            ...(multiVendTag &&
              get(multiVendTag, 'name') && {
                isMultiVendor: true,
                multiVendorGroupName: kebabCase(get(multiVendTag, 'name')),
              }),
          },
        }),
      )
    })
    .catch(error => dispatch(_fetchVenue.rejected({ payload: error })))
}

/**
 * Reducer
 */

const initialState = {
  isLoading: false,
  info: {},
}

export default handleActions(
  {
    [_fetchVenue]: state => ({
      ...state,
      isLoading: true,
      info: {},
    }),
    [_fetchVenue.fulfilled]: (state, { payload }) => ({
      ...state,
      info: payload,
      isLoading: false,
    }),
    [_fetchVenue.rejected]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
)

/**
 * Selectors
 */

export const selectIsLoadingVenue = state =>
  get(state, 'vendor.info.isLoading', false)
export const selectInfo = state => state.vendor.info.info
export const selectIsOnline = state =>
  statusToIsOnline(get(state, 'vendor.info.info.status'))

// Note: why supply venueId when there could only be one venue name at a time (in this reducer)?
// -- to ensure you'll only have the correct one (if venueId doesn't match, the correct venue data
// hasn't been requested/is still being requested)
export const selectVenueNameById = (state, venueId) =>
  get(selectInfoById(state, venueId), 'name', null)

export const selectDeliveryMessageById = (state, venueId) =>
  get(selectInfoById(state, venueId), 'property.deliveryMessage', null)

export const selectDeliverySuburbs = (state, venueId) => {
  const deliverySettings = get(
    selectInfoById(state, venueId),
    'property.deliverySettings',
    [],
  )

  return deliverySettings
    ? deliverySettings
        .map(val => val.suburbs)
        .reduce((acc, suburbs) => acc.concat(suburbs), [])
        .filter(suburb => !!suburb)
    : []
}

export const selectInfoById = (state, venueId) =>
  String(get(state, 'vendor.info.info.id', false)) === String(venueId)
    ? get(state, 'vendor.info.info')
    : null

export const selectServiceTypes = (state, venueId) => {
  return get(selectInfoById(state, venueId), 'serviceTypes', [])
}

/**
 * Internal Functions
 */

const statusToIsOnline = status => !['OFFLINE', 'CLOSED'].includes(status)
