import React from 'react'
import PT from 'prop-types'
import SliderBase from 'react-slick'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'
import Heading from 'components.v2/Heading'
import Icon from 'components/Icon.v2'
import Text from 'components.v2/Text'
import Spinner from 'components.v2/Spinner'
import DesktopContainer from 'layouts/DesktopContainer'

import withContainer from './TrendsSectionContainer'
import TrendItem from './TrendItem'

const SLIDE_QTY_DT = 11
const SLIDER_SETTINGS_DT = {
  swipeToSlide: true,
  dots: false,
  arrows: false,
  variableWidth: true,
  infinite: false,
}

const TrendsSection = ({
  trends,
  isFiltered,
  slideNo,
  setSlideNo,
  onNext,
  onPrev,
  sliderRef,
  isLoading,
  query,
}) => {
  const hasTrends = trends && trends.length
  if (!hasTrends && !isLoading) return null

  if (!hasTrends && isLoading)
    return (
      <DesktopContainer className="px-15 pt-20 xl:p-0">
        <Text as="p" variant="h4" light>
          Searching for “
          <Text as="strong" variant="h4">
            {query}
          </Text>
          ”
        </Text>
        <Spinner
          style={{ marginTop: 130 }}
          className="mx-auto"
          width={70}
          height={70}
          thickness={5}
        />
      </DesktopContainer>
    )

  return (
    <section className="px-15 py-20 xl:px-0 xl:pt-0">
      <Heading level={2}>
        {isFiltered ? 'Looking for this?' : 'Trending Searches'}
      </Heading>
      <div className="pt-20 xl:pt-30">
        <div className="relative">
          <Slider
            afterChange={setSlideNo}
            ref={sliderRef}
            {...SLIDER_SETTINGS_DT}
          >
            {trends.map((t, i) => (
              <TrendItemContainer key={i}>
                <TrendItem {...t} />
              </TrendItemContainer>
            ))}
          </Slider>

          {trends.length > SLIDE_QTY_DT && slideNo !== 0 && (
            <Button onClick={onPrev} left>
              <Icon type="arrowLeft" size={12} fill="primary" />
            </Button>
          )}

          {trends.length > SLIDE_QTY_DT &&
            !isLastSlide(slideNo, trends.length) && (
              <Button onClick={onNext} right>
                <Icon type="arrowRight" size={12} fill="primary" />
              </Button>
            )}
        </div>
      </div>

      {isLoading && (
        <DesktopContainer className="px-15 xl:px-0">
          <Spinner
            style={{ marginTop: 130 }}
            className="mx-auto"
            width={70}
            height={70}
            thickness={5}
          />
        </DesktopContainer>
      )}
    </section>
  )
}

TrendsSection.propTypes = {
  trends: PT.array.isRequired,
  isFiltered: PT.bool.isRequired,
  slideNo: PT.number.isRequired,
  setSlideNo: PT.func.isRequired,
  onNext: PT.func.isRequired,
  onPrev: PT.func.isRequired,
  sliderRef: PT.object.isRequired,
  isLoading: PT.bool.isRequired,
  query: PT.string.isRequired,
}

export default withContainer(TrendsSection)

const Slider = styled(SliderBase)`
  margin: 0 -5px;

  ${media.screen`
    margin: 0 -6.75px;
  `}
`

const TrendItemContainer = styled.div`
  padding: 0 5px;

  ${media.screen`
    padding: 0 6.75px;
  `}
`

const Button = styled.button.attrs({ type: 'button' })`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${p => p.theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);

  ${p =>
    p.left &&
    `
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  `}


  ${p =>
    p.right &&
    `
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  `}

  ${media.screen`
    display: flex;
  `}
`

const perSlide = SLIDE_QTY_DT
const isLastSlide = (curr, max) => curr + perSlide > max - 1
