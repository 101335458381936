import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import PT from 'prop-types'

import { selectIsResettingPassword, forgotPassword } from 'auth/modules/auth'
import { types, hide, show } from 'store/modals'
import Confirmation from './ConfirmationV2'
import EmailForm from './EmailFormV2'
import StyleableModal from 'components.v2/Modal/StylableModal'
import styled from 'styled-components'

const Modal = styled(StyleableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 470px;
    width: ${p => (p.small ? 'calc(100vw - 60px)' : 'calc(100vw - 30px)')};
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

class ForgotPassword extends PureComponent {
  handleHide = () => {
    const { type, show, hide, isSubmitting } = this.props
    if (isSubmitting) return
    if (type === types.FORGOT_V2) {
      hide()
    } else if (type === types.FORGOT_V2_SENT) {
      show(types.FORGOT_V2_SENT)
    } else {
      hide()
    }
  }

  handleSubmit = async ({ email }) => {
    try {
      const { show, forgotPassword, hideBackButton } = this.props

      await forgotPassword(email)

      show(types.FORGOT_V2_SENT, { hideBackButton: hideBackButton })
    } catch (err) {
      console.error(err)
    }
  }

  handleLogin = () => this.props.show(types.LOGIN_V2)

  handlePrevious = () =>
    this.props.show(types.FORGOT_V2, {
      hideBackButton: this.props.hideBackButton,
    })

  get modalContent() {
    const { type, isSubmitting, hideBackButton } = this.props
    if (type === types.FORGOT_V2) {
      return (
        <EmailForm
          onSubmit={this.handleSubmit}
          onLogin={this.handleLogin}
          isSubmitting={isSubmitting}
          hideBackButton={hideBackButton}
        />
      )
    } else if (type === types.FORGOT_V2_SENT) {
      return (
        <Confirmation
          onPrevious={this.handlePrevious}
          onLogin={this.handleLogin}
        />
      )
    }

    return null
  }

  render() {
    return (
      <Modal
        isOpen
        contentLabel="Forgot Password"
        onRequestClose={this.handleHide}
        noCloseButton
      >
        {this.modalContent}
      </Modal>
    )
  }
}

ForgotPassword.propTypes = {
  type: PT.oneOf([types.FORGOT_V2, types.FORGOT_V2_SENT]),
  show: PT.func.isRequired,
  hide: PT.func.isRequired,
  forgotPassword: PT.func.isRequired,
  isSubmitting: PT.bool.isRequired,
  hideBackButton: PT.bool,
}

const withContainer = connect(
  state => ({
    isSubmitting: selectIsResettingPassword(state),
  }),
  { show, hide, forgotPassword },
)

export default withContainer(ForgotPassword)
