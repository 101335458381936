import { handleActions } from 'redux-actions'
import isEmpty from 'lodash/isEmpty'

import createActionFactory from 'utils/createActionFactory'
import { fetchVenueMenu } from 'api/venues'

/**
 * Actions
 */

const ca = createActionFactory(`unicorn/venue/menu/`)
const _fetchMenu = ca.promise('FETCH_MENU')
export const loadMenu = (id, params) => (dispatch, getState) => {
  const isMenuLoaded = String(id) === String(selectMenuId(getState()))

  if (isMenuLoaded) return Promise.resolve()

  dispatch(_fetchMenu(id))

  return fetchVenueMenu(id, params)
    .then(menu => dispatch(_fetchMenu.fulfilled({ payload: { menu, id } })))
    .catch(error => dispatch(_fetchMenu.rejected({ payload: error })))
}

/**
 * Reducer
 */

const initialState = {
  isLoading: false,
  id: null,
  menus: [],
}

export default handleActions(
  {
    [_fetchMenu]: state => ({
      ...state,
      isLoading: true,
      id: null,
      menus: [],
    }),
    [_fetchMenu.fulfilled]: (state, { payload: { id, menu } }) => ({
      ...state,
      id,
      menus: menu,
      isLoading: false,
    }),
    [_fetchMenu.rejected]: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
)

/**
 * Helpers
 */

export const formatMenu = menu => {
  if (!menu || !menu.optionGroups || isEmpty(menu.optionGroups)) {
    return []
  }

  const { normal = [], advanced = [] } = menu.optionGroups
  const optionGroups = [...normal, ...advanced]

  return optionGroups
}

/**
 * Selectors
 */

export const selectMenuId = state => state.vendor.menu.id
