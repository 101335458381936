import { withProps } from 'recompose'

import notif from 'services/notification'

export default withProps({
  notify: (options = {}) => {
    const { type, title, message, ...rest } = options
    if (typeof notif[type] === 'function') {
      notif[type]({
        title: title || '',
        message,
        ...rest,
      })
    }
  },
})
