import { put, takeLatest } from 'redux-saga/effects'
import * as api from 'api/venues'
import * as city from './city'

export default [
  takeLatest(String(city.set), function* fetchCityVenuesTask({
    payload,
    meta: { resolve },
  }) {
    try {
      const resp = yield api.fetchVenuesWithDiscounts({
        after: '',
        range: '50km',
        ...payload.geo,
      })

      yield put(city.set.fulfilled({ payload: resp.data.venues }))
      resolve()
    } catch (error) {
      yield put(
        city.set.rejected({
          payload: error,
          meta: {
            type: 'notification',
            payload: {
              title: 'Error',
              message: 'Failed to load city venues',
              error,
            },
          },
        }),
      )
      resolve()
    }
  }),
]
