import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withProps,
  withState,
  withHandlers,
} from 'recompose'
import { requestTrends, selectTrends } from 'store/trends'
import { getQuery, changeCuisineInput } from 'common/CuisineSearchBar/cuisine'
import { searchVenues, selectIsLoading } from 'pages/search/venues'

const nrml = s => s.toLowerCase().trim()

export default compose(
  connect(
    state => {
      const query = nrml(getQuery(state))
      const alltrends = selectTrends(state).data || []
      const isLoading = query && selectIsLoading(state)

      return {
        trends: query
          ? alltrends.filter(t => {
              const curr = nrml(t.name)
              return curr.includes(query) && query !== curr
            })
          : alltrends,
        isFiltered: !!query,
        isLoading,
        query,
      }
    },
    { requestTrends, changeCuisineInput, searchVenues },
  ),

  withProps(p => ({
    trends: p.trends.map(t => ({
      ...t,
      onClick: () => {
        p.changeCuisineInput(t.name)
        p.searchVenues()
      },
    })),
  })),

  lifecycle({
    componentDidMount() {
      document.body.style.overflow = 'hidden'
      this.props.requestTrends()
    },

    componentWillUnmount() {
      document.body.style.overflow = 'visible'
    },
  }),

  // Slides related state
  withProps(({ items }) => ({ sliderRef: React.createRef() })),
  withState('slideNo', 'setSlideNo', 0),
  withHandlers({
    onNext: ({ sliderRef }) => () => sliderRef.current.slickNext(),
    onPrev: ({ sliderRef }) => () => sliderRef.current.slickPrev(),
  }),
)
