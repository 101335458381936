import React, { Component } from 'react'

import { Formik, Form } from 'formik'
import PT from 'prop-types'
import * as Yup from 'yup'

import { Text } from './styled'
import Button from 'components.v2/Button'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { emailRegex } from 'utils/validator'
import styled from 'styled-components'
import Icon from 'components.v2/Icon/IconV2'
import { Title, Container, BackButton } from 'common/auth/Shared/AuthModalV2'

const ForgotPasswordForm = styled(Form)`
  & > div.block.w-full {
    margin-top: 32px;
  }

  & > div > input {
    background: #f2f2f2;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    & ::placeholder {
      color: var(--charcoal-2, #999);
    }

    & :focus {
      border: 1.5px solid #f26722;
    }

    & :not(:focus) {
      border: 1px solid #cccccc;
    }
    border-radius: 8px;
  }

  & > p {
    margin-top: 12px;
  }

  & > button {
    margin-top: 32px;
  }
`

const Caption = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #999999;
`

const SubmitButton = styled(Button)`
  background: #f26722;
  border-radius: 8px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  height: 52px;

  &:hover {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: #0000001a;
    }
  }

  &:active {
    padding: 0px;
    & > span {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

const CustomFormError = styled(FormError)`
  font-family: Montserrat;
`

export default class extends Component {
  static propsTypes = {
    isSubmitting: PT.bool,
    onSubmit: PT.func,
    hideBackButton: PT.bool,
  }

  render() {
    const { isSubmitting, onSubmit, onLogin, hideBackButton } = this.props

    return (
      <Container>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .matches(emailRegex, 'Invalid email address') // TODO: Add to a constant value
              .required('Email is required!'), // TODO: Add to a constant value
          })}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, handleBlur }) => {
            return (
              <ForgotPasswordForm>
                <Title>
                  {!hideBackButton && (
                    <BackButton className="mr-auto" onClick={onLogin}>
                      <Icon src="BackButton.svg" />
                    </BackButton>
                  )}
                  <div className="w-full text-center">Forgot Password</div>
                </Title>

                <Input
                  className="block w-full"
                  disabled={isSubmitting}
                  hasError={!!errors.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Email"
                  type="email"
                  value={values.email}
                />
                <div className="text-left">
                  {errors.email && (
                    <CustomFormError>{errors.email}</CustomFormError>
                  )}
                </div>

                <Caption as="p" className="text-left">
                  We will send you a link to reset your password.
                </Caption>

                <SubmitButton
                  className="w-full px-0"
                  disabled={isSubmitting}
                  large
                  largeText
                  type="submit"
                  variant="primary"
                >
                  {isSubmitting ? 'Please Wait...' : 'Reset Password'}
                </SubmitButton>
              </ForgotPasswordForm>
            )
          }}
        </Formik>
      </Container>
    )
  }
}
