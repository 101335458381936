import { createAction, handleActions } from 'redux-actions'

export const changeCuisineInput = createAction('unicorn/cuisine/CHANGE_INPUT')

/**
 * Reducer
 */

const initialState = ''

const cuisineReducer = handleActions({
  [changeCuisineInput]: (state, { payload }) => payload
}, initialState)

/**
 * Selectors
 */

export const getCuisineParams = state => ({ query: state.cuisine })
export const getQuery = state => state.cuisine

export default cuisineReducer
