import { put, take, takeLatest, race, select, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import isEmpty from 'lodash/isEmpty'

import notif from 'services/notification'
import * as api from 'api/venues'
import { getCuisineParams } from 'common/CuisineSearchBar/cuisine.js'
import { getLocationParams } from 'common/LocationSearchBar/location'
import {
  searchVenues,
  loadMore,
  selectOverrideParams,
  loadSavedResults,
  selectSave,
  selectMergeParams,
} from './venues'
import { DEFAULT_ITEMS_PER_PAGE } from './venues.const'

const DEBOUNCE_MS = 400

const searchVenuesSagaFactory = ({ hasAds }) =>
  function* searchVenuesSaga(action) {
    const hadSave = yield select(selectSave)
    yield call(delay, DEBOUNCE_MS)
    const hasSave = yield select(selectSave)

    if (hadSave && !hasSave)
      // if save is cleared (meaning it has been loaded)
      return // cancel the debounced search

    const resolve = (action.meta && action.meta.resolve) || (() => {})

    try {
      let params
      const state = yield select()
      const overrideParams = selectOverrideParams(state)
      const mergeParams = selectMergeParams(state)

      if (isEmpty(overrideParams)) {
        const locationParams = yield getLocationParams(state)
        const cuisineParams = getCuisineParams(state)
        params = { ...mergeParams, ...locationParams, ...cuisineParams }
      } else {
        params = { ...mergeParams, ...overrideParams }
      }

      if (isEmpty(params)) {
        return
      }

      const { page } = state.venues
      const itemsPerPage = params.itemsPerPage || DEFAULT_ITEMS_PER_PAGE
      const requester = hasAds
        ? api.smartSearchVenuesWithDiscounts
        : api.smartSearchVenues

      const { resp, hasLoadedSavedResults } = yield race({
        resp: requester({
          mode: 'full',
          offset: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
          ...params,
        }),
        hasLoadedSavedResults: take(String(loadSavedResults)),
      })

      if (hasLoadedSavedResults) return

      yield put(searchVenues.fulfilled({ payload: resp.data }))
      resolve()
    } catch (error) {
      notif.error({
        title: 'Error',
        message: `Failed to fetch venues`,
        error,
      })
      yield put(searchVenues.rejected())
      resolve()
    }
  }

export default [
  takeLatest(String(searchVenues), searchVenuesSagaFactory({ hasAds: true })),
  takeLatest(String(loadMore), searchVenuesSagaFactory({ hasAds: false })),
]
