import styled from 'styled-components'
import { Modal as StyledModal } from 'pages/party/styled'
import { hide } from 'store/modals'
import { connect } from 'react-redux'
import { detachParty } from 'store/order-party/order-party.actions'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

const Layout = styled.div`
  display: flex;
  width: 360px;
  padding: 32px 20px 20px 20px;
  flex-direction: column;

  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 32px;

  & > div > div.icon {
    color: var(--Black, #000);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Cera;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 24px;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 13px;
`

const Button = styled.button`
  height: 52px;
  width: 100%;
  border-radius: 8px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &.primary {
    background: var(--Orange-0, #f26722);
    color: var(--White, #fff);
  }

  &.secondary {
    background: var(--Orange-3, #fdf0e9);
    color: var(--Orange-0, #f26722);
  }

  &:focus {
    outline: none;
  }
`

const Subcontent = styled.div`
  color: var(--Grey-1, #666);
  text-align: center;
  margin-top: 12px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const ContentTitle = styled.div`
  color: var(--Black, #000);
  text-align: center;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

const Modal = styled(StyledModal)`
  &__content {
    width: 360px;
  }
`

const Icon = styled.img`
  width: 44px;
  height: 44px;
  margin-bottom: 12px;
`

const MemberLeftBehindModal = ({
  hide,
  venueId,
  detachParty,
  history,
  isMember,
}) => {
  const handleClick = () => {
    detachParty(venueId)
    hide()
    history.push(`/restaurant/${venueId}/menu`)
  }

  return (
    <Modal isOpen noCloseButton onRequestClose={handleClick}>
      <Layout>
        <div className="flex items-center flex-col">
          <div className="icon">{isMember ? '🐌' : '⏰'}</div>
          <ContentTitle>
            {isMember
              ? 'The group order was placed without you'
              : 'The group order has ended'}
          </ContentTitle>
          <Subcontent>
            {isMember
              ? `We've saved all items in your cart so you can place your order as an individual.`
              : `It's no longer possible to join or add items. Please contact the
            host for further assistance`}
          </Subcontent>
        </div>
        <Buttons>
          <Button className="primary" onClick={handleClick}>
            {isMember ? 'Got it' : 'Return to menu'}
          </Button>
        </Buttons>
      </Layout>
    </Modal>
  )
}

const withContainer = compose(
  withRouter,
  connect(
    (state, { venueId }) => ({
      venueId,
    }),
    {
      hide,
      detachParty,
    },
  ),
)

export default withContainer(MemberLeftBehindModal)
