import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from 'components.v2/Button'

import { HeaderContainer, HeaderContent, RightSection } from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import events from 'const/analytics-events'
import { DummyProfile } from 'auth/Profile'
import withAnalytics from 'enhancers/withAnalytics'
import WithAuthentication from 'global/WithAuthentication'
import withContainer from './PageHeaderContainer'
import WhiteLabelProfile from 'auth/Profile/WhiteLabelProfile'
import WhiteLabelLogo from '../../components.v2/WhiteLabelLogo/WhiteLabelLogo'

/*
 * About <DummyProfile /> & 'dummy-authed' className
 * DummyProfile is part of a hack for performance optimization
 * When server rendered content adds the className 'dummy-authed',
 * we'll be able to show (fake) profile dropdown without having to
 * fetch (and more importantly, cache) individual user data
 */

const LoginBtn = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_LOGIN'],
  },
])(Button)

class Header extends React.Component {
  state = { isProfileOpen: false }

  openProfile = isProfileOpen => {
    this.setState({
      isProfileOpen,
    })
  }

  componentDidMount = () => {
    const body = document.getElementsByTagName('BODY')[0]
    body.classList.remove('dummy-authed')
  }

  render() {
    const { isProfileOpen } = this.state
    const {
      customer,
      isAuthenticated,
      showLoginModal,
      authActions: { logout },
    } = this.props
    return (
      <HeaderContainer isFixed={isProfileOpen}>
        <DesktopContainer background="white">
          <HeaderContent>
            <WhiteLabelLogo />
            <RightSection>
              {isAuthenticated ? (
                <WhiteLabelProfile
                  logout={logout}
                  openProfile={this.openProfile}
                  {...customer}
                />
              ) : (
                <Fragment>
                  <DummyProfile />
                  <LoginBtn variant="whitelabel" onClick={showLoginModal}>
                    Login
                  </LoginBtn>
                </Fragment>
              )}
            </RightSection>
          </HeaderContent>
        </DesktopContainer>
      </HeaderContainer>
    )
  }
}

Header.propTypes = {
  customer: PropTypes.object,
  authActions: PropTypes.object,
  isAuthenticated: PropTypes.bool,
}

export default WithAuthentication(withContainer(Header))
