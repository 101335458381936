import React from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as actions from '../auth/modules/auth'
import * as profileActions from '../auth/modules/profile'

const AuthContext = React.createContext()

// TODO: to be used later on
class AuthContainer extends React.Component {
  static propTypes = {
    authActions: PropTypes.object,
    customer: PropTypes.object,
    isAuthenticated: PropTypes.bool,
  }

  getProvidedValues() {
    const {
      authActions: { logout },
      customer,
      isAuthenticated,
    } = this.props
    return {
      customer,
      isAuthenticated,
      logout,
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.getProvidedValues()}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.profile.customer,
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(actions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
})

const AuthProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthContainer)

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
