import React from 'react'
import config from 'app-config'
import bugsnagClient from 'services/bugsnag-client'

let ErrorBoundary

if (bugsnagClient) {
  if (config.ENV !== 'production') window.bugsnagClient = bugsnagClient

  ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React)
} else {
  ErrorBoundary = React.Fragment
}

export default ErrorBoundary
