import React, { useEffect, useRef } from 'react'
import { compose, withProps } from 'recompose'
import { login } from 'auth/modules/auth'
import CONFIG from 'app-config'
import notif from 'services/notification'
import { connect } from 'react-redux'
import shortid from 'shortid'
import SocialButton from './SocialButtonV2'
import noScroll from 'no-scroll'

const loadScript = src =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = err => reject(err)
    document.body.appendChild(script)
  })

const GoogleAuth = ({ onLoginSuccess, appId, provider }) => {
  const googleButton = useRef(null)

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: appId,
          callback: onLoginSuccess,
        })
        google.accounts.id.renderButton(googleButton.current, {
          theme: 'outline',
          height: 50,
        })
        googleButton.current.style.display = 'none'
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  const handleButtonClick = e => {
    e.preventDefault()
    const googleSignInButton = googleButton.current.querySelector(
      'div[role=button]',
    )

    googleSignInButton.click()
  }

  return (
    <div>
      <div className="googleSignIn" ref={googleButton} />
      <SocialButton provider={provider} triggerLogin={handleButtonClick} />
    </div>
  )
}

export default compose(
  connect(null, { login }),
  withProps(
    ({ login, provider = 'google', onGoogleSignIn = () => {}, changeKey }) => ({
      provider,
      variant: provider,
      version: 'v14.0',
      appId: {
        facebook: CONFIG.FACEBOOK_APP_ID,
        google: CONFIG.GOOGLE_CLIENT_ID,
      }[provider],
      onLoginSuccess: ({ credential }) => {
        login(
          '',
          {
            google: `googlejwt:${credential}`,
          }[provider],
        )
          .then(() => {
            notif.success({ title: 'Login successful' })
            onGoogleSignIn()
          })
          .then(noScroll.off())
          .catch(error =>
            notif.error({
              title: 'Login failed',
              message: 'please try again',
              error,
            }),
          )
      },
      onLoginFailure: error => {
        notif.error({
          title: 'Authentication failed',
          message: 'please try again',
          error,
        })

        changeKey(shortid.generate())
      },
    }),
  ),
)(GoogleAuth)
