import React from 'react'
import styled, { css } from 'styled-components'
import Spinner from 'components.v2/Spinner'
import PT from 'prop-types'

/**
 * Note: onClick is not fired when disabled/loading is true
 */
const Button = ({
  children,
  loading,
  onClick,
  disabled,
  variant,
  ...props
}) => (
  <StyledButton
    onClick={disabled || loading ? () => {} : onClick}
    disabled={disabled}
    variant={variant}
    {...props}
  >
    {loading && (
      <LoaderContainer>
        <Spinner variant={spinnerPresets[variant]} />
      </LoaderContainer>
    )}
    <Content loading={loading}>{children}</Content>
  </StyledButton>
)

/**
 * Constants
 */

const primaryDisabled = '#F8B390'
const primaryOutline = '#FBD1BC'
const whiteLabelDisabled = '#cccccc'
const secondaryDisabled = '#96CCCC'
const secondaryOutline = '#D5EBEB'
const facebookOutline = 'rgba(59, 89, 152, 0.5)'
const googleOutline = 'rgba(220, 78, 65, 0.5)'

/**
 * Helpers
 */
const c = (
  key,
  props, // Curried color accessor
) => (props ? props.theme.colors[key] : props2 => props2.theme.colors[key])

const createOutlineCss = color => css`
  &:focus {
    outline: none;
  }

  &:focus::before {
    content: ' ';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 3px solid ${p => (typeof color === 'function' ? color(p) : color)};
    border-radius: 3px;
  }

  &:active::before {
    display: none !important;
  }
`

/**
 * Styled Components
 */
const StyledButton = styled.button`
  position: relative;
  padding: ${p => `calc((${p.large ? '50px' : '40px'} - 1.25em) / 2) 24px`};
  line-height: 125%;
  text-align: center;
  text-decoration: none;
  font-size: ${p => (p.largeText ? '15px' : '13px')};
  ${p => p.fullWidth && 'width: 100%;'}

  ${createOutlineCss(primaryOutline)}
  ${p => presets[p.variant]}
`

const LoaderContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: ${p => (p.loading ? 'hidden' : 'visible')};
`

const Content = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  visibility: ${p => (p.loading ? 'hidden' : 'visible')};
`

/***
 * Presets
 */
const presets = {
  primary: css`
    background-color: ${p => (p.disabled ? primaryDisabled : c('primary', p))};
    color: ${c('white')};
    border: 1px solid ${p => (p.disabled ? primaryDisabled : c('primary', p))};

    &:hover {
      background-color: ${p =>
        p.disabled ? primaryDisabled : c('primaryDarker', p)};
    }

    &:active {
      background-color: ${p =>
        p.disabled ? primaryDisabled : c('primaryDarkest', p)};
    }
  `,

  secondary: css`
    background-color: ${p =>
      p.disabled ? secondaryDisabled : c('secondary', p)};
    color: ${c('white')};
    border: 1px solid
      ${p => (p.disabled ? secondaryDisabled : c('secondary', p))};

    &:hover {
      background-color: ${p =>
        p.disabled ? secondaryDisabled : c('secondaryDarker', p)};
    }

    &:active {
      background-color: ${p =>
        p.disabled ? secondaryDisabled : c('secondaryDarkest', p)};
    }

    ${createOutlineCss(secondaryOutline)}
  `,

  whitelabel: css`
    background-color: ${p =>
      p.disabled ? whiteLabelDisabled : c('whitelabel', p)};
    color: ${c('white')};

    &:hover {
      background-color: ${p =>
        p.disabled ? whiteLabelDisabled : c('whitelabelLighter', p)};
    }
  `,

  'outline-primary': css`
    background-color: transparent;
    color: ${p => (p.disabled ? c('gray4', p) : c('primary', p))};
    border: 1px solid ${p => (p.disabled ? c('gray4', p) : c('primary', p))};

    &:hover {
      color: ${p => (p.disabled ? c('gray4', p) : c('primaryDarker', p))};
      border: 1px solid
        ${p => (p.disabled ? c('gray4', p) : c('primaryDarker', p))};
    }

    &:active {
      color: ${p => (p.disabled ? c('gray4', p) : c('primaryDarkest', p))};
      border: 1px solid
        ${p => (p.disabled ? c('gray4', p) : c('primaryDarkest', p))};
      background-color: ${p => (p.disabled ? 'transparent' : c('gray7', p))};
    }
  `,

  'outline-neutral': css`
    background-color: transparent;
    color: ${p => (p.disabled ? c('gray4', p) : c('black', p))};
    border: 1px solid ${p => (p.disabled ? c('gray4', p) : c('gray3', p))};

    ${createOutlineCss(c('gray4'))}
  `,

  'alt-primary': css`
    background-color: ${c('white')};
    color: ${c('primary')};
    border: ${c('white')};
  `,

  'outline-alt-neutral': css`
    background-color: transparent;
    color: ${c('white')};
    border: 1px solid ${c('white')};
  `,

  facebook: css`
    background-color: ${c('facebook')};
    color: ${c('white')};
    border: 1px solid ${c('facebook')};
    ${createOutlineCss(facebookOutline)}
  `,

  google: css`
    background-color: ${c('google')};
    color: ${c('white')};
    border: 1px solid ${c('google')};
    ${createOutlineCss(googleOutline)}
  `,
}

const spinnerPresets = {
  primary: 'alt-primary',
  secondary: 'alt-secondary',
  'outline-alt-neutral': 'primary',
  facebook: 'facebook',
  google: 'google',
}

Button.propTypes = {
  variant: PT.oneOf(Object.keys(presets)),
  loading: PT.bool,
  large: PT.bool,
  largeText: PT.bool,
  /** Some variants have 'disabled' style */
  disabled: PT.bool,
}

export default Button
