export default number => {
  const numberArr = number.split('')
  const spacePos = number.startsWith('04')
    ? [4, 7]
    : [3, 6, 9]

  spacePos.forEach((pos, i) => {
    numberArr.splice(pos + i, 0, ' ')
  })

  return numberArr.join('')
}
