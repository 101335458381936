import { put, takeLatest } from 'redux-saga/effects'
import * as api from 'api'
import bugsnagClient from 'services/bugsnag-client'
import { fetchProfile } from './profile'

import { setPaymentMethod, setBalance, setTopupPlanId } from 'store/payment'

import { setMobile, requestVerify } from 'store/smsv/smsv'
import get from 'lodash/get'

const VERIFIED_STATUSES = ['VERIFIED', 'PRE-VERIFIED', 'AUTO-VERIFIED']

export default [
  takeLatest(String(fetchProfile), function* fetchProfileTask({
    meta: { resolve, reject },
  }) {
    try {
      const {
        paymentSources,
        paymentMethod,
        balance,
        mobile,
        verifiedStatus,
        autoTopupPlanId,
        ...profile
      } = yield api
        .fetchProfile()
        .then(data => get(data, 'data.customer'))
        .catch(err => {
          throw err
        })

      // Save payment data
      yield put(setPaymentMethod(paymentMethod))
      yield put(setBalance(balance))
      yield put(setTopupPlanId(autoTopupPlanId))

      // Save SMSV data
      const isVerified = VERIFIED_STATUSES.includes(verifiedStatus)
      yield put(setMobile(mobile || null))
      yield put(requestVerify.fulfilled({ payload: isVerified }))

      /**
       * Save Profile data
       * Note: do it LAST, as `fetchProfile.fulfilled` watchers expect that other values
       * are already saved (what we do above) by the time it fires
       */
      yield put(fetchProfile.fulfilled({ payload: profile }))
      resolve()
    } catch (e) {
      yield put(fetchProfile.rejected(e))
      if (bugsnagClient) bugsnagClient.notify(e)
      reject(e)
    }
  }),
]
