import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PT from 'prop-types'
import styled from 'styled-components'

import { show, hide, types } from 'store/modals'
import WhiteLabelLoginForm from 'common/auth/WhiteLabelLoginForm'
import Modal from 'components.v2/Modal'
import WhiteLabelRegisterForm from 'common/auth/WhiteLabelRegisterForm'
import SocialButton from 'common/auth/Shared/SocialButton'
import titleCase from 'utils/titleCase'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'

const SUCCESS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  REGISTER_FORM: 'register-form',
}

const withContainer = connect(
  null,
  { show, hide },
)

class AuthModal extends React.Component {
  handleSuccess = registrationType => () => {
    const { type, hide, trackEvent } = this.props

    hide()

    if (type !== types.REGISTER) return

    if (registrationType === SUCCESS.FACEBOOK) {
      trackEvent(events['SIGNUP_CLICK_FACEBOOK'])
    }

    if (registrationType === SUCCESS.GOOGLE) {
      trackEvent(events['SIGNUP_CLICK_GOOGLE'])
    }

    if (registrationType === SUCCESS.REGISTER_FORM) {
      trackEvent(events['SIGNUP_CLICK_EMAIL'])
    }
  }

  render() {
    const { type, show, hide } = this.props
    return (
      <Modal
        isOpen
        contentLabel={titleCase(type)}
        onRequestClose={hide}
        noCloseButton
      >
        <div className="flex">
          <Tab
            isActive={type === types.REGISTER}
            onClick={() => type !== types.REGISTER && show(types.REGISTER)}
          >
            Create an Account
          </Tab>
          <Tab
            isActive={type === types.LOGIN}
            onClick={() => type !== types.LOGIN && show(types.LOGIN)}
          >
            Login
          </Tab>
        </div>
        <Divider />
        <div className="flex pt-30 px-15 xl:px-25">
          <div className="px-5 w-1/2">
            <SocialButton
              onSuccess={this.handleSuccess(SUCCESS.FACEBOOK)}
              provider="facebook"
              className="w-full"
            />
          </div>
          <div className="px-5 w-1/2">
            <SocialButton
              onSuccess={this.handleSuccess(SUCCESS.GOOGLE)}
              provider="google"
              className="w-full"
            />
          </div>
        </div>
        <div className="flex pt-10 pb-15 px-20 xl:pb-30 xl:px-30">
          {{
            [types.REGISTER]: () => (
              <WhiteLabelRegisterForm
                onSuccess={this.handleSuccess(SUCCESS.REGISTER_FORM)}
                className="w-full"
              />
            ),
            [types.LOGIN]: () => (
              <WhiteLabelLoginForm
                show={show}
                onSuccess={hide}
                className="w-full"
              />
            ),
          }[type]()}
        </div>
      </Modal>
    )
  }
}

AuthModal.propTypes = { type: PT.oneOf(['LOGIN', 'REGISTER']) }

const Tab = styled.button`
  position: relative;
  padding-top: 5px;
  width: 50%;
  height: 70px;
  font-size: 14px;
  color: ${p => (p.isActive ? p.theme.colors.primary : p.theme.colors.black)};
  outline: none !important;

  &::after {
    content: ' ';
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
    display: ${p => (p.isActive ? 'block' : 'none')};
    height: 2px;
    width: 60%;
    background-color: ${p => p.theme.colors.primary};
  }
`

const Divider = styled.div`
  height: 2px;
  margin: 0 15px;
  background-color: ${p => p.theme.colors.gray7};
`

export default compose(
  withContainer,
  withAnalyticsHandler,
)(AuthModal)
