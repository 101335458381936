import styled from 'styled-components'
import { bool } from 'prop-types'

const InputLeftContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => (p.large ? '55px' : '45px')};
`

InputLeftContainer.propTypes = {
  large: bool,
}

/** @component */
export default InputLeftContainer
