import config from './../../config'
import LINKS from './../../const/links'
import suburbs from 'services/suburbs'

export const partnersLinks = [
  { title: 'Partner with us', to: LINKS.LIST_BUSINESS },
  { title: 'Vendor login', to: config.VENDOR_DASHBOARD },
]

export const aboutLinks = [
  { title: 'About Hey You', to: LINKS.ABOUT },
  { title: 'Our Green Projects', to: LINKS.GREEN_PROJECTS },
  { title: 'Our Blog', to: LINKS.BLOG },
  { title: 'Work with us', to: LINKS.WORK_WITH_US },
  { title: 'Help Centre', to: LINKS.FAQ },
  { title: 'Merchant Login', to: LINKS.MERCHANT_LOGIN },
]

export const suburbLinks = [
  { title: 'Sydney Food Takeaway', to: '/sydney' },
  { title: 'Melbourne Food Takeaway', to: '/melbourne' },
  { title: 'Brisbane Food Takeaway', to: '/brisbane' },
  { title: 'Perth Food Takeaway', to: '/perth' },
]

const { Sydney, Melbourne, Brisbane, Perth } = suburbs.getTopSuburbsPerCity()
const rawSuburbLinkGroups = [
  { title: 'Sydney', items: Sydney },
  { title: 'Melbourne', items: Melbourne },
  { title: 'Brisbane', items: Brisbane },
  { title: 'Perth', items: Perth },
]

export const topSuburbsLinks = rawSuburbLinkGroups.map(({ title, items }) => ({
  title,
  items: items
    .map(({ suburb }) => ({
      title: `${suburb} food takeaway`,
      to: encodeURI(`/${title}/${suburb}`.toLowerCase()),
    }))
    .concat([
      {
        title: `Show all ${title} Food Takeaway`,
        to: encodeURI(`/${title}`.toLowerCase()),
        hasArrow: true,
      },
    ]),
}))

export const privacyPolicyLink = { title: 'Privacy Policy', to: LINKS.PRIVACY }

export const termsLink = { title: "T&C's", to: LINKS.TERMS }
