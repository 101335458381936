import React from 'react'
import PT from 'prop-types'
import pick from 'lodash/fp/pick'
import Modal from 'components.v2/Modal'
import Button from 'components.v2/Button'
import Input from 'components.v2/Input'
import FormError from 'components.v2/FormError'
import { propTypes as formValidatorPT } from 'utils/validator'
import withContainer from './SendCodeModalContainer'
import { Heading, Text } from './sharedStyled'

const SendCodeModal = ({
  sendCodeState,
  fields,
  errors,
  handleHide,
  handleChange,
  handleBlur,
  handleSubmit,
  handleBack,
}) => (
  <Modal
    isOpen
    contentLabel="Verify Modal Number"
    onRequestClose={handleHide}
    className="text-center pb-15 px-15"
    small
  >
    <form onSubmit={handleSubmit}>
      <Heading className="mt-40">Verify mobile number</Heading>
      <Text as="p" className="mt-10 xl:mt-15">
        Enter your mobile number below and we will send you an SMS or automated
        call with a verification code.
      </Text>
      <div className={`text-left ${errors.length ? 'mt-10' : 'mt-30'}`}>
        {!!errors.length &&
          errors.map((error, i) => <FormError key={i}>{error}</FormError>)}
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter your mobile"
          name="mobile"
          value={fields.mobile.value}
          hasError={!!fields.mobile.error}
          className="w-full"
        />
      </div>
      <div className="flex -mx-5 mt-30">
        {handleBack && (
          <div className="w-1/2 px-5">
            <Button
              variant="outline-primary"
              type="button"
              className="w-full"
              onClick={handleBack}
              large
              largeText
            >
              Back
            </Button>
          </div>
        )}
        <div className={`px-5 ${handleBack ? 'w-1/2' : 'w-full'}`}>
          <Button
            variant="primary"
            type="submit"
            className="w-full px-0"
            disabled={sendCodeState.isLoading}
            large
            largeText
          >
            {sendCodeState.isLoading ? 'Processing…' : 'Get code'}
          </Button>
        </div>
      </div>
    </form>
  </Modal>
)

SendCodeModal.propTypes = {
  ...pick(
    ['handleChange', 'handleBlur', 'handleSubmit', 'fields', 'errors'],
    formValidatorPT,
  ),
  sendCodeState: PT.shape({
    isLoading: PT.bool.isRequired,
  }),
  handleHide: PT.func.isRequired,
  handleBack: PT.func,
}

export default withContainer(SendCodeModal)
