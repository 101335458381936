import { put, take, takeLatest, fork, select } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import get from 'lodash/fp/get'
import getVal from 'lodash/get'
import includes from 'lodash/includes'
import isNode from 'detect-node'
import bugsnagClient from 'services/bugsnag-client'

import * as orderApi from 'api/order'
import {
  placeOrder,
  pullStatus,
  pullMultipleCartStatus,
  selectIsSubmitting,
  selectMultiVendorRecentGuestOrdersCartIds,
  getRecentOrdersByCustomerId,
  getRecentOrdersByCartIds,
  selectMultiVendorTotals,
} from './multi.vendor.order'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import {
  selectGuestCheckoutHasEnabled,
  selectLatesGuestEmail,
} from 'store/guest-checkout/selectors'
import { selectIsAuthenticated } from 'auth/modules/auth'

const PULL_INTERVAL = 10000

const PULL_INTERVAL_MVO = 15000

export default [
  fork(function* placeOneOrderAtATime() {
    while (true) {
      const action = yield take(placeOrder)

      try {
        const {
          payload: {
            venueId,
            serviceType,
            note,
            payment,
            seatingInfo,
            orderPartyId,
            customerId,
            address,
            mobile,
            customer,
            cart,
            orderType,
            excludedVendors,
          },
          meta: { resolve },
        } = action

        const state = yield select(x => x)

        const params = {
          venueId,
          serviceType,
          orderNote: note,
          payment,
          seatingInfo,
          orderPartyId,
          customerId,
          address,
          mobile,
          ...(customer && { customer: customer }),
          ...(cart && { cart: cart }),
          ...(orderType && { orderType: orderType }),
        }

        const orderData = yield orderApi.multiVendorSubmit(params)
        const cartId = getVal(orderData, 'cart_id')

        reportGA(state, cartId)

        const isAnonymous = yield select(selectGuestCheckoutHasEnabled)
        const latestGuestEmail = yield select(selectLatesGuestEmail)
        yield put(
          placeOrder.fulfilled({
            payload: {
              cartId,
              payment,
              customerId,
              isAnonymous,
              latestGuestEmail,
              orderType,
              excludedVendors,
            },
          }),
        )
        resolve(orderData)
      } catch (error) {
        const {
          payload: { cartId },
          meta: { reject },
        } = action

        yield put(
          placeOrder.rejected({
            payload: formatError(error),
            meta: { cartId },
          }),
        )
        yield put(unsetGuestCheckoutEmail())
        reject(error)
      }
    }
  }),
  takeLatest(String(pullStatus), function* pullStatusTask({
    payload: orderId,
    meta: { resolve, reject },
  }) {
    try {
      const isSubmitting = yield select(selectIsSubmitting)
      if (isSubmitting) {
        // Do we really need this? I think no
        // reject(new Error('Pulling blocked because an order is being placed'))
        return
      }

      const {
        orderStatuses,
        paymentStatus,
        reason,
        etaInMinutes,
        acceptedAt,
        paymentTitle,
        paymentMessage,
        paymentNote,
        action,
      } = yield orderApi.fetchMultiOrderStatus(orderId).then(resp => resp.data)

      const paymentErrorData = {
        title: paymentTitle,
        message: paymentMessage,
        note: paymentNote,
        action,
      }

      yield put(
        pullStatus.fulfilled({
          payload: {
            orderStatuses,
            paymentStatus,
            orderId,
            etaInMinutes,
            acceptedAt,
            reason,
            paymentErrorData,
          },
        }),
      )

      resolve()

      if (
        (orderStatuses &&
          !orderStatuses.every(
            item => !includes(getVal(item, 'status.flags', []), 'PENDING'),
          )) ||
        (paymentStatus &&
          includes(
            getVal(paymentStatus, 'status.flags', []),
            'AWAITING_PAYMENT',
          )) ||
        (orderStatuses &&
          !orderStatuses.every(
            item => !includes(getVal(item, 'status.flags', []), 'ACCEPTED'),
          ))
      ) {
        yield delay(PULL_INTERVAL)
        yield put(pullStatus(orderId))
      }
    } catch (error) {
      yield put(
        pullStatus.rejected({
          payload: { error: formatError(error), orderId },
        }),
      )
      reject(error)
    }
  }),
  takeLatest(
    String(pullMultipleCartStatus),
    function* pullMultipleCartStatusTask({
      payload: customerId,
      meta: { resolve, reject },
    }) {
      try {
        const isLoggedIn = yield select(selectIsAuthenticated)
        const cartIds = yield select(selectMultiVendorRecentGuestOrdersCartIds)

        yield isLoggedIn
          ? put(getRecentOrdersByCustomerId(customerId))
          : put(getRecentOrdersByCartIds({ carts: cartIds }))

        resolve()
        yield delay(PULL_INTERVAL_MVO)
        yield put(pullMultipleCartStatus(customerId))
      } catch (error) {
        yield put(
          pullMultipleCartStatus.rejected({
            payload: { error: formatError(error), customerId },
          }),
        )
        reject(error)
      }
    },
  ),
]

const formatError = e => get(['response', 'data', 'status'], e) || e

const reportGA = (state, cartId) => {
  try {
    // Note that on development, there will be no `gtag()` even on client side (staging/production only)
    if (isNode || typeof window.gtag !== 'function') return

    const { ordersTotal } = selectMultiVendorTotals(state)

    window.gtag('event', 'conversion', {
      send_to: 'AW-850287204/NZ2zCPvs84EBEOS0uZUD',

      value: ordersTotal,
      currency: 'AUD',
    })
  } catch (e) {
    if (bugsnagClient) bugsnagClient.notify(e)
  }
}
