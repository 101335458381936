import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import RationalImage from 'components.v2/RationalImage'
import { mobileFirstMedia as media } from 'utils/media'

/**
 * Renders fluid cuisine card component
 */
const Cuisine = ({ imgUrl, name }) =>
  <RationalImage
    className={'relative'}
    src={imgUrl}
    alt={name}
    ratio={[285, 125]}
  >
    <Label>{name}</Label>
  </RationalImage>

Cuisine.propTypes = {
  imgUrl: PT.string.isRequired,
  name: PT.string.isRequired
}

const Label = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  text-align: center;
  line-height: 110%;

  ${media.screen`
    font-size: 24px;
  `}
`

export default Cuisine
