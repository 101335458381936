import { fonts } from 'theme/fonts'
import colors from 'theme/colors'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

export const EditableRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px ${colors['border']};
`

export const EditableRowContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 70px;
`

export const EditableRowLabel = styled.div`
  align-items: center;
  color: ${colors['darkGrey']};

  display: ${props => (props.isHidden ? 'none' : 'flex')};

  ${media.screen`
    display: flex;
  `}

  font-size: ${fonts['text']['size']}px;
  justify-content: flex-start;
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  width: 126px;
`

export const EditableRowGroup = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: ${fonts['text']['size']}px;
  overflow: hidden;
  white-space: nowrap;
`

export const EditableRowLink = styled.a`
  color: ${colors['secondary']};
  font-size: ${fonts['text']['size']}px;
  line-height: normal;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
`

export const EditableRowValue = styled.div`
  flex: 1;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EditableRowAction = styled.div`
  color: ${colors['grey']};
  margin-left: auto;
`
export const EditableRowConfirmation = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px dashed ${colors['orange']};
  border-radius: 12px;
  background: #fdf0e9;
  padding: 12px 16px;
  margin: 0 10px 25px 10px;

  ${media.phone`
    margin-left: 126px;
  `}

  svg path {
    fill: ${colors['orange']};
  }

  & > p {
    font-size: ${fonts['smallText']['size']}px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 16px;
    color: ${colors['orange']};
    flex: 1;
  }

  & > button {
    font-size: ${fonts['smallText']['size']}px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 16px;
    background: ${colors['orange']};
    color: ${colors['white']};
    border-radius: 8px;
    text-wrap: nowrap;
    padding: 6px 0;
    width: 110px;

    &:disabled {
      opacity: 0.65;
    }
  }
`
