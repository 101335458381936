import notif from 'services/notification'

export default () => {
  return (next) => (action) => {
    if (
      action.meta &&
      action.meta.type === 'notification' &&
      action.meta.payload
    ) {
      const { type, title, message, ...rest } = action.meta.payload || {}
      if (typeof notif[type] === 'function') {
        notif[type]({
          title: title || '',
          message,
          ...rest,
        })
      }
    }
    return next(action)
  }
}
