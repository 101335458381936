export const h1 = {
  element: 'h1',
  size: 24,
  lineHeight: 32
}

export const h2 = {
  element: 'h2',
  size: 18,
  lineHeight: 24
}

export const h3 = {
  element: 'h3',
  size: 16,
  lineHeight: 20
}

export const h4 = {
  element: 'h4',
  size: 14,
  lineHeight: 20
}

export const text = {
  element: 'div',
  size: 14,
  lineHeight: 20
}

export const smallText = {
  element: 'div',
  size: 12,
  lineHeight: 14
}
