export default [
  {
    suburb: 'sydney',
    name: 'Sydney',
    placeId: 'ChIJP5iLHkCuEmsRwMwyFmh9AQU',
    geo: { lat: -33.8688197, lng: 151.2092955 }
  },
  {
    suburb: 'melbourne',
    name: 'Melbourne',
    placeId: 'ChIJgf0RD69C1moR4OeMIXVWBAU',
    geo: { lat: -37.81361100000001, lng: 144.963056 }
  },
  {
    suburb: 'brisbane',
    name: 'Brisbane',
    placeId: 'ChIJM9KTrJpXkWsRQK_e81qjAgQ',
    geo: { lat: -27.4697707, lng: 153.0251235 }
  },
  {
    suburb: 'perth',
    name: 'Perth',
    placeId: 'ChIJc9U7KdW6MioR4E7fNbXwBAU',
    geo: { lat: -31.9505269, lng: 115.8604572 }
  }
]
