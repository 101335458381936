import styled from 'styled-components'

/** @component */
export default styled.span`
  display: block;
  color: ${p => p.theme.colors.primary};
  font-size: 13px;

  ::before {
    content: '* ';
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
`
