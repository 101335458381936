import titleCase from 'utils/titleCase'

export const addCityToVenue = ({ address, vendorProperty, ...venue }) => ({
  ...venue,
  vendorProperty,
  address: {
    ...address,
    city: vendorProperty.timezone.startsWith('Australia/')
      ? titleCase(vendorProperty.timezone.replace('Australia/', ''))
      : null
  }
})
