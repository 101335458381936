export const ERROR_PRIMARY_MESSAGE_EXPIRED = 'Your card is expired'
export const ERROR_SECONDARY_MESSAGE_EXPIRED =
  'Please add another payment method to continue.'

export const ERROR_PRIMARY_MESSAGE_INSUFFICIENT = 'You have insufficient funds'
export const ERROR_SECONDARY_MESSAGE_INSUFFICIENT =
  'Please top up your account with sufficient funds, or add another payment method to continue.'

export const ERROR_PRIMARY_MESSAGE_ISSUE = "There's an issue with your card"
export const ERROR_SECONDARY_MESSAGE_ISSUE =
  'Please contact your card issuer for more information, or add another payment method to continue.'

export const STRIPE_ERROR_CODE_EXPIRED_CARD = 'expired_card'
export const STRIPE_ERROR_CODE_CARD_DECLINED = 'card_declined'
export const STRIPE_DECLINE_CODE_INSUFFICIENT_FUNDS = 'insufficient_funds'
