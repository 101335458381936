import React from 'react'

import ErrorScreen from './ErrorScreen'

export default () => (
  <ErrorScreen
    title="Page not found"
    message="Oooh oh, the page you’re looking for doesn’t exist."
  />
)
