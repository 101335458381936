import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import { mobileFirstMedia as media } from 'utils/media'

import Text from 'components.v2/Text'

export const AccountSourceAddLink = styled(props => (
  <Text {...props} color="secondary" />
))`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
`

export const AccountSourceAdd = styled.div`
  margin-left: auto;
  margin-top: 5px;
`

export const SourceCardInner = styled.div`
  padding: 30px 15px 0px 15px;
`

export const SourcesHeader = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  display: none;

  color: ${colors['textDark']};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0;
  text-transform: uppercase;

  ${media.screen`
    display: flex;
  `}
`

export const SourcesHeaderLeft = styled.div`
  width: 40%;
  height: 33px;
  display: flex;
  align-items: center;
`

export const SourcesHeaderRight = styled.div`
  flex: 1;
  height: 33px;
  display: flex;
  margin-left: auto;
  align-items: center;
`

export const SourcesHeaderExpiry = styled.div`
  width: 50%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SourcesHeaderOptions = styled.div`
  flex: 1;
  height: 33px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
`
export const Source = styled.div`
  display: flex;
  height: 70px;
  border-top: solid 1px ${colors['gray5']};

  padding-left: 15px;
  padding-right: 15px;

  &:first-child {
    border-top: none;
  }

  ${media.screen`
  &:first-child {
    border-top: solid 1px ${colors['gray5']};
  }
`}
`

export const Card = styled.img`
  margin-right: 15px;
  width: 40px;
  height: 30px;
`

export const CardLabel = styled.div`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${colors['textDark']};
`

export const SourceLeft = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 60%;
`

export const SourceRight = styled.div`
  flex: 1;
  margin-left: auto;
  height: 100%;
  align-items: center;
`

export const SourceRightExpiry = styled.div`
  font-size: 13px;
  width: 50%;
  height: 100%;
  display: none;

  justify-content: center;
  align-items: center;

  color: ${colors['gray2']};

  ${media.screen`
    display: flex;
  `}
`

export const SourceRightOptions = styled.div`
  flex: 1;
  height: 100%;
  color: ${colors['gray4']};
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  display: flex;
`

export const SourceOptionDelete = styled(props => (
  <Text {...props} color="primary" />
))`
  font-size: 14px !important;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
  display: none;

  ${media.screen`
    display: inline-block;
  `}
`

export const SourceOptionPrimary = styled(props => (
  <Text {...props} color="secondary" />
))`
  font-size: 14px !important;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  display: none;

  ${media.screen`
    display: inline-block;
  `}
`

export const SourceOptionDivider = styled.span`
  display: none;

  ${media.screen`
  display: inline-block;
`}
`

export const DropdownWrapper = styled.div`
  display: inline-block;
  position: relative;

  ${media.screen`
    display: none;
  `}
`

export const DropdownActionLink = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const DropdownItems = styled.div`
  top: 10px;
  left: -125px;
  position: absolute;
  transform: translateZ(0);
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  min-width: 140px;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  z-index: 3000;
`
export const DropdownItem = styled.div`
  display: flex;
  font-size: 14px;
  color: ${colors['textDark']};
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  height: 45px;
  align-items: center;
  cursor: pointer;

  &:first-child {
    border-bottom: solid 1px ${colors['lightGrey']};
  }
`

export const WalletAds = styled.div`
padding: 12px;
`
