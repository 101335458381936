import React from 'react'

const Search = props => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...props}>
    <path
      d='M8.18.18c-4.407 0-8 3.593-8 8 0 4.406 3.593 8 8 8 1.837 0 3.453-.73 4.806-1.778l5.13 5.127 1.413-1.414-5.127-5.129c1.048-1.353 1.778-2.97 1.778-4.806 0-4.407-3.594-8-8-8zm0 2c3.325 0 6 2.674 6 6 0 3.325-2.675 6-6 6-3.326 0-6-2.675-6-6 0-3.326 2.674-6 6-6z'
      fillRule='evenodd'
    />
  </svg>
)

export default Search
