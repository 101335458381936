const createHistory = require('history').createBrowserHistory

let history

const createAndSaveHistory = (...args) => {
  history = createHistory(...args)
  return history
}

const getHistory = () => history

export default getHistory
export { createAndSaveHistory as createHistory }
