import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import StyleableModal from './StylableModal'
import Icon from 'components/Icon.v2'

const Modal = ({
  children,
  noCloseButton,
  small,
  onRequestClose,
  ...props
}) => (
  <ModalCmpt small={small} onRequestClose={onRequestClose} {...props}>
    {!noCloseButton && <CloseButton onClick={onRequestClose} />}
    {children}
  </ModalCmpt>
)

Modal.propTypes = {
  children: PT.node.isRequired,
  noCloseButton: PT.bool,
  small: PT.bool,
  onRequestClose: PT.func,
}

export default Modal

const ModalCmpt = styled(StyleableModal)`
  &__content {
    position: relative;
    display: block;
    max-width: 400px;
    width: ${p => (p.small ? 'calc(100vw - 60px)' : 'calc(100vw - 30px)')};
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
const CloseButton = styled(p => (
  <button>
    <Icon type="close" {...p} />
  </button>
))`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${p => p.theme.colors.gray3};
  font-size: 18px;
`
