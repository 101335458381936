import { defineAction } from 'redux-define'
import { createPromiseAction } from 'utils/asyncActions'

const REQUEST_TYPES = ['REQUEST', 'SUCCESS', 'FAILURE']

export const FETCH_ORDER_PARTIES = defineAction(
  'FETCH_ORDER_PARTIES',
  REQUEST_TYPES,
)

export const GET_ORDER_PARTY = defineAction('GET_ORDER_PARTY', [
  ...REQUEST_TYPES,
  'FINISHED',
])

export const CREATE_ORDER_PARTY = 'unicorn/orderParty/CREATE_ORDER_PARTY'

export const GET_ORDER_PARTY_STATUS =
  'unicorn/orderParty/GET_ORDER_PARTY_STATUS'

export const LEAVE_ORDER_PARTY = defineAction(
  'LEAVE_ORDER_PARTY',
  REQUEST_TYPES,
)

export const JOIN_ORDER_PARTY = defineAction('JOIN_ORDER_PARTY', REQUEST_TYPES)

export const DELETE_ORDER_PARTY = defineAction(
  'DELETE_ORDER_PARTY',
  REQUEST_TYPES,
)

export const DETACH_PARTY = 'DETACH_PARTY'

export const pullStatus = createPromiseAction('unicorn/order-party/PULL_STATUS')
export const placeOrderParty = createPromiseAction(
  'unicorn/order-party/PLACE_ORDER',
)
