import { handleActions } from 'redux-actions'
import cities from 'const/cities'
import { createPromiseAction } from 'utils/asyncActions'
import * as homepage from './homepage'

const initialState = { city: cities[0], venues: [] }

// Actions
export const set = createPromiseAction('unicorn/city-venues/SET_CITY_VENUES')

// Reducer
export default handleActions(
  {
    [set]: (state, { payload }) => ({ ...state, city: payload, venues: [] }),
    [set.fulfilled]: (state, { payload }) => ({
      ...state,
      venues: payload,
    }),
  },
  initialState,
)

// Selectors
export const selectCity = state => homepage.getCity(state).city
export const selectVenues = state => homepage.getCity(state).venues
