import React from 'react'
import Modal from 'react-modal'
import PT from 'prop-types'
import isTesting from 'utils/is-testing'

if (!isTesting) Modal.setAppElement('[id$="root"]') // Also covers react-styleguidist's root component

const StyleableModal = ({ className, prependClassName, ...props }) => (
  <Modal
    portalClassName={className}
    className={`${
      prependClassName ? `${prependClassName} ` : ''
    }${className}__content`}
    overlayClassName={`${className}__overlay`}
    {...props}
  />
)

StyleableModal.propTypes = {
  className: PT.string,
}

export default StyleableModal
