import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'
import LazyLoad from 'react-lazyload'

const propsToPadding = defaultViewport => p => {
  const [wd, ht] = p.ratio[defaultViewport] || p.ratio
  return (ht / wd) * 100
}

/** Fluid, accessible `styled-div` with image background and fixed ratio */
const RationalImage = styled(({ src, alt, ratio, ...props }) => (
  <div {...props} />
)).attrs({
  role: 'img',
  'aria-label': p => p.alt || '',
})`
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-top: ${propsToPadding('phone')}%;
    background-image: url('${p => p.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: ${p => (p.overflow ? p.overflow : '')};

    ${media.screen`
      padding-top: ${propsToPadding('screen')}%;
    `}

    &.multivendor {
      outline: 1px solid rgba(0, 0, 0, 0.05);
      outline-offset: -1px;
    }
  `

export const ratioPropType = PT.arrayOf(PT.number)

RationalImage.propTypes = {
  src: PT.string,
  /** Description of image (for accessibility) */
  alt: PT.string,
  /**
   * - `ratio`/`ratio.phone`/`ratio.screen` must be an array of form [width<Number>, height<Number>]
   * - when using `ratio[viewport]` signature, `ratio.phone` is required
   **/
  ratio: PT.oneOfType([
    PT.shape({
      screen: ratioPropType,
      desktop: ratioPropType,
      tablet: ratioPropType,
      phone: ratioPropType.isRequired,
    }),
    ratioPropType,
  ]).isRequired,
}

const LazyImage = props => {
  return (
    <LazyLoad>
      <RationalImage {...props} />
    </LazyLoad>
  )
}

/** @component */
export default LazyImage
