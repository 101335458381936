import styled from 'styled-components'
import { Modal as StyledModal } from 'pages/party/styled'

const Layout = styled.div`
  display: flex;
  width: 360px;
  padding: 32px 20px 20px 20px;
  flex-direction: column;

  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  gap: 32px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`

const Button = styled.button`
  height: 52px;
  width: 50%;
  border-radius: 8px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &.primary {
    background: var(--Orange-0, #f26722);
    color: var(--White, #fff);
  }

  &.secondary {
    background: var(--Orange-3, #fdf0e9);
    color: var(--Orange-0, #f26722);
  }

  &:focus {
    outline: none;
  }
`

const Subcontent = styled.div`
  color: var(--Grey-1, #666);
  text-align: center;
  margin-top: 12px;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const ContentTitle = styled.div`
  color: var(--Black, #000);
  text-align: center;

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

const Modal = styled(StyledModal)`
  &__content {
    width: 360px;
  }
`

const HostPlacingOrderWithoutMember = ({
  primaryAction,
  primaryButtonText,
  secondaryAction,
  secondaryButtonText,
  handleHide,
  title,
  subcontent,
}) => {
  return (
    <Modal isOpen noCloseButton onRequestClose={handleHide}>
      <Layout>
        <div className="flex flex-col">
          <ContentTitle>{title}</ContentTitle>
          <Subcontent>{subcontent}</Subcontent>
        </div>
        <Buttons>
          <Button className="secondary" onClick={secondaryAction}>
            {secondaryButtonText || 'Cancel'}
          </Button>
          <Button className="primary" onClick={primaryAction}>
            {primaryButtonText || 'Submit'}
          </Button>
        </Buttons>
      </Layout>
    </Modal>
  )
}

export default HostPlacingOrderWithoutMember
