import { defineAction } from 'redux-define'
import { getError } from 'utils/get-message'
import { handleActions } from 'redux-actions'

import { fetchOrderHistory as fetchOrders } from 'api/order'

const TYPES = ['FAILURE', 'SUCCESS', 'REQUEST']

export const FETCH_ORDER_HISTORY = defineAction(
  'FETCH_ORDER_HISTORY',
  TYPES,
  'unicorn/favourites',
)

const initialState = {
  isLoading: false,
  isLoaded: false,
  items: [],
  params: { offset: 0, limit: 10 },
}

export const fetchOrderHistory = (
  params = { offset: 0 },
  replace = true,
) => async dispatch => {
  try {
    dispatch({ type: FETCH_ORDER_HISTORY.REQUEST })
    const items = await fetchOrders(params).then(({ data }) => data.history)
    dispatch({ type: FETCH_ORDER_HISTORY.SUCCESS, items, replace, params })
  } catch (err) {
    dispatch({
      type: FETCH_ORDER_HISTORY.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
    })
    throw err
  }
}

export default handleActions(
  {
    [FETCH_ORDER_HISTORY.REQUEST]: state => {
      return {
        ...state,
        isLoading: true,
      }
    },
    [FETCH_ORDER_HISTORY.SUCCESS]: (
      state,
      { items = [], replace, params = {} },
    ) => {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        items: replace ? items : state.items.concat([...items]),
        params: {
          ...state.params,
          offset: items.length === 0 ? state.params.offset : params.offset,
        },
      }
    },
    [FETCH_ORDER_HISTORY.FAILURE]: state => {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      }
    },
  },
  initialState,
)
