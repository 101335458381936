import React from 'react'
import PropTypes from 'prop-types'
import { Ring as RingContainer, RingContent } from './styled'

const Ring = ({ className, size, borderSize, color }) => (
  <RingContainer className={className} size={size} borderSize={borderSize} color={color}>
    <RingContent index={0} />
    <RingContent index={1} />
    <RingContent index={2} />
    <RingContent index={3} />
  </RingContainer>
)

Ring.defaultProps = {
  size: 40,
  borderSize: 2,
  color: 'white'
}

Ring.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  borderSize: PropTypes.number,
  color: PropTypes.string
}

export default Ring
