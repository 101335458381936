import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { Router as BrowserRouter, StaticRouter } from 'react-router'
import { ThemeProvider } from 'styled-components'
import isNode from 'detect-node'
import PropTypes from 'prop-types'
import '@stripe/stripe-js' // Load stripe as side effect to enable fraud detection
import Router from 'router'
import get from 'lodash/get'

import { createHistory } from 'services/history'
import { LayoutProvider } from 'contexts/layout-context'
import { ConfigProvider } from 'contexts/config-context'
import { RoktLauncherContextProvider } from 'contexts/rokt-context'
import registerOptimizedEvent from 'services/registerOptimizedEvent'
import theme from 'theme'
import config from 'app-config'

let exposedStore, exposedPersistor
const isClient = !isNode

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

class App extends React.Component {
  componentDidMount = () => {
    registerOptimizedEvent('scroll', 'optimizedScroll') // TODO: Move to higher level components
  }

  getContent = () =>
    isClient ? (
      <BrowserRouter
        history={createHistory({ initialEntries: [this.props.location] })}
      >
        <Router />
      </BrowserRouter>
    ) : (
      <StaticRouter location={this.props.location} context={{}}>
        <Router />
      </StaticRouter>
    )

  exposeStore = () => {
    if (!exposedStore) {
      exposedStore = this.props.store
      exposedPersistor = this.props.persistor
    }

    if (process.env.NODE_ENV === 'development' && !window.__STORE__)
      window.__STORE__ = this.props.store
  }

  render = () => {
    const { helmetContext, store } = this.props
    this.exposeStore()

    return (
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <ConfigProvider>
            <ThemeProvider theme={theme}>
              <ConditionalWrapper
                condition={get(config, 'ROKT_ENABLED', false)}
                wrapper={children => (
                  <RoktLauncherContextProvider
                    accountId={get(config, 'ROKT_ACCOUNT_ID')}
                    sandbox={get(config, 'ROKT_SANDBOX', false)}
                  >
                    {children}
                  </RoktLauncherContextProvider>
                )}
              >
                <LayoutProvider>{this.getContent()}</LayoutProvider>
              </ConditionalWrapper>
            </ThemeProvider>
          </ConfigProvider>
        </HelmetProvider>
      </Provider>
    )
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object,
  helmetContext: PropTypes.object,
  routerContext: PropTypes.object,
  location: PropTypes.string,
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App
export const getStore = () => exposedStore
export const getPersistor = () => exposedPersistor
