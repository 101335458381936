import React from 'react'
import { ReactSVG } from 'react-svg'
import asset from 'utils/asset'

export default ({ src = '', ...props }) => {
  if (src.indexOf('://') > 0 || src.indexOf('//') === 0) {
    return <ReactSVG src={src} {...props} />
  } else {
    return <ReactSVG src={`${asset('images/icons/svg/' + src)}`} {...props} />
  }
}
