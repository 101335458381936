import styled from 'styled-components'
import colors from 'theme/colors'
import StylableModal from 'components.v2/Modal/StylableModal'

export const PartyPage = styled.div`
  align-items: center;
  background-color: ${colors['gray7']};
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 78px);
  width: 100%;
  position: relative;
`

export const Modal = styled(StylableModal)`
  &__content {
    position: relative;
    display: block;

    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
