import styled from 'styled-components'
import BaseButton from 'components.v2/Button'
import media from 'utils/media'

export const Link = styled.button`
  color: ${p => p.theme.colors.secondary};
  font-size: 13px;
  text-decoration: none;
`

export const Button = styled(BaseButton)`
  width: 130px;
  order: ${p => (p.actionsReversed ? -1 : 0)};
`

export const ButtonLonger = styled(BaseButton)`
  width: 160px;
  order: ${p => (p.actionsReversed ? -1 : 0)};
  margin: ${p => (p.buttonAlign ? '0 auto' : 0)};
`

export const GuestCheckoutButton = styled(BaseButton)`
  width: 100%;
  height: 50px;
  margin: 0 5px;
  background: #000;
  color: #fff;
  text-align: center;
  outline: none;
`

export const GuestCheckoutText = styled.span`
  width: 100%;
`

export const GuestCheckoutText2 = styled.button`
  font-family: Montserrat;
  letter-spacing: 0em;
  text-align: center;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  display: flex;

  align-items: center;
  justify-content: center;
  color: #ffffff;

  background: #f26722;
  border-radius: 8px;

  width: 100%;
  height: 52px;
  border-radius: 8px;
  margin-top: 20px;

  bottom: 0px;

  ${media.phone`
    margin-top: 19px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `}
`
