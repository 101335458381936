import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import CuisineCard from 'components.v2/CuisineCard'
import events from 'const/analytics-events'
import withAnalytics from 'enhancers/withAnalytics'
import imageURL from 'utils/imageURL'

const CardItem = ({ name, image, onSelect, onClick }) => {
  const Wrapper = onSelect ? 'div' : Link
  const wrapperProps = onSelect
    ? { onClick: onSelect }
    : { to: `/cuisines/${encodeURI(name.toLowerCase())}` }

  return (
    <li
      onClick={onClick}
      key={name}
      className="block px-2pt5 pt-5 xl:px-5 xl:pt-10 w-1/2 sm:w-1/3 xl:w-1/4"
    >
      <Wrapper {...wrapperProps}>
        <CuisineCard
          name={name}
          imgUrl={imageURL(image, { width: 370, height: 174 })}
        />
      </Wrapper>
    </li>
  )
}

const CardItemTracked = withAnalytics()(CardItem)

/**
 * Renders cuisines into grid of `CuisineCards`
 * - nothing is rendered if `cuisines` is undefined or empty
 * - passes `className` prop to parent `ul` (when it renders)
 **/
const CuisineGrid = ({ cuisines, className }) => {
  return cuisines && cuisines.length > 0 ? (
    <ul
      className={`flex flex-wrap -mx-2pt5 -mt-5 xl:-mx-5 xl:-mt-15 ${className}`}
    >
      {cuisines.map(c => {
        return (
          <CardItemTracked
            {...c}
            key={c.name}
            analytics={[
              {
                eventType: 'onClick',
                options: { ...events['CUISINE_CLICK'], label: c.name },
              },
            ]}
          />
        )
      })}
    </ul>
  ) : null
}

CuisineGrid.propTypes = {
  cuisines: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      /** Link to the cuisine's image */
      image: PT.string.isRequired,
      /** If onSelect is defined, cuisine item will be <div> with handler instead of <Link> */
      onSelect: PT.func,
    }),
  ),
}

export default CuisineGrid
