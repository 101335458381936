import { handleActions } from 'redux-actions'
import { createPromiseAction } from 'utils/asyncActions'
import * as homepage from './homepage'
import get from 'lodash/get'

export const fetchAds = createPromiseAction('unicorn/homescreen/ads/FETCH_ADS')

const initState = { ads: [], checkoutAds: [], walletAds: [], heroAds: [] }

export default handleActions(
  {
    [fetchAds]: state => ({ ...state }),
    [fetchAds.fulfilled]: (state, { payload }) => {
      return ({
        ...state,
        ...payload,
      })
    },
  },
  initState,
)

export const getAds = state => homepage.getAds(state)
export const getHeroAds = state => homepage.getHeroAds(state)
export const selectCheckoutAds = (state) => state.homepage.ads.checkoutAds
export const selectWalletAds = (state) => get(state,'homepage.ads.walletAds',[])
