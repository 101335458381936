import { css } from 'styled-components'

import sizes from './sizes'

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})

// Mobile first version
export const mobileFirstMedia = Object.keys(sizes)
  .reverse()
  .reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label] + 1}px) {
        ${css(...args)};
      }
    `
    return acc
  }, {})

export default media
