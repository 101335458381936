import React from 'react'

import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import IconV2 from 'components.v2/Icon/IconV2'

export const ProfileModalContainer = styled.div`
  position: fixed;
  top: 78px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 2000;
  background-color: ${props => props.theme.colors.white};

  transform: translateY(${props => (props.isOpen ? 0 : '-150%')});
  transition: all 0.2s ease-in-out;
`

export const AvatarSection = styled.section`
  height: 160px;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Avatar = styled.div`
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: ${props =>
    props.color
      ? `1.5px solid ${props.color}`
      : `1px solid ${props.theme.colors.darkGrey}`};
  background-color: ${props => props.theme.colors.grey};
  background-image: url(${props => props.avatar});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const LinkAction = styled(Link)`
  cursor: pointer;
  display: block;
  font-size: 12px;
  color: ${props => props.theme.colors.gray3};
  text-decoration: none;
`

export const LinksSection = styled.div`
  margin: 40px 50px;
`

export const ActionGroup = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  &.active {
    * {
      color: ${props => props.theme.colors.orange} !important;
    }
  }
`

export const NativeLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  &.active {
    * {
      color: ${props => props.theme.colors.orange} !important;
    }
  }
`

export const ActionIcon = ({ width, height, src, ...rest }) => {
  return (
    <IconV2
      {...rest}
      src={src}
      beforeInjection={svg => {
        svg.setAttribute(
          'style',
          'width: 24px; height: 24px; margin-right: 30px',
        )
      }}
      wrapper="span"
    />
  )
}

export const Divider = styled.hr`
  border: 1px solid rgba(0, 0, 0, 0.1);
`

export const Logout = styled.div`
  cursor: pointer;
  margin: 30px 50px;
`
