import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import DesktopContainer from 'layouts/DesktopContainer'
import Heading from 'components.v2/Heading'
import Suggestion from './Suggestion'
import { mobileFirstMedia as media } from 'utils/media'
import { MobileScreen, DesktopScreen } from 'utils/screen'

const SuggestionSection = ({ heading, items, className }) => {
  if (!items || !items.length) return null

  const createSuggestionsJsx = limit => (
    <div className='flex flex-wrap pt-5 xl:pt-0'>
      {groupInto(3, items.slice(0, limit))
        .map((grp, i) => (
          <SuggestionRow key={i}>
            {grp.map((item, j) => (
              <SuggestionContainer key={j}>
                <Suggestion {...item} />
              </SuggestionContainer>
            ))}
          </SuggestionRow>
        ))}
    </div>
  )

  return (
    <DesktopContainer background='white' className={className}>
      <section className='px-15 pt-20 xl:px-0 xl:py-30'>
        <Heading level={2}>{heading}</Heading>

        <MobileScreen>
          {createSuggestionsJsx(3)}
        </MobileScreen>
        <DesktopScreen>
          {createSuggestionsJsx(6)}
        </DesktopScreen>
      </section>
    </DesktopContainer>
  )
}

SuggestionSection.propTypes = {
  heading: PT.string.isRequired,
  items: PT.array.isRequired,
  className: PT.string,
}

export default SuggestionSection

const SuggestionContainer = styled.div`
  padding: 15px 0;
  border-top: 1px solid ${p => p.theme.colors.gray6};

  ${media.screen`
    width: calc(100% / 3);
    padding: 30px 0;
    border: none;
  `}
`

const SuggestionRow = styled.div`
  width: 100%;

  &:first-child > ${SuggestionContainer}:first-child {
    border-top: 0;
  }

  ${media.screen`
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid ${p => p.theme.colors.gray6};

    :first-child {
      border-top: 0;
    }
  `}
`

const groupInto = (k, xs) => xs.reduce(
  (acc, x) => {
    const lastIndex = acc.length - 1

    if (acc[lastIndex].length === k) {
      return [...acc, [x]]
    }

    acc[lastIndex] = [...acc[lastIndex], x]

    return acc
  },
  xs.length ? [[]] : [])
