import { getError } from 'utils/get-message'

import OrderPartyApi from 'api/order-party'

import {
  FETCH_ORDER_PARTIES,
  GET_ORDER_PARTY,
  CREATE_ORDER_PARTY,
  LEAVE_ORDER_PARTY,
  JOIN_ORDER_PARTY,
  DELETE_ORDER_PARTY,
  DETACH_PARTY,
  GET_ORDER_PARTY_STATUS,
  pullStatus,
} from './order-party.constants'
import client from 'client'
import get from 'lodash/get'
import {
  selectOrderPartyByCode,
  selectPlaceOrderParty,
} from './order-party.selectors'
import { show, types } from 'store/modals'
import getHistory from 'services/history'
import { hide } from '../modals/modals'

export const detachParty = venueId => dispatch =>
  dispatch({
    type: DETACH_PARTY,
    payload: {
      venueId,
    },
  })

export const fetchOrderParties = (params = {}) => async dispatch => {
  try {
    dispatch({ type: FETCH_ORDER_PARTIES.REQUEST })

    const response = await OrderPartyApi.fetchOrderParties(params)

    dispatch({ type: FETCH_ORDER_PARTIES.SUCCESS, payload: { response } })
  } catch (err) {
    dispatch({
      type: FETCH_ORDER_PARTIES.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
    })
  }
}

export const createOrderParty = ({
  vendorId,
  inviteMessage = '',
  customerIds = [],
}) => async (dispatch, getState) => {
  const state = getState()
  const isLoggedIn = state.auth.isAuthenticated

  const customerId = get(state, 'profile.customer.id')

  const checkOrderPartyResponse = await OrderPartyApi.checkCustomerOrderParty(
    customerId,
  )

  if (get(checkOrderPartyResponse, 'orderParty')) {
    throw checkOrderPartyResponse
  }

  if (!isLoggedIn) {
    return
  }

  const createOrderPartyResponse = await dispatch({
    type: CREATE_ORDER_PARTY,
    payload: client().post('/orderparties', {
      vendorId,
      inviteMessage,
      customerIds,
    }),
  })

  dispatch(
    getOrderParty({
      orderPartyId: get(createOrderPartyResponse, 'value.data.id'),
      type: 'host',
    }),
  )

  return {
    value: {
      data: {
        ...get(createOrderPartyResponse, 'value.data'),
        partyCode: get(createOrderPartyResponse, 'value.data.inviteLink', '')
          .split('/')
          .slice(-1)[0],
      },
    },
  }
}

export const getOrderParty = ({ orderPartyId }) => async (
  dispatch,
  getState,
) => {
  try {
    const state = getState()
    const isLoggedIn = state.auth.isAuthenticated

    dispatch({ type: GET_ORDER_PARTY.REQUEST, payload: { orderPartyId } })

    const response = await OrderPartyApi.getOrderParty(orderPartyId, isLoggedIn)

    const partyCode = get(response, 'inviteLink', '')
      .split('/')
      .slice(-1)[0]

    dispatch({
      type: GET_ORDER_PARTY.FINISHED,
      payload: {
        ...response,
        partyCode,
      },
    })

    if (response.memberStatus !== 'ORDER_PLACED') {
      dispatch({ type: GET_ORDER_PARTY.SUCCESS, payload: { party: response } })
    } else {
      dispatch({ type: DETACH_PARTY, payload: { venueId: response.vendorId } })
    }

    return response
  } catch (err) {
    dispatch({
      type: GET_ORDER_PARTY.FAILURE,
      payload: { orderPartyId },
    })
    // throw err
  }
}

export const getOrderPartyStatus = ({ partyCode }) => async (
  dispatch,
  getState,
) => {
  try {
    const response = await OrderPartyApi.getOrderPartyStatus(partyCode)

    dispatch({
      type: `${GET_ORDER_PARTY_STATUS}_FULFILLED`,
      payload: {
        ...response,
        partyCode,
      },
    })
  } catch (error) {
    const state = getState()
    const currentOrderParty = selectOrderPartyByCode(state, partyCode)

    dispatch(
      pullStatus.rejected({
        payload: {
          ...currentOrderParty,
          hasError: true,
        },
      }),
    )
  }
}

export const leaveOrderParty = ({
  orderPartyId,
  venueId,
}) => async dispatch => {
  try {
    dispatch({ type: LEAVE_ORDER_PARTY.REQUEST, payload: { venueId } })

    await OrderPartyApi.leaveOrderParty(orderPartyId)

    dispatch({
      type: LEAVE_ORDER_PARTY.SUCCESS,
      payload: { venueId },
      meta: {
        type: 'notification',
        payload: {
          type: 'success',
          message: 'You have successfully left the group order.',
        },
      },
    })

    dispatch(detachParty(venueId))
  } catch (err) {
    if (get(err, 'response.data.status.code') !== 180000) {
      dispatch({
        type: LEAVE_ORDER_PARTY.FAILURE,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: getError(err),
          },
        },
        payload: { venueId },
      })
    }

    dispatch(detachParty(venueId))
    throw err
  }
}

export const deleteOrderParty = ({
  orderPartyId,
  venueId,
}) => async dispatch => {
  try {
    dispatch({ type: DELETE_ORDER_PARTY.REQUEST, payload: { venueId } })

    await OrderPartyApi.deleteOrderParty(orderPartyId)

    dispatch({
      type: DELETE_ORDER_PARTY.SUCCESS,
      payload: { venueId },
      meta: {
        type: 'notification',
        payload: {
          type: 'success',
          message: 'You’ve cancelled the group order',
        },
      },
    })

    dispatch(detachParty(venueId))
  } catch (err) {
    dispatch({
      type: DELETE_ORDER_PARTY.FAILURE,
      meta: {
        type: 'notification',
        payload: {
          type: 'error',
          message: getError(err),
        },
      },
      payload: { venueId },
    })
    dispatch(detachParty(venueId))
    throw err
  }
}

export const joinOrderParty = ({ partyCode, venueId }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: JOIN_ORDER_PARTY.REQUEST, payload: { venueId } })

    const profile = await client().get('/customers/me')

    const customerId = get(profile, 'data.customer.id')

    const customerOrderParty = await OrderPartyApi.checkCustomerOrderParty(
      customerId,
    )

    const orderParty = await OrderPartyApi.getOrderPartyStatus(partyCode)

    const isOrderPartyDone = [
      'ORDER_PAID',
      'ORDER_SUBMITTED',
      'ORDER_PLACED',
    ].includes(get(orderParty, 'leaderStatus'))

    if (isOrderPartyDone) {
      dispatch(
        show(types.ORDER_PARTY_MEMBER_LEFT_BEHIND, {
          venueId: get(orderParty, 'vendorId'),
          orderPartyId: get(orderParty, 'id'),
          isMember: false,
        }),
      )
      return orderParty
    }

    const orderPartyId = get(orderParty, 'id')

    const sameOrderParty =
      get(customerOrderParty, 'orderParty.id') === orderPartyId

    if (
      get(customerOrderParty, 'orderParty') &&
      get(customerOrderParty, 'orderParty.id') !== get(orderParty, 'id')
    ) {
      dispatch(
        show(types.HAS_ACTIVE_ORDER_PARTY_MODAL, {
          handleViewGroup: () => {
            const history = getHistory()
            history.push(
              `/order/${get(customerOrderParty, 'orderParty.vendorId')}/review`,
            )
            dispatch(hide())
          },
          handleDismiss: () => {
            dispatch(
              detachParty(get(customerOrderParty, 'orderParty.vendorId')),
            )
            dispatch(hide())
          },
          handleClose: () => {
            dispatch(hide())
          },
          activeOrderParty: get(customerOrderParty, 'orderParty'),
        }),
      )
      return {
        ...get(customerOrderParty, 'orderParty'),
        partyCode: get(customerOrderParty, 'orderParty.inviteLink', '')
          .split('/')
          .slice(-1)[0],
      }
    }

    if (!get(customerOrderParty, 'orderParty') || sameOrderParty) {
      await OrderPartyApi.joinOrderParty(orderPartyId)

      await dispatch(getOrderParty({ orderPartyId }))

      dispatch({
        type: JOIN_ORDER_PARTY.SUCCESS,
        payload: { venueId },
        meta: {
          type: 'notification',
          payload: {
            type: 'success',
            message: 'You have successfully joined the party.',
          },
        },
      })

      return {
        ...orderParty,
        partyCode: get(orderParty, 'inviteLink', '')
          .split('/')
          .slice(-1)[0],
      }
    }
  } catch (error) {
    throw error
  }
}

export const refreshOrderParty = venueId => async (dispatch, getState) => {
  const state = getState()

  const isAuthenticated = get(state, 'auth.isAuthenticated', false)

  if (isAuthenticated) {
    const profile = get(state, 'profile.customer')

    const customerId = get(profile, 'id')

    const customerOrderParty = await OrderPartyApi.checkCustomerOrderParty(
      customerId,
    )

    const currentOrderParty = get(customerOrderParty, 'orderParty')

    const placedOrderParty = selectPlaceOrderParty(state, venueId)

    if (
      currentOrderParty === null ||
      get(currentOrderParty, 'memberStatus') === 'DECLINED' ||
      get(customerOrderParty, 'orderParty.memberStatus') === 'ORDER_PLACED'
    ) {
      const leaderStatus = get(placedOrderParty, 'orderStatus', []).find(
        i => i.isLeader === true,
      )

      if (
        get(leaderStatus, 'flags', []).includes('ACCEPTED') ||
        get(leaderStatus, 'flags', []).includes('PENDING') ||
        get(leaderStatus, 'flags', []).includes('AWAITING_PAYMENT') ||
        get(placedOrderParty, 'leaderStatus') === 'ACCEPTED'
      ) {
        dispatch(pullStatus(get(placedOrderParty, 'partyCode')))
      }
      dispatch(detachParty(venueId))
      return
    }

    const orderPartyId = get(currentOrderParty, 'id')

    dispatch({ type: GET_ORDER_PARTY.REQUEST, payload: { orderPartyId } })

    const response = await OrderPartyApi.getOrderParty(orderPartyId, true)

    const partyCode = get(response, 'inviteLink', '')
      .split('/')
      .slice(-1)[0]

    dispatch(pullStatus(partyCode))

    dispatch({
      type: GET_ORDER_PARTY.FINISHED,
      payload: {
        ...response,
        partyCode,
      },
    })
  }
}
