import styled from 'styled-components'

export const Footer = styled.footer`
  height: 83px;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.img`
  height: 23px;
`

export const Title = styled.span`
  font-size: 12px;
  letter-spacing: 1px;
  color: #f26722;
  font-weight: 500;
  margin-right: 8px;
`
