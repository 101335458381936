import styled from 'styled-components'

export const ProfileContainer = styled.div`
  position: relative;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  height: 100%;

  &.multivendor {
    justify-content: center;
    flex-direction: column;
    gap: 0px;
  }
`

export const Avatar = styled.div`
  width: ${p => p.width || '24px'};
  height: ${p => p.height || '24px'};
  border-radius: 15px;
  background-color: ${props => props.theme.colors.grey};
  border: ${props =>
    props.color
      ? `1.5px solid ${props.color}`
      : `1px solid ${props.theme.colors.darkGrey}`};
  background-image: url(${props => props.avatar});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.multivendor {
    outline: 1.5px solid rgba(0, 0, 0, 0.1);
    outline-offset: -1.5px;
    width: 17px;
    height: 17px;
    border: none;
  }
`

export const UserNameContainer = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  width: 52px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`

export const AvatarContainer = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid black;
`
