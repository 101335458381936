import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import Text from 'components.v2/Text'

export const AccountCardDivider = styled.hr`
  border-bottom: solid 1px ${colors.gray5};
  margin: 0;
`

export const AccountCardWrapper = styled.div`
  margin-bottom: 15px;
  position: relative;
`

export const AccountCardInner = styled.div`
  padding-top: ${p => p['pt'] || '30px'};
  padding-right: ${p => p['pr'] || '15px'};
  padding-bottom: ${p => p['pb'] || '30px'};
  padding-left: ${p => p['pl'] || '15px'};
`

export const AccountCardHeader = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  display: flex;
`

export const AccountCardHeaderInner = styled.div`
  margin: 0;
`

export const AccountCardTitle = styled(props => (
  <Text variant="h3" {...props} />
))`
  line-height: normal;
  margin-bottom: ${props => (props.hasLead ? 0 : '30px')};
  flex: 1;
`

export const AccountCardBalance = styled(props => (
  <Text variant="h3" {...props} />
))`
  margin-left: auto;
`

export const AccountCardActions = styled.div`
  margin-left: auto;
`

export const AccountCardLead = styled(props => (
  <Text as="div" variant="tertiary" {...props} color="gray1" />
))`
  letter-spacing: 0.5px;
  margin: 5px 0 0 0;
`

export const AccountHeaderTitle = ({
  title = null,
  subtitle = null,
  ...rest
}) => {
  return (
    <AccountCardHeader {...rest}>
      <AccountCardHeaderInner>
        <AccountCardTitle hasLead={!!subtitle}>{title}</AccountCardTitle>
        {subtitle && <AccountCardLead>{subtitle}</AccountCardLead>}
      </AccountCardHeaderInner>
    </AccountCardHeader>
  )
}
