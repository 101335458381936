import qs from 'qs'

export const createSearchRoute = params => {
  const { city, suburb, query, placeid } = params

  if (city && suburb && query)
    return (
      `/${city}/${suburb}/${query}`.toLowerCase().replace(' ', '-') +
      `?${qs.stringify({ placeid })}`
    )
  else return `/search?${qs.stringify(params)}`
}
