import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Loader from 'components/Loader'

import { hasValidToken } from 'auth/helpers'

import * as actions from '../auth/modules/auth'
import * as profileActions from '../auth/modules/profile'

const WithAuthentication = Component => {
  return class WithAuthenticationContainer extends React.Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      history: PropTypes.object.isRequired,
      location: PropTypes.object,
      authActions: PropTypes.object.isRequired,
      profileActions: PropTypes.object.isRequired,
    }

    state = { isLoading: false }

    async componentDidMount() {
      this.setState({
        isLoading: true,
      })
      const {
        customer,
        isAuthenticated,
        authActions,
        profileActions,
        token,
        isFetchingProfile,
      } = this.props

      if (isAuthenticated) {
        if (!hasValidToken(token)) {
          await authActions.refreshToken(token.refresh_token)
        }
        if (!customer || (!customer.id && !isFetchingProfile)) {
          profileActions.fetchProfile()
        }
      }

      this.setState({
        isLoading: false,
      })
    }

    render() {
      return this.state.isLoading ? (
        <Loader page />
      ) : (
        <Component {...this.props} />
      )
    }
  }
}

const mapStateToProps = state => ({
  isFetchingProfile: state.profile.isLoading,
  customer: state.profile.customer,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
})
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(actions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  WithAuthentication,
)
