import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import noScroll from 'no-scroll'
import Icon from 'components/Icon'

import { MobileScreen, DesktopScreen } from 'utils/screen'
import ProfileModal from './ProfileModal'
import ProfileMenu from './ProfileMenu'
import {
  ProfileContainer,
  Avatar,
  UserNameContainer,
  AvatarContainer,
} from './styled'
import { connect } from 'react-redux'
import {
  selectIsLoaded,
  fetchProfile,
  selectIsLoading,
} from 'auth/modules/profile'
import { selectIsAuthenticated } from 'auth/modules/auth'
import asset from 'utils/asset'

class Profile extends React.Component {
  state = {
    isOpen: false,
  }

  componentDidMount = () => {
    if (this.props.shouldFetchProfile) this.props.fetchProfile()
  }

  componentWillUnmount() {
    noScroll.off()
  }

  toggleOpen = () => {
    if (this.state.isOpen) {
      noScroll.off()
    } else {
      noScroll.on()
    }
    const isOpen = !this.state.isOpen
    this.props.openProfile(isOpen)
    this.setState({
      isOpen,
    })
  }

  logout = () => {
    this.toggleOpen()
    this.props.logout()
  }

  render() {
    const { image, firstname, lastname, isMultiVendor } = this.props
    const { isOpen } = this.state
    const icon = isOpen ? 'up' : 'down'

    return (
      <Fragment>
        <MobileScreen>
          <ProfileContainer
            pointer
            onClick={this.toggleOpen}
            className={isMultiVendor && 'multivendor'}
          >
            {isMultiVendor ? (
              <Fragment>
                <AvatarContainer>
                  <Avatar
                    avatar={image || asset('images/guest-avatar.jpeg')}
                    color="black"
                    className="multivendor"
                  />
                </AvatarContainer>
                <UserNameContainer>{firstname}</UserNameContainer>
              </Fragment>
            ) : (
              <Fragment>
                <Avatar avatar={image} />
                <Icon name={`fas fa-chevron-${icon}`} size={10} />
              </Fragment>
            )}
          </ProfileContainer>
          <ProfileModal
            isOpen={isOpen}
            toggleOpen={this.toggleOpen}
            logout={this.logout}
            image={
              isMultiVendor ? image || asset('images/guest-avatar.jpeg') : image
            }
            firstname={firstname}
            lastname={lastname}
            color={isMultiVendor && 'black'}
            isMultiVendor={isMultiVendor}
          />
        </MobileScreen>
        <DesktopScreen>
          <ProfileContainer className={isMultiVendor && 'multivendor'}>
            {isMultiVendor ? (
              <Fragment>
                <AvatarContainer>
                  <Avatar
                    avatar={image || asset('images/guest-avatar.jpeg')}
                    color="black"
                    className="multivendor"
                  />
                </AvatarContainer>
                <UserNameContainer>{firstname}</UserNameContainer>
              </Fragment>
            ) : (
              <Fragment>
                <Avatar avatar={image} />
                <Icon name={`fas fa-chevron-${icon}`} size={10} />
              </Fragment>
            )}
            <ProfileMenu
              avatar={
                isMultiVendor
                  ? image || asset('images/guest-avatar.jpeg')
                  : image
              }
              color={isMultiVendor && 'black'}
              isMultiVendor={isMultiVendor}
            />
          </ProfileContainer>
        </DesktopScreen>
      </Fragment>
    )
  }
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired,
  openProfile: PropTypes.func,
  image: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
}

const withState = connect(
  state => ({
    shouldFetchProfile:
      selectIsAuthenticated(state) &&
      !selectIsLoaded(state) &&
      !selectIsLoading(state),
  }),
  { fetchProfile },
)

export default withState(Profile)
