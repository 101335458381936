import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import pipe from 'lodash/fp/pipe'

import { hide } from 'store/modals'
import Modal from 'components.v2/Modal'
import Text from 'components.v2/Text'
import Button from 'components.v2/Button'

const withContainer = pipe(
  connect(
    null,
    { hide },
  ),
  withRouter,
)

export default ({ heading, body, createAction, actionText }) =>
  withContainer(props => (
    <Modal
      isOpen
      contentLabel={callOrReturn(heading, props)}
      onRequestClose={props.hide}
      className="pt-30 pb-15 px-15"
      noCloseButton
    >
      <Avatar src={props.avatar} className="mb-30 mx-auto rounded-full block" />
      <Text variant="h4" align="center" className="mb-5">
        {callOrReturn(heading, props)}
      </Text>
      <Text variant="body" align="center">
        {callOrReturn(body, props)}
      </Text>
      <Button
        large
        largeText
        fullWidth
        variant="primary"
        className="mt-30"
        onClick={createAction(props)}
      >
        {actionText}
      </Button>
    </Modal>
  ))

const callOrReturn = (fnOrPrimitive, ...args) =>
  typeof fnOrPrimitive === 'function' ? fnOrPrimitive(...args) : fnOrPrimitive

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`
