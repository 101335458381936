import styled from 'styled-components'

import Button from 'components.v2/Button'

// todo merge all styled components for modals

export const Heading = styled.h2`
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
`

export const Text = styled.span`
  font-family: 'Montserrat', sans-serif !important;
  color: #666666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
  margin-top: 32px;
`

export const List = styled.ul`
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  text-align: center;
  display: flex;
  list-style: disc;
  justify-content: center;
  flex-direction: column;
  text-align: left;
`

export const ListItem = styled.li`
  margin: 0 auto;
`

export const Link = styled.button`
  color: ${p => p.theme.colors.secondary};
  opacity: ${p => (p.disabled ? 0.5 : 1)};
`

export const ModalButton = styled(Button)`
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 24px;
`
