import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import get from 'lodash/get'
import isNode from 'detect-node'

import config from 'app-config'

export const firebaseConfig = {
  apiKey: get(config, 'FIREBASE_API_KEY'),
  authDomain: get(config, 'FIREBASE_AUTH_DOMAIN'),
  projectId: get(config, 'FIREBASE_PROJECT_ID'),
  storageBucket: get(config, 'FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: get(config, 'FIREBASE_MESSAGING_SENDER_ID'),
  appId: get(config, 'FIREBASE_APP_ID'),
  measurementId: get(config, 'FIREBASE_MEASUREMENT_ID'),
}

const app = initializeApp(firebaseConfig)
const analytics = isNode ? null : getAnalytics(app)

export const trackEvent = options => {
  const { action } = options
  return analytics ? logEvent(analytics, action, options) : null
}

export const trackPage = options => {
  return analytics ? logEvent(analytics, 'page_view', options) : null
}
