import { handleActions } from 'redux-actions'
import pick from 'lodash/fp/pick'
import mapValues from 'lodash/fp/mapValues'
import { matchPath } from 'react-router-dom'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import getHistory from 'services/history'
import createActionFactory from 'utils/createActionFactory'
import { createPromiseAction } from 'utils/asyncActions'
import slugify from 'utils/slugify'
import { getCuisineParams } from 'common/CuisineSearchBar/cuisine.js'
import { getLocationParams } from 'common/LocationSearchBar/location'
import { DEFAULT_ITEMS_PER_PAGE } from './venues.const'
import { createSearchRoute } from './venues.utils.js'

const prefix = 'unicorn/venues/'
const ca = createActionFactory(prefix)

/* Action Creators */
export const searchVenues = createPromiseAction(`${prefix}SEARCH_VENUES`)
export const loadMore = ca('LOAD_MORE')

/**
 * These actions are originally created for Suburb to retain data when user clears input
 * after using full-search screen (which overrides venues data)
 */
export const saveResults = ca('SAVE_RESULTS')
export const loadSavedResults = ca('LOAD_SAVED_RESULTS')
export const clearSavedResults = ca('Clear_SAVED_RESULTS')

/* Reducer */
const initLists = {
  venues: [],
  menus: [],
  sites: [],
}

const initState = {
  ...initLists,
  page: 1,
  isLoading: true,
  save: null,

  // useful for determining if component/page has the right venues or is bootstrapped
  searchId: null,
}

const venuesReducer = handleActions(
  {
    [searchVenues]: (
      state,
      { payload: { searchId, overrideParams = {}, mergeParams = {} } = {} },
    ) => ({
      ...state,
      ...initLists,
      overrideParams,
      mergeParams,
      searchId,
      page: 1,
      isLoading: true,
    }),

    [searchVenues.fulfilled]: (state, { payload }) => ({
      ...state,
      ...initLists,
      isLoading: false,
      venues: Array.isArray(payload.venues)
        ? dedupeVenues(state.venues, payload.venues)
        : [],
      sites: Array.isArray(payload.sites)
        ? dedupeVenues(state.sites, payload.sites)
        : [],
      menus: Array.isArray(payload.menus)
        ? dedupeVenues(state.menus, payload.menus)
        : [],
    }),

    [loadMore]: state =>
      state.isLoading
        ? state
        : {
            ...state,
            isLoading: true,
            page: state.page + 1,
          },

    [saveResults]: state => ({
      ...state,
      save: pick(
        [
          'page',
          'venues',
          'sites',
          'menus',
          'status',
          'overrideParams',
          'mergeParams',
        ],
        state,
      ),
    }),

    [loadSavedResults]: state => ({
      ...state,
      ...state.save,
      save: null,
      isLoading: false,
    }),

    [clearSavedResults]: state => ({ ...state, save: null }),
  },
  initState,
)

export default venuesReducer

/**
 * Thunk Action Creators
 */

export const updateSearchRoute = ({ shouldRedir = true } = {}) => (
  dispatch,
  getState,
) => {
  dispatch({ type: 'unicorn/venues/UPDATE_SEARCH_ROUTE' }) // only for debugging purposes

  const { location: loc, ...history } = getHistory()
  const isRouteSearch =
    loc.pathname === '/search' ||
    matchPath(loc.pathname, '/:city/:suburbs/:cuisine')

  if (!isRouteSearch && !shouldRedir) return

  const state = getState()

  getLocationParams(state).then(locationParams => {
    const params = {
      ...locationParams,
      ...getCuisineParams(state),
    }

    const updatedRoute = createSearchRoute(params)
    const currentRoute = loc.pathname + loc.search

    if (currentRoute !== updatedRoute) {
      const historyFn = isRouteSearch ? history.replace : history.push
      historyFn(updatedRoute)
    }
  })
}

/**
 * Selectors
 */

export const selectSearchId = state => state.venues.searchId
export const selectVenues = state => state.venues.venues
export const selectSites = state => state.venues.sites
export const selectMenuSites = state => state.venues.menus
export const selectOverrideParams = state => state.venues.overrideParams
export const selectMergeParams = state => state.venues.mergeParams
export const selectSave = state => state.venues.save
export const selectIsLoading = state => state.venues.isLoading

export const selectNoMorePost = (state, types) => {
  const { overrideParams, page } = state.venues
  const isLoading = selectIsLoading(state)

  // could be venues/sites/menus
  const resultType = types.find(
    t => state.venues[t] && state.venues[t].length > 0,
  )

  const venues = resultType ? state.venues[resultType] : []
  const itemsPerPage =
    (overrideParams && overrideParams.itemsPerPage) || DEFAULT_ITEMS_PER_PAGE
  const expectedItemsCount = page * itemsPerPage

  return isLoading && venues.length ? false : venues.length < expectedItemsCount
}

export const selectSiteAndMenuSuggestions = data => {
  const empty = {
    sites: [],
    menus: [],
  }

  if (isUndefined(data) || isEmpty(data)) {
    return empty
  }

  const { venues } = data

  const resp = mapValues(list => {
    return list
      .filter(item => isPlainObject(item) && !isEmpty(item))
      .map(item => {
        const { name, tags = [], id, logoImage, profileImages, location } = item
        const distance = typeof location === 'object' && location.distance

        return {
          name,
          description: createDescription(distance, tags),
          url: `/restaurant/${id}/${slugify(name)}`,
          img: logoImage || (profileImages && profileImages[0]) || '',
        }
      })
  }, pick(['sites', 'menus'], venues))

  return isEmpty(resp) || !isPlainObject(resp) ? empty : resp
}

/**
 * Utility Functions
 */

const addDistanceUnit = km =>
  km >= 1 ? `${Math.round(km)}km` : `${Math.round(km * 1000)}m`

export const createDescription = (distance, tags) =>
  [
    distance !== 'null' && distance && addDistanceUnit(distance),
    tags &&
      tags.length &&
      tags
        .map(t => t.name.replace(/ /g, ' ')) // replace space with &nbsp;
        .join(' · '),
  ]
    .filter(x => x)
    .join(' - ')

/**
 * Create unique venues array.
 *
 * @param {Array} prev
 * @param {Array} next
 *
 * @returns {Array}
 */
export const dedupeVenues = (prev, next) => {
  // Handle the case if next is undefined.
  // Immidiately return prev array.
  if (!next) return prev

  // Handle if next array is empty
  // Immidiately return prev array.
  if (Array.isArray(next) && next.length === 0) return prev

  const venues = [...prev]
  const venueIds = prev.map(p => String(p.id)) // gets all the ids all at once.

  next.forEach(venue => {
    if (venue) {
      const index = venueIds.indexOf(String(venue.id))
      if (index !== -1) {
        venues[index] = Object.assign({}, venues[index], venue)
      } else if (venue) {
        venues.push(venue)
      }
    }
  })

  return venues
}
