import React from 'react'
import PT from 'prop-types'

import { paymentSourceToImg } from 'store/payment/payment.helpers'

import ConfirmModal from 'components.v2/Modal/ConfirmModal'

import {
  Source,
  SourceLeft,
  SourceRight,
  Card,
  CardLabel,
  SourceRightOptions,
  SourceOptionDelete,
  SourceOptionPrimary,
  SourceOptionDivider,
} from './styled'

import SourcesItemDropdown from './SourcesItemDropdown'

import { SOURCE_DELETE_CONFIRM, SOURCE_PRIMARY_CONFIRM } from 'const/confirm'
import { FZ_CLICK_TO_PAY_HOSTED_PAYMENT } from 'store/payment/const'

class SourcesListItem extends React.Component {
  state = {
    isConfirmOpen: false,
    isLoading: false,
    confirm: {},
  }

  get primaryAction() {
    return (
      !this.props.activated && (
        <React.Fragment>
          <SourceOptionPrimary onClick={this.onPrimary}>
            Make Primary
          </SourceOptionPrimary>
          { this.props.type !== FZ_CLICK_TO_PAY_HOSTED_PAYMENT &&
          <SourceOptionDivider> |</SourceOptionDivider>
          }
        </React.Fragment>
      )
    )
  }

  onCancel = () => {
    this.setState({
      isConfirmOpen: false,
      confirm: {},
    })
  }

  onDelete = event => {
    event && event.preventDefault()
    this.setState({
      isConfirmOpen: true,
      confirm: SOURCE_DELETE_CONFIRM,
    })
  }

  onPrimary = event => {
    event && event.preventDefault()
    this.setState({
      isConfirmOpen: true,
      confirm: SOURCE_PRIMARY_CONFIRM,
    })
  }

  onConfirm = async () => {
    this.setState({ isLoading: true })

    const { type } = this.state.confirm

    if (type === SOURCE_DELETE_CONFIRM.type) {
      await this.props.onDelete()
    }

    if (type === SOURCE_PRIMARY_CONFIRM.type) {
      await this.props.onPrimary()
    }

    this.setState({ isLoading: false, isConfirmOpen: false })
  }

  render() {
    const { type, description, activated } = this.props
    return (
      <Source>
        <SourceLeft>
          <Card src={paymentSourceToImg({ type, description })} />
          <CardLabel>{description}</CardLabel>
        </SourceLeft>
        <SourceRight>
          <SourceRightOptions>
            {!(activated && type === FZ_CLICK_TO_PAY_HOSTED_PAYMENT) && <SourcesItemDropdown
              isPrimary={activated}
              onDelete={this.onDelete}
              onPrimary={this.onPrimary}
              type={type}
            />}

            {this.primaryAction}
            { type !== FZ_CLICK_TO_PAY_HOSTED_PAYMENT && 
            <SourceOptionDelete onClick={this.onDelete}>
              Delete
            </SourceOptionDelete>
            }
          </SourceRightOptions>
        </SourceRight>
        <ConfirmModal
          {...this.state.confirm}
          isDisabled={this.state.isLoading}
          isLoading={this.state.isLoading}
          isOpen={this.state.isConfirmOpen}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        />
      </Source>
    )
  }
}

SourcesListItem.propTypes = {
  renderCard: PT.func,
  description: PT.string,
  onDelete: PT.func,
  onPrimary: PT.func,
}

export default SourcesListItem
