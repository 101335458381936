import get from 'lodash/get'
import {
  ERROR_PRIMARY_MESSAGE_EXPIRED,
  ERROR_SECONDARY_MESSAGE_EXPIRED,
  ERROR_PRIMARY_MESSAGE_INSUFFICIENT,
  ERROR_SECONDARY_MESSAGE_INSUFFICIENT,
  ERROR_PRIMARY_MESSAGE_ISSUE,
  ERROR_SECONDARY_MESSAGE_ISSUE,
  STRIPE_ERROR_CODE_EXPIRED_CARD,
  STRIPE_ERROR_CODE_CARD_DECLINED,
  STRIPE_DECLINE_CODE_INSUFFICIENT_FUNDS,
} from 'containers/CardAdder/const'

export const getStripeError = intent => {
  if (
    get(intent, 'error.code') === STRIPE_ERROR_CODE_CARD_DECLINED &&
    get(intent, 'error.decline_code') === STRIPE_DECLINE_CODE_INSUFFICIENT_FUNDS
  ) {
    return {
      title: `<b>${ERROR_PRIMARY_MESSAGE_INSUFFICIENT}</b>`,
      message: ERROR_SECONDARY_MESSAGE_INSUFFICIENT,
    }
  } else if (get(intent, 'error.code') === STRIPE_ERROR_CODE_EXPIRED_CARD) {
    return {
      title: `<b>${ERROR_PRIMARY_MESSAGE_EXPIRED}</b>`,
      message: ERROR_SECONDARY_MESSAGE_EXPIRED,
    }
  }

  return {
    title: `<b>${ERROR_PRIMARY_MESSAGE_ISSUE}</b>`,
    message: ERROR_SECONDARY_MESSAGE_ISSUE,
  }
}
