import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import CardForm from './CardForm'

const StripeForm = ({ addPaymentSource, handleClose, notify }) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CardForm
        stripe={stripe}
        elements={elements}
        addPaymentSource={addPaymentSource}
        handleClose={handleClose}
      />
    )}
  </ElementsConsumer>
)

export default StripeForm
