import styled from 'styled-components'
import { setDefault } from 'utils/css'
export const IconContainer = element => styled(`${element}`)`
  text-align: center;
  line-height: ${props => setDefault(props.height, 'inherit', 'px')};
  width: ${props => setDefault(props.width, 'auto', 'px')};
  height: ${props => setDefault(props.width, 'auto', 'px')};
  font-size: ${props => setDefault(props.size, 'auto', 'px')};
  color: ${props => props.theme.colors[props.color]};
`
