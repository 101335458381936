import { put, select } from 'redux-saga/effects'
import notif from 'services/notification'
import { takeFirst } from 'utils/sagaHelpers'
import { trends as trendsApi, getErrorMessage } from 'api'
import { requestTrends, selectTrends } from './trends'

export default [
  takeFirst(String(requestTrends), function * trendsTask ({ payload: verifCode, meta }) {
    try {
      const { data } = yield select(selectTrends)

      if (data) { return }

      const { data: { items } } = yield trendsApi.fetch()

      yield put(requestTrends.fulfilled({ payload: items }))
      meta.resolve()
    } catch (error) {
      yield put(requestTrends.rejected({ payload: getErrorMessage(error) }))
      notif.error({
        title: 'Failed to fetch trends',
        message: getErrorMessage(error),
        error
      })
      meta.reject(error)
    }
  })
]
