import { ErrorContainer, ErrorModal } from './styled'
import get from 'lodash/get'

const ActiveOrderPartyModal = ({
  handleClose,
  handleViewGroup,
  activeOrderParty,
  handleDismiss,
}) => (
  <ErrorModal isOpen onRequestClose={handleClose} noCloseButton>
    <ErrorContainer>
      <div className="title">You're already in a group order</div>
      <div className="subheader">
        You’re currently part of a group order at{' '}
        {get(activeOrderParty, 'vendorName')}. What would you like to do?
      </div>
      <div className="button-container">
        <button className="dismiss" onClick={handleDismiss}>
          Dismiss
        </button>
        <button className="view-group" onClick={handleViewGroup}>
          View group
        </button>
      </div>
    </ErrorContainer>
  </ErrorModal>
)

export default ActiveOrderPartyModal
