import React, { PureComponent } from 'react'
import PT from 'prop-types'

import { redactEmail, redactPhoneNumber } from 'utils/redact'
import {
  EditableRowWrapper,
  EditableRowContainer,
  EditableRowLabel,
  EditableRowGroup,
  EditableRowLink,
  EditableRowValue,
  EditableRowAction,
  EditableRowConfirmation,
} from './styled'
import Icon from 'components.v2/Icon/IconV2'

class EditableRow extends PureComponent {
  state = {
    confirmSent: false,
    isConfirmSending: false,
  }

  static defaultProps = {
    label: 'Label',
    actions: [
      {
        label: 'Edit',
        handler: () => {},
      },
    ],
    value: '',
    separator: '|',
    isEditing: false,
    actionRenderer: null,
    valueRenderer: null,
    labelRenderer: null,
    onCancel: () => {},
    secure: false,
    isConfirmed: true,
    onConfirm: () => {},
  }

  static propTypes = {
    label: PT.string,
    actions: PT.array,
    value: PT.any,
    separator: PT.string,
    isEditing: PT.bool,
    actionRenderer: PT.func,
    valueRenderer: PT.func,
    labelRenderer: PT.func,
    onCancel: PT.func,
    secure: PT.bool,
    children: PT.node,
    isConfirmed: PT.bool,
    onConfirm: PT.func,
  }

  renderEditableInput() {
    if (!this.props.isEditing) return null
    return <EditableRowValue>{this.props.children}</EditableRowValue>
  }

  renderValue() {
    if (this.props.isEditing) return null

    if (typeof this.props.valueRenderer === 'function') {
      return this.props.valueRenderer()
    }

    let redactedValue = this.props.value

    if (this.props.secure) {
      if (this.props.label === 'Email address')
        redactedValue = redactEmail(redactedValue)
      if (this.props.label === 'Mobile')
        redactedValue = redactPhoneNumber(redactedValue)
    }

    return (
      <EditableRowValue>
        <span>{redactedValue}</span>
      </EditableRowValue>
    )
  }

  handleItemClick = handler => event => {
    event.preventDefault()
    handler(event)
  }

  handleCancel = event => {
    event.preventDefault()
    this.props.onCancel(event)
  }

  handleSendConfirm = async event => {
    event.preventDefault()
    this.setState({ isConfirmSending: true })
    await this.props.onConfirm()
    this.setState({ confirmSent: true, isConfirmSending: false })
  }

  renderActions() {
    if (typeof this.props.actionRenderer === 'function') {
      return this.props.actionRenderer()
    }

    if (this.props.isEditing) {
      return (
        <EditableRowAction>
          <EditableRowLink href="#" onClick={this.handleCancel}>
            Cancel
          </EditableRowLink>
        </EditableRowAction>
      )
    }

    const actionLength = this.props.actions.length

    return (
      <EditableRowAction>
        {this.props.actions.map((action, i) => {
          let last = actionLength === i + 1 ? true : false
          return (
            <React.Fragment key={i}>
              <EditableRowLink
                href="#"
                onClick={this.handleItemClick(action.handler)}
              >
                {action.label}
              </EditableRowLink>
              {last ? '' : ` ${this.props.separator} `}
            </React.Fragment>
          )
        })}
      </EditableRowAction>
    )
  }

  renderLabel() {
    if (typeof this.props.labelRenderer === 'function') {
      return this.props.labelRenderer(this.props.label)
    }

    return (
      <EditableRowLabel isHidden={this.props.isEditing}>
        {this.props.label}
      </EditableRowLabel>
    )
  }

  renderConfirmation() {
    return (
      <EditableRowConfirmation>
        {this.state.confirmSent && <Icon src="Tick.svg" />}
        <p>
          {this.state.confirmSent
            ? "Check your inbox - we've sent you a confirmation email."
            : 'Help keep your account secure by confirming your email address'}
        </p>
        <button
          onClick={this.handleSendConfirm}
          disabled={this.state.isConfirmSending}
          style={{ visibility: this.state.confirmSent ? 'hidden' : 'initial' }}
        >
          {this.state.isConfirmSending ? 'Sending...' : 'Confirm now'}
        </button>
      </EditableRowConfirmation>
    )
  }

  render() {
    return (
      <EditableRowWrapper>
        <EditableRowContainer>
          {this.renderLabel()}
          <EditableRowGroup>
            {this.renderValue()}
            {this.renderEditableInput()}
            {this.renderActions()}
          </EditableRowGroup>
        </EditableRowContainer>
        {!this.props.isConfirmed && this.renderConfirmation()}
      </EditableRowWrapper>
    )
  }
}

export default EditableRow
