import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { getCuisines, fetchCuisines, getIsCuisinesLoaded } from 'store/cuisines'
import isNode from 'detect-node'

export default compose(
  connect(
    state => ({
      cuisines: getCuisines(state),
      isLoaded: getIsCuisinesLoaded(state),
    }),
    { fetchCuisines },
  ),

  lifecycle({
    bootstrap() {
      return this.props.fetchCuisines()
    },

    componentDidMount() {
      if (
        !isNode &&
        Array.isArray(this.props.cuisines) &&
        this.props.cuisines.length < 1
      ) {
        this.bootstrap()
      }
    },
  }),
)
