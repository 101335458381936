import isPlainObject from 'lodash/isPlainObject'
import config from 'app-config'

const getImageBase = url => {
  if (!url) return ''
  return url
    .replace(/https?:\/\//, '')
    .replace('//', '')
    .replace('static.heyyou.io/', '')
    .replace('static-dev.heyyou.io/', '')
}

/**
 *  https://cloudinary.com/blog/the_holy_grail_of_image_optimization_or_balancing_visual_quality_and_file_size#automatic_image_quality_fine_tuning
 */
const getCloudSettings = imageSettings => {
  const settings = {
    c_fill: 'c_fill',
    quality: 'eco',
    f_auto: 'f_auto',
    ...imageSettings,
  }
  return Object.keys(settings)
    .map(key => {
      if (key === 'width') {
        return `w_${settings[key]}`
      } else if (key === 'height') {
        return `h_${settings[key]}`
      } else if (key === 'quality') {
        return `q_auto:${settings[key]}`
      } else if (key === 'c_fill' || key === 'f_auto') {
        return key
      } else {
        return ''
      }
    })
    .filter(s => s)
    .join(',')
}

const getFullURL = (imageBase, settings = '') => {
  const settingsPath = settings ? `${settings}/` : ''
  return `//res.cloudinary.com/${
    config.CLOUDINARY
  }/image/upload/${settingsPath}${imageBase}`
}

const getImageURL = (imageURL, imageSettings) => {
  const imageBase = getImageBase(imageURL)

  if (!isPlainObject(imageSettings)) {
    return getFullURL(imageBase)
  }

  return getFullURL(imageBase, getCloudSettings(imageSettings))
}

export default getImageURL
