import injectableStyles from 'utils/injectableStyles'

import * as styledComponents from './styled'
import desktopContainerFactory from './DesktopContainer'

export const factory = injectableStyles(
  desktopContainerFactory,
  styledComponents,
)
export default factory()
