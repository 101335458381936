import React, { useRef, useState } from 'react'
import {
  Modal,
  Container,
  InviteHeader,
  InviteSubHeader,
  ContentRow,
  InviteButton,
  CancelButton,
  ImageContainer,
  InviteLink,
  Link,
} from './styled'
import asset from 'utils/asset'
import { hide, show, types } from 'store/modals'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Icon from 'components.v2/Icon/IconV2'
import PropTypes from 'prop-types'
import notif from 'services/notification'
import SocialButton from 'common/auth/Shared/SocialButtonV2'
import GoogleLogin from 'common/auth/Shared/GoogleLoginV2'
import styled from 'styled-components'
import media from 'utils/media'
import noScroll from 'no-scroll'
import withAuthState from 'enhancers/withAuthState'
import { trackEvent } from 'utils/firebase'
import { withRouter } from 'react-router'
import events from 'const/analytics-events'
import LINKS from 'const/links'

const ButtonSection = styled.div`
  margin-top: 32px;
  justify-content: center;
  gap: 16px;

  & > button,
  > div > button {
    width: 168px;
  }
`

export const ButtonIcon = styled.button`
  width: 88px;
  height: 56px;

  border: 1px solid #e6e6e6;
  border-radius: 12px;
  background: linear-gradient(0deg, #fafafa, #fafafa);

  &:active {
    background: #e6e6e6;
  }

  &:hover {
    background: rgb(0, 0, 0, 0.05);
  }
`

export const Title = styled.div`
  color: var(--Black, #000);
  text-align: center;
  /* Web - H3 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */

  ${media.phone`
    &.auth {
      font-size: 18px;
    }

  `}
`

const HorizontalBar = styled.div`
  width: 20%;
  height: 1px;
  background: #e6e6e6;
`

const TermsAndCondition = styled.div`
  margin-top: 32px;
  color: var(--Grey-2, #999);
  text-align: center;

  /* Mobile - Small */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  & > a {
    text-decoration: underline;
    cursor: pointer;
    color: var(--Grey-2, #999);
  }
`

const SUCCESS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  REGISTER_FORM: 'register-form',
}

export const SocialLogin = ({
  hide,
  trackEvent,
  history,
  inviteLink,
  show,
}) => {
  const handleSuccess = registrationType => () => {
    noScroll.off()
    hide()

    if (registrationType === SUCCESS.FACEBOOK) {
      trackEvent(events['SIGNUP_CLICK_FACEBOOK'])
    }

    if (registrationType === SUCCESS.GOOGLE) {
      trackEvent(events['SIGNUP_CLICK_GOOGLE'])
    }

    const partyCode = inviteLink.split('/').slice(-1)[0]

    history.push(`/party/${partyCode}`)
  }

  const handleEmailLoginSuccess = () => {
    noScroll.off()
    hide()

    const partyCode = inviteLink.split('/').slice(-1)[0]

    history.push(`/party/${partyCode}`)
  }

  return (
    <div className="w-full">
      <div className="flex flex-row justify-center items-center">
        <HorizontalBar className="mr-auto" />
        <Title className="auth">Continue with an account</Title>
        <HorizontalBar className="ml-auto" />
      </div>
      <ButtonSection className="flex">
        <SocialButton
          onSuccess={handleSuccess(SUCCESS.FACEBOOK)}
          provider="facebook"
        />
        <GoogleLogin onGoogleSignIn={handleSuccess(SUCCESS.GOOGLE)} />
        <ButtonIcon
          onClick={() => {
            show(types.LOGIN_V2, {
              handleSuccess: handleEmailLoginSuccess,
            })
          }}
        >
          <Icon src="GuestEmail.svg" />
        </ButtonIcon>
      </ButtonSection>
      <TermsAndCondition>
        By signing up you agree to our <br />
        <a href={LINKS.TERMS} target="_blank" rel="noopener">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={LINKS.PRIVACY} target="_blank" rel="noopener">
          Privacy Policy
        </a>
      </TermsAndCondition>
    </div>
  )
}

const OrderPartyModal = ({
  inviteLink,
  reinvite,
  isAuthenticated,
  trackEvent,
  hide,
  show,
  vendorName,
  leaderCustomerName,
  history,
  name,
}) => {
  const handleHide = () => {
    hide()
  }

  const [showInviteLink, toggleInviteLink] = useState(false)
  const linkRef = useRef()

  const copyInviteLink = () => {
    linkRef.current.select()
    document.execCommand('copy')
    notif.success({ title: 'Invite link copied to clipboard' })
  }

  return (
    <Modal isOpen onRequestClose={handleHide} small noCloseButton>
      <Container>
        <ImageContainer src={asset('images/OrderParty.jpeg')} />
        {!isAuthenticated ? (
          <div>
            <div>
              <InviteHeader>
                {leaderCustomerName
                  ? `Join ${leaderCustomerName.slice(0, -3)}'s group order?`
                  : name}
              </InviteHeader>
              <InviteSubHeader>
                Sign up to add your order to the group
              </InviteSubHeader>
            </div>
            <div>
              <ContentRow>
                <Icon src="orderParty/LocationPin.svg" />
                <div className="col text-left">
                  <div className="header">Ordering from</div>
                  <div className="subheader">{vendorName}</div>
                </div>
              </ContentRow>

              <ContentRow className="mt-15">
                <Icon src="CoinYellow.svg" />
                <div className="col text-left">
                  <div className="header">Everyone pays for themselves</div>
                </div>
              </ContentRow>
            </div>

            <SocialLogin
              hide={hide}
              show={show}
              trackEvent={trackEvent}
              history={history}
              inviteLink={inviteLink}
            />
          </div>
        ) : (
          <div>
            {showInviteLink ? (
              <InviteHeader>Share link to invite friends</InviteHeader>
            ) : (
              <div>
                <InviteHeader>Let's start a group order</InviteHeader>
                <InviteSubHeader>
                  Share the invite link & order together
                </InviteSubHeader>
              </div>
            )}
            {!showInviteLink && !reinvite && (
              <ContentRow>
                <Icon src="CoinYellow.svg" />
                <div className="col text-left">
                  <div className="header">Who's paying?</div>
                  <div className="subheader">Everyone pays for themselves</div>
                </div>
              </ContentRow>
            )}
            {(showInviteLink || reinvite) && (
              <InviteLink>
                <input value={inviteLink} readOnly ref={linkRef}></input>
                <Link className="ml-auto" onClick={copyInviteLink}>
                  <Icon
                    className="flex flex-row items-center"
                    src="CopyLink.svg"
                  />
                </Link>
              </InviteLink>
            )}
            {reinvite ? (
              <InviteButton onClick={handleHide}>Got it</InviteButton>
            ) : (
              <InviteButton
                onClick={() =>
                  showInviteLink
                    ? handleHide()
                    : toggleInviteLink(!showInviteLink)
                }
              >
                {showInviteLink ? 'Return to menu' : 'Invite Friends'}
              </InviteButton>
            )}

            {!showInviteLink && !reinvite && (
              <CancelButton onClick={handleHide}>Cancel</CancelButton>
            )}
          </div>
        )}
      </Container>
    </Modal>
  )
}

OrderPartyModal.propTypes = {
  hide: PropTypes.func,
}

export default compose(
  withAuthState,
  withRouter,
  connect(
    null,

    {
      show,
      hide,
      trackEvent,
    },
  ),
)(OrderPartyModal)
