import {
  login,
  selectError,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import notif from 'services/notification'
import get from 'lodash/get'
import { show, types } from 'store/modals'

import {
  withFormValidator,
  isRequired,
  isEmail,
  composeValidators as composeV,
} from 'utils/validator'

export default compose(
  connect(
    state => ({
      asyncError: selectError(state),
      isAuthenticating: selectIsAuthenticating(state),
    }),
    (dispatch, { onSuccess = () => {} }) => ({
      onSubmit: ({ email, password }) =>
        dispatch(login(email, password))
          .then(onSuccess)
          .then(() => notif.success({ title: 'Login successful' }))
          .catch(err => {
            notif.error({
              title: get(
                err,
                'response.data.status.message',
                'Login failed!test',
              ),
              hasCloseButton: true,
            })
          }),
      clearAsyncError: () => dispatch(clearError()),
      showForgotPassModal: () => dispatch(show(types.FORGOT)),
    }),
  ),

  withProps({
    onSubmitFail: () =>
      notif.error({
        title: 'Login failed',
        message: 'please check the form errors',
      }),
  }),

  withFormValidator({
    email: composeV(isRequired, isEmail)('Email'),
    password: isRequired('Password'),
  }),
)
