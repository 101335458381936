import { handleActions } from 'redux-actions'
import pick from 'lodash/fp/pick'
import { REHYDRATE } from 'redux-persist'

import createActionFactory from 'utils/createActionFactory'
import { searchVenues, updateSearchRoute } from 'pages/search/venues'
import * as gmaps from 'api/googleMaps'

const ca = createActionFactory('unicorn/location/')

/**
 * Action Creators
 */

const changeLocationInputPure = ca('CHANGE_LOCATION_INPUT')
export const setLocationDetails = ca('SET_LOCATION_DETAILS')
const fetchCurrentLocationPure = ca('FETCH_CURRENT_LOCATION')
const fetchCurrentLocationSuccess = ca('FETCH_CURRENT_LOCATION_SUCCESS')
const fetchCurrentLocationFailed = ca('FETCH_CURRENT_LOCATION_FAILED')
const fetchAutoCompleteSuccess = ca('FETCH_AUTOCOMPLETE_SUCCESS')
const selectAutoCompletePure = ca('SELECT_AUTOCOMPLETE')
const selectAutoCompleteSuccess = ca('SELECT_AUTOCOMPLETE_SUCCESS')

/**
 * Reducer
 */

const initialState = {
  inputValue: '',
  lat: null,
  lng: null,
  placeid: null,
  suburb: '',
  city: '',
  autocompleteItems: [],
  isDeterminingLocation: false,
  isCurrentLocation: false,

  /*
   * `locationName`'s difference to `inputValue` is that, it serves more like `selectedLocation`.
   * It is useful for allowing pages to keep record of last selected location even if the
   * inputValue changes.
   */
  locationName: '',
}

export const persistBlacklist = [
  'inputValue',
  'autocompleteItems',
  'isDeterminingLocation',
]

const locationReducer = handleActions(
  {
    [REHYDRATE]: (state, { payload }) =>
      payload && payload.locationName
        ? {
            ...state,
            inputValue: handleStringBool(payload.isCurrentLocation)
              ? 'Current Location'
              : payload.locationName,
          }
        : state,

    [changeLocationInputPure]: (state, { payload }) => ({
      ...state,
      inputValue: payload,
      autocompleteItems: [],
    }),

    [fetchCurrentLocationPure]: state => ({
      ...state,
      isDeterminingLocation: true,
    }),

    [fetchCurrentLocationSuccess]: (
      state,
      { payload: { locationName, lat, lng } },
    ) => ({
      ...state,
      locationName,
      lat,
      lng,
      city: '',
      suburb: '',
      inputValue: 'Current Location',
      placeid: null,
      autocompleteItems: [],
      isDeterminingLocation: false,
      isCurrentLocation: true,
    }),

    [fetchCurrentLocationFailed]: state => ({
      ...state,
      isDeterminingLocation: false,
    }),

    [fetchAutoCompleteSuccess]: (state, { payload }) => ({
      ...state,
      autocompleteItems: payload,
    }),

    [selectAutoCompletePure]: (state, { payload }) => {
      const {
        structured_formatting: { main_text: locationName },
        place_id: placeid,
      } = state.autocompleteItems.find(x => x.id === payload)

      return {
        ...state,
        placeid,
        locationName,
        inputValue: locationName,
        lat: null,
        lng: null,
        city: '',
        suburb: '',
        isCurrentLocation: false,
      }
    },

    [selectAutoCompleteSuccess]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),

    [setLocationDetails]: (
      state,
      {
        payload: {
          locationName = '',
          lat,
          lng,
          placeid,
          city,
          suburb,
          isCurrentLocation,
        } = {},
      },
    ) => ({
      ...state,
      city,
      suburb,
      lat,
      lng,
      placeid,
      locationName,
      isCurrentLocation: handleStringBool(isCurrentLocation),
      inputValue: handleStringBool(isCurrentLocation)
        ? 'Current Location'
        : locationName,
    }),
  },
  initialState,
)

/**
 * Thunk Action Creators
 */

export const changeLocationInput = (input, disableAutocomplete) => dispatch => {
  dispatch(changeLocationInputPure(input))

  if (!input || disableAutocomplete) return

  gmaps
    .fetchAutocompleteItems(input)
    .then(autocompleteItems =>
      dispatch(fetchAutoCompleteSuccess(autocompleteItems)),
    )
    .catch(console.error)
}

export const fetchCurrentLocation = () => dispatch => {
  dispatch(fetchCurrentLocationPure())

  gmaps
    .fetchCurrentLocationDetails()
    .then(({ locationName, lat, lng }) => {
      dispatch(fetchCurrentLocationSuccess({ locationName, lat, lng }))
      dispatch(updateSearchRoute())
      dispatch(searchVenues())
    })
    .catch(err => {
      dispatch(fetchCurrentLocationFailed())
      console.error(err)
    })
}

export const selectAutoComplete = autocompleteId => (dispatch, getState) => {
  dispatch(selectAutoCompletePure(autocompleteId))
  const placeid = getState().location.placeid

  gmaps.fetchLocationDetails({ placeid }).then(details => {
    dispatch(selectAutoCompleteSuccess(details))
    dispatch(updateSearchRoute())
    dispatch(searchVenues())
  })
}

/**
 * Selectors
 */

/**
 * Note: returns promise that resolves into location params. It returns promise because when called
 * while lat-lng is still being fetched, we need to wait for it
 */
export const getLocationParams = state => {
  // TODO: prevent unnecessary re-requests (which happens when called latLng is still loading)

  const isLocationSelected = getIsLocationSelected(state)

  if (!isLocationSelected) {
    return Promise.resolve({})
  }

  const locParams = pick(
    [
      'lat',
      'lng',
      'placeid',
      'locationName',
      'isCurrentLocation',
      'city',
      'suburb',
    ],
    state.location,
  )

  const isParamsSufficient = locParams.lat && locParams.lng

  if (isParamsSufficient) return Promise.resolve(locParams)
  else
    return gmaps
      .fetchLocationDetails({ placeid: locParams.placeid })
      .then(details => ({ ...locParams, ...details }))
}

// Determine if a location is selected, either from autocompleteItem or currentLocation
export const getIsLocationSelected = ({
  location: { lat, lng, placeid, locationName },
}) => !!(lat && lng && locationName) || !!(placeid && locationName)

export const getLocationName = state => state.location.locationName
export const selectIsCurrentLocation = state => state.location.isCurrentLocation

export default locationReducer

/**
 * Internal Functions
 */
const handleStringBool = x =>
  x === 'true' ? true : x === 'false' ? false : !!x
