import React from 'react'

const Check = props => (
  <svg width="1em" height="1em" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4203 2.00628C13.0786 1.66457 12.5246 1.66457 12.1829 2.00628L4.11134 10.0778L1.84382 7.8103C1.50211 7.46859 0.948087 7.46859 0.606379 7.8103C0.26467 8.15201 0.264671 8.70603 0.60638 9.04774L3.42498 11.8663C3.80466 12.246 4.42024 12.246 4.79991 11.8663L5.31918 11.3471C5.32204 11.3442 5.32442 11.3411 5.32631 11.3377C5.3283 11.3357 5.33028 11.3338 5.33225 11.3318L13.4203 3.24372C13.762 2.90201 13.762 2.34799 13.4203 2.00628Z"
    />
  </svg>
)

export default Check
