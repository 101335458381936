import React from 'react'
import times from 'lodash/times'
import { compose, withProps, withPropsOnChange, withState } from 'recompose'
import { isOneDigitNumber } from 'utils/regex'

export default compose(
  withProps(() =>
    ({
      inputRefs: times(4, React.createRef)
    })),

  withState('_value', '_setValue', p => p.value),

  withPropsOnChange(['_value', 'value', 'inputRefs'], ({
    value,
    onChange,
    name,
    _value,
    _setValue,
    inputRefs
  }) => {
    const handleChange = i => ev => {
      const newVal = ev.target.value

      if (newVal && !isOneDigitNumber.test(newVal)) { return }

      const nextInput = inputRefs[i + 1]
      const nextInputInst = nextInput && nextInput.current
      const updatedValue = updateString(i, newVal, _value)
      const cb = () => {
        if (nextInputInst) {
          nextInputInst.focus()
        } else {
          onChange({ target: { name, value: updatedValue } })
        }
      }

      _setValue(updatedValue, cb)
    }

    return {
      handleChange,
      handleFocus: i => () => {
        const indexToFocus = Math.min(_value.length, 3)

        if (i !== indexToFocus) {
          inputRefs[indexToFocus].current.focus()
          return
        }
        if (value !== _value) {
          onChange({ target: { name, value: _value } })
        }
      },
      handleKeyDown: i => ev => {
        const isBackspace = ev.keyCode === 8
        const prevInput = inputRefs[i - 1]
        const hasValue = _value[i]

        // if cursor is at the start, delete value as if the cursor is at the end
        if (hasValue && isBackspace) {
          ev.preventDefault()
          handleChange(i)({ target: { value: '' } })
        }

        if (!hasValue && isBackspace && prevInput) {
          const prevInputInst = prevInput.current
          const cb = () => prevInputInst.focus()
          _setValue(updateString(i - 1, '', _value), cb)
        }
      }
    }
  })
)

const updateString = (i, newVal, value) =>
  times(4, i => value[i] || '')
    .map((v, j) => j === i ? newVal : v)
    .join('')
