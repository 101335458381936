import styled from 'styled-components'
import Icon from 'components/Icon.v2'
import media from 'utils/media'
import BaseModal from 'components/Modal/BaseModal'

export const Modal = styled(BaseModal)`
  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    position: relative;
    border-radius: 3px;
    padding-top: 40px;
    width: calc(100% - 60px);
    max-width: 400px;
  }

  .StripeElement {
    margin: 10px 20px 4px;
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid #cfd7df;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    ${media.phone`
      &.Details {
        font-size: 16px !important;
      }
    `}

    &.Details {
      display: block;
      width: calc(100% - 40px);
      margin: 10px 20px 20px;
      font-family: sans-serif;
      font-size: 0.9em;

      &::placeholder {
        color: #757575;
        opacity: 1;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        color: #cfd7df;
      }
    }
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .stripe-form-label {
    margin: 20px;
  }

  .card-errors {
    color: #fa755a;
    font-size: 12px;
    margin: 0 20px 20px;
  }
`

/**
 * NOTE: the height and max-height properties below are hard-coded as we have no means to
 * dynamically resize the iframe. Remember to update this values whenever the size of iframe's
 * content changes
 */
export const ModalIframe = styled.iframe`
  display: block;
  margin: 30px 0 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 369px;
  max-height: calc(100vh - 90px);
  background-color: transparent;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
`

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const ModalCloseButtonIcon = styled(Icon).attrs({
  type: 'close',
  size: 24,
  fill: '#999999',
})``

export const ModalHeading = styled.h2`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;

  ${media.screen`
    font-size: 18px;
  `}
`

export const ModalLoader = styled.div`
  margin-bottom: 20px;
`
