import { select, put, takeLatest } from 'redux-saga/effects'

import notif from 'services/notification'
import * as api from 'api/ads'

import { fetchAds } from './ads'
import { set as setCity, selectCity } from './city'

export default [
  takeLatest(fetchAds, function* fetchAdsTask({ meta: { resolve } }) {
    try {
      const { geo } = yield select(selectCity)
      const ads = yield api.fetchAds(geo)
      yield put(fetchAds.fulfilled({ payload: ads }))
      resolve()
    } catch (error) {
      yield put(fetchAds.rejected({ payload: error }))
      notif.error({
        title: 'Error',
        message: `Failed to fetch ads`,
        error,
      })
      resolve()
    }
  }),
  takeLatest(setCity, function* updateAdsTask({
    payload,
    meta: { resolve, reject },
  }) {
    yield put(fetchAds())
  }),
]
