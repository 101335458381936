import styled from 'styled-components'

import media from 'utils/media'

export const HeaderContainer = styled.header`
  position: ${props => (props.isFixed ? 'fixed' : 'relative')};
  z-index: ${props => (props.isFixed ? 1 : '999')};
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  ${media.screen`
    padding: 0 15px;
  `};
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 78px;

  & #dummy-profile {
    display: none;
  }

  .dummy-authed & #dummy-profile {
    display: block;
  }

  .dummy-authed & #dummy-profile + * {
    display: none;
  }
`

export const RightSection = styled.div`
  display: flex;
  flex-direction: row;
`
