import createActionFactory from 'utils/createActionFactory'
import { handleActions } from 'redux-actions'

const ca = createActionFactory('unicorn/cuisines/')

export const fetchCuisines = ca('FETCH_CUISINES')
export const fetchCuisinesFulfilled = ca('FETCH_CUISINES_FULFILLED')

const initState = {
  isLoading: false,
  isLoaded: false,
  items: [],
}

export const getCuisines = state => state.cuisines.items
export const getIsCuisinesLoading = state => state.cuisines.isLoading
export const getIsCuisinesLoaded = state => state.cuisines.isLoaded

export default handleActions(
  {
    [fetchCuisines]: state => ({ ...state, isLoading: true }),
    [fetchCuisinesFulfilled]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      items: payload,
    }),
  },
  initState,
)
