import React from 'react'
import PropTypes from 'prop-types'

import { H2, H3 } from 'components/Text'
import { MENU, MULTIVENDOR_MENU } from '../const'
import { withRouter } from 'react-router-dom'
import Action from './Action'
import events from 'const/analytics-events'
import withAnalytics from 'enhancers/withAnalytics'
import { authCanny } from 'api'
import get from 'lodash/get'

import {
  ProfileModalContainer,
  AvatarSection,
  Avatar,
  LinkAction,
  LinksSection,
  Divider,
  Logout,
} from './styled'

const ProfileLink = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_PROFILE'],
  },
])(LinkAction)

const ActionItem = withAnalytics()(props => {
  return <Action {...props} />
})

class ProfileModal extends React.Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen(this.hide)
  }

  componentWillUnmount() {
    this.unlisten()
    this.hide()
  }

  hide = () => {
    const { isOpen, toggleOpen } = this.props
    if (isOpen) toggleOpen()
  }

  state = {
    ssoToken: '',
  }

  async componentDidMount() {
    authCanny().then(data => {
      this.setState({
        ssoToken: get(data, 'ssoToken', ''),
      })
    })
  }

  render() {
    const {
      firstname,
      lastname,
      image,
      logout,
      isOpen,
      color,
      isMultiVendor,
    } = this.props

    return (
      <ProfileModalContainer isOpen={isOpen}>
        <AvatarSection>
          <Avatar avatar={image} color={color} />
          <H2>{`${firstname} ${lastname || ''}`}</H2>
          <ProfileLink to="/account/profile">EDIT PROFILE</ProfileLink>
        </AvatarSection>
        <LinksSection>
          {(isMultiVendor ? MULTIVENDOR_MENU : MENU).map(menu => (
            <ActionItem
              {...menu}
              key={menu.analytics}
              analytics={[
                {
                  eventType: 'onClick',
                  options: events[`HEADER_${menu.analytics}`],
                },
              ]}
            />
          ))}
        </LinksSection>
        <Divider />
        <Logout onClick={logout}>
          <H3 color="grey">Log out</H3>
        </Logout>
      </ProfileModalContainer>
    )
  }
}

ProfileModal.propTypes = {
  toggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  image: PropTypes.string,
  logout: PropTypes.func,
}

export default withRouter(ProfileModal)
