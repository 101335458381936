import React from 'react'
import styled from 'styled-components'
import media from 'utils/media'
import asset from 'utils/asset'
import { Player } from '@lottiefiles/react-lottie-player'

const MaintenanceDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
`

const SvgContainer = styled.div`
  ${media.screen`
    gap: 100px;
  `}
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  align-items: center;
`

const TextContainer = styled.div`
  ${media.phone`
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  `}

  width: 548px;
  margin-top: 40px;

  font-family: Montserrat;
  font-style: normal;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 12px;

  & > .title {
    color: var(--Black, #000);

    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  & > .subtitle {
    color: var(--Grey-1, #666);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

const RocketContainer = styled.div`
  position: relative;
  background-image: url(${p => p.src});
  background-size: contain;
  width: 200px;
  height: 200px;
`
const Maintenance = () => {
  return (
    <React.Fragment>
      <MaintenanceDiv>
        <SvgContainer>
          <img src={asset('images/icons/svg/Heyyou.svg')} />
          <RocketContainer
            src={asset('images/icons/svg/maintenance-circle.svg')}
          >
            <Player
              autoplay
              keepLastFrame
              loop
              src={asset('json/Rocket.json')}
              style={{ height: '200px' }}
            />
          </RocketContainer>
        </SvgContainer>
        <TextContainer>
          <div className="title">We’re sprucing things up!</div>
          <div className="subtitle">
            Brief interlude as we buff and polish your Hey You experience. We’ll
            be sparkling again very soon, with even more to offer - check back
            soon!
          </div>
        </TextContainer>
      </MaintenanceDiv>
    </React.Fragment>
  )
}

export default Maintenance
