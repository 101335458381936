// From https://developer.mozilla.org/en-US/docs/Web/Events/resize#requestAnimationFrame_customEvent
export default (type, name, obj) => {
  obj = obj || window
  let running = false

  obj.addEventListener(type, () => {
    if (running) { return }
    running = true
    window.requestAnimationFrame(function () {
      obj.dispatchEvent(new window.CustomEvent(name))
      running = false
    })
  })
}
