import { connect } from 'react-redux'
import { compose, withProps, lifecycle } from 'recompose'
import { setGuestCheckoutEmail } from 'store/guest-checkout'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import {
  selectError,
  selectIsAuthenticating,
  clearError,
} from 'auth/modules/auth'
import {
  withFormValidator,
  isRequired,
  isEmail,
  isMobileNum,
  isName,
  composeValidators as composeV,
} from 'utils/validator'
import { selectSelectedServiceType } from 'pages/order/modules/order'
import { DELIVERY } from 'pages/order/const'
import notif from 'services/notification'
import { getQueryParams } from 'utils/url'
import { isWhiteLabel } from 'utils/whiteLabel'
import { isMultiVendor } from 'utils/multiVendor'

export default compose(
  withRouter,
  connect(
    (state, props) => {
      const getVenueIdFromHistory = () => {
        const idMatches = /(?<vendorId>[0-9]+)/gim.exec(
          get(props, 'history.location.pathname', ''),
        )
        return get(idMatches, 'groups.vendorId', 0)
      }
      const venueId = get(props, 'match.params.id') || getVenueIdFromHistory()

      return {
        serviceType: selectSelectedServiceType(state, venueId),
        asyncError: selectError(state),
        isAuthenticating: selectIsAuthenticating(state),
      }
    },

    (dispatch, { onSuccess = () => {}, history }) => ({
      onSubmit: ({ ...fields }) => {
        if (get(fields, 'serviceType') === DELIVERY) {
          notif.error({
            message: 'Sorry, delivery is not supported for guest orders.',
          })

          return
        }

        dispatch(setGuestCheckoutEmail({ ...fields }))
        onSuccess()
        const qstr = getQueryParams(history.location.search)
        const isGfoOrder =
          get(qstr, 'channel') === 'gfo_redirect' &&
          !(isWhiteLabel() || isMultiVendor())
        history.push(
          `${get(history, 'location.pathname')}?${
            isGfoOrder ? 'channel=gfo_redirect&' : ''
          }reload-cart=true`,
        )
      },
      clearAsyncError: () => dispatch(clearError()),
    }),
  ),

  withProps(({ serviceType }) => ({
    initValues: {
      serviceType: serviceType,
    },
  })),

  withFormValidator({
    firstName: composeV(isRequired, isName)('First name'),
    lastName: composeV(isRequired, isName)('Last name'),
    email: composeV(isRequired, isEmail)('Email'),
    mobile: composeV(isRequired, isMobileNum)('Mobile'),
    serviceType: null,
  }),

  lifecycle({
    componentDidUpdate(prev) {
      if (get(this.props, 'serviceType') !== get(prev, 'serviceType')) {
        this.props.handleHidden({
          name: 'serviceType',
          value: this.props.serviceType,
        })
      }
    },
  }),
)
