import { handleActions } from 'redux-actions'
import { createPromiseAction as cpa } from 'utils/asyncActions'
import camelCase from 'lodash/camelCase'

export default (PREFIX, NAME_SPACE) => {
  const request = cpa(`${PREFIX}REQUEST_${NAME_SPACE}`)
  const reset = cpa(`${PREFIX}RESET_${NAME_SPACE}`)
  const resetError = cpa(`${PREFIX}RESET_${NAME_SPACE}_ERROR`)
  const initState = { data: null, isLoading: false, error: null }
  const reducer = handleActions({
    [reset]: state => initState,
    [request]: state => ({ ...state, isLoading: true, error: null }),
    [resetError]: state => ({ ...state, error: null }),
    [request.fulfilled]: (state, { payload }) => ({ data: payload, isLoading: false, error: null }),
    [request.rejected]: (state, { payload }) => ({ data: null, isLoading: false, error: payload })
  }, initState)

  return {
    [camelCase(`REQUEST_${NAME_SPACE}`)]: request,
    [camelCase(`RESET_${NAME_SPACE}`)]: reset,
    [camelCase(`RESET_${NAME_SPACE}_ERROR`)]: resetError,
    [camelCase(NAME_SPACE)]: reducer
  }
}
