import React, { createContext, useContext, useState } from 'react'
import isNode from 'detect-node'

export const RoktLauncherContext = createContext(null)

export function useRoktLauncher() {
  return useContext(RoktLauncherContext)
}

export function RoktLauncherContextProvider({
  children,
  accountId,
  sandbox = false,
}) {
  // Do not use hook on node
  if (isNode) {
    return (
      <RoktLauncherContext.Provider>{children}</RoktLauncherContext.Provider>
    )
  }

  const [launcher, setLauncher] = useState(null)

  if (!launcher) {
    const target = document.head || document.body
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://apps.rokt.com/wsdk/integrations/launcher.js'
    script.fetchpriority = 'high'
    script.crossOrigin = 'anonymous'
    script.async = true

    target.appendChild(script)

    const launcherPromise = new Promise((resolve, reject) => {
      script.onload = () => {
        const launcherCreated = window.Rokt.createLauncher({
          accountId,
          sandbox,
        })
        resolve(launcherCreated)
        return launcherCreated
      }
      script.onerror = error => reject(error)
    })

    setLauncher(launcherPromise)
  }
  // Return the context provider
  return (
    <RoktLauncherContext.Provider value={launcher}>
      {children}
    </RoktLauncherContext.Provider>
  )
}
